import BreadCrumbs from '@/components/BreadCrumbs'
import { QuoteReportFilter } from './QuoteReportFilter'
import QuoteReportTable from './QuoteReportTable'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { quoteReportFilterAtom } from '@/store/reports'

export const QuotesReport = () => {
    const setInvoiceReportFilter = useSetAtom(quoteReportFilterAtom)
    
    useEffect(() => {
        setInvoiceReportFilter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Quotes' />
            <QuoteReportFilter />
            <QuoteReportTable />
        </div>
    )
}
