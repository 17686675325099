import BreadCrumbs from '@/components/BreadCrumbs'
import JobDetails from './JobDetails'
import JobLineItems from './LineItems'
import JobTimeEntry from './TimeEntry'
import JobExpenses from './Expenses'
import { useQuery } from '@tanstack/react-query'

import { useParams } from 'react-router-dom'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { getJobCompletionReportById } from '@/api/reports'

const ViewJobCompletionRecord = () => {
    const { jobId } = useParams()

    const { data: job, isLoading: isJobLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobCompletionReportById(jobId ?? ''),
    })

    if (!job) {
        return null
    }

    if (isJobLoading) {
        return 'Loading...'
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='View Crew' pathId={job.jobId} label='View Crew' />
            <div className='rounded-none'>
                <JobDetails job={job} />
                <JobLineItems quote={job.quote} />
                <JobTimeEntry job={job} />
                <JobExpenses quote={job.quote} />
            </div>
        </div>
    )
}

export default ViewJobCompletionRecord
