import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogTitle } from '@/components/AlertDialog'
import { Button } from '../Button'
import mapIcon from '@/assets/private/map.svg'

interface GeolocationWarningModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    onEnableLocation: () => void
}

const GeolocationWarningModal: React.FC<GeolocationWarningModalProps> = ({
    open,
    setOpen,
    onEnableLocation
}) => {

    const handleClose = () => {
        setOpen(false)
        sessionStorage.setItem('geolocationWarningShown', 'true');
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogContent className='bg-white h-[320px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10 text-center'>
                    <div className='overflow-auto'>
                        <div className='flex flex-col items-center'>
                            <img src={mapIcon} className='w-[60px] h-[60px]' />
                        </div>
                        <AlertDialogTitle className='text-center font-semibold text-2xl text-zentive-green-dark mt-2.5'>
                            Allow "Zentive" to Access your Location?
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-center text-md text-black-500 mt-5'>
                            To provide you with an accurate time format, Zentive requires access to your location.
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='m-0 bg-gray-100 rounded-b-[5px] w-full flex flex-row justify-center items-center gap-2 px-6'>
                        <Button
                            variant='outline'
                            onClick={() => handleClose()}
                            className='h-11 w-full font-semibold'
                        >
                            Continue without Location Access
                        </Button>
                        <Button
                            onClick={onEnableLocation}
                            className='h-11 w-full font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Test Location Access Permission
                        </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default GeolocationWarningModal
