import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { CompleteInprogressJobType } from '@/api/job/schema'
import { ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { toast } from '@/hooks/useToast'
import dayjs from 'dayjs'
import { formatToCustomerTime, formatToUTCWithOffset } from '@/utils/time'
import { useAtomValue } from 'jotai'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'

interface ICompleteJobModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    jobId: string
    updateJobStatusMu: (data: CompleteInprogressJobType) => void
}

const CompleteJobModal: React.FC<ICompleteJobModal> = ({ open, setOpen, jobId, updateJobStatusMu }) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const handleSave = () => {
        const currentDate = dayjs()

        const formattedCurrentDate = formatToCustomerTime(
            currentDate.toString(),
            timezone?.timeZoneId,
        )
    
        const endDate = formatToUTCWithOffset(
            formattedCurrentDate,
            timezone?.timeZoneId,
            ISO_8601_WITH_UTC_OFFSET,
             "General Format"
        )
        
        updateJobStatusMu({
            jobId: jobId ?? '',
            date: endDate,
        })

        setOpen(false)
        toast({
            description: 'Successfully Completed Job',
            variant: 'default',
        })
    }
    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px]'
        >
            <div className=''>
                <div className='flex gap-5 px-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <h1 className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Complete Job
                        </h1>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            Completing this job via this channel will lead to incomplete data for this job's invoice. You can manually add the missing data before saving the invoice later.
                        </p>
                        <p className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to complete this job?
                        </p>
                    </div>
                </div>
                <div className='mt-6 flex justify-end gap-x-4 bg-gray-300 py-6 px-6'>
                    <Button
                        variant='ghost'
                        className='w-97 h-11 text-base font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                    >
                        Yes, please
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default CompleteJobModal
