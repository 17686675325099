import { validateSchedule } from '@/api/appointment'
import { ValidateScheduleType } from '@/api/appointment/schema'
import { getJobById, rescheduleJob } from '@/api/job'
import { JobStatusType, RescheduleJobType, rescheduleJobSchema } from '@/api/job/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form } from '@/components/Forms'
import {
    APPOINTMENT_TYPES,
    BREADCRUMBS_PADDING_STYLE,
    ISO_8601_WITH_UTC_OFFSET,
    JOB_STATUS_LABEL,
    getJobStatusClasses,
} from '@/constants'
import { useToast } from '@/hooks/useToast'
import { AssignCrew } from '@/pages/common/Schedule/Job/AssignCrew'
import CalendarComponent from '@/pages/common/Schedule/Job/FullCalendar'
import { OneOffJobSchedule } from '@/pages/common/Schedule/Job/OneOffJobSchedule'
import { editJobAtom } from '@/store/job'
import { cn, formatRecordNumber } from '@/utils/helper'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import JobExpenses from '../ViewJob/Expenses'
import InternalNotesAndAttachments from '../ViewJob/InternalNotesAndAttachments'
import JobDetails from '../ViewJob/JobDetails'
import JobLineItems from '../ViewJob/LineItems'
import useValidateFormTime from '@/hooks/useValidateFormTime'
import { formatToUTCWithOffset, formatToCustomerTime } from '@/utils/time'
import dayjs from 'dayjs'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import JobCrewMemberModalResched from '@/components/JobCrewMemberModalResched'

const RescheduleJob = () => {
    const { jobId } = useParams()
    const queryClient = useQueryClient()
    const setIsEditing = useSetAtom(editJobAtom)
    const navigate = useNavigate()
    const { toast } = useToast()
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const [crewMemberModal, setCrewMemberModal] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const { data: job, isLoading: isJobLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })
    const rescheduleJobMethods = useForm<RescheduleJobType>({
        defaultValues: {
            jobId: job?.jobId,
            appointment: {
                ...job?.appointment,
                appointmentType: APPOINTMENT_TYPES.ONEOFF_JOB,
                startDateTime: dayjs()
                    .hour(9)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
                endDateTime: dayjs()
                    .hour(17)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
            },
            description: job?.description,
            jobImages: [],
            crewId: job?.crew?.crewId,
        },
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: zodResolver(rescheduleJobSchema),
    })

    const jobsStatus = job?.status as JobStatusType

    const {
        setValue,
        watch,
        handleSubmit,
        clearErrors,
        setError,
        formState: { errors, isValid },
    } = rescheduleJobMethods
    const appointmentType = watch('appointment.appointmentType')
    const crewId = watch('crewId')
    const startDT = watch('appointment.startDateTime')
    const endDT = watch('appointment.endDateTime')
    const availableAnytime = watch('appointment.availableAnytime')
    const repeat = watch('appointment.repeat')

    const {
        mutateAsync: validateScheduleMu,
        data: isScheduleAvailable,
        isPending: validatePending,
    } = useMutation<boolean, AxiosError, ValidateScheduleType>({
        mutationFn: validateSchedule,
    })
    const { mutate: rescheduleJobMu, isPending } = useMutation({
        mutationFn: (data: RescheduleJobType) => rescheduleJobFunc(data),
        onSuccess: () => {
            toast({
                description: 'Successfully rescheduled!',
                variant: 'default',
            })

            navigate(`/schedule/jobs/${job?.jobId}`)
        },
        onError: () => {
            toast({
                description: 'Unable to reschedule job.',
                variant: 'destructive',
            })
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById', job?.jobId || ''] })
        },
    })

    const isEmpty = (obj: any) => {
        return Object.keys(obj).length === 0
    }

    useEffect(() => {
        validateScheduleMu({
            crewId: crewId as string,
            startDateTime: formatToUTCWithOffset(
                formatToCustomerTime(startDT, timezone?.timeZoneId),
                timezone?.timeZoneId,
            ),
            endDateTime: formatToUTCWithOffset(
                formatToCustomerTime(endDT, timezone?.timeZoneId),
                timezone?.timeZoneId,
            ),
            repeat: repeat,
            appointmentType: appointmentType,
            availableAnytime: availableAnytime,
        })
    }, [crewId, startDT, endDT, appointmentType, availableAnytime])

    const onSubmit = (data: RescheduleJobType) => {
        setSubmitting(true)

        if (crewId) {
            rescheduleJobMu(data)
        } else {
            setCrewMemberModal(true)
        }
    }

    const rescheduleJobFunc = async (data: RescheduleJobType) => {
        const formatData = {
            ...data,
            appointment: {
                ...data.appointment,
                startDateTime: formatToUTCWithOffset(
                    formatToCustomerTime(data.appointment.startDateTime, timezone?.timeZoneId),
                    timezone?.timeZoneId || 'Asia/Singapore',
                    ISO_8601_WITH_UTC_OFFSET,
                ),
                endDateTime: formatToUTCWithOffset(
                    formatToCustomerTime(data.appointment.endDateTime, timezone?.timeZoneId),
                    timezone?.timeZoneId || 'Asia/Singapore',
                    ISO_8601_WITH_UTC_OFFSET,
                ),
            },
        }

        await rescheduleJob(formatData)
    }

    if (!job) {
        return null
    }

    if (isJobLoading) {
        return 'Loading...'
    }

    const formattedJobNumber = `Job Order #${formatRecordNumber(job?.jobNumber ?? 0)}`

    useValidateFormTime<RescheduleJobType>({
        endDateTime: endDT,
        endField: 'appointment.endDateTime',
        startDateTime: startDT,
        startField: 'appointment.startDateTime',
        clearErrors,
        setError,
        additionalDeps: [appointmentType, errors],
    })


    console.log("🚀 ~ RescheduleJob ~   !isEmpty(errors):",   !isEmpty(errors))
    console.log("🚀 ~ RescheduleJob ~ submitting:", submitting)
    console.log("🚀 ~ RescheduleJob ~ validatePending:", validatePending)
    console.log("🚀 ~ RescheduleJob ~ !isValid:", !isValid)
    console.log("🚀 ~ RescheduleJob ~ !isScheduleAvailable:", !isScheduleAvailable)
    console.log("🚀 ~ RescheduleJob ~ isPending:", isPending)


    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={formattedJobNumber} pathId={job.jobId} label='Edit Job' />
            <div className='bg-[#f1f1f1] p-4 border-t-8 border-t-zentive-green-dark rounded-none'>
                <div className='flex border-b border-b-gray-200 p-4 justify-between'>
                    <h1 className='text-2xl font-bold mt-2'>{formattedJobNumber}</h1>
                    <p
                        className={cn(
                            'text-base py-2 px-4 rounded-3xl',
                            getJobStatusClasses(job?.status as string),
                        )}
                    >
                        {job?.status && JOB_STATUS_LABEL[jobsStatus]}
                    </p>
                </div>
                <Form {...rescheduleJobMethods}>
                    <JobCrewMemberModalResched
                        open={crewMemberModal}
                        setOpen={setCrewMemberModal}
                        submit={rescheduleJobMu}
                        methods={rescheduleJobMethods as UseFormReturn<RescheduleJobType>}
                        onClose={() => setSubmitting(false)}
                    />
                    <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data'>
                        <JobDetails job={job} />

                        <div className='bg-[#e7e7e7] px-4 py-4 mt-4 border-t-8 border-[#d6d6d6]'>
                            <section className='flex flex-row gap-5 mb-5'>
                                <OneOffJobSchedule />
                                <CalendarComponent />
                            </section>
                            <AssignCrew
                                schedAvailable={!!isScheduleAvailable}
                                rsdCrewId={job?.crew?.crewId}
                            />
                            <JobLineItems quote={job.quote} />
                            {/* {job?.crew ? (
                                    <JobTimeEntry job={job} />
                                ) : (
                                    
                                )} */}

                            <JobExpenses quote={job.quote} />
                        </div>

                        <InternalNotesAndAttachments job={job} />

                        <div className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] py-4 px-6'>
                            <div className='flex space-x-4'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-97 mr-3 text-zentive-green-dark font-semibold'
                                    onClick={() => {
                                        navigate(-1)
                                        setIsEditing(false)
                                        setValue('description', '')
                                        setValue('crewId', '')
                                        setValue('jobImages', [])
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    className='w-97 mr-5'
                                    disabled={
                                        isPending ||
                                        !isScheduleAvailable ||
                                        !isValid ||
                                        validatePending ||
                                        !isEmpty(errors) ||
                                        submitting
                                    }
                                >
                                    Update
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default RescheduleJob
