import { Button } from '@/components/Button'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE, FEATURE_RESTRICTIONS } from '@/constants'
import { useNavigate } from 'react-router-dom'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import RoutingTable from './RoutingTable'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { useQuery } from '@tanstack/react-query'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { useState } from 'react'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import { AxiosError } from 'axios'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { getSubscriptions } from '@/api/profile'
import { DowngradeNotification } from '@/components/DowngradeNotification'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const Routing = () => {
    const navigate = useNavigate()

    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)

    const user = useViewedAsUser()

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['routingFeatureSubscription', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.liveTracking,
            ),
    })

    const { data: subscriptionsData } = useQuery<unknown, AxiosError, SubscriptionsInfoType[]>({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(user?.customerId as string),
        enabled: !!user?.customerId,
    })

    const isFirstPlan: boolean = (subscriptionsData?.length ?? 0) <= 1

    const handleRouteValidation = (nav: string) => {
        setRestrictionModal(!isFeatureAvailable)
        if (isFeatureAvailable) {
            navigate(nav)
        }
    }

    console.log(featureLoading)

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <div className='flex flex-row gap-5'>
                <BreadCrumbs titleName='Routing' />
                {!isFeatureAvailable && !featureLoading && (
                    <div>
                        <UpgradeBadge />
                    </div>
                )}
            </div>

            {!isFirstPlan && !isFeatureAvailable && <DowngradeNotification />}

            <div className='flex items-start text-left justify-between mt-10 bg-white py-5 px-5 rounded-xl mb-10'>
                <span className='my-auto text-zentive-blue-dark font-semibold text-2xl'>
                    Routes
                </span>
                <div className='flex flex-row'>
                    <Button
                        type='button'
                        onClick={() => navigate('/customers/customer-messaging')}
                        className='border bg-zentive-blue-dark text-white tracking-wide font-normal hover:text-zentive-green-light mr-2'
                        disabled={featureLoading}
                    >
                        Send a Message
                    </Button>

                    <Button
                        type='button'
                        onClick={() =>
                            handleRouteValidation('/schedule/routing/route-optimization')
                        }
                        className='border border-zentive-green-dark tracking-wide bg-white text-zentive-green-dark font-semibold hover:text-zentive-green-light mr-2'
                        disabled={featureLoading}
                    >
                        <FaArrowRotateLeft className='mr-1' />
                        Route Optimization
                    </Button>

                    <Button
                        type='button'
                        onClick={() => handleRouteValidation('/schedule/routing/map')}
                        className='border bg-zentive-green-dark text-zentive-green-light tracking-wide font-normal hover:text-zentive-green-light'
                        disabled={featureLoading}
                    >
                        <HiOutlineLocationMarker className='mr-1' />
                        Create Job Route
                    </Button>
                </div>
            </div>
            <div className=' border-t-2 w-full'></div>
            <RoutingTable isFieldRestricted={!isFeatureAvailable && !featureLoading} />
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}

export default Routing
