import { APPOINTMENT_TYPES, RECURRING_TYPES } from '@/constants'
import 'dayjs/plugin/isSameOrBefore'
import { ZodObject, ZodRawShape, z } from 'zod'
import { profileSchema } from '../profile/schema'

const baseAppointmentSchema = z.object({
    appointmentId: z.string().optional(),
    appointmentType: z.enum([APPOINTMENT_TYPES.ONEOFF_JOB, APPOINTMENT_TYPES.RECURRING]),
    repeat: z
        .enum([
            RECURRING_TYPES.MONTHLY,
            RECURRING_TYPES.WEEKLY,
            RECURRING_TYPES.YEARLY,
            RECURRING_TYPES.BI_WEEKLY,
            RECURRING_TYPES.DAILY,
        ])
        .optional(),
    duration: z.string().optional(),
    jobId: z.string().optional(),
    startDateTime: z.string(),
    status: z.string().optional(),
    occurrences: z.number().optional(),
    endDateTime: z.string(),
    availableAnytime: z.boolean(),
    timeZone: z.string().optional(),
})

export const updateAppointmentTimeSchema = z.object({
    jobId: z.string(),
    crewId: z.string(),
    startDateTime: z.string(),
    endDateTime: z.string(),
    appointmentType: z.string(),
})

export const appointmentSchema = baseAppointmentSchema.extend({
    profile: profileSchema.optional(),
    crewId: z.string().optional(),
})

export const refineAppointmentSchema = <T extends ZodRawShape>(schema: ZodObject<T>) => {
    return schema
}

export type ValidateScheduleType = z.infer<typeof appointmentSchema>
export type UpdateAppointmentTimeType = z.infer<typeof updateAppointmentTimeSchema>
