import { axiosInstance } from '../axiosInstance'

export const createStripeSubscription = async (
    userId: string,
    changePlan: boolean,
): Promise<string> => {
    const res = await axiosInstance.post(
        `stripe/subscription?profileID=${userId}&changePlan=${changePlan}`,
    )

    return res.data.content
}
