import { getJobsByInvoiceId } from '@/api/invoicing'
import { CustomerProfileType } from '@/api/profile/schema'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'
import { US_FORMAT } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'

interface IHeaderClientDetails {
    profile: CustomerProfileType
    status?: string
    invoiceNumber?: number
    sharedNotes?: string
    quoteNumber?: number
    quoteId?: string
    dateSent?: string
    invoiceId: string
}

const HeaderClientDetails = ({
    profile,
    status,
    invoiceNumber,
    sharedNotes,
    quoteNumber,
    quoteId,
    dateSent,
    invoiceId
}: IHeaderClientDetails) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const { data: jobByQuoteId } = useQuery({
        
        enabled: !!quoteId,
        queryKey: ['jobByQuoteId', quoteId, invoiceId],
        queryFn: () => getJobsByInvoiceId(quoteId as string, invoiceId),
    })
    return (
        <div className='flex flex-row justify-between gap-x-10'>
            <div className='flex flex-col mx-5 mt-2'>
                <p className='text-2xl font-bold leading-none tracking-tight'>Invoice for</p>
                <div className='text-zentive-green-darker font-semibold text-2xl mt-5'>
                    {profile?.firstName} {profile?.lastName}
                </div>
                <div className='flex flex-col'>
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-col whitespace-no-wrap pr-5'>
                            <div className='pt-6'>
                                <span className='font-bold text-base'>Property Address</span>
                            </div>
                            <div className='whitespace-nowrap'>
                                <p className='text-zentive-gray-medium'>
                                    {profile?.address?.streetAddress}
                                </p>
                                <p className='text-zentive-gray-medium'>
                                    {profile?.address?.city}, {profile?.address?.state}{' '}
                                </p>
                                <p className='text-zentive-gray-medium'>
                                    {profile?.address?.zipCode}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-col mb-4'>
                            <span className='font-bold pt-6 text-base'>Contact Details</span>
                            <p className='text-zentive-gray-medium'>{profile?.phoneNumber}</p>
                            <p className='text-zentive-gray-medium'>{profile?.email}</p>
                        </div>
                    </div>
                    <div className=' max-w-[700px] md:w-[400px] lg:w-[400px]'>
                        <p className='text-base mt-5 font-bold'>Shared Notes:</p>
                        <p className='border-2 h-24 break-words rounded-md overflow-hidden text-ellipsis overflow-y-auto'>
                            {sharedNotes ?? 'No notes attached to display'}
                        </p>
                    </div>

                    {/* <div className='flex flex-row'>
                        <div className='font-semibold text-base mr-4 pt-6'> Status: </div>{' '}
                        <InvoiceStatusBar status={status as string} />
                    </div> */}
                </div>
            </div>
            <div className='flex flex-col'>
                <div>
                    <div className='flex flex-row'>
                        <p className='text-2xl font-bold'>Invoice Number: &nbsp;</p>
                        <p className='text-2xl font-bold border-2 rounded-md pr-20 py-0'>
                            {' '}
                            000{invoiceNumber}
                        </p>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col border p-4 my-2'>
                        <p className='text-base font-bold'>Invoice Details</p>
                        <div className='flex flex-row justify-between border-b py-1'>
                            <p>Quote Number</p>
                            <p>{`00${quoteNumber}`}</p>
                        </div>
                        {jobByQuoteId?.content?.jobNumber && (
                            <div className='flex flex-row justify-between border-b py-1'>
                                <p>Job Number</p>
                                <p>{`00${jobByQuoteId?.content?.jobNumber}`}</p>
                            </div>
                        )}
                        <div className='flex flex-row justify-between border-b py-1'>
                            <p>Date Sent</p>
                            <p>{dayjs(dateSent).tz(timezone.timeZoneId).format(US_FORMAT)}</p>
                        </div>
                        <div className='flex flex-row'>
                            <div className='font-semibold text-base mr-4 pt-6'> Status: </div>{' '}
                            <InvoiceStatusBar status={status as string} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
