import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { StickyNoteIcon, XIcon } from 'lucide-react'
import { useState } from 'react'

interface ExpenseNotesProps {
    notes: string[]
}

export const ExpenseNotes: React.FC<ExpenseNotesProps> = ({ notes }) => {
    const [open, setIsOpen] = useState<boolean>(false)

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen} key={'expense-notes'}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button type='button'>
                    <StickyNoteIcon className='h-5 w-5 text-zentive-green-dark' />
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-5 pb-7 max-w-[38rem]'>
                <div className='flex flex-col gap-5 px-5'>
                    <AlertDialogTitle className='flex flex-row justify-between text-left font-semibold text-lg text-zentive-black pb-0'>
                        <p className='text-2xl font-bold text-left'>Notes</p>
                        <div>
                            <button onClick={() => setIsOpen(!open)}>
                                <XIcon />
                            </button>
                        </div>
                    </AlertDialogTitle>

                    <div className=' flex flex-col'>
                        {notes ? (
                            notes.map((note: string, i: number) => (
                                <>
                                    <div
                                        className='p-5 border border-zentive-gray-light border-1'
                                        key={i}
                                    >
                                        <p>{note}</p>
                                    </div>
                                </>
                            ))
                        ) : (
                            <div className='p-5 border border-zentive-gray-light border-1 text-center'>
                                <p>Notes are empty.</p>
                            </div>
                        )}
                    </div>
                </div>
            </AlertDialogContent>
        </AlertDialog>
    )
}
