import { useFormContext } from 'react-hook-form'

import { AppRichTextBox } from '@/components/Common/AppRichTextbox'
import { EmailRequestServiceParamType } from '@/api/service/schema'

export const RichTextEditorWrapper = () => {
    const { setValue } = useFormContext<EmailRequestServiceParamType>()

    const onBodyChange = (val: string) => {
        const textOnly = val
            .replace(/<[^>]*>/g, '') // Remove all HTML tags
            .replace(/"/g, '')
            .trim()

        if (textOnly.length > 0) {
            const modifiedValue = val
                .replace(/<p><\/p>/g, '<br>')
                .replace(/\\/g, '')
                .replace(/<p\s*style\s*=\s*"text-align:\s*center"\s*><\/p>/g, '<br>')
                .replace(/<p\s*style\s*=\s*"text-align:\s*right"\s*><\/p>/g, '<br>')
            setValue('body', modifiedValue)
        } else {
            setValue('body', '')
        }
    }

    return (
        <div className='space-y-4 mt-[36px]'>
            <p className='text-zentive-green-dark font-semibold text-[16px] mb-[10px]'>
                Service Request
            </p>
            <AppRichTextBox
                name='email'
                placeholder='Please enter your service request here...'
                onChange={(val) => onBodyChange(val)}
            />
        </div>
    )
}
