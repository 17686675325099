import { InvoiceListRecordType } from '@/api/customer/schema'
import {
    Dialog,
    DialogContent,
    DialogOverlay,
    DialogPortal,
    DialogTrigger,
} from '@/components/Dialog'
import { TableCell } from '@/components/Table'
import { FiFileText } from 'react-icons/fi'
import InvoiceDialogHeader from './InvoiceDialogHeader'
import InvoiceDialogContent from './InvoiceDialogContent'
import InvoiceDialogFooter from './InvoiceDialogFooter'

interface InvoiceListTypeProps {
    invoiceList: InvoiceListRecordType
}

const InvoiceDialog = ({ invoiceList }: InvoiceListTypeProps) => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <TableCell className='flex justify-center  '>
                    <button key='note-icon'>
                        <FiFileText className='h-4 w-4 cursor-pointer text-indigo-900' />
                    </button>
                </TableCell>
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className=' max-h-[700px] max-w-[900px] w-[900px] rounded-md overflow-y-auto no-scrollbar'>
                        <InvoiceDialogHeader invoiceList={invoiceList} />
                        <InvoiceDialogContent invoiceList={invoiceList} />
                        <InvoiceDialogFooter />
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}
export default InvoiceDialog
