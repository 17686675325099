import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { updateServiceAvailability } from '@/api/service'
import { useAtom } from 'jotai'
import { businessServiceTypeAtom } from '@/store/customer'
import { ProductServiceType } from '@/api/service/schema'
import { useState } from 'react'
import { Switch } from '@/components/Switch'

type UpdateServiceAvailabilityModalProps = {
    service: ProductServiceType
}

const UpdateServiceAvailabilityModal = (props: UpdateServiceAvailabilityModalProps) => {
    const { toast } = useToast()
    const [businessServiceType, setBussinessServiceType] = useAtom(businessServiceTypeAtom)
    const queryClient = useQueryClient()
    const [open, setOpen] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(true)

    const { productServiceId = '', isAvailable } = props.service

    const { mutate: updateProductAvailabilityMu, isPending: updateLoading } = useMutation({
        mutationFn: () => updateServiceAvailability(productServiceId, isChecked),
        onSuccess: () => {
            toast({
                description: 'Product availability has been successfully updated',
                variant: 'default',
            })
            const updatedServiceTypes = businessServiceType?.filter(
                (service) => service.productServiceId !== props.service.productServiceId,
            )
            setBussinessServiceType(updatedServiceTypes! != null ? updatedServiceTypes : [])
            queryClient.invalidateQueries({ queryKey: ['productServiceList'] })
            setOpen(false)
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'

            if (err.request.status === 409) {
                const responseData = err.response?.data as { message: string } | undefined
                const errorToastMessage = responseData?.message || defaultErrMessage

                toast({
                    description: `${errorToastMessage
                        .charAt(0)
                        .toUpperCase()}${errorToastMessage.slice(1)}`,
                    variant: 'default',
                })
            } else {
                toast({
                    description: defaultErrMessage,
                    variant: 'destructive',
                })
            }
        },
    })

    const handelOnCheckedChange = (isChecked: boolean) => {
        if(isChecked) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }

    return (
        <AlertDialog open={open} onOpenChange={() => setOpen(!open)}>
            <AlertDialogTrigger>
                <Switch
                    checked={isAvailable}
                    className='w-9'
                    onCheckedChange={(checked) => handelOnCheckedChange(checked)}
                    disabled={updateLoading}
                />
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px] capitalize'>
                            Update Availability for {props.service.name}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            This action updates the availability of this product/service. Unavailable products/service
                            will not show when creating a job, quote or invoice.
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel
                            className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                updateProductAvailabilityMu()
                            }}
                            className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Update Availability
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default UpdateServiceAvailabilityModal
