import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import _ from 'lodash'
import { CreateExpenseAccountType, expenseAccountSchema } from '@/api/expense_account/schema'
import { createExpenseAccount, getNextExpenseAccountNumber } from '@/api/expense_account'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'

const CreateNewExpenseAccount = () => {
    const navigate = useNavigate()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const updateExpenseAccountMethods = useForm<CreateExpenseAccountType>({
        mode: 'onSubmit',
        resolver: zodResolver(expenseAccountSchema),
    })

    const { data: nextExpenseAccountCode, isLoading } = useQuery({
        queryKey: ['nextExpenseAccountCode', user],
        queryFn: () => getNextExpenseAccountNumber(user?.businessId as string),
    })

    const { control, setValue, handleSubmit, watch } = updateExpenseAccountMethods

    const isFormDirty = useCallback(() => {
        if (watch().expenseAccountName === undefined || watch().expenseAccountName === '') {
            return true
        } else {
            return false
        }
    }, [user?.businessId])

    const { mutate: createExpenseAccountMu, isPending: createLoading } = useMutation({
        mutationFn: (data: CreateExpenseAccountType) => createExpenseAccount(data),
        onSuccess: () => {
            navigate('/settings/expense-accounts')
            toast({
                description: 'Successfully Saved',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['expenseAccounts'] })
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'
            navigate('/settings/expense-accounts')
            if (err.request.status === 409) {
                const responseData = err.response?.data as { message: string } | undefined
                const errorToastMessage = responseData?.message || defaultErrMessage
                toast({
                    description: `${errorToastMessage
                        .charAt(0)
                        .toUpperCase()}${errorToastMessage.slice(1)}`,
                    variant: 'default',
                })
            } else {
                toast({
                    description: defaultErrMessage,
                    variant: 'destructive',
                })
            }
        },
    })

    useEffect(() => {
        setValue('expenseAccountCode', nextExpenseAccountCode as number)
        setValue('businessId', user?.businessId as string)
    }, [user?.businessId, isLoading])

    const onSubmit = (data: CreateExpenseAccountType) => {
        createExpenseAccountMu(data)
    }

    const handleCancelClick = () => {
        navigate('/settings/expense-accounts')
    }
    return (
        <section>
            <div>
                <BreadCrumbs titleName='Add Items' />
            </div>
            <div className='w-full py-16 justify-center bg-white rounded-md my-10'>
                <Form {...updateExpenseAccountMethods}>
                    <form className='flex flex-col w-full items-start justify-start text-zentive-black text-base pl-16 space-y-10 whitespace-nowrap'>
                        <div>
                            <FormField
                                control={control}
                                name='expenseAccountCode'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className='font-sans pl-1 text-base h-11 w-full border  text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                type='number'
                                                placeholder='Account Code'
                                                disabled
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div>
                            <FormField
                                control={control}
                                name='expenseAccountName'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className='font-sans pl-1 text-base h-11 w-[400px] max-w-[400px border text-gray-900 rounded-sm'
                                                type='text'
                                                placeholder='Account Name'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='w-full flex flex-row gap-x-4 justify-end pr-10'>
                            <Button
                                variant='secondary'
                                onClick={() => handleCancelClick()}
                                className='w-28 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    createLoading ||
                                    isFormDirty() ||
                                    watch().expenseAccountName.trim().length === 0
                                }
                                onClick={handleSubmit(onSubmit)}
                                className='w-28 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </section>
    )
}

export default CreateNewExpenseAccount
