import {
    ProfileBusinessType,
    ProfileSelfResponseType,
    profileBusinessSchema,
} from '@/api/business/schema'
import goTrueClient from '@/api/goTrueClient'
import { getProfile, getProfileById, updateProfile } from '@/api/profile'
import { Avatar, AvatarComponent, AvatarFallback } from '@/components/Avatar'
import { Button } from '@/components/Button'
import ImageUploader, { IFile } from '@/components/Common/ImageUploader'
import { Form } from '@/components/Forms'
import { useToast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { getFirstChar } from '@/utils/helper'
import { extractUserMetaData, getSession } from '@/utils/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { Session } from '@supabase/gotrue-js'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import AddressInput from '@/components/CompleteAddress'
import { BusinessInput } from '@/components/BusinessInput'
import { FullProfileType } from '@/api/profile/schema'
import dayjs from 'dayjs'
import { imageUploadErrorAtom } from '@/store/error'
import { BusinessInformationInput } from '@/components/BusinessInformationInput'
import { ROLE } from '@/constants'

const PersonalInformationForm = () => {
    const { toast } = useToast()

    const [selectedImage, setSelectedImage] = useState<IFile[]>([])
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [user, setUser] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const firstNameInitial = getFirstChar(user?.firstName)

    const lastNameInitial = getFirstChar(user?.lastName)
    const imageProfileError = useAtomValue(imageUploadErrorAtom)
    const defaultValues = {
        profileId: user?.profileId,
        addressId: user?.addressId,
        businessId: user?.businessId,
        business: {
            businessName: user?.business?.businessName,
            businessWebsite: user?.business?.businessWebsite,
            createdAt: dayjs(user?.createdAt).format('MMMM DD, YYYY'),
        },
        status: user?.status,
        isPasswordGenerated: !!user?.isPasswordGenerated,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        address: {
            streetAddress: user?.address.streetAddress,
            streetTwoAddress: user?.address.streetTwoAddress || '',
            city: user?.address.city,
            state: user?.address.state,
            zipCode: user?.address.zipCode,
        },
    }

    const updateMethods = useForm<ProfileBusinessType>({
        defaultValues,
        mode: 'onChange',
        resolver: zodResolver(profileBusinessSchema),
    })

    const {
        handleSubmit,
        setValue,
        formState: { isSubmitSuccessful, isValid },
        watch,
        reset,
        unregister,
    } = updateMethods

    const isSame = JSON.stringify(watch()) === JSON.stringify(defaultValues)
    const profileImage = watch('profileImage')

    const handleError = (err: AxiosError) => console.error(err.message)

    const successfulUpdateToast = () =>
        toast({
            description: 'Profile updated successfully',
            variant: 'default',
        })

    const { mutate: getProfileMu } = useMutation({
        mutationFn: getProfile,
        onSuccess: (res) => {
            setUser(res)
            successfulUpdateToast()
        },
        onError: handleError,
    })

    const { mutate: getProfileByIdMu } = useMutation<FullProfileType, AxiosError, string>({
        mutationFn: getProfileById,
        onSuccess: (data: FullProfileType) => {
            setUser(data)
            successfulUpdateToast()
        },
    })

    const { mutate: updateProfileMu, isPending: isLoading } = useMutation({
        mutationFn: updateProfile,
        onSuccess: (res: ProfileSelfResponseType) => {
            isViewedAsOwner
                ? getProfileByIdMu(res.profileId)
                : goTrueClient
                      .updateUser({
                          data: extractUserMetaData(getSession() as Session, res),
                      })
                      .then(() => {
                          getProfileMu()
                      })
        },
        onError: handleError,
    })

    const onSubmit = (data: ProfileSelfResponseType) => updateProfileMu(data)

    useEffect(() => {
        if (isSubmitSuccessful) {
            // Keep updated values on the form
            // reset isSubmitted state from true to false
            reset(undefined, {
                keepValues: true,
                keepIsSubmitted: false,
            })

            // NO NEED TO INCLUDE the profile image on the default values
            // If a NEW profile image is uploaded, unregister it after successful submission
            // We unregister it from the form so that isSame will work without the profileImage
            if (profileImage) unregister('profileImage')
        }
    }, [isSubmitSuccessful, profileImage])

    useEffect(() => {
        if (!isViewedAsOwner) {
            reset({ ...defaultValues })
            setSelectedImage([])
        }
    }, [isViewedAsOwner])

    return (
        <Form {...updateMethods}>
            <form className='relative w-full bg-white' onSubmit={handleSubmit(onSubmit)}>
                <div className='w-full px-[26px] pt-[35px] pb-[56px]'>
                    <section className='w-full flex flex-col ml-6 gap-[12px] items-center'>
                        <Avatar className='w-[74px] h-[74px]'>
                            <AvatarComponent user={user ?? null} selectedImage={selectedImage} />
                            <AvatarFallback>{firstNameInitial + lastNameInitial}</AvatarFallback>
                        </Avatar>
                        <ImageUploader
                            onFilesChange={(data) => {
                                setSelectedImage(data)
                                setValue('profileImage', data[0]?.file)
                            }}
                            maxSize={4.9}
                            accept='.png,.jpeg,.jpg'
                            label='Change photo'
                            labelClassname='text-zentive-blue-dark text-decoration-line: underline'
                        />
                    </section>
                    <section className='w-full space-y-[28px]'>
                        <BusinessInput />
                        {user?.role?.roleName !== ROLE.CO_OWNER && (
                            <>
                                <AddressInput />
                                <BusinessInformationInput />
                            </>
                        )}
                    </section>
                </div>

                <div className='py-5 md:py-0 bg-[#EBEBEB] left-0 w-full md:h-[75px] rounded-b-[8px] flex items-center justify-end px-[26px]'>
                    <Button
                        className='w-[95px] h-[43px] text-white font-semibold bg-zentive-green-dark  rounded-[5px] text-[16px] hover:bg-zentive-green-medium'
                        disabled={isLoading || !isValid || isSame || !!imageProfileError}
                        type='submit'
                    >
                        Update
                    </Button>
                </div>
            </form>
        </Form>
    )
}

export default PersonalInformationForm
