import { Switch } from '@/components/Switch'
import { useFormContext } from 'react-hook-form'

export const ScheduleToggle = () => {
    const { setValue, getValues } = useFormContext()

    return (
        <div className='mt-4 ml-4 flex flex-row border border-zentive-gray-light border-1 p-5 bg-zentive-green-bg_light justify-between'>
            <p className='font-bold text-base'>Schedule</p>

            <div className='flex flex-row justify-between gap-3'>
                <p className='text-zentive-black font-semibold'>Available Anytime</p>
                <Switch
                    className='mt-[.20rem]'
                    onCheckedChange={(val: boolean) => setValue('availableAnytime', val)}
                    checked={getValues('availableAnytime')}
                />
            </div>
        </div>
    )
}
