import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, numberFormatter } from '@/utils/helper'
import { ProductServiceGroupType, QuoteType } from '@/api/quoting/schema'

const TABLE_HEADER = ['Name', 'Quantity', 'Unit Price', 'Markup %', 'Cost']

interface IJobLineItems {
    quote: QuoteType
}

const JobLineItems = ({ quote }: IJobLineItems) => {
    const total = quote?.productServiceGroup?.reduce(
        (sum, productService) => sum + (productService.cost || 0),
        0,
    )

    return (
        <div className='flex flex-col mt-4 gap-x-2 bg-white  px-6'>
            <div className='flex flex-row justify-between w-full px-6'>
                <h1 className='text-lg font-semibold mt-5 mb-2'>Product/Service</h1>
            </div>
            <div className='flex flex-row justify-between w-full px-2'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader style={{ marginBottom: '10px' }}>
                        <TableRow>
                            {TABLE_HEADER?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row items-center text-center font-semibold text-base text-zentive-gray-medium',
                                        {
                                            'text-right': header === 'Cost',
                                            'items-start text-start w-[25%]': header === 'Name',
                                        },
                                    )}
                                >
                                    <div className='inline-flex gap-[14px] whitespace-nowrap'>
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {quote &&
                            quote?.productServiceGroup &&
                            quote?.productServiceGroup?.map((services: ProductServiceGroupType) => (
                                <TableRow
                                    key={services.productServiceId}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='whitespace-nowrap'>
                                        <p className='text-base font-semibold capitalize'>{services.name}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='text-base text-center'>
                                            {services.quantity && services.quantity > 0 ? services.quantity : 1}
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='text-base text-center'>
                                            {services.cost
                                                ? '$' + numberFormatter.format(services.unitPrice)
                                                : '$' + '0.00'}
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='text-base text-center'>
                                            {services.cost
                                                ? '$' +
                                                  (
                                                      (((services.quantity
                                                          ? services.quantity
                                                          : 1) * services.unitPrice) *
                                                          (services.markup ? services.markup : 0)) /
                                                      100
                                                  ).toFixed(2)
                                                : '$' + '0.00'}
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p className='text-base text-right'>
                                            {services.cost
                                                ? '$' + numberFormatter.format(services.cost)
                                                : '$' + '0.00'}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            ))}
                        <TableRow className='whitespace-nowrap hover:bg-transparent'>
                            <TableCell className='whitespace-nowrap'>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-base'> </p>
                            </TableCell>
                            <TableCell>
                                <p className='text-right text-base font-semibold'>
                                    {total && '$' + numberFormatter.format(total)}
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default JobLineItems
