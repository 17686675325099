// Write any auth-related managed states here.
import { atomWithStorage } from 'jotai/utils'
import { atom } from 'jotai'
import { Session } from '@supabase/gotrue-js'
import { SubscriptionType } from '@/api/subscription/schema'
import { FullProfileType } from '@/api/profile/schema'
import { getSession } from '@/utils/user'
import { AuthChangeEvent } from '@supabase/gotrue-js'
import { TimeZoneResult } from '@/hooks/useGeocode'

export const authEventAtom = atomWithStorage<AuthChangeEvent>(
    'authEvent',
    'PASSWORD_RECOVERY',
    undefined,
    {
        getOnInit: true,
    },
)

export type StatusType = 'ACT' | 'INA' | 'DEL'

export const timezoneAtom = atomWithStorage<TimeZoneResult | null>('timezone', null, undefined, {
    getOnInit: true,
})

export const isAddressSelectedAtom = atom<boolean>(false)

export const isPlanSelected = atom<boolean>(false)

export const isViewedAsAtom = atomWithStorage<boolean>('isViewedAsAtom', false, undefined, {
    getOnInit: true,
})

export const isViewedDismissedAtom = atom<boolean>(true)

export const rememberMeAtom = atomWithStorage<boolean>('rememberMe', false, undefined, {
    getOnInit: true,
})

export const rememberedEmailAtom = atomWithStorage<string>('rememberedEmail', '', undefined, {
    getOnInit: true,
})

export const businessIdAtom = atomWithStorage<string>('businessIdAtom', '', undefined, {
    getOnInit: true,
})

export const sessionAtom = atomWithStorage<Session | null>(
    'supabase.auth.token',
    getSession(),
    undefined,
    {
        getOnInit: true,
    },
)

export const signUpGalleryAtom = atom<SubscriptionType[] | undefined>(undefined)

export const userAtom = atomWithStorage<FullProfileType | null>(
    'user',
    JSON.parse(localStorage.getItem('user') || 'null'),
)

export const userCoordinatesAtom = atom<{
    lat: number
    lng: number
}>({
    lat: 0,
    lng: 0,
})

export const viewedAsUserAtom = atomWithStorage<FullProfileType | null>(
    'viewedAsUser',
    null,
    undefined,
    {
        getOnInit: true,
    },
)

export const ownerFreeTrialEndedForCrewAtom = atomWithStorage<boolean>(
    'ownerFreeTrialEndedForCrew',
    false,
    undefined,
    {
        getOnInit: true,
    },
)

export const couponValidityAtom = atom<boolean>(true)
