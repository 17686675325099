import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { ISO_8601_WITH_UTC_OFFSET, JOB_STATUS, JOB_STATUS_LABEL } from '@/constants'
import { Checkbox } from '@/components/Checkbox'
import { useAtom, useSetAtom } from 'jotai'
import { jobPaginationAtom, jobStartFilterAtom, jobStatusFilterAtom } from '@/store/job'
import { JobStatusType, JobFilterStatusType } from '@/api/job/schema'
import dayjs from 'dayjs'

interface JobStatusFilterModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const JobStatusFilterModal: React.FC<JobStatusFilterModalProps> = ({ open, setOpen }) => {
    const [statusFilter, setStatusFilter] = useAtom(jobStatusFilterAtom)
    const [selectedStatus, setSelectedStatus] = useState<JobFilterStatusType | null>(statusFilter)
    const [pagination, setPagination] = useAtom(jobPaginationAtom)
    const setJobStartFilter = useSetAtom(jobStartFilterAtom)

    const handleCheck = (status: JobStatusType, isChecked: boolean) => {
        if (isChecked) {
            if (status === JOB_STATUS.JOBS_TODAY) {
                setSelectedStatus([JOB_STATUS.JOBS_TODAY])
            } else if (status === JOB_STATUS.COMPLETED) {
                setSelectedStatus([JOB_STATUS.COMPLETED])
            } else if (status === JOB_STATUS.NEEDS_INVOICING && selectedStatus?.includes(JOB_STATUS.COMPLETED)) {
                setSelectedStatus([JOB_STATUS.COMPLETED, JOB_STATUS.NEEDS_INVOICING])
            } else {
                const updatedStatus = selectedStatus ? [...selectedStatus, status] : [status]
                setSelectedStatus(updatedStatus as JobFilterStatusType)
            }
        } else {
            if (status === JOB_STATUS.COMPLETED) {
                setSelectedStatus([])
            } else {
                const updatedStatus = selectedStatus?.filter((s) => s !== status) || []
                setSelectedStatus(updatedStatus?.length ? updatedStatus : [])
            }
        }
    }
    
    const handleApply = () => {
        setStatusFilter(selectedStatus)
        if(selectedStatus?.[0] === 'JOBS_TODAY') {
            setJobStartFilter(dayjs().format(ISO_8601_WITH_UTC_OFFSET))
        } else {
            setJobStartFilter('')
        }
        setOpen(false)
        setPagination({
            ...pagination,
            page: 1,
        })
    }

    const handleClear = () => {
        setSelectedStatus([])
        setJobStartFilter('')
    }

    const handleClose = () => {
        setSelectedStatus(null)
        setOpen(false)
    }

    useEffect(() => {
        setSelectedStatus(statusFilter)
    }, [open])

    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px] p-0'
        >
            <div className='flex gap-5 px-10'>
                <div className=''>
                    <h1 className='text-left font-semibold text-2xl text-zentive-black capitalize'>
                        Filter
                    </h1>
                    <p className='text-left text-base font-semibold text-zentive-gray-medium mt-5 mb-1'>
                        Job Status
                    </p>
                      <div className='overflow-auto max-h-50'>
                          <ul>
                              {/* Jobs Today Option */}
                              <li className='flex flex-row gap-2 mt-2 text-base'>
                                  <Checkbox
                                      className='mt-1'
                                      onCheckedChange={(val) => handleCheck(JOB_STATUS.JOBS_TODAY, val as boolean)}
                                      checked={selectedStatus?.includes('JOBS_TODAY')}
                                      disabled={selectedStatus?.includes(JOB_STATUS.COMPLETED)}
                                  />
                                  <p>Jobs Today</p>
                              </li>

                              {/* Other Status Options */}
                              {Object.entries(JOB_STATUS)
                                  .filter(([status]) => !['ALL', 'JOBS_TODAY', 'NEEDS_INVOICING'].includes(status))
                                  .map(([key, status]) => (
                                      <li key={key} className='flex flex-row gap-2 mt-2 text-base'>
                                          <Checkbox
                                              className='mt-1'
                                              onCheckedChange={(val) =>
                                                  handleCheck(status as JobStatusType, val as boolean)
                                              }
                                              checked={selectedStatus?.includes(status)}
                                              disabled={selectedStatus?.includes('JOBS_TODAY') || 
                                                      (status !== JOB_STATUS.COMPLETED && selectedStatus?.includes(JOB_STATUS.COMPLETED))}
                                          />
                                          <p>{status === 'ACT' ? 'Open' : JOB_STATUS_LABEL[status]}</p>
                                      </li>
                                  ))}

                              {/* Needs Invoicing Option - Only shows when Completed is selected */}
                              {selectedStatus?.includes(JOB_STATUS.COMPLETED) && (
                                  <li className='flex flex-row gap-2 mt-2 text-base ml-6'>
                                      <Checkbox
                                          className='mt-1'
                                          onCheckedChange={(val) =>
                                              handleCheck(JOB_STATUS.NEEDS_INVOICING, val as boolean)
                                          }
                                          checked={selectedStatus?.includes('NEEDS_INVOICING')}
                                      />
                                      <p>Needs Invoicing</p>
                                  </li>
                              )}
                          </ul>
                      </div>
                  </div>
            </div>
            <div className='mt-6 flex justify-between bg-gray-300 py-6 px-6'>
                <Button
                    variant={'link'}
                    className='text-zentive-blue-dark font-semibold text-base'
                    onClick={handleClear}
                >
                    Clear Filters
                </Button>
                <div className='flex gap-x-4'>
                    <Button
                        onClick={handleClose}
                        className='w-97 h-11 text-base font-semibold cursor-pointer'
                        variant={'outline'}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium cursor-pointer'
                        onClick={handleApply}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default JobStatusFilterModal
