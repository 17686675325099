import { useLocation } from 'react-router-dom'
import SignInForm from './SignInForm'
import './index.css'
import CrewSignInForm from './CrewSignInForm'
import { cn } from '@/utils/helper'
import { useSetAtom } from 'jotai'
import { ownerFreeTrialEndedForCrewAtom } from '@/store/auth'
import { useEffect } from 'react'

const crewSignInPathname = '/crew/sign-in'
const SignIn = () => {
    const { pathname } = useLocation()
    const setIsOwnerFreeTrialEndedForCrew = useSetAtom(ownerFreeTrialEndedForCrewAtom)

    useEffect(() => {
        setIsOwnerFreeTrialEndedForCrew(false)
    }, [])
    return (
        <div
            className={cn(
                'flex justify-center items-center z-0',
                pathname === '/sign-in' ? 'sign-in-bg' : 'crew-sign-in-bg',
            )}
        >
            {pathname !== crewSignInPathname ? (
                <div className='sign-in-overlay w-140 h-160'>
                    <SignInForm />
                </div>
            ) : (
                <div className='sign-in-overlay crew-sign-in-translucent w-140 h-155 m-6 md:m-0'>
                    <CrewSignInForm />
                </div>
            )}
        </div>
    )
}

export default SignIn
