import { getAllEmployees } from '@/api/employee'
import { EmployeeListType, EmployeeToCrewType } from '@/api/employee/schema'
import { JobsCompletedByEmployeeFilterType } from '@/api/reports/schema'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { USER_STATUS } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { employeeSortByAtom, employeeSortOrderAtom } from '@/store/employee'
import { jobCompletedByEmployeeFilterAtom } from '@/store/reports'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'

export const EmployeeFilter = () => {
    const [open, setIsOpen] = useState<boolean>(false)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const employeeSortBy = useAtomValue(employeeSortByAtom)
    const employeeSortOrder = useAtomValue(employeeSortOrderAtom)

    const [searchValue, setSearchValue] = useState('')
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const [jobsCompletedByEmployeeFilter, setJobsCompletedByEmployeeFilter] = useAtom(
        jobCompletedByEmployeeFilterAtom,
    )
    const [jobTypeFilter, setJobTypeFilter] = useState<JobsCompletedByEmployeeFilterType | null>(
        null,
    )

    const handleGetCheckState = (productSvcId: string) => {
        return jobTypeFilter?.employeeIds?.some((id) => productSvcId === id)
    }

    const { isLoading: loading, data: employeeListData } = useQuery<EmployeeListType>({
        queryKey: [
            'employeeListFilter',
            user?.businessId as string,
            pagination,
            searchValue,
            employeeSortBy,
            employeeSortOrder,
        ],
        queryFn: () =>
            getAllEmployees(
                searchValue,
                USER_STATUS.ACTIVATED,
                user?.businessId as string,
                pagination,
                { sortBy: '', sortOrder: '' },
            ),
    })

    const handleAddJobFilter = (employeeId: string) => {
        setJobTypeFilter((prevFilter) => {
            if (!prevFilter) {
                // If there is no existing filter, create one with the new employeeId
                return {
                    start: jobsCompletedByEmployeeFilter?.start ?? '',
                    end: jobsCompletedByEmployeeFilter?.end ?? '',
                    svcIds: jobsCompletedByEmployeeFilter?.svcIds || [],
                    employeeIds: [employeeId],
                    keyword: '',
                }
            }

            // Check if the employeeId already exists in the array
            const updatedEmployeeIds = prevFilter.employeeIds?.includes(employeeId)
                ? prevFilter?.employeeIds?.filter((id) => id !== employeeId) // Remove the employeeId
                : [...(prevFilter.employeeIds || []), employeeId] // Add the employeeId if it doesn't exist

            return {
                ...prevFilter,
                employeeIds: updatedEmployeeIds,
            }
        })
    }

    const handleSaveJobFilter = () => {
        setJobsCompletedByEmployeeFilter((prevNetGrossFilter) => {
            if (!jobTypeFilter) {
                // If there is no jobTypeFilter, return the previous netGrossFilter
                return prevNetGrossFilter
            }

            const employeeIdsFromFilter = jobTypeFilter.employeeIds || []
            const netGrossEmployeeIds = prevNetGrossFilter?.employeeIds || []
            const keyword = prevNetGrossFilter?.keyword || ''
            const svcIds = prevNetGrossFilter?.svcIds || []
            const start = prevNetGrossFilter?.start ?? ''
            const end = prevNetGrossFilter?.end ?? ''

            // Filter out employeeIds that are in netGrossEmployeeIds but not in employeeIdsFromFilter
            const filteredEmployeeIds = netGrossEmployeeIds.filter((id) =>
                employeeIdsFromFilter.includes(id),
            )

            // Add employeeIds that are in employeeIdsFromFilter but not in filteredEmployeeIds
            const updatedEmployeeIds = [
                ...filteredEmployeeIds,
                ...employeeIdsFromFilter.filter((id) => !filteredEmployeeIds.includes(id)),
            ]

            return {
                ...prevNetGrossFilter,
                employeeIds: updatedEmployeeIds,
                keyword,
                svcIds,
                start,
                end,
            }
        })

        setIsOpen(false)
    }

    const handleClearFilters = () => {
        setJobsCompletedByEmployeeFilter((prevState) => {
            if (!prevState) return null; 
            return {
                ...prevState,
                employeeIds: [],
                keyword: '',
            }
        })

        setIsOpen(false)
    }

    useEffect(() => {
        setSearchValue('')
        if (open) {
            setJobTypeFilter({
                svcIds: jobsCompletedByEmployeeFilter?.svcIds || [],
                keyword: jobsCompletedByEmployeeFilter?.keyword ?? '',
                employeeIds: jobsCompletedByEmployeeFilter?.employeeIds || [],
                start: jobsCompletedByEmployeeFilter?.start ?? '',
                end: jobsCompletedByEmployeeFilter?.end ?? '',
            })
        }
    }, [open])

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen} key={'customer-filter'}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button type='button' className='button-outline'>
                    Employee Filter
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-0 max-w-[38rem]'>
                <div className='flex flex-col gap-5 px-16'>
                    <AlertDialogTitle className='text-left font-semibold text-lg text-zentive-black py-10 pb-0'>
                        <p className='text-2xl font-bold text-left'>Filter by Employee</p>
                    </AlertDialogTitle>
                    <SearchBar
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                        placeHolder='Search For...'
                    />

                    <div className='p-5 flex flex-row border border-1 border-zentive-gray-light'>
                        <RadioGroup>
                            {employeeListData && !loading ? (
                                employeeListData?.content?.map((e: EmployeeToCrewType) => (
                                    <div
                                        className='flex flex-row gap-2 items-center'
                                        key={e.profileId}
                                    >
                                        <RadioGroupItem
                                            checked={handleGetCheckState(e.profileId as string)}
                                            value={e.profileId as string}
                                            onClick={() =>
                                                handleAddJobFilter(e.profileId as string)
                                            }
                                            className='text-zentive-gray-medium'
                                        />
                                        <p>
                                            {e.firstName} {e.lastName}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <li>No Products and Services Yet</li>
                            )}
                        </RadioGroup>
                    </div>

                    <Pagination
                        itemsPerPage={pagination.pageSize ?? 5}
                        setPagination={setPagination}
                        totalRecords={employeeListData?.meta?.totalRecords ?? 1}
                        pagination={pagination}
                    />
                </div>

                <AlertDialogFooter className='py-5 w-full rounded-b-md items-center justify-center bg-zentive-gray-light max-w-[38rem]'>
                    <div className='flex flex-row gap-2'>
                        <Button
                            type='button'
                            variant='link'
                            className='w-[8rem] font-semibold text-zentive-blue-dark text-base'
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                        <div className='flex flex-row gap-5 mr-5'>
                            <Button
                                type='button'
                                variant='outline'
                                className='w-[11rem]'
                                onClick={() => {
                                    setIsOpen(!open)
                                    setJobTypeFilter(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='button'
                                disabled={!jobTypeFilter}
                                className={`w-[11rem]`}
                                onClick={handleSaveJobFilter}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
