import { NOT_FOUND_ERRORS } from '@/constants'
import { axiosInstance } from '../axiosInstance'

type SyncResponse = {
    customersCount: number
    invoicesCount: number
}

export const auth = async (profileId: string | undefined): Promise<string> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/authorize/${profileId}`)

    return res.data.content
}

export const checkAutoSync = async (businessId: string | undefined): Promise<boolean> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/check-auto-sync/${businessId}`)

    return res.data.content
}

export const checkIntegration = async (businessId: string | undefined): Promise<boolean> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/check-integration/${businessId}`)

    return res.data.content
}

export const getData = async (businessId: string | undefined): Promise<SyncResponse> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/customers/${businessId}`)

    return res.data.content
}

export const getQuickbooksRealmIdByBusinessId = async (businessId: string | undefined): Promise<string> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/account?businessID=${businessId}`)

    return res.data.content
}

export const syncCustomersAndInvoices = async (businessId: string | undefined, profileId: string | undefined): Promise<string> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.post(`/quickbooks/sync/customers-invoices/${businessId}/${profileId}`)

    return res.data.content
}

export const updateAutoSync = async (
    businessId: string | undefined,
    val: boolean,
): Promise<string> => {
    if (!businessId) {
        throw new Error(NOT_FOUND_ERRORS.BUSINESS_ID)
    }

    const res = await axiosInstance.patch(`/quickbooks/update-auto-sync/${businessId}`, {
        isAutoSync: val,
    })

    return res.data.content
}
