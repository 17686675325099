import { useState, useEffect, useCallback, useRef } from 'react'
import { GoogleMap, PolylineF } from '@react-google-maps/api'
import Markers from './Marker'
import { CustomerProfileType } from '@/api/profile/schema'
import { mapBoundsAtom } from '@/store/location'
import { useAtom, useAtomValue } from 'jotai'
import { isLandmarkShownAtom, routeAtom, routeCrewPolylineAtom } from '@/store/route'
import polyline from '@mapbox/polyline'
import { OwnerForemanProfileType } from '@/api/routing/schema'
import AnimatedMarker from './GoogleMarker'
import { userAtom } from '@/store/auth'
import { WEBSOCKET_URL } from '@/api/axiosInstance'


const containerStyle = {
    borderRadius: '8px',
    width: '100%',
    height: '100%',
}

const Map = () => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [activeMarker, setMarker] = useState<string | null>(null)
    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [previousBounds, setPreviousBounds] = useAtom(mapBoundsAtom)
    const isLandmarkShown = useAtomValue(isLandmarkShownAtom)
    const polylineResult = useAtomValue(routeCrewPolylineAtom)
    const [visibility, setVisibility] = useState<string>('off')
    const route = useAtomValue(routeAtom)
    const user = useAtomValue(userAtom)
    const [polylines, setPolylines] = useState<
        
        {
            lat: number
            lng: number
        }[]
    >([])
    const [currentLocation, setCurrentLocation] = useState<google.maps.LatLngLiteral | null>(null)
    const ws = useRef<WebSocket | null>(null)
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>()
    
    useEffect(() => {
        if (isLandmarkShown) {
            setVisibility('on')
        } else {
            setVisibility('off')
        }
    }, [isLandmarkShown])

    const mapOptions = {
        keyboardShortcuts: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: true,
        zoomControl: true,
        styles: [
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: visibility,
                    },
                ],
            },
        ],
    }

    const [coordinates, setCoordinates] = useState<CustomerProfileType[]>([])

    const handleLoad = useCallback((mapInstance: google.maps.Map) => {
        setMap(mapInstance)
    }, [])

    useEffect(() => {
        setIsMounted(true)
    }, [])

    const [permissionGranted, setPermissionGranted] = useState<boolean>(false)

    useEffect(() => {
        const askForLocationPermission = async () => {
            try {
                const granted = await navigator.permissions.query({ name: 'geolocation' })
                if (granted && granted.state === 'granted') {
                    setPermissionGranted(true)
                } else {
                    setPermissionGranted(false)
                }
            } catch (error) {
                console.error('Error getting location permission:', error)
                setPermissionGranted(false)
            }
        }

        askForLocationPermission()
        connect()
        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current)
            }
            if (ws.current) {
                ws.current.close()
            }
        }
    }, [])

    // const { mutate: updateCurrentLocationMu } = useMutation<
    //     unknown,
    //     AxiosError,
    //     CurrentPositionType
    // >({
    //     mutationFn: async (currentPosition: CurrentPositionType) =>
    //         updateCurrentLocation(currentPosition),
    // })

    const connect = () => {
        if (ws.current?.readyState === WebSocket.OPEN || 
            ws.current?.readyState === WebSocket.CONNECTING) return

            ws.current = new WebSocket(`${WEBSOCKET_URL}/live-tracking`,)

        ws.current.onopen = () => {
            console.log('Connected to WebSocket server')
        }

        ws.current.onmessage = (event) => {
            const updatedRoutes = JSON.parse(event.data)
            setCurrentLocation({
                lat: updatedRoutes.lat,
                lng: updatedRoutes.lng
            })
        }

        ws.current.onclose = () => {
            console.log('WebSocket connection closed, retrying...')
            reconnectTimeoutRef.current = setTimeout(connect, 1000)
        }

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const newLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                        routeId: route?.routeId,
                        from: "crew"
                    }
                    
                    setCurrentLocation(newLocation)
                    
                    if (user?.profileId == route?.jobGroup[0].job?.foreman?.profileId) {
                        
                        
                        if (ws.current?.readyState === WebSocket.OPEN) {
                            ws.current.send(JSON.stringify(newLocation))
                        }
                    }
                },
                () => {},
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                }
            )
        }, 10000) // Run every 10seconds

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if (polylineResult?.encodedPolyline) {
            setCoordinates(polylineResult.customers)
            const decodedPolyline = polyline
                .decode(polylineResult?.encodedPolyline)
                ?.map(([lat, lng]) => ({ lat, lng }))
            setPolylines(decodedPolyline)
        } else {
            const profiles = route?.jobGroup
                ?.map((profile) => profile.job?.quote.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)
            setCoordinates(profiles!)
        }
    }, [polylineResult, route])

    useEffect(() => {
        if (coordinates!?.length > 0 && map) {
            const bounds = new google.maps.LatLngBounds()
            coordinates!.forEach((coord: CustomerProfileType) => {
                bounds.extend(
                    new google.maps.LatLng(
                        coord.address.gmapLat as number,
                        coord.address.gmapLng as number,
                    ),
                )
            })

            setPreviousBounds(bounds)
        }
    }, [coordinates, map])

    useEffect(() => {
        if (!isMounted || !map || !coordinates) return

        const bounds = new google.maps.LatLngBounds()

        coordinates.forEach((coord: CustomerProfileType) => {
            bounds.extend(
                new google.maps.LatLng(
                    coord.address.gmapLat as number,
                    coord.address.gmapLng as number,
                ),
            )
        })

        if (!coordinates?.length && previousBounds) {
            map.fitBounds(previousBounds)
        } else {
            map.fitBounds(bounds)
            setPreviousBounds(bounds)
        }

        if (coordinates?.length === 1) {
            map.setZoom(10)
        }
    }, [coordinates, isMounted, map])

    if (!permissionGranted) {
        return <p>Please allow access to your location to use this feature.</p>
    }


    return (
        <div className=' w-full h-full'>
            <GoogleMap mapContainerStyle={containerStyle} onLoad={handleLoad} options={mapOptions}>
                {currentLocation && <AnimatedMarker position={currentLocation} />}

                {isMounted &&
                    typeof google !== 'undefined' &&
                    coordinates?.map((coord, index) => {
                        return (
                            <Markers
                                activeMarker={activeMarker}
                                coordinate={coord}
                                key={coord.profileId}
                                position={{
                                    lat: coord.address.gmapLat as number,
                                    lng: coord.address.gmapLng as number,
                                }}
                                setMarker={setMarker}
                                text={`${index + 1}`}
                            />
                        )
                    })}
                {isMounted && typeof google !== 'undefined' && (
                    <PolylineF
                        path={polylines}
                        options={{
                            strokeColor: '#FF0000',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                            icons: [
                                {
                                    icon: {
                                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                        scale: 2,
                                        strokeColor: '#FF0000',
                                        fillOpacity: 1,
                                        fillColor: '#FF0000',
                                    },
                                    offset: '0%',
                                    repeat: '100px',
                                },
                            ],
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    )

}
export default Map
