import { Card, CardContent } from '@/components/Card'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Suspense } from 'react'
import { Skeleton } from '@/components/Skeleton'
import PaymentInformation from './PaymentInformation'

const ResubscriptionPayment = () => {
    const stripeKey = import.meta.env.PROD
        ? import.meta.env.VITE_PROD_STRIPE_PUBLISHABLE_KEY
        : import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY

    const stripePromise = loadStripe(stripeKey)

    return (
        <Elements stripe={stripePromise}>
            <Card className='w-[556px] relative h-[540px]'>
                <CardContent className='p-7 w-full h-full space-y-[36px]'>
                    <Suspense fallback={<Skeleton></Skeleton>}>
                        <PaymentInformation />
                    </Suspense>
                </CardContent>
            </Card>
        </Elements>
    )
}

export default ResubscriptionPayment
