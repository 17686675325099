import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'

interface IPDFJobLogExport {
    date: string
    job: string
    customer: string
    address: string
    crew: string
    start: string
    finish: string
    status: string
    invoiceStatus: string
}

export const PDFJobLogExport = (zentiveLogoUrl: string, tableData: IPDFJobLogExport[]) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10

    const imageHeight = 17
    const imageWidth = 50

    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)
    doc.text('Job Logs', pageWidth / 2, startY, { align: 'center' })

    startY += 10

    const head = [
        [
            'Date',
            'Job',
            'Customer',
            'Address',
            'Crew',
            'Start',
            'Finish',
            'Job Status',
            'Invoice Status',
        ],
    ]

    const body = tableData?.map((data) => [
        data.date,
        data.job,
        data.customer,
        data.address,
        data.crew,
        data.start,
        data.finish,
        data.status,
        data.invoiceStatus,
    ])

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (data) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${data.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('Job-log.pdf')
}
