import { WEBSOCKET_URL } from '@/api/axiosInstance'
import { useEffect, useRef, useState } from 'react'

interface RouteLocation {
    routeId: string
    lat: number
    lng: number
    from: string
}

const LiveTracking = () => {
    const [routes, setRoutes] = useState<Record<string, RouteLocation>>({})
    const [location, setLocation] = useState<RouteLocation>({
        routeId: "9fac89b9-e054-433f-b2d9-9ee034faeaee", // Convert to string
        lat: 0,
        lng: 0,
        from: "owner",
    })
    const ws = useRef<WebSocket | null>(null)
    const reconnectTimeoutRef = useRef<NodeJS.Timeout>()

    const connect = () => {
        if (ws.current?.readyState === WebSocket.OPEN || 
            ws.current?.readyState === WebSocket.CONNECTING) return

            ws.current = new WebSocket(`${WEBSOCKET_URL}/live-tracking`,)
            
        ws.current.onopen = () => {
            console.log('Connected to WebSocket server')
        }

        ws.current.onmessage = (event) => {
            const updatedRoutes = JSON.parse(event.data)
            console.log("🚀 ~ connect ~ updatedRoutes:", updatedRoutes)
            setRoutes(updatedRoutes)
        }

        ws.current.onclose = () => {
            console.log('WebSocket connection closed, retrying...')
            reconnectTimeoutRef.current = setTimeout(connect, 1000)
        }

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    const sendLocation = () => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            console.log('Sending location:', location)
            ws.current.send(JSON.stringify(location))
        }
    }

    useEffect(() => {
        connect()
        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current)
            }
            if (ws.current) {
                ws.current.close()
            }
        }
    }, [])

    return (
        <div className="p-4">
            <div className="mb-4">
                <input
                    type="number"
                    value={location.lat}
                    onChange={(e) => setLocation(prev => ({ ...prev, latitude: Number(e.target.value) }))}
                    placeholder="Latitude"
                    className="mr-2 p-2 border"
                />
                <input
                    type="number"
                    value={location.lng}
                    onChange={(e) => setLocation(prev => ({ ...prev, longitude: Number(e.target.value) }))}
                    placeholder="Longitude"
                    className="mr-2 p-2 border"
                />
                <button onClick={sendLocation} className="p-2 bg-blue-500 text-white">
                    Update Location
                </button>
            </div>
            <div>
                <h2>Active Routes:</h2>
                {Object.entries(routes).map(([routeId, route]) => (
                    <div key={routeId}>
                        Route {routeId}: Lat {route.lat}, Long {route.lng}
                    </div>
                ))}
            </div>
        </div>
    )
}
export default LiveTracking