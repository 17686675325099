import 'react-datepicker/dist/react-datepicker.css'
import JobList from './JobList'
import WrappedRoutingMapView from './RouteMap'
import { Card, CardFooter } from '@/components/Card'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { saveRouting, validateRouteSchedule } from '@/api/routing'
import { RouteParamType, RouteType } from '@/api/routing/schema'
import { useToast } from '@/hooks/useToast'
import {
    routeJobListAtom,
    routePolylineAtom,
    isPolylineLoadingAtom,
    hasJobResultAtom,
    routeCustomerListAtom,
} from '@/store/route'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useFormContext } from 'react-hook-form'
import { timezoneAtom } from '@/store/auth'
import { formatToUTCWithOffset } from '@/utils/time'
import { ISO_8601_WITH_UTC_OFFSET } from '@/constants'

const RoutingMapBody = () => {
    const [routeJobList, setRouteJobList] = useAtom(routeJobListAtom)
    const [polyLineList, setPolylineList] = useAtom(routePolylineAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const setHasJobResult = useSetAtom(hasJobResultAtom)
    const setRouteCustomerList = useSetAtom(routeCustomerListAtom)
    const navigate = useNavigate()

    const { toast } = useToast()

    const tz = useAtomValue(timezoneAtom)

    const { handleSubmit, watch } = useFormContext<RouteParamType>()

    const jobDate = watch('jobDueDate')

    const polyLine = polyLineList?.encodedPolyline

    const clearAtoms = () => {
        setRouteJobList([])
        setPolylineList(null)
        setIsPolylineLoading(false)
        setHasJobResult(true)
        setRouteCustomerList([])
    }

    const { mutate: saveRouteMu } = useMutation<ResponseType, AxiosError, RouteType>({
        mutationKey: ['SaveRoute'],
        mutationFn: (data) => saveRouting(data),
        onSuccess: () => {
            clearAtoms()
            toast({
                description: 'Route saved!',
                variant: 'default',
                duration: 2000,
            })
            navigate('/schedule/routing')
        },
    })

    const onSubmit = async (data: RouteType) => {
        try {
            if (routeJobList) {
                const isValid = await validateRouteSchedule(
                    routeJobList[0].crewId ?? '',
                    formatToUTCWithOffset(
                        jobDate ? jobDate.toDateString() : '',
                        tz?.timeZoneId,
                        ISO_8601_WITH_UTC_OFFSET,
                        'General Format',
                    ),
                )

                if (isValid) {
                    saveRouteMu(data)
                } else {
                    toast({
                        description: 'Route already exists!',
                        variant: 'destructive',
                        duration: 2000,
                    })
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='w-full flex flex-col mt-6'>
            <div className='w-full flex flex-row'>
                <JobList />
                <WrappedRoutingMapView />
            </div>
            <Card className='w-full text-center mt-4 mb-4'>
                <CardFooter className='h-full p-4 lg:flex rounded-t-sm lg:justify-end rounded-b-md bg-zentive-gray-light my-auto'>
                    <div className='flex space-x-4 '>
                        <Button
                            onClick={() => {
                                clearAtoms()
                                navigate('/schedule/routing')
                            }}
                            type='button'
                            variant={'outline'}
                            className='w-full px-8 py-2 h-full rounded-sm hover:text-zentive-green-light hover:border-zentive-green-medium hover:bg-zentive-green-medium'
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-full px-8 py-2 h-full rounded-sm hover:border-zentive-green-medium  hover:bg-zentive-green-medium'
                            type='submit'
                            onClick={handleSubmit(onSubmit)}
                            disabled={!polyLine}
                        >
                            Save
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default RoutingMapBody
