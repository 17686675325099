import { z } from 'zod'
import { UserDataType } from '../auth/schema'

export const subscriptionTypeSchema = z.object({
    subscriptionTypeId: z.string().optional(),
    stripeProductId: z.string().optional(),
    stripePriceId: z.string().optional(),
    name: z.string().optional(),
    price: z.number().optional(),
    interval: z.string().optional(),
})

export const UpdateSubscriptionShema = z.object({
    userId: z.string(),
    planId: z.string(),
})

export const ProratedSubscriptionSchema = z.object({
    amountDue: z.number(),
    remainingTimeAmount: z.number(),
    unusedTimeAmount: z.number(),
    nextBillingDate: z.number(),
})

export type SubscriberInfoType = UserDataType & {
    cardNumber?: string
}

export const featureSchema = z.object({
    feature: z.string(),
    starter: z.boolean(),
    standard: z.boolean(),
    pro: z.boolean(),
    tooltip: z.string().optional(),
    starterValue: z.string().optional(),
    standardValue: z.string().optional(),
    proValue: z.string().optional(),
    limit: z.number().optional(),
    label: z.string().optional(),
    order: z.number().default(0),
})

export const subscriptionMatrixSchema = z.object({
    features: z.array(featureSchema),
    customerManagement: z.array(featureSchema),
    jobManagement: z.array(featureSchema),
    financeManagement: z.array(featureSchema),
    reportGeneration: z.array(featureSchema),
    users: z.array(featureSchema),
    additionalItems: z.array(featureSchema),
})

export const subSeatSchema = z.object({
    owner: z.number(),
    coOwner: z.number(),
    crew: z.number(),
    numberOfSeats: z.number(),
})

// TypeScript types inferred from the Zod schema
export type FeatureType = z.infer<typeof featureSchema>

export type SubscriptionMatrix = z.infer<typeof subscriptionMatrixSchema>

export type SubscriptionType = z.infer<typeof subscriptionTypeSchema>
export type UpdateSubscriptionType = z.infer<typeof UpdateSubscriptionShema>
export type ProratedSubscriptionType = z.infer<typeof ProratedSubscriptionSchema>
export type SubSeatType = z.infer<typeof subSeatSchema>
