import { Button } from '@/components/Button'
import { userAtom } from '@/store/auth'
import { manageNewSubTypeAtom } from '@/store/manageSubs'
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { FaCircle } from 'react-icons/fa'
import {
    UpdateSubscriptionType,
    SubscriptionType,
    ProratedSubscriptionType,
} from '@/api/subscription/schema'
import { createSubscription, updateSubscription } from '@/api/subscription'
import { getPricingById } from '@/api/subscription'
import { displayCurrency, numberFormatter } from '@/utils/helper'
import { checkUpgradeData } from '@/api/owner'
import { CheckUpgradeType } from '@/api/owner/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { toast } from '@/hooks/useToast'
import { useSearchParams } from 'react-router-dom'
import {
    BREADCRUMBS_PADDING_STYLE,
    FULL_MONTH_WITH_DAY_AND_FULL_YEAR,
    SUBSCRIPTION_TYPES,
} from '@/constants'
import { createStripeSubscription } from '@/api/payment'
import { Elements, useStripe } from '@stripe/react-stripe-js'
import { getFullProfile } from '@/api/profile'
import { extractUserReponse } from '@/utils/user'
import { loadStripe } from '@stripe/stripe-js'
import { isFreeTrialEndedAtom } from '@/store/owner'
import { useState } from 'react'
import dayjs, { ManipulateType } from 'dayjs'

type CreateSubscriptionType = UpdateSubscriptionType
export type CreateSubscriptionRes = Omit<CreateSubscriptionType, 'userId'> & {
    profileId: string
}
const ChangePlanConfirmation = () => {
    const [user, setUser] = useAtom(userAtom)
    const newSubId = useAtomValue(manageNewSubTypeAtom)
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const process = params.get('process')
    const newPlanId = params.get('newPlanId')
    const stripe = useStripe()
    const [disable, setDisable] = useState<boolean>(false)

    const quqeryClient = new QueryClient()

    const setIsFreeTrialEnded = useSetAtom(isFreeTrialEndedAtom)

    const dateToday = dayjs()

    const toastMessage = `${
        process === 'Upgrade'
            ? 'Successfully Upgraded'
            : process === 'Downgrade'
              ? 'Sucessfully Confirmed Downgrade'
              : 'Billing cycle changed successfully'
    }`

    const isFreeTrial = user?.business?.subscriptionType?.name === SUBSCRIPTION_TYPES.FREE_TRIAL

    const prevPlanId = user?.business?.subscriptionTypeId as string

    const { data: newPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['newPlan'],
        queryFn: () => getPricingById(newPlanId as string),
    })

    const { data: upgradeSavedVal } = useQuery<unknown, AxiosError, ProratedSubscriptionType>({
        queryKey: ['savedVal'],
        queryFn: () =>
            checkUpgradeData({
                profileId: user?.profileId as string,
                planId: newPlanId as string,
            } as CheckUpgradeType),
    })

    const handleSuccessfulSubscription = async (): Promise<void> => {
        // retrieve yung latest records ng user tapos iset sa userAtom
        // para updated yung subscription type niya sa client side
        const getProfileRes = await getFullProfile()

        setUser(extractUserReponse(getProfileRes))

        setIsFreeTrialEnded(false)

        toast({
            description: toastMessage,
            variant: 'default',
        })

        quqeryClient.invalidateQueries({ queryKey: ['businessProfile'] })
        quqeryClient.invalidateQueries({ queryKey: ['Plan'] })

        setTimeout(() => navigate('/settings/owner-subscription'), 1000)
    }

    const { mutate: updateSubscriptionMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        UpdateSubscriptionType
    >({
        mutationFn: updateSubscription,
        onSuccess: handleSuccessfulSubscription,
        onError: (err) => console.log(err),
    })

    const showDestructiveToast = (err: AxiosError | Error) => {
        console.error(err)
        toast({
            description: err.message,
            variant: 'destructive',
        })
    }

    const { mutate: createSubscriptionMu } = useMutation<
        CreateSubscriptionRes, // ito yung type na nirereturm ng mutationFn a.k.a createSubscription katapos maresolve nung Promise<>
        AxiosError, // type nung error pag nagfail yung mutationFn
        CreateSubscriptionType // type ng arguments na tinatanggap ng mutationFn a.k.a yung ma-iinject kay createSubsciption
    >({
        mutationFn: createSubscription,
        // mutationFn: (data) => createSubscription(data),
        // di na to need gawin ^ yang nakacomment sa taas, kasi pagna-call si createSubscriptionMu(argument), iinject nya yun kay createSubscription
        onSuccess: async (data) => {
            // makukuha nireturn ni mutationFn dito sa argument "data" ng onSuccess callback function

            try {
                const clientSecret = await createStripeSubscription(data.profileId, true)

                if (stripe) {
                    // no need na to lagyan ng object argument with payment_method property kagaya sa src\pages\public\Payment kasi nakapag set up na
                    // coconfirm nito yung payment from createStripeSubscription
                    const { error } = await stripe.confirmCardPayment(clientSecret)

                    if (error) throw new Error(error.message)

                    handleSuccessfulSubscription()
                }
            } catch (err) {
                if (err instanceof AxiosError || err instanceof Error) {
                    showDestructiveToast(err)
                }
            }
        },
        onError: async (err) => {
            showDestructiveToast(err)
            try {
                await createSubscription({
                    planId: prevPlanId as string,
                    userId: user?.profileId as string,
                })
            } catch (err) {
                if (err instanceof AxiosError) {
                    showDestructiveToast(err)
                }
            }
        },
    })

    const handleConfirmation = (userId: string, planId: string, isFreeTrial: boolean) => {
        setDisable(true)
        const subscriptionMu = isFreeTrial ? createSubscriptionMu : updateSubscriptionMu

        subscriptionMu({
            userId,
            planId,
        })
        setDisable(false)
    }

    return (
        <div className={BREADCRUMBS_PADDING_STYLE}>
            <BreadCrumbs titleName='Review Changes' />
            <div className='bg-white mt-10'>
                <div className='flex justify-center'>
                    <p className='pt-[36px] text-zentive-green-dark font-semibold text-2xl items-center'>
                        Confirm {process} to {newPlan?.name}
                    </p>
                </div>
                <div className='mx-[105px] mt-5 text-gray-900 pb-[36.25px]'>
                    {process === 'billing-cycle' ? (
                        <p className='mt-[19px] flex justify-start items-center gap-1'>
                            You've chosen to switch your billing cycle from{' '}
                            {newPlan?.interval === 'month'
                                ? 'annual to monthly'
                                : 'monthly to annual'}{' '}
                            for the {newPlan?.name} plan. By clicking 'Proceed with{' '}
                            {newPlan?.interval === 'month' ? 'Monthly' : 'annual'} plan', you agree
                            to the following:
                        </p>
                    ) : (
                        <p>
                            You have chosen to {process?.toLocaleLowerCase()} your subscription plan
                            to {newPlan?.name}. By clicking 'Confirm {process}' you agree to the
                            following:
                        </p>
                    )}

                    {process === 'Upgrade' ? (
                        <div className='pb-5'>
                            <p className='mt-[19px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' /> Your current plan will be immediately{' '}
                                {process?.toLowerCase()}d to{' '}
                                <span className='text-zentive-green-dark'>{newPlan?.name}</span> .{' '}
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                {`A one-time charge of ${displayCurrency(
                                    upgradeSavedVal?.amountDue ?? 0,
                                )}, will be applied for
                                the remaining days of the current billing cycle.`}
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                Your billing cycle will be adjusted to match the new plan's{' '}
                                {newPlan?.interval == 'annual' ? 'annual' : 'monthly'} cycle.
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                Future billing will occur at the full{' '}
                                {newPlan?.interval == 'annual' ? 'annual' : 'monthly'} rate of $
                                {numberFormatter.format(newPlan?.price ?? 0)} starting from the next
                                billing cycle.
                            </p>
                        </div>
                    ) : process === 'billing-cycle' ? (
                        <div className='pb-5'>
                            <p className='mt-[19px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                You've chosen to switch your billing cycle from{' '}
                                {newPlan?.interval === 'month'
                                    ? 'annual to monthly'
                                    : 'monthly to annual'}{' '}
                                for the{' '}
                                <span className='text-zentive-green-dark'>{newPlan?.name}</span>{' '}
                                plan.
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                Your new billing date will be adjusted to reflect the{' '}
                                {newPlan?.interval === 'month' ? 'monthly' : 'annual'} cycle,
                                effective from{' '}
                                {dayjs(
                                    dateToday.add(1, newPlan?.interval as ManipulateType),
                                ).format(FULL_MONTH_WITH_DAY_AND_FULL_YEAR)}
                                .
                            </p>
                        </div>
                    ) : (
                        <div className='pb-5'>
                            <p className='mt-[19px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                Your current plan will be {process?.toLowerCase()} to{' '}
                                <span className='text-zentive-green-dark'>{newPlan?.name}</span> on
                                your next billing cycle.
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                You will have access to all features in your {newPlan?.name} until
                                your renewal date on{' '}
                                {dayjs(dateToday.add(1, 'month')).format(
                                    FULL_MONTH_WITH_DAY_AND_FULL_YEAR,
                                )}
                                .
                            </p>
                            <p className='mt-[5px] flex justify-start items-center gap-1'>
                                <FaCircle className='h-2' />
                                Future billing will occur at the full{' '}
                                {newPlan?.interval == 'annual' ? 'annual' : 'monthly'} rate of $
                                {numberFormatter.format(newPlan?.price ?? 0)} starting from the next
                                billing cycle.
                            </p>
                        </div>
                    )}
                    <p className='flex justify-start text-zentive-gray-medium items-center gap-1'>
                        **You can review or modify your subscription details at any time in your
                        account settings.**
                    </p>
                </div>
            </div>
            <div className='flex justify-end bg-gray-200 rounded-b-[15px] gap-3 py-5 px-5'>
                <Button
                    onClick={() => navigate(-1)}
                    className='w-[95px] text-zentive-green-dark font-semibold text-lg'
                    variant={'outline'}
                >
                    Cancel
                </Button>
                {process === 'billing-cycle' ? (
                    <Button
                        disabled={updateLoading || disable}
                        onClick={() => {
                            handleConfirmation(user?.profileId as string, newSubId, isFreeTrial)
                        }}
                        className='w-[250px]'
                    >
                        Proceed with {newPlan?.interval === 'month' ? 'Monthly' : 'Annual'} Plan
                    </Button>
                ) : (
                    <Button
                        disabled={updateLoading || disable}
                        onClick={() => {
                            handleConfirmation(user?.profileId as string, newSubId, isFreeTrial)
                        }}
                        className='w-[250px]'
                    >
                        Confirm {process}
                    </Button>
                )}
            </div>
        </div>
    )
}

const ChangePlanWrapper = () => {
    const stripeKey = import.meta.env.PROD
        ? import.meta.env.VITE_PROD_STRIPE_PUBLISHABLE_KEY
        : import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY

    const stripePromise = loadStripe(stripeKey)

    return (
        <Elements stripe={stripePromise}>
            <ChangePlanConfirmation />
        </Elements>
    )
}

export default ChangePlanWrapper
