import { Label } from '@/components/Label'
import { RadioGroup } from '@/components/RadioGroup'
import { FaInfoCircle } from 'react-icons/fa'
import { currentSubsInfoAtom } from '@/store/manageSubs'
import { useAtomValue } from 'jotai'
import RadioButtonStatus from './RadioBtnStatus'
import { USER_STATUS } from '@/constants'
import Tooltip from '@/components/Tooltip'

const generateStatusFromCode = (code: string) => {
    switch (code) {
        case USER_STATUS.BILLING_PAUSED:
            return 'billPaused'
        case USER_STATUS.TERMINATED:
            return 'Terminated'
        case USER_STATUS.ACTIVATED:
        default:
            return 'active'
    }
}

const SubscriberSettings = () => {
    const currentSubsInfo = useAtomValue(currentSubsInfoAtom)

    return (
        <section>
            <div>
                <div className='bg-white w-full flex '>
                    <div className='w-full  mx-6'>
                        <div className='bg-gray-100 w-full px-4  mt-9 mb-4'>
                            <p className='w-full pt-4 owner text-base font-semibold'>
                                Account Status
                            </p>
                            <p className='w-full mt-4 owner text-base text-zentive-gray-medium'>
                                Indicates whether the user's subscription is currently active or
                                terminated.
                            </p>
                            <div className='owner mt-4 text-base pb-3'>
                                <RadioGroup
                                    defaultValue='active'
                                    value={generateStatusFromCode(
                                        currentSubsInfo?.business?.status ?? USER_STATUS.ACTIVATED,
                                    )}
                                >
                                    <div className='flex items-center space-x-2'>
                                        <RadioButtonStatus
                                            status={USER_STATUS.ACTIVATED}
                                            id='active'
                                        />
                                        <Label htmlFor='active' className='flex'>
                                            <label className='mr-2'>Active</label>
                                            <Tooltip
                                                content='User currently have access to the services, content, or features associated with the subscription plan'
                                                position='bottom'
                                                alignment='start'
                                                width='64'
                                            >
                                                <FaInfoCircle className='text-gray-500 hover:text-zentive-black mt-1' />
                                            </Tooltip>
                                        </Label>
                                    </div>
                                    <div className='flex items-center space-x-2'>
                                        <RadioButtonStatus
                                            status={USER_STATUS.BILLING_PAUSED}
                                            id='billPause'
                                        />
                                        <Label htmlFor='billPause' className='flex'>
                                            <label className='mr-2'>Bill Paused</label>
                                            <Tooltip
                                                content='Indicates that the automatic billing for the subscription has been temporarily stopped.'
                                                position='bottom'
                                                alignment='start'
                                                width='64'
                                            >
                                                <FaInfoCircle className='text-gray-500 hover:text-zentive-black mt-1' />
                                            </Tooltip>
                                        </Label>
                                    </div>
                                    {/* <div className='flex items-center space-x-2'>
                                        <RadioButtonStatus
                                            status={USER_STATUS.TERMINATED}
                                            id='Terminated'
                                        />
                                        <Label htmlFor='Terminated' className='flex'>
                                            <label className='mr-2'>Terminated</label>
                                            <Tooltip
                                                content='Subscription has been ended and user no longer have access to the services, content or features associated with the subscription plan'
                                                position='bottom'
                                                alignment='start'
                                                width='72'
                                            >
                                                <FaInfoCircle className='text-gray-500 hover:text-zentive-black mt-1' />
                                            </Tooltip>
                                        </Label>
                                    </div> */}
                                </RadioGroup>
                            </div>
                        </div>
                        {/* <div className='bg-zentive-gray-bg w-full mt-[18px] px-4 mb-[75px] overflow-'>
                            <p className='w-full pt-4  text-base font-semibold'>Delete Account</p>
                            <p className='w-full mt-4 text-base text-zentive-gray-medium'>
                                Deleting your account will permanently remove all associated data
                                and access. Please be aware that this action is irreversible and
                                cannot be undone.
                            </p>
                            <DeleteModal />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubscriberSettings
