import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import dayjs from 'dayjs'
import { toExportLeadAtom } from '@/store/lead'
import { useAtomValue } from 'jotai'
import { convertImageToBase64 } from '@/utils/helper'
import { PDFExport } from '../ExportFile/ExportPDF'

export const useExportLeadPDF = () => {
    const leadsData = useAtomValue(toExportLeadAtom)

    const handleExportToPDF = async () => {
        if (!leadsData || leadsData.length === 0) return null

        try {
            const zentiveLogoUrl = await convertImageToBase64(zentiveLogo)
            const tableData = leadsData.map((lead) => ({
                dealSize: lead?.dealSize !== undefined ? lead.dealSize.toString() : '0',
                customerName: `${lead.firstName} ${lead.lastName}`,
                contactType: lead?.contactType,
                source: lead?.source,
                serviceType: lead?.productServiceNames.join(', '),
                dealStage: lead?.label,
                createdAt: dayjs(lead?.createdAt).format('MM/DD/YYYY hh:mm A'),
            }))

            PDFExport(zentiveLogoUrl, tableData)
        } catch (err) {
            console.error('Error during PDF export:', (err as Error).message)
        }
    }

    return { handleExportToPDF }
}
