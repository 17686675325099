import { NOT_FOUND_ERRORS } from '@/constants'
import { axiosInstance } from '../axiosInstance'
import { SortParams } from '../job'
import {
    BulkDeleteInvoiceType,
    InvoiceCustomerListType,
    InvoiceListType,
    InvoiceType,
} from './schema'
import { PaginationType } from '@/components/Pagination/schema'
import { JobListType, JobResponseType } from '../job/schema'

export const getNextBusinessInvoiceNumber = async (businessId: string): Promise<number> => {
    const response = await axiosInstance.get(`invoice/next-invoice-number/${businessId}`)
    return response.data.content
}

export const getJobByQuoteId = async (quoteId: string): Promise<JobResponseType> => {
    const response = await axiosInstance.get(`invoice/job?quoteId=${quoteId}`)
    return response.data
}

export const getJobsByInvoiceId = async (quoteId: string, invoiceId: string): Promise<JobResponseType> => {
    const response = await axiosInstance.get(`invoice/job-invoice?quoteId=${quoteId}&invoiceId=${invoiceId}`)
    return response.data
}


export const getJobsForInvoicing = async (profileId: string, businessId: string): Promise<JobListType> => {
    const response = await axiosInstance.get(`jobs/jobs-for-invoicing?profileID=${profileId}&businessID=${businessId}`)
    return response.data
}

export const getAllInvoices = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
    sort: SortParams,
): Promise<InvoiceListType> => {
    const { page, pageSize } = p

    const params = {
        businessId: businessId,
        keyword: keyword,
        page: page,
        pageSize: pageSize,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const response = await axiosInstance.get(`/invoice/list`, { params })
    return response.data
}

export const getAllInvoiceCustomer = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
): Promise<InvoiceCustomerListType> => {
    const response = await axiosInstance.get(
        `/invoice/customers?businessId=${businessId}&page=${p.page}&pageSize=${p.pageSize}&keyword=${keyword}`,
    )
    return response.data
}

export const saveInvoice = async (invoice: InvoiceType) => {
    const res = await axiosInstance.post(`/stripe/invoice/new`, invoice)
    return res.data
}

export const getInvoice = async (invoiceId: string) => {
    const response = await axiosInstance.get(`/invoice/${invoiceId}`)
    return response.data.content as InvoiceType
}

export const sendReimbursement = async (invoiceId: string, amount: string) => {
    const response = await axiosInstance.post(
        `/invoice/refund?invoiceId=${invoiceId}&amount=${amount}`,
    )
    return response.data.content
}

export const payInvoiceManually = async (invoiceNumber: string) => {
    const response = await axiosInstance.post(
        `/stripe/invoice/manual-payment?invoiceNumber=${invoiceNumber}`,
    )
    return response.data.content
}

export const getOwnerRefund = async (businessId: string) => {
    const response = await axiosInstance.post(`/invoice/refund/list?businessId=${businessId}`)
    return response.data.content
}

export const getCustomerRefund = async (businessId: string, profileId: string) => {
    const response = await axiosInstance.post(
        `/invoice/customer-refund/list?businessId=${businessId}&profileId=${profileId}`,
    )
    console.log("🚀 ~ getCustomerRefund ~ response:", response)
    return response.data.content
}

export const bulkDeleteInvoices = async (invoices: BulkDeleteInvoiceType) => {
    const response = await axiosInstance.post(`/invoice/bulk-delete`, invoices)
    return response.data.content
}

export const checkExistingInvoice = async (quoteId: string | undefined, jobId: string | undefined): Promise<boolean> => {
    if (!quoteId) {
        throw new Error(NOT_FOUND_ERRORS.QUOTE_ID)
    }

    const res = await axiosInstance.get(`/invoice/check-unique-invoice?quoteId=${quoteId}&jobId=${jobId}`)
    return res.data.content
}
