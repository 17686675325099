import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import { ExpenseReportExportType } from '@/api/reports/schema'
import { EXPENSE_REPORT_TABS, RECORD_PREFIX, US_FORMAT } from '@/constants'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'

export const GenerateExpenseReportPDF = (
    zentiveLogoUrl: string,
    tableData: ExpenseReportExportType,
    businessName: string,
    selectedTab: string,
) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10

    const imageHeight = 17
    const imageWidth = 50
    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)

    doc.text(
        businessName +
            (selectedTab === EXPENSE_REPORT_TABS.internal ? ' Internal' : ' External') +
            ' Expense Report',
        pageWidth / 2,
        startY,
        {
            align: 'center',
        },
    )

    startY += 10

    // Define flex-like layout for the overview section
    doc.setFontSize(10)
    doc.setTextColor('#707070')

    startY += 6 // Move down to start content

    doc.setFont('Helvetica', 'normal')

    startY += 5 // Space before the table

    const head = [['Job Number', 'Date', 'Expense Name', 'Description', 'Amount']]

    const body = tableData?.records?.map((data) => [
        RECORD_PREFIX.job + formatRecordNumber(data.jobNumber as number) ?? 'N/A', // Ensure that the value is either string or number
        dayjs(data.date).format(US_FORMAT),
        data.name,
        data.description === '' ? '--' : data.description,
        displayCurrency(data.amount),
    ])

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (data) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${data.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('expense-report.pdf')
}
