import { cn } from '@/utils/helper'
import { Menu } from '@headlessui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import ImportLeads from '../AddLeadSelection/ImportCSV'
import LeadCSVTemplate from '../AddLeadSelection/DownloadCSVTemplate'

interface ILeadsImportDropdown {
    disabled: boolean
}
const LeadsImportDropdown = ({ disabled }: ILeadsImportDropdown) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left'>
            <Menu>
                <Menu.Button
                    className={cn(
                        'bg-transparent text-zentive-green-dark',
                        'flex justify-center text-center items-center px-12 w-auto h-[43px] gap-x-1.5 rounded-[4px] text=[#3B671A] font-semibold border border-zentive-green-dark text-[16px] disabled:cursor-not-allowed disabled:opacity-50',
                    )}
                    disabled={disabled}
                    onClick={onOpenMenu}
                >
                    Import
                    <FiChevronDown className='-mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                </Menu.Button>

                {isOpen ? (
                    <div className='mt-2 w-[250x] h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                        <div className='bg-white rounded-md'>
                            <ImportLeads />
                            <LeadCSVTemplate />
                        </div>
                    </div>
                ) : null}
            </Menu>
        </div>
    )
}

export default LeadsImportDropdown
