import { addBulkEmployee } from '@/api/employee'
import { AddBulkEmployeeType } from '@/api/employee/schema'
import { FailedImportType, FullProfileType, InviteEmployeeViaCSVType } from '@/api/profile/schema'
import { useToast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { Dispatch, SetStateAction, useRef, useState } from 'react'
import ImportEmployeeCSVModal from './ImportEmployeeCSVModal'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { FEATURE_RESTRICTIONS } from '@/constants'

interface ImportEmployeeCSVProps {
    setRestrictionModal: Dispatch<SetStateAction<boolean>>
    setRestrictionContactModal: Dispatch<SetStateAction<boolean>>
    seatLimit?: number
    totalUsers?: number
}

const ImportEmployeeCSV = (props: ImportEmployeeCSVProps) => {
    const { setRestrictionModal, setRestrictionContactModal , seatLimit, totalUsers } = props
    const inputFileRef = useRef<HTMLInputElement | null>(null)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const { toast } = useToast()
    const [employeeCSVModal, setEmployeeCSVModal] = useState<boolean>(false)
    const [csvErr, setCsvErr] = useState<string>('')
    const [failedImports, setFailedImports] = useState<FailedImportType[]>([])

    const queryClient = useQueryClient()

    const handleFailedImports = (data: InviteEmployeeViaCSVType) => {
        const newFailedImports = data.failedImports.map((f) => ({
            email: f.email,
            name: f.name,
            reason: f.reason,
        }))

        setFailedImports((prev) => [
            ...prev, // Retain existing records
            ...newFailedImports, // Add new failed import records
        ])
    }

    const { mutate: addBulkEmployeeMu } = useMutation<
        InviteEmployeeViaCSVType,
        AxiosError,
        AddBulkEmployeeType
    >({
        mutationFn: addBulkEmployee,
        onSuccess: (data) => {
            if (data?.failedImports?.length > 0) {
                handleFailedImports(data)
                setEmployeeCSVModal(true)
            } else {
                queryClient.invalidateQueries({ queryKey: ['employeeList'] })
                queryClient.invalidateQueries({ queryKey: ['subSeat'] })
                toast({
                    description: 'Employee profiles has been added successfully',
                })
            }
            if (inputFileRef.current) {
                inputFileRef.current.value = ''
            }
        },
        onError: (err: AxiosError) => {
            const importErr = err?.response?.data as { content: {}; error: string }
            // usual error from date formatting
            if (importErr.error.startsWith('parsing time')) {
                setCsvErr('Invalid date format, please refer to the downloadable CSV template.')
                setEmployeeCSVModal(true)
            } else if (importErr.error.startsWith('You have reached')) {
                if (seatLimit == 15) {
                    setCsvErr(
                        'You have exceeded the maximum allowable number of employees for your subscription plan.',
                    )
                    setEmployeeCSVModal(true)
                } else {
                    setCsvErr(
                        'You have exceeded the maximum allowable number of employees for your subscription plan. You can upgrade your subscription plan to add more employees.',
                    )

                    setEmployeeCSVModal(true)
                }
            }
            if (inputFileRef.current) {
                inputFileRef.current.value = ''
            }
        },
    })

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files?.length > 0) {
            const file = files[0]
            addBulkEmployeeMu({ businessId: user.businessId, employeeCSV: file })
        }
    }

    const { mutate: checkSubsRestrictionMu } = useMutation<
        boolean,
        AxiosError,
        { businessId: string; name: string }
    >({
        mutationFn: ({ businessId, name }) => checkSubscriptionFeatureRestriction(businessId, name),
        onSuccess: (res) => {
            if (totalUsers == seatLimit && seatLimit == 15) {
                setRestrictionContactModal(!res)
                return
            } else {
                setRestrictionModal(!res)
            }
            if (res) {
                inputFileRef.current?.click()
            }
        },
    })

    const handleClick = () => {
        checkSubsRestrictionMu({
            businessId: user?.businessId as string,
            name: FEATURE_RESTRICTIONS.userCount,
        })
    }

    return (
        <>
            <ImportEmployeeCSVModal
                open={employeeCSVModal}
                setOpen={setEmployeeCSVModal}
                error={csvErr}
                failedImports={failedImports}
            />
            <input
                type='file'
                accept='.csv,.xlsx,.xls'
                ref={inputFileRef}
                className='hidden'
                onChange={handleFileInputChange}
            />
            <button
                className='w-full h-8 hover:bg-[#00000029] text-left px-3'
                onClick={handleClick}
            >
                Import CSV
            </button>
        </>
    )
}

export default ImportEmployeeCSV
