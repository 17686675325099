import { getCustomerContact } from '@/api/profile'
import { CustomerContactContentType, FullProfileType } from '@/api/profile/schema'
import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import SearchBar from '@/components/InputSearchBox'
import { Modal } from '@/components/Modal'
import { Pagination } from '@/components/Pagination'
import { customerContactContentAtom, customerContactPaginationAtom } from '@/store/customer'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'

interface IBulkSelectModalProps {
    isOpen: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const BulkSelectModal: React.FunctionComponent<IBulkSelectModalProps> = (props) => {
    const [searchKeyword, setSearchKeyword] = useState('')
    const [selectedContact, setSelectedContact] = useAtom(customerContactContentAtom)
    const [selected, setSelected] = useState<CustomerContactContentType[]>([])
    const [pagination, setPagination] = useAtom(customerContactPaginationAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const { data } = useQuery({
        queryKey: ['messagesList', searchKeyword, pagination],
        queryFn: () => getCustomerContact(searchKeyword, pagination, user.businessId)
    }) 

    const handleCheckClick = (content: CustomerContactContentType, isCheck: boolean) => {
        setSelected((prev) => {
            if (isCheck && !prev.some((item) => item.profileId === content.profileId)) {
                return [...prev, content];
            } else if (!isCheck) {
                return prev.filter((item) => item.profileId !== content.profileId);
            }
            return prev
        })
    }

    const handleCheckAllClick = (isCheck: boolean) => {
        if (data) {
            isCheck ? setSelected(data.content) : setSelected([])
        }
    }

    const cancelClearSelected = () => {
        if (selected?.length > 0) {
            setSelected((prev: CustomerContactContentType[]) =>
                prev.filter(
                    (sub: CustomerContactContentType) =>
                        !selectedContact.some(
                            (selectedItem) => selectedItem.profileId === sub.profileId,
                        ),
                ),
            )
        } else {
            setSelected([])
        }
        // submit selected
        setSelected(selectedContact)
        props.onClose(false)
    }

    const submitSelected = () => {
        setSelectedContact(selected)
        props.onClose(false)
    }

    const isAllContactsSelected = useMemo(() => {
        if (!data || !data.content) {
            return false;
        }
        const selectedOnPage = data.content.filter(
            (item) => selected.some((selectedItem) => selectedItem.profileId === item.profileId),
        );
        return selectedOnPage.length === data.content.length;
    }, [selected])
    
    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }, [setPagination])

    return (
        <Modal
            isOpen={props?.isOpen}
            isHideCloseButton
            onClose={() => {
                props.onClose(false)
            }}
            title='Bulk Select'
            titleClassName='text-indigo-900'
            containerClassName='max-w-[900px]'
        >
            <div className='px-5 pb-5'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                />
                <div className='pt-1'>
                    <table className='table-auto text-left w-full border'>
                        <thead>
                            <tr className='border-b text-indigo-900'>
                                <th className='px-6 py-3'>
                                    {' '}
                                    <Checkbox
                                        checked={isAllContactsSelected}
                                        className='mr-1 mt-0.5 accent-zentive-green-dark'
                                        onCheckedChange={(val) =>
                                            handleCheckAllClick(val as boolean)
                                        }
                                    />
                                    &nbsp; Name
                                </th>
                                <th className='px-6 py-3'>Email</th>
                                <th className='px-6 py-3'>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.content?.length ? (
                                data.content?.map((items) => (
                                    <tr key={items.profileId} className='border-b text-indigo-900'>
                                        <td className='px-6 py-3'>
                                            <Checkbox
                                                id={'customer-id' + ' ' + items.profileId}
                                                checked={selected?.some(
                                                    (prev) =>
                                                        prev.profileId === items.profileId ||
                                                        prev.email === items.email,
                                                )}
                                                className='mr-[10px] mt-[2px]'
                                                onCheckedChange={(val) => {
                                                    handleCheckClick(items, val as boolean)
                                                }}
                                            />
                                            &nbsp;
                                            {items.name}
                                        </td>
                                        <td className='px-6 py-3'>{items.email}</td>
                                        <td className='px-6 py-3'>{items.phoneNumber}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} className='text-center py-3 '>
                                        No results found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {pagination.pageSize !== undefined && (
                        <div className='mb-5 mt-3 p-0'>
                            <Pagination
                                itemsPerPage={pagination.pageSize ?? 5}
                                setPagination={setPagination}
                                totalRecords={data?.meta?.totalRecords ?? 1}
                                pagination={pagination}
                            />
                        </div>
                    )}
                </div>
                <div className='mt-3 flex justify-between'>
                    <Button
                        className='w-97 h-11 text-base border-2 border-zentive-green-dark font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark hover:text-white'
                        onClick={() => cancelClearSelected()}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={selected?.length === 0} 
                        onClick={() => submitSelected()}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                    >
                        Proceed
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default BulkSelectModal
