import { getPricingById } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import { Alert, AlertDescription } from '@/components/Alert'
import { Label } from '@/components/Label'
import { manageCurrentSubTypeAtom, manageSubsTabAtom } from '@/store/manageSubs'
import { cn, numberFormatter } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { USER_STATUS } from '@/constants'
import { getBusinessProfile } from '@/api/profile'
import { ProfileSelfResponseType } from '@/api/business/schema'

const Subscription = () => {
    const status = useAtomValue(manageSubsTabAtom)
    const params = new URLSearchParams(window.location.search)
    const expiryDate = params.get('expiryDate')
    dayjs.extend(customParseFormat)
    const subID = useAtomValue(manageCurrentSubTypeAtom)
    const profileID = params.get('profileid')

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile',profileID],
        queryFn: () => getBusinessProfile(profileID as string), // replace this once getAllSubs is already integrated
    })
    

    const { data: plan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['subscriberPlan',businessProfileData],
        enabled: !!subID || !!businessProfileData?.business?.subscriptionTypeId,
        queryFn: () =>
            getPricingById(
                businessProfileData?.business?.subscriptionTypeId as string
            ),
    })

    const getAutoBillDate = (interval: string) => {
        if (status === USER_STATUS.ACTIVATED) {
            const date = dayjs(expiryDate?.substring(0, 10)).format('MMMM DD, YYYY')

            if (interval === 'days') {
                return dayjs(expiryDate?.substring(0, 10))
                    .add(14, 'day')
                    .format('MMMM DD, YYYY')
            } else {
                return date
            }
        } else {
            return 'Billing is Paused'
        }
    }

    if (plan)
        return (
            <section>
                <div className='bg-white w-full py-14'>
                    {status === USER_STATUS.ACTIVATED && (
                        <div className='mb-8 mx-[75px]'>
                            <Alert
                                className={
                                    status === USER_STATUS.ACTIVATED
                                        ? 'bg-zentive-green-dark'
                                        : 'bg-gray-100'
                                }
                            >
                                <AlertDescription
                                    className={cn(
                                        'text-base ',
                                        status === USER_STATUS.ACTIVATED
                                            ? 'text-white'
                                            : 'text-gray-100',
                                    )}
                                >
                                    <Label className='text-base font-semibold'>
                                        {plan?.interval !== 'days'
                                            ? 'Next auto-bill date: '
                                            : 'Free trial expiration date: '}
                                    </Label>
                                    {getAutoBillDate(plan?.interval ?? '')}
                                </AlertDescription>
                            </Alert>
                        </div>
                    )}
                    <Label className='mx-[75px] font-semibold text-base'>Current Plan</Label>
                    <div className='rounded-4px ring-1 mx-[75px] my-2 px-5 py-2'>
                        <div className='flex justify-between'>
                            <div>
                                <Label className='font-semibold text-base'>
                                    {plan?.name ?? ''}
                                </Label>
                                <br />
                                {plan?.interval !== 'days' && (
                                    <>
                                        <Label className='flex text-base text-zentive-gray-medium'>
                                            {`Price: $${numberFormatter.format(plan?.price ?? 0)}`}
                                        </Label>
                                        <Label className='text-base  text-zentive-gray-medium'>
                                            {`Automatic renewal every ${plan?.interval}`}
                                        </Label>
                                    </>
                                )}
                            </div>
                            {status !== USER_STATUS.TERMINATED &&
                                status !== USER_STATUS.BILLING_PAUSED &&
                                plan?.interval !== 'days' && (
                                    <NavLink
                                        className='text-zentive-green-dark'
                                        onClick={() => {}}
                                        to={`/manage-subs/update-plan?subscriptionTypeId=${plan.subscriptionTypeId}&profileID=${profileID}`}
                                    >
                                        Change Plan
                                    </NavLink>
                                )}
                        </div>
                    </div>
                </div>
            </section>
        )
    return <></>
}

export default Subscription
