import { deleteMessage } from '@/api/customer'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useToast } from '@/hooks/useToast'
import { customemrMessageListAtom } from '@/store/customer'
import { paginationAtom } from '@/store/manageSubs'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { RiDeleteBinLine } from 'react-icons/ri'

interface DeleteMessageModalProps {
    messageId: string
}

const DeleteMessageModal = ({ messageId }: DeleteMessageModalProps) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const [pagination, setPagination] = useAtom(paginationAtom)
    const messageList = useAtomValue(customemrMessageListAtom)
    const { mutate: deleteMessageMu } = useMutation({
        mutationFn: (data: string) => deleteMessage(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Deleted',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['messagesList'] })
            if (messageList?.content?.length === 1 && (pagination.page! ?? 0 > 1)) {
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    page: prevPagination.page! - 1,
                }))
            }
        },
    })

    const onCancel = () => {
        // setOpen(false)
    }

    const onSubmit = () => {
        deleteMessageMu(messageId)
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <RiDeleteBinLine className='w-6 h-6' style={{ color: '#405993' }} />
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px]'>
                            Delete Confirmation{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to delete this message?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='absolute self-end py-3 px-6 w-full rounded-b-md justify-center items-center bg-zentive-gray-light'>
                    <div className='w-full flex justify-end items-end gap-x-4'>
                        <AlertDialogCancel
                            onClick={onCancel}
                            className='w-[30%] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[30%] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default DeleteMessageModal
