import { ChangeEvent, FC } from 'react'
import { AddLeadFormProps } from '..'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import InputSelect from '@/components/InputSelect'
import { LEAD_CONTACT_TYPES, LEAD_SOURCES } from '@/constants'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { useQuery } from '@tanstack/react-query'
import { getProductsAndServices } from '@/api/service'
import { Checkbox } from '@/components/Checkbox'
import { SingleProductService } from '@/api/quoting/schema'
import { AxiosError } from 'axios'
import Spinner from '@/components/Spinner'
import { handleNumberInputChange } from '@/utils/eventHandler'
import { Label } from '@/components/Label'
import { cn } from '@/utils/helper'
import { Link } from 'react-router-dom'

const contactTypes = Object.values(LEAD_CONTACT_TYPES).map((item) => ({
    label: item,
    value: item,
}))

const sources = Object.values(LEAD_SOURCES).map((item) => ({
    label: item,
    value: item,
}))

const ContactInformation: FC<
    AddLeadFormProps & {
        isOpen: boolean
    }
> = ({ isOpen, useFormMethods }) => {
    const currentUser = useViewedAsUser()

    const { businessId = '' } = currentUser

    const { data, isSuccess, isLoading } = useQuery<SingleProductService[], AxiosError>({
        enabled: !!(businessId && isOpen),
        queryKey: ['productsAndServices', businessId],
        queryFn: () => getProductsAndServices(businessId),
    })

    const hasLoaded = isSuccess && Array.isArray(data)

    const items: { id: string; label: string }[] =
        data?.map((service) => ({
            id: service?.productServiceId as string,
            label: service?.name as string,
        })) ?? ([] as const)

    const handleDealSizeChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const total = handleNumberInputChange(evt)

        useFormMethods.setValue('dealSize', total, {
            shouldValidate: true,
        })
    }

    const {
        formState: { errors },
    } = useFormMethods

    return (
        <div className='w-full flex flex-col gap-[16px] pb-[36px]'>
            <Label className='text-zentive-gray-medium font-semibold'>Contact Information</Label>
            <FormField
                name='dealSize'
                control={useFormMethods.control}
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                isCurrency
                                placeholder='Deal Size*'
                                type='number'
                                {...field}
                                onChange={handleDealSizeChange}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <div className='grid grid-cols-2 gap-[16px]'>
                <FormField
                    name='firstName'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='First Name*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='lastName'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Last Name*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='email'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Input placeholder='Email Address*' type='text' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='contactNumber'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <PhoneNumberInput
                                    inputProps={{
                                        name: 'Contact Number',
                                        placeholder: 'Contact Number*',
                                    }}
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='contactType'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <InputSelect
                                {...field}
                                options={contactTypes}
                                placeholder='Select Contact Type*'
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    name='source'
                    control={useFormMethods.control}
                    render={({ field }) => (
                        <FormItem>
                            <InputSelect
                                {...field}
                                options={sources}
                                placeholder='Select Source*'
                            />
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={useFormMethods.control}
                    name='productServiceTypes'
                    render={() => (
                        <FormItem className='col-span-2'>
                            <div className='mb-4'>
                                <FormLabel className='text-zentive-gray-medium font-semibold'>
                                    Service Types
                                </FormLabel>
                            </div>

                            {isLoading && (
                                <Spinner variant='normal' className='h-[70px] w-[70px]' />
                            )}

                            {hasLoaded && items.length ? (
                                items?.map((item) => (
                                    <FormField
                                        key={item.id}
                                        control={useFormMethods.control}
                                        name='productServiceTypes'
                                        render={({ field }) => {
                                            return (
                                                <FormItem
                                                    key={item.id}
                                                    className='flex flex-row items-center space-x-3 space-y-0'
                                                >
                                                    <FormControl>
                                                        <Checkbox
                                                            className={cn(
                                                                errors?.productServiceTypes
                                                                    ? 'border-destructive'
                                                                    : '',
                                                            )}
                                                            checked={field.value?.includes(item.label)}
                                                            onCheckedChange={(checked) => {
                                                                return checked
                                                                    ? field.onChange([
                                                                          ...field.value,
                                                                          item.label,
                                                                      ])
                                                                    : field.onChange(
                                                                          field.value?.filter(
                                                                              (value) =>
                                                                                  value !== item.label,
                                                                          ),
                                                                      )
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormLabel className='font-normal'>
                                                        {item.label}
                                                    </FormLabel>
                                                </FormItem>
                                            )
                                        }}
                                    />
                                ))
                            ) : (
                                <Link
                                    className='underline text-zentive-green-dark'
                                    to='/settings/product-services'
                                >
                                    + Add Products or Services
                                </Link>
                            )}
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
        </div>
    )
}
export default ContactInformation
