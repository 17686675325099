import React from 'react'
import { CSVLink } from 'react-csv'

const EmployeeCSVTemplate = ({
    isOpen,
    setIsOpen,
}: {
    isOpen?: boolean
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const csvHeaders = [
        'First Name',
        'Last Name',
        'Email Address',
        'Contact Number',
        'Date of Birth',
        'Chemical Applicator License',
        'Date Hired',
        'Street 1',
        'Street 2',
        'City',
        'State',
        'Zip Code',
        'Pay Per Hour',
    ]

    const csvInputGuide = [
        {
            'First Name': 'John',
            'Last Name': 'Doe',
            'Email Address': 'zentive-test@gmail.com',
            'Contact Number': '1 234 567 8910',
            'Date of Birth': '1996-10-27 08:00:00.000 +0800',
            'Chemical Applicator License': 'SGG-2W3FG',
            'Date Hired': '2023-04-16 08:00:00.000 +0800',
            'Street 1': 'Street Parking',
            'Street 2': '',
            City: 'Los Angeles',
            State: 'California',
            'Zip Code': '90027',
            'Pay Per Hour': '8',
        },
    ]
    return (
        <CSVLink
            data={csvInputGuide}
            headers={csvHeaders}
            filename={'employee-list-template.csv'}
            className={
                'w-[230px] h-[34px] text-zentive-black hover:bg-[#00000029] whitespace-nowrap block px-3 py-1 text-base rounded-b-md'
            }
            onClick={() => {
                setIsOpen && setIsOpen(!isOpen)
            }}
        >
            Download CSV Template
        </CSVLink>
    )
}

export default EmployeeCSVTemplate
