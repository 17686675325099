import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    AddBulkEmployeeType,
    EmployeeCrewDetailsType,
    EmployeeListType,
    EmployeeSelfResponseType,
    UpdateEmployeeStatusType,
    UpdateEmployeeType,
    profileEmployeeSchema,
} from './schema'
import { FullAddress, GeocodeResult, fetchCoordinates, fetchTimezone } from '@/hooks/useGeocode'
import { InviteEmployeeViaCSVType } from '../profile/schema'
import { SortParams } from '../job'

export const getAllEmployees = async (
    keyword: string,
    status: string,
    businessId: string,
    p: PaginationType,
    sort: SortParams,
): Promise<EmployeeListType> => {
    const { page, pageSize } = p

    const params = {
        keyWord: keyword,
        businessId: businessId,
        status: status,
        page: page,
        pageSize: pageSize,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const response = await axiosInstance.get(`/profiles/employees`, { params })

    return response.data
}

export const updateEmployeeProfile = async (
    data: UpdateEmployeeType,
): Promise<EmployeeSelfResponseType> => {
    const payload = profileEmployeeSchema.parse(data)
    const formData = new FormData()

    const coordinates = await fetchCoordinates(payload.address as FullAddress)
    const timeZone = await fetchTimezone(coordinates as GeocodeResult)

    formData.append('profileId', payload.profileId as string)
    formData.append('addressId', payload.addressId as string)
    formData.append('firstName', payload.firstName as string)
    formData.append('lastName', payload.lastName as string)
    formData.append('phoneNumber', payload.phoneNumber as string)
    formData.append('streetAddress', payload.address.streetAddress as string)
    formData.append('streetTwoAddress', payload.address.streetTwoAddress as string)
    formData.append('city', payload.address.city as string)
    formData.append('state', payload.address.state as string)
    formData.append('zipCode', payload.address.zipCode as string)
    formData.append('caLicense', payload.caLicense as string)
    formData.append('compensation', String(payload.compensation))
    formData.append('dateOfBirth', payload.dateOfBirth as string)
    formData.append('timeOffset', timeZone?.rawOffset.toString() as string)
    formData.append('timezone', timeZone?.timeZoneId as string)

    if (typeof coordinates?.lat === 'number') {
        formData.append('gmapLat', String(coordinates.lat))
    }

    if (typeof coordinates?.lng === 'number') {
        formData.append('gmapLng', String(coordinates.lng))
    }

    if (payload.profileImage) {
        formData.append('profileImage', payload.profileImage)
    }

    try {
        const response = await axiosInstance.patch('/profiles/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    } catch (err) {
        console.error('Failed to update profile:', err)
        throw err
    }
}

export const updateEmployeeStatus = async (data: UpdateEmployeeStatusType) => {
    const response = await axiosInstance.post('/profiles/status', data)

    return response
}

export const getNextEmployeeNumber = async (businessId: string) => {
    const response = await axiosInstance.get(`crews/next-emp-no/${businessId}`)
    return response.data.content
}

export const addBulkEmployee = async (
    data: AddBulkEmployeeType,
): Promise<InviteEmployeeViaCSVType> => {
    const formData = new FormData()

    formData.append('businessId', data.businessId ?? '')
    formData.append('employeeCSV', data.employeeCSV)

    try {
        const response = await axiosInstance.post('/profiles/import/employee', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data.content
    } catch (err) {
        console.error('Failed to export employees via csv:', err)
        throw err
    }
}

export const getEmployeeCrewDetails = async (
    profileId: string,
    isForeman: boolean,
): Promise<EmployeeCrewDetailsType> => {
    const response = await axiosInstance.get(
        `crews/employee-crew-details?id=${profileId}&isForeman=${isForeman}`,
    )

    return response.data.content
}
