import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { Card, CardContent, CardHeader } from '@/components/Card'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { Button } from '@/components/Button'
import { Cross2Icon } from '@radix-ui/react-icons'
import FileSizeModal from './FileSizeModal'

export type FileInfo = {
    name: string
    size: number
    type: string
}

export const NotesAndAttachments = () => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const dragDropRef = useRef<HTMLDivElement | null>(null)
    const fileInputLocalRef = useRef<HTMLInputElement | null>(null)
    const uploadedFiles: File[] = watch('jobImages')

    const [fileSizeModal, setFileSizeModal] = useState<boolean>(false)
    const [nonImageError, setNonImageError] = useState<boolean>(false)

    const uploadedFilesErrors = errors?.jobImages ? errors.jobImages : []
    const imagePreviews = useMemo(
        () =>
            uploadedFiles?.map((file) =>
                URL.createObjectURL(
                    new Blob(Array(file), {
                        type: file.type,
                    }),
                ),
            ) || [],
        [uploadedFiles],
    )

    const MAX_FILE_SIZE_MB = 5 // Example: Maximum file size allowed in megabytes

    const handleFileChange = (fileList: FileList | null, onChange: (val: FileInfo[]) => void) => {
        if (!fileList || fileList?.length === 0) return

        const file: File = fileList[0]

        // Check if the file is an image
        if (!file.type.startsWith('image/')) {
            // Show error for non-image file
            setNonImageError(true)
            return
        }

        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            setFileSizeModal(true)
            return
        }

        const existingFiles = uploadedFiles
        onChange([...existingFiles, file])
    }

    const handleFiles = useCallback(
        (files: FileList) => {
            const fileArray = Array.from(files)

            // Filter out non-image files
            const validFiles = fileArray.filter(
                (file) =>
                    file.type.startsWith('image/') && file.size <= MAX_FILE_SIZE_MB * 1024 * 1024,
            )

            // Update form value for 'jobImages' with new files info
            setValue('jobImages', [...uploadedFiles, ...validFiles], { shouldValidate: true })
        },
        [setValue, uploadedFiles],
    )

    const handleDragOver = useCallback((e: DragEvent) => {
        e.preventDefault() // Necessary to allow for drop
    }, [])

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault()
            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files?.length > 0) {
                handleFiles(e.dataTransfer.files)
                e.dataTransfer.clearData()
            }
        },
        [handleFiles],
    )

    const handleFileRemove = (val: number) => {
        const filesToRetain = uploadedFiles.filter((_, index) => index !== val)

        setValue('jobImages', filesToRetain, { shouldValidate: true })
    }

    useEffect(() => {
        const div = dragDropRef.current
        if (div) {
            div.addEventListener('dragover', handleDragOver as (e: Event) => void)
            div.addEventListener('drop', handleDrop as (e: Event) => void)

            return () => {
                div.removeEventListener('dragover', handleDragOver as (e: Event) => void)
                div.removeEventListener('drop', handleDrop as (e: Event) => void)
            }
        }
    }, [handleDragOver, handleDrop])

    useEffect(() => {
        return () => {
            imagePreviews.forEach((url) => window.URL.revokeObjectURL(url))
        }
    }, [imagePreviews])

    useEffect(() => {
        // Clear non-image error state when uploadedFiles change
        if (uploadedFiles?.length > 0) {
            setNonImageError(false)
        }
    }, [uploadedFiles])

    return (
        <Card className='rounded-none'>
            <FileSizeModal open={fileSizeModal} setOpen={setFileSizeModal} />
            <CardHeader className='bg-zentive-bg-100 text-zentive-black text-lg font-semibold'>
                Internal Notes & Attachments
            </CardHeader>
            <CardContent className='p-6'>
                <FormField
                    control={control}
                    name='description'
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    className='border p-4 h-24 w-full max-w-full border-gray-400 text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0'
                                    placeholder='Description'
                                    {...field}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <div
                    className='bg-[#F1F8DF] h-[65.02px] flex flex-row gap-2 items-center justify-center mt-6'
                    ref={dragDropRef}
                >
                    <p className='text-sm'>Drag your image here or</p>
                    <Button
                        className='h-[22px] w-[143px] text-sm bg-transparent text-zentive-green-dark font-semibold border-zentive-blue-dark hover:bg-transparent'
                        onClick={() => {
                            if (fileInputLocalRef.current) {
                                fileInputLocalRef.current.click()
                            }
                        }}
                        variant='outline'
                        type='button'
                    >
                        Upload Image
                    </Button>
                    <FormField
                        control={control}
                        name='jobImages'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='hidden'
                                        type='file'
                                        multiple={true}
                                        onChange={(evt) =>
                                            handleFileChange(evt.target.files, field.onChange)
                                        }
                                        name={field.name}
                                        onBlur={field.onBlur}
                                        ref={(e) => {
                                            fileInputLocalRef.current = e
                                            field.ref(e)
                                        }}
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>
                {nonImageError && (
                    <div className='mt-2 text-red-500'>Only image files are allowed.</div>
                )}
            </CardContent>

            <CardContent className='p-6 border-t-2 border-zentive-gray-light'>
                <div className='h-[82px] w-full flex flex-row gap-[16px] '>
                    {uploadedFiles?.length && imagePreviews?.length
                        ? imagePreviews?.map((src, index) => (
                              <div className='relative h-[80px] w-[82px]' key={src}>
                                  <img
                                      key={index}
                                      src={src}
                                      alt={uploadedFiles[index].name}
                                      className='h-full w-full object-cover'
                                  />
                                  <div
                                      className='absolute top-0 right-0 cursor-pointer'
                                      onClick={() => handleFileRemove(index)}
                                  >
                                      <Cross2Icon className='bg-white text-zentive-green-dark font-semibold w-5 h-5' />
                                  </div>
                              </div>
                          ))
                        : null}
                </div>

                {Object.keys(uploadedFilesErrors)?.length ? (
                    <div className='mt-[24px]'>
                        {Object.keys(uploadedFilesErrors)?.map((index) => (
                            <p className='text-base text-zentive-red-dark' key={`error=${index}`}>
                                {`File ${Number(index) + 1} ${(uploadedFilesErrors as FieldError[])[
                                    Number(index)
                                ]?.message}`}
                            </p>
                        ))}
                    </div>
                ) : null}
            </CardContent>
        </Card>
    )
}
