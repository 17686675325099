import { UpdateJobAttachmentDescription, updateJobAttachmentDescription } from '@/api/job'
import { ReadJobType } from '@/api/job/schema'
import { Button } from '@/components/Button'
import { Card, CardContent, CardFooter } from '@/components/Card'
import { Textarea } from '@/components/TextArea'
import { useToast } from '@/hooks/useToast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

interface IJobAttachmentDescription {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    job: ReadJobType
}

const EditJobAttachmentDescription = ({ job, setIsOpen }: IJobAttachmentDescription) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const [description, setDescription] = useState<string>('')

    const { mutate: updateJobAttachmentDescriptionMu } = useMutation({
        mutationFn: (data: UpdateJobAttachmentDescription) => updateJobAttachmentDescription(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully updated!',
                variant: 'default',
            })
            setIsOpen(false)
        },
        onError: () =>
            toast({
                description: 'Unable to update job attachment description.',
                variant: 'destructive'
            }),
    })

    useEffect(() => {
        setDescription(job?.description as string)
    }, [job])

    return (
        <Card className='rounded-none bg-transparent border border-zentive-green-dark bg-white'>
            <CardContent className='pb-6 flex flex-col pt-4'>
                <div className={``}>
                    <Textarea
                        className='border p-4 h-24 w-full max-w-full border-gray-400 text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0'
                        placeholder='Description'
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value)
                        }}
                    />
                </div>
            </CardContent>
            <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] '>
                <div className='flex space-x-4 mt-[16px]'>
                    <Button
                        type='button'
                        variant={'outline'}
                        className='w-97 mr-3 text-zentive-green-dark font-semibold'
                        onClick={() => {
                            setIsOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className='w-97 mr-5'
                        disabled={job?.description === description || description === ''}
                        onClick={() =>
                            updateJobAttachmentDescriptionMu({
                                jobId: job?.jobId as string,
                                description: description,
                            })
                        }
                    >
                        Update
                    </Button>
                </div>
            </CardFooter>
        </Card>
    )
}

export default EditJobAttachmentDescription
