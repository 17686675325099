import React, { FormEventHandler, useEffect, useState } from 'react'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { Checkbox } from '@/components/Checkbox'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader } from '@/components/Card'
import stripeCC from '@/assets/public/Edited-CC.png'
import poweredByStripe from '@/assets/public/Powered-by-Stripe.png'
import { useAtomValue, useSetAtom } from 'jotai'
import { userAtom } from '@/store/auth'
import { useMutation } from '@tanstack/react-query'
import { updateCreditCard } from '@/api/owner'
import { UpdateCreditCardType } from '@/api/owner/schema'
import { AxiosError } from 'axios'
import ActionButton from '../ActionButton'
import { toast } from '@/hooks/useToast'
import { getStripeErrorMessage, StripeErrorTypes } from '@/utils/stripe'
import { IoMdInformationCircle } from 'react-icons/io'
import Tooltip from '@/components/Tooltip'
import { isSetupPaymentOpenAtom } from '@/store/subscription'

const ChangeCardDetails: React.FC = () => {
    const user = useAtomValue(userAtom)
    const stripe = useStripe()
    const navigate = useNavigate()
    const elements = useElements()
    const [agree, setAgree] = useState<boolean>(false)
    const [cardNumberError, setCardNumberError] = useState<string | null>(null)
    const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false)

    const [cardExpiryError, setCardExpiryError] = useState<string | null>(null)
    const [cardExpiryComplete, setCardExpiryComplete] = useState<boolean>(false)

    const [cardCvcError, setCardCvcError] = useState<string | null>(null)
    const [cardCvcComplete, setCardCvcComplete] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const setSetupPaymentAtom = useSetAtom(isSetupPaymentOpenAtom)

    const getErrorSetter = (type: StripeErrorTypes) => {
        switch (type) {
            case 'cardNum':
                return setCardNumberError
            case 'cvc':
                return setCardCvcError
            default:
                return setCardExpiryError
        }
    }

    const { mutate: UpdateCreditCardMu } = useMutation<string, AxiosError, UpdateCreditCardType>({
        mutationFn: async (updateCard: UpdateCreditCardType) => updateCreditCard(updateCard),
        onSuccess: () => {
            setIsLoading(false)
            toast({
                description: 'Sucessfully Updated',
            })

            setSetupPaymentAtom(false)
            navigate(-1)
        },
        onError: (err) => {
            const cardError = JSON.parse(err.message)

            const { message, type } = getStripeErrorMessage(
                cardError.decline_code,
                cardError.code,
                cardError.message,
            )

            const setError = getErrorSetter(type)
            setError(message)
        },
    })

    const updateCardMu = useMutation<unknown, StripeError>({
        mutationFn: async () => {
            setIsLoading(true)

            if (!stripe || !elements) return

            const cardElement = elements.getElement(CardNumberElement)

            if (!cardElement) {
                console.error('Card Element not found')
                throw new Error('Card Element not found')
            }

            const res = await stripe.createToken(cardElement)

            const { token, error } = res

            if (error) {
                const { message, type } = getStripeErrorMessage(
                    error.decline_code,
                    error.code,
                    error.message,
                )

                const setError = getErrorSetter(type)
                setError(message)
            }

            if (token?.id !== null) {
                UpdateCreditCardMu({
                    profileId: user?.profileId,
                    tokenId: token?.id,
                } as UpdateCreditCardType)
            } else {
                throw new Error(`Error adding new payment method`)
            }
        },
        onSuccess: () => {},
        onError: () => {},
    })

    const handlePayment: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        try {
            await updateCardMu.mutateAsync()
        } catch (error) {
            // Handle mutation error
            console.error('Mutation error:', error)
        }
    }

    useEffect(() => {
        // Add event listeners for card element change
        const cardNumberElement = elements?.getElement(CardNumberElement)
        const cardExpiryElement = elements?.getElement(CardExpiryElement)
        const cardCvcElement = elements?.getElement(CardCvcElement)

        const handleCardNumberChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardNumberError(null)
            setIsLoading(false)
            setCardNumberComplete(complete)
            setCardNumberError(error ? error.message ?? '' : null)
        }

        const handleCardExpiryChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardExpiryError(null)
            setIsLoading(false)
            setCardExpiryComplete(complete)
            setCardExpiryError(error ? error.message ?? '' : null)
        }

        const handleCardCvcChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardCvcError(null)
            setIsLoading(false)
            setCardCvcComplete(complete)
            setCardCvcError(error ? error.message ?? '' : null)
        }

        if (cardNumberElement) {
            cardNumberElement.on('change', handleCardNumberChange)
        }

        if (cardExpiryElement) {
            cardExpiryElement.on('change', handleCardExpiryChange)
        }

        if (cardCvcElement) {
            cardCvcElement.on('change', handleCardCvcChange)
        }

        return () => {
            // Remove the event listeners when the component unmounts
            if (cardNumberElement) {
                cardNumberElement.off('change', handleCardNumberChange)
            }

            if (cardExpiryElement) {
                cardExpiryElement.off('change', handleCardExpiryChange)
            }

            if (cardCvcElement) {
                cardCvcElement.off('change', handleCardCvcChange)
            }
        }
    }, [elements])

    return (
        <form onSubmit={handlePayment}>
            <Card className='w-full mt-10 flex justify-center'>
                <div className='flex-col mt-[36px] w-full'>
                    <CardHeader className='space-y-4'>
                        <div className='flex flex-col items-center w-full'>
                            <img
                                src={stripeCC}
                                alt='Stripe CC Logo'
                                className='h-[29px] w-[241px] '
                            />
                            <img
                                src={poweredByStripe}
                                alt='Powered by Stripe Logo'
                                className='h-[21.93px] w-[96.76px]'
                            />
                        </div>
                    </CardHeader>
                    <CardContent className='space-y-4 pt-5'>
                        <div className='flex-col'>
                            <div className='flex justify-center gap-4 ml-8'>
                                <div className='flex-col'>
                                    <div className='w-100 '>
                                        <CardNumberElement
                                            options={{
                                                showIcon: false,
                                                style: {
                                                    base: {
                                                        color: cardNumberError ? '#F44336' : '',
                                                    },
                                                },
                                            }}
                                            className='w-full rounded-[2px] border px-3 py-3 text-sm shadow-sm h-11 border-zentive-gray-medium'
                                        />
                                        <div className='text-red-500'>{cardNumberError}</div>
                                    </div>
                                    <div className='flex justify-between w-100 mt-[18px]'>
                                        <div className='w-[142px]'>
                                            <CardExpiryElement
                                                options={{
                                                    style: {
                                                        base: {
                                                            color: cardExpiryError ? '#F44336' : '',
                                                        },
                                                    },
                                                }}
                                                className='w-full rounded-[2px] border px-3 py-3 text-sm shadow-sm h-11 border-zentive-gray-medium'
                                            />
                                            <div className='text-red-500'>{cardExpiryError}</div>
                                        </div>
                                        <div className='w-[142px]'>
                                            <CardCvcElement
                                                options={{
                                                    style: {
                                                        base: {
                                                            color: cardCvcError ? '#F44336' : '',
                                                        },
                                                    },
                                                }}
                                                className='w-full rounded-[2px] border px-3 py-3 text-sm shadow-sm h-11 border-zentive-gray-medium'
                                            />
                                            <div className='text-red-500'>{cardCvcError}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-col mt-[74px]'>
                                    <Tooltip
                                        position='bottom'
                                        alignment='start'
                                        width='64'
                                        fontstyle='font-sans'
                                        content={
                                            <p className='text-zentive-green-medium'>
                                                VISA / Mastercard
                                                {
                                                    <p className='text-zentive-gray-bg'>
                                                        A 3-digit number in reverse italics on the
                                                        back of your card
                                                    </p>
                                                }
                                                {<br />}
                                                {
                                                    <p className='text-zentive-green-medium'>
                                                        American Express
                                                    </p>
                                                }
                                                {
                                                    <p className='text-zentive-gray-bg'>
                                                        A 4-digit number on the front, just above
                                                        your credit card number
                                                    </p>
                                                }
                                            </p>
                                        }
                                    >
                                        <IoMdInformationCircle />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='mt-7'>
                                <p className='text-zentive-gray-medium text-center w-3/5 mx-auto'>
                                    Your personal data will only be used to process your order,
                                    support your experience throughout this application, and for
                                    other purposes described in our payment &nbsp;
                                    <a
                                        href='https://zentive.io/privacy-policy/'
                                        target='_blank'
                                        className='text-indigo-900 underline'
                                    >
                                        Privacy Policy.
                                    </a>
                                </p>
                            </div>
                            <div className='flex text-center items-center mt-7 justify-center'>
                                <span className='flex self-start p-1'>
                                    <Checkbox
                                        checked={agree}
                                        onCheckedChange={() => setAgree(!agree)}
                                    />
                                </span>

                                <p className='text-zentive-gray-medium'>
                                    I have read and agree to the website &nbsp;
                                    <a
                                        href='https://zentive.io/terms-of-service/'
                                        target='_blank'
                                        className='text-indigo-900 underline'
                                    >
                                        Terms and Conditions
                                    </a>
                                    <span className='text-red-800'>*</span>
                                </p>
                            </div>
                        </div>
                    </CardContent>
                    <CardContent className='flex justify-end bg-[#EBEBEB] p-4'>
                        <div className='my-auto'>
                            <ActionButton
                                disabled={
                                    agree &&
                                    cardNumberComplete &&
                                    cardExpiryComplete &&
                                    cardCvcComplete &&
                                    cardNumberError === null &&
                                    cardExpiryError === null &&
                                    cardCvcError === null &&
                                    !isLoading
                                }
                                isLoading={isLoading}
                            />
                        </div>
                    </CardContent>
                </div>
            </Card>
        </form>
    )
}

export default ChangeCardDetails
