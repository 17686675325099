import { InvoiceListRecordType } from '@/api/customer/schema'
import { DialogHeader } from '@/components/Dialog'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'

interface InvoiceListTypeProps {
    invoiceList: InvoiceListRecordType
}

const InvoiceDialogHeader = ({ invoiceList }: InvoiceListTypeProps) => {
    return (
        <DialogHeader>
            <div className='flex justify-center mt-3'>
                <div className='row-auto w-full'>
                    <div className='flex  justify-center'>
                        <img src={zentiveLogo} alt='Zentive Logo' className='h-[44px] w-[125px]' />
                    </div>
                    <div className='font-sans text-2xl py-10 font-semibold'>Invoice</div>
                    <div className='grid grid-cols-2 justify-items-start w-full'>
                        <div className='flex flex-col'>
                            <p className='font-bold text-left'>
                                {invoiceList?.ownerBillingInfo.firstName}{' '}
                                {invoiceList?.ownerBillingInfo.lastName}
                            </p>
                            <p className='text-gray-500 text-left'>
                                {invoiceList?.ownerBillingInfo.address.streetOne &&
                                    invoiceList.ownerBillingInfo.address.streetOne + ', '}
                            </p>
                            <p className='text-gray-500 text-left'>
                                {invoiceList?.ownerBillingInfo.address.city &&
                                    invoiceList.ownerBillingInfo.address.city + ', '}
                                {invoiceList?.ownerBillingInfo.address.state &&
                                    invoiceList.ownerBillingInfo.address.state + ', '}
                                {invoiceList?.ownerBillingInfo.address.zipCode}
                            </p>
                            <p className='text-gray-500 text-left'>
                                {invoiceList?.ownerBillingInfo.contactNumber}
                            </p>
                        </div>
                        <div className='flex flex-col w-full  items-end'>
                            <div className='justify-items-start'>
                                <p className='font-bold text-left'>Billed To</p>
                                <p className='text-gray-500 text-left'>
                                    {invoiceList?.customerBillingInfo.firstName}{' '}
                                    {invoiceList?.customerBillingInfo.lastName}
                                </p>
                                <p className='text-gray-500 text-left'>
                                    {invoiceList?.customerBillingInfo.address.streetOne &&
                                        invoiceList.customerBillingInfo.address.streetOne + ', '}
                                </p>
                                <p className='text-gray-500 text-left'>
                                    {invoiceList?.customerBillingInfo.address.city &&
                                        invoiceList.customerBillingInfo.address.city + ', '}
                                    {invoiceList?.customerBillingInfo.address.state &&
                                        invoiceList.customerBillingInfo.address.state + ', '}
                                    {invoiceList?.customerBillingInfo.address.zipCode}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DialogHeader>
    )
}

export default InvoiceDialogHeader
