import { JobListType } from '@/api/job/schema'
import { Card, CardContent } from '@/components/Card'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { APPOINTMENT_TYPES, JOB_STATUS, JOB_STATUS_LABEL, getJobFontColor } from '@/constants'
import { editJobAtom, jobSortByAtom, jobSortOrderAtom } from '@/store/job'
import { cn } from '@/utils/helper'
import { UseQueryResult } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useSetAtom } from 'jotai'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'
import { US_FORMAT } from '@/constants'
import { formatRecordNumber } from '@/utils/helper'

const tableHeader = [
    'Job Number',
    'Client',
    'Title/Property',
    'Schedule',
    'Invoice Status',
    'Job Status',
    'Crew',
    'Type',
]

interface IJobTable {
    jobs: UseQueryResult<JobListType>
}

const JobTable: React.FC<IJobTable> = ({ jobs }) => {
    const [sortBy, setSortBy] = useAtom(jobSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(jobSortOrderAtom)
    const isEdit = useSetAtom(editJobAtom)

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                >
                                    <div className='inline-flex gap-1'>
                                        {header}
                                        {header === 'Client'
                                            ? ` (${jobs.data?.content?.length})`
                                            : null}
                                        <button
                                            onClick={() => onHandleSorting(header)}
                                            disabled={header === 'Invoice Status'}
                                            className='ml-2'
                                        >
                                            {getSortIcon(header)}
                                        </button>
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(jobs?.data?.content) &&
                            jobs?.data?.content?.length > 0 &&
                            jobs?.data?.content?.map((job) => (
                                <TableRow key={job.jobId} className='whitespace-nowrap'>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p className='text-base'>
                                                {formatRecordNumber(job.jobNumber ?? 0)}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/jobs/${job.jobId}`}
                                            onClick={() => isEdit(false)}
                                        >
                                            <p>{job?.quote?.name}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p>
                                                {job?.address?.streetAddress +
                                                    ', ' +
                                                    job?.address?.city +
                                                    ', ' +
                                                    job?.address?.state}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <div className='flex flex-col text-base'>
                                                {job.status !== JOB_STATUS.COMPLETED ? (
                                                    <p>
                                                        {dayjs(
                                                            job?.appointment?.startDateTime,
                                                        ).format(US_FORMAT)}
                                                    </p>
                                                ) : (
                                                    <p>COMPLETED</p>
                                                )}
                                                <p>
                                                    {dayjs(job?.appointment?.endDateTime).format(
                                                        US_FORMAT,
                                                    )}
                                                </p>
                                            </div>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p className='text-zentive-black text-base'>
                                                {job?.invoice?.status
                                                    ? job?.invoice?.status
                                                    : 'Reminder: Upon Job Completion'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p
                                                className={cn(
                                                    'text-base',
                                                    getJobFontColor(job?.status as string),
                                                )}
                                            >
                                                {job?.status && JOB_STATUS_LABEL[job?.status]}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p className='text-zentive-black text-base'>
                                                {job?.crew ? job?.crew?.description : 'Unassigned'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink to={`/schedule/jobs/${job.jobId}`}>
                                            <p className='text-zentive-black text-base'>
                                                {job?.appointment?.appointmentType ===
                                                APPOINTMENT_TYPES.ONEOFF_JOB
                                                    ? 'One-off Job'
                                                    : 'Recurring Job'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}
export default JobTable
