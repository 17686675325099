import { getCustomersRouteByRouteId } from '@/api/routing'
import { CustomersRouteByRouteIdType } from '@/api/routing/schema'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

const useRoutePolyline = (routeId: string) => {
    const queryData = useQuery<unknown, AxiosError, CustomersRouteByRouteIdType>({
        queryKey: ['routePolyline', routeId],
        queryFn: () => getCustomersRouteByRouteId(routeId as string),
        enabled: !!routeId,
        staleTime: 5000,
    })

    return queryData
}

export default useRoutePolyline
