import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { NET_GROSS_TABS } from '@/constants'
import { netGrossExportAtom, netGrosstabsATom } from '@/store/reports'
import { useSetAtom } from 'jotai'

export const NetGrossTabs = () => {
    const setTabValue = useSetAtom(netGrosstabsATom)

    const setExportAtom = useSetAtom(netGrossExportAtom)

    const handleTabChange = (val: string) => {
        setTabValue(val)
        setExportAtom(null)
    }

    return (
        <Tabs
            defaultValue={NET_GROSS_TABS.gross}
            onValueChange={(val) => handleTabChange(val)}
            className='rounded-b-none'
        >
            <TabsList className='w-full flex flex-row space-x-0 rounded-b-none p-0'>
                <TabsTrigger
                    onClick={() => handleTabChange(NET_GROSS_TABS.gross)}
                    value={NET_GROSS_TABS.gross}
                    className={
                        'w-1/2 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]:border data-[state=active]:border-zentive-blue-dark data-[state=active]:border-b-2 data-[state=active]:border-x-0 data-[state=active]:border-t-0 data-[state=active]:font-bold data-[state=active]:border-b-4 bg-white sm:truncate ...'
                    }
                >
                    Gross
                </TabsTrigger>
                <TabsTrigger
                    onClick={() => handleTabChange(NET_GROSS_TABS.net)}
                    value={NET_GROSS_TABS.net}
                    className={
                        'w-1/2 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]:border data-[state=active]:border-zentive-blue-dark data-[state=active]:border-b-2 data-[state=active]:border-x-0 data-[state=active]:border-t-0 data-[state=active]:font-bold data-[state=active]:border-b-4 bg-white sm:truncate ...'
                    }
                >
                    Net
                </TabsTrigger>
            </TabsList>
        </Tabs>
    )
}
