import { chekcCustomerResponseValidity, updateEncryptedProfileStatus } from '@/api/profile'
import { EncryptedProfileStatusType, UserAccountStatusType } from '@/api/profile/schema'
import resultLogo from '@/assets/public/declined_invitation_email.svg'
import verifiedLogo from '@/assets/public/open-envelope-invitation.svg'
import { Button } from '@/components/Button'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/Card'
import { USER_STATUS } from '@/constants'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CustomerInvitationResult = () => {
    const [result, setResult] = useState<UserAccountStatusType | null>(null)
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate()

    const queryParams = new URLSearchParams(window.location.search)

    const { mutate: UpdateEncryptedProfileStatusMu } = useMutation<
        UserAccountStatusType,
        AxiosError,
        EncryptedProfileStatusType
    >({
        mutationFn: updateEncryptedProfileStatus,
        onSuccess: (data) => {
            setResult(data)
            setIsLoading(false)
        },
        onError: () => {
            navigate('/401')
        },
    })

    useEffect(() => {
        const checkResponseValidity = async (profileId: string, businessId: string) => {
            try {
                const isValid = await chekcCustomerResponseValidity(profileId, businessId)
                return isValid
            } catch (error) {
                setResult(null)
            }
        }

        const res = queryParams.get('res')
        const profileId = queryParams.get('profileId')
        const businessId = queryParams.get('businessId')

        if (profileId && businessId) {
            checkResponseValidity(profileId, businessId).then((isValid) => {
                if (isValid) {
                    UpdateEncryptedProfileStatusMu({
                        profileId: profileId as string,
                        businessId: businessId as string,
                        encryptedStatus: res as string,
                    })
                } else {
                    setResult(null)
                    setIsLoading(false)
                }
            })
        } else {
            setResult(null)
            setIsLoading(false)
        }
    }, [UpdateEncryptedProfileStatusMu, navigate])

    if (isLoading) {
        return <div>Loading...</div> // Loading indicator
    }

    return result === USER_STATUS.ACTIVATED ? (
        <Card className='bg-zentive-gray-bg border-none shadow-none'>
            <CardHeader>
                <div className='flex justify-center content-center w-full my-10'>
                    <img
                        src={verifiedLogo}
                        alt='Verified Logo'
                        className='w-[20rem] h-[15rem]'
                    />
                </div>
                <CardTitle className='text-zentive-green-dark text-center'>Invitation Accepted!</CardTitle>
            </CardHeader>
            <CardContent className='text-center'>
                <div className='text-zentive-gray-medium text-center'>
                    <p>Thank you for accepting our invite. Please click the button below to sign in. </p>
                    <p>If you encounter any problem, please reach out to us at <a>support@zentive.io</a>.</p>
                </div>
            </CardContent>
            <CardFooter>
                <div className='flex justify-center content-center w-full'>
                    <Button className='bg-zentive-green-dark px-20' onClick={() => navigate('/sign-in')}>
                        Go to Sign In
                    </Button>
                </div>
            </CardFooter>
        </Card>
    ) : (
        <Card className='bg-zentive-gray-bg border-none shadow-none'>
            <CardHeader>
                <div className='flex justify-center content-center w-full my-10'>
                    <img src={resultLogo} alt='Result Logo' className='w-[20rem] h-[15rem]' />
                </div>
                <CardTitle className='text-zentive-green-dark text-center font-bold'>
                    {result === null ? 'Invitation Expired' : 'Invitation Declined!'}
                </CardTitle>
            </CardHeader>
            <CardContent className='text-center mb-10'>
                <div className='text-zentive-gray-dark text-center w-[50rem]'>
                    {result !== null && (
                        <p>
                            Thank you for your response. We understand that you have chosen not to
                            join our customer portal at this time. If you change your mind, please
                            reach out to us at <b>{queryParams.get('email')}</b> to receive a new
                            invitation.
                        </p>
                    )}
                </div>
            </CardContent>
        </Card>
    )
}

export default CustomerInvitationResult
