import { useEffect } from 'react'
import dayjs from 'dayjs'
import { FieldValues, Path, UseFormClearErrors, UseFormSetError } from 'react-hook-form'
import { BASE_DATE, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { formatTo24HourClock, formatToCustomerTime, formatToUTCWithOffset } from '@/utils/time'

type UseTimeValidationParams<T extends FieldValues> = {
    endDateTime: string
    endField: Path<T>
    startDateTime: string
    startField: Path<T>
    clearErrors: UseFormClearErrors<T>
    setError: UseFormSetError<T>
    additionalDeps?: any[]
    jobStartDateTime?: string
    timezone?: string
    skipEndDateValidation?: boolean
}

const DATE_TIME_MESSAGES = {
    ADVANCE_DATE: 'Date must not use previous dates',
    ADVANCE_START_TIME: 'Start time must be before End time',
    INVALID_DATE_FORMAT: 'Invalid date format',
    SAME_START_AND_END_TIME: 'Start time and End time must not be the same',
    WAY_AHEAD_OF_SCHEDULE: 'Start time cannot be ahead of schedule'
}

const useTimeValidation = <T extends FieldValues>({
    startDateTime,
    endDateTime,
    setError,
    clearErrors,
    startField,
    endField,
    additionalDeps = [],
    jobStartDateTime,
    timezone,
    skipEndDateValidation

}: UseTimeValidationParams<T>) => {
    useEffect(() => {
        const start = dayjs(startDateTime)
        const end = dayjs(endDateTime)

        const startHourMinute = dayjs(`${BASE_DATE} ${formatTo24HourClock(startDateTime)}`)
        const endHourMinute = dayjs(`${BASE_DATE} ${formatTo24HourClock(endDateTime)}`)

        const errors: {
            field: Path<T>
            message: string
        }[] = []

        if (start.isBefore(dayjs(), 'day')) {
            errors.push({
                field: 'jobDate' as Path<T>,
                message: DATE_TIME_MESSAGES.ADVANCE_DATE,
            })
        }

        

        if (!skipEndDateValidation) {
            if (!start.isValid()) {
                errors.push({ field: startField, message: DATE_TIME_MESSAGES.INVALID_DATE_FORMAT })
            }
            
            if (!end.isValid()) {
                errors.push({ field: endField, message: DATE_TIME_MESSAGES.INVALID_DATE_FORMAT })
            }
        } 

        if (startHourMinute.isAfter(endHourMinute)) {
            errors.push({
                field: startField,
                message: DATE_TIME_MESSAGES.ADVANCE_START_TIME,
            })
        }

        if (startHourMinute.isSame(endHourMinute)) {
            errors.push(
                {
                    field: startField,
                    message: DATE_TIME_MESSAGES.SAME_START_AND_END_TIME,
                },
                {
                    field: endField,
                    message: DATE_TIME_MESSAGES.SAME_START_AND_END_TIME,
                },
            )
        }

        if (startDateTime && jobStartDateTime) {
            const formatStartTime = formatToUTCWithOffset(formatToCustomerTime(startDateTime, timezone), timezone || "Asia/Singapore", ISO_8601_WITH_UTC_OFFSET)
            if (dayjs(formatStartTime).isBefore(jobStartDateTime)) {
                errors.push(
                    {
                        field: startField,
                        message: DATE_TIME_MESSAGES.WAY_AHEAD_OF_SCHEDULE,
                    }
                )
            }
        }

        setTimeout(() => {
            clearErrors([startField, endField, 'jobDate' as Path<T>])
        }, 0)

        if (errors?.length > 0) {
            errors.forEach((error) => {
                setTimeout(() => {
                    setError(error.field, { message: error.message })
                }, 0)
            })
        }
    }, [startDateTime, endDateTime, setError, clearErrors, startField, endField, ...additionalDeps])
}

export default useTimeValidation
