import { Avatar, AvatarComponent, AvatarFallback } from '@/components/Avatar'
import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import ImageUploader from '@/components/Common/ImageUploader'
import { customerImgAtom, customerProfileAtom } from '@/store/customer'
import { getFirstChar } from '@/utils/helper'
import { useAtom } from 'jotai'
import { FC, useEffect, useState } from 'react'
import CustomerButtons from './CustomerButtons'
import CustomerDetails from './CustomerDetailsFields'
import CustomerLocationFields from './CustomerLocationFields'
import CustomerOtherFields from './CustomerOtherFields'
import Spinner from '@/components/Spinner'
import { useAtomValue } from 'jotai'
import { UserDataType } from '@/api/auth/schema'

export type ActionMode = 'create' | 'update'

type BaseCustomerProps = {
    mode: ActionMode
    isValid: boolean
    isSubmitting: boolean
    isFetching?: boolean
    isFormDirty?: boolean
}

const BaseCustomer: FC<BaseCustomerProps> = ({
    mode,
    isValid,
    isSubmitting,
    isFetching,
    isFormDirty,
}) => {
    const [selectedImageAtom, setSelectedImageAtom] = useAtom(customerImgAtom)
    const customerProfileData = useAtomValue(customerProfileAtom) as UserDataType

    const [isAvatarChanged, setIsAvatarChanged] = useState(false)

    const firstNameInitial = getFirstChar(customerProfileData?.firstName)
    const lastNameInitial = getFirstChar(customerProfileData?.lastName)

    useEffect(() => {
        setIsAvatarChanged(selectedImageAtom && selectedImageAtom?.length > 0)
    }, [selectedImageAtom])

    return (
        <div className='w-full'>
            <CardHeader className='bg-white flex justify-center items-center pt-[36px] shadow-lg rounded-t-lg'>
                <Avatar className='w-[74px] h-[74px]'>
                    {isFetching && (
                        <AvatarFallback>
                            <Spinner size='5' variant='normal' />
                        </AvatarFallback>
                    )}
                    {mode === 'update' && !isFetching && (
                        <>
                            <AvatarComponent
                                user={customerProfileData ?? null}
                                selectedImage={selectedImageAtom}
                            />
                            <AvatarFallback>{firstNameInitial + lastNameInitial}</AvatarFallback>
                        </>
                    )}
                    {mode === 'create' && !isFetching && (
                        <>
                            <AvatarComponent user={null} selectedImage={selectedImageAtom} />
                            <AvatarFallback> </AvatarFallback>
                        </>
                    )}
                </Avatar>
                <ImageUploader
                    onFilesChange={(data) => setSelectedImageAtom(data)}
                    maxSize={4.9}
                    accept='.png,.jpeg,.jpg'
                    label={mode === 'update' ? 'Change photo' : 'Upload photo'}
                    labelClassname='text-zentive-blue-dark text-decoration-line: underline'
                    isDisabled={customerProfileData?.status === 'INA'}
                />
            </CardHeader>
            <CardContent className='bg-white shadow-lg'>
                <CustomerDetails mode={mode} profileStatus={customerProfileData?.status} />
                <CustomerLocationFields profileStatus={customerProfileData?.status} />
                {mode === 'update' && <CustomerOtherFields />}
            </CardContent>
            {customerProfileData?.status !== 'INA' && (
                <Card className='w-full text-center'>
                    <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] '>
                        <CustomerButtons
                            mode={mode}
                            isValid={isValid}
                            isAvatarChanged={isAvatarChanged}
                            isSubmitting={isSubmitting}
                            isFormDirty={isFormDirty || false}
                        />
                    </CardFooter>
                </Card>
            )}
        </div>
    )
}

export default BaseCustomer
