import {
    SubscriptionInterval,
    SIGN_UP_GALLERY,
    SIGN_UP_GALLERY_HEADER_TITLES,
    SIGN_UP_GALLERY_TABS,
} from '@/assets/static/signupGallery'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import GalleryTable from './Table'
import { useState, useRef, useEffect } from 'react'
import { getSubscriptionTypes } from '@/api/subscription'
import { useQuery } from '@tanstack/react-query'
import { TableHead, TableHeader, TableRow, Table } from '@/components/Table'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { EmailSchemaType, emailSchema } from '@/api/auth/schema'
import { Button } from '@/components/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import { Suspense } from 'react'
import { Skeleton } from '@/components/Skeleton'

const SignUpGallery = () => {
    const [interval, setInterval] = useState<SubscriptionInterval>('month')
    const formMethods = useForm<EmailSchemaType>({
        resolver: zodResolver(emailSchema),
    })
    const navigate = useNavigate()
    const tableRef = useRef<HTMLDivElement>(null)
    const [showScrollTopButton, setShowScrollTopButton] = useState(false)
    const [showScrollDownButton, setShowScrollDownButton] = useState(false)

    const { data, error, isLoading } = useQuery({
        queryKey: ['subscription-types', interval],
        queryFn: () => getSubscriptionTypes(interval),
    })

    useEffect(() => {
        const tableElement = tableRef.current
        if (tableElement) {
            const handleScroll = () => {
                setShowScrollTopButton(tableElement.scrollTop > 200)
                setShowScrollDownButton(
                    tableElement.scrollHeight - tableElement.scrollTop - tableElement.clientHeight >
                        200,
                )
            }
            tableElement.addEventListener('scroll', handleScroll)
            return () => {
                tableElement.removeEventListener('scroll', handleScroll)
            }
        }
    }, [data])

    if (isLoading) return <div>Loading...</div>

    if (error) return <div>Error loading subscriptions. Please try again later.</div>

    const filteredData = data?.filter(
        (subscription) => subscription.interval === interval || subscription.interval === 'days',
    )

    if (!filteredData || filteredData?.length === 0) return <div>No subscriptions available.</div>

    const freeTrialSubscriptionId =
        filteredData.find((subscription) => subscription.name === 'Free Trial')
            ?.subscriptionTypeId || ''

    const handleClick = (planId: string) => {
        if (!planId) return

        const email = formMethods.getValues('email')
        const encodedEmail = encodeURIComponent(email)
        navigate(`/sign-up/form?planid=${planId}&email=${encodedEmail}`)
    }

    const scrollToTop = () => {
        if (tableRef.current) {
            tableRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    const scrollToBottom = () => {
        if (tableRef.current) {
            tableRef.current.scrollTo({ top: tableRef.current.scrollHeight, behavior: 'smooth' })
        }
    }

    return (
        <Suspense fallback={<Skeleton> Loading .... </Skeleton>}>
            <div className='flex flex-col'>
                <div className='h-[324px] bg-zentive-green-dark my-10 rounded-lg flex items-center justify-center flex-col gap-6'>
                    <div className='text-white text-[24px]'> Try Zentive for Free!</div>
                    <div className='text-zentive-gray-light text-[16px] w-[479px] text-center'>
                        Don't miss out on this opportunity to elevate your landscaping business.
                        Sign up for your free trial today and experience the difference!
                    </div>
                    <div>
                        <FormProvider {...formMethods}>
                            <div className='w-[479px] bg-white p-4 rounded-md flex flex-row gap-2 '>
                                <FormField
                                    name='email'
                                    control={formMethods.control}
                                    render={({ field }) => (
                                        <FormItem className='w-1/2'>
                                            <FormControl>
                                                <Input
                                                    placeholder='Email Address*'
                                                    type='text'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button
                                    disabled={!formMethods.formState.isValid}
                                    className={`bg-zentive-blue-dark w-1/2`}
                                    onClick={() => handleClick(freeTrialSubscriptionId)}
                                >
                                    Start 14-day Free Trial
                                </Button>
                            </div>
                        </FormProvider>
                    </div>
                </div>
                <Card className='w-[1088px] my-10'>
                    <CardHeader>
                        <CardTitle className='text-[#3B671A] text-center text-[24px]'>
                            Sign up and Pricing
                        </CardTitle>
                    </CardHeader>
                    <CardContent className='p-0 relative'>
                        <div className='flex justify-center mt-4 mb-8'>
                            <Tabs
                                defaultValue='month'
                                onValueChange={(val) => setInterval(val as SubscriptionInterval)}
                                className='w-[400px]'
                                value={interval}
                            >
                                <TabsList className='grid w-full h-auto grid-cols-2 rounded-full bg-[#0C3D3C]'>
                                    {SIGN_UP_GALLERY_TABS?.map((item) => (
                                        <TabsTrigger
                                            className={item.className}
                                            key={item.title}
                                            value={item.value}
                                        >
                                            {item.title}
                                        </TabsTrigger>
                                    ))}
                                </TabsList>
                            </Tabs>
                        </div>
                        <Table>
                            <TableHeader className='w-full sticky top-0 z-50 text-[24px]'>
                                <TableRow className='bg-zentive-green-dark divide-x divide-gray-200 hover:bg-zentive-green-dark'>
                                    {SIGN_UP_GALLERY_HEADER_TITLES?.map((item) => (
                                        <TableHead className={item.className} key={item.title}>
                                            {item.title}
                                        </TableHead>
                                    ))}
                                </TableRow>
                            </TableHeader>
                        </Table>
                        <div
                            ref={tableRef}
                            className='h-[460px] overflow-y-auto no-scrollbar relative'
                        >
                            <GalleryTable
                                subscriptionTypes={filteredData}
                                tableData={SIGN_UP_GALLERY[interval]}
                                interval={interval}
                            />
                        </div>
                    </CardContent>
                </Card>
                {showScrollTopButton && (
                    <button
                        className='fixed bottom-20 right-10 p-4 bg-[#0C3D3C] text-white rounded-full shadow-lg flex items-center justify-center'
                        onClick={scrollToTop}
                        style={{ width: '50px', height: '50px' }}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth={2}
                        >
                            <path strokeLinecap='round' strokeLinejoin='round' d='M5 15l7-7 7 7' />
                        </svg>
                    </button>
                )}
                {showScrollDownButton && (
                    <button
                        className='fixed bottom-20 right-10 p-4 bg-[#0C3D3C] text-white rounded-full shadow-lg flex items-center justify-center'
                        onClick={scrollToBottom}
                        style={{ width: '50px', height: '50px' }}
                    >
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth={2}
                        >
                            <path strokeLinecap='round' strokeLinejoin='round' d='M19 9l-7 7-7-7' />
                        </svg>
                    </button>
                )}
            </div>
        </Suspense>
    )
}

export default SignUpGallery
