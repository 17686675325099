import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'
import { USER_STATUS } from '@/constants'
import ColorPicker from '@/components/ColorPicker'
import { Textarea } from '@/components/TextArea'
import { ActionMode } from '..'

type EmployeeDetailsFieldsProps = {
    mode?: ActionMode
    profileStatus?: string
}

const CrewDetailFields = ({ profileStatus, mode }: EmployeeDetailsFieldsProps) => {
    const { control, watch, setValue } = useFormContext()

    const isAccountInactive = profileStatus === USER_STATUS.INACTIVE

    return (
        <div className='grid grid-cols-1 sm:grid-cols-1 gap-4 p-2 py-12'>
            <div className='flex flex-col'>
                <div className='flex col-span-1 gap-x-10'>
                    <FormField
                        control={control}
                        name='crewNumber'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 w-[400px] text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                        type={mode === 'create' ? 'text' : 'number'}
                                        disabled={true}
                                        placeholder='Crew Number*'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={control}
                        name='crewColor'
                        disabled={isAccountInactive}
                        render={() => (
                            <FormItem>
                                <FormControl>
                                    <ColorPicker
                                        className='pl-3 w-[200px] text-base border rounded-sm'
                                        value={{
                                            label: watch().crewColor,
                                            value: watch().crewColor,
                                        }}
                                        onChange={(val) => setValue('crewColor', val.value)}
                                        placeHolder='Crew Color*'
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='grid grid-cols-1 mt-10'>
                    <FormField
                        control={control}
                        name='description'
                        disabled={isAccountInactive}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Textarea
                                        className='border p-4 h-24 w-full max-w-full border-gray-400 text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0'
                                        value={field.value}
                                        onChange={(val) => {
                                            setValue('description', val.target.value)
                                        }}
                                        placeholder='Crew Description*'
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
export default CrewDetailFields
