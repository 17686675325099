import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, numberFormatter } from '@/utils/helper'
import { JobCrewMemberType, ReadJobType } from '@/api/job/schema'
import { formatWorkingHours } from '@/utils/time'
// import AssignCrewModal from './NewTimeEntryModal'
import Tooltip from '@/components/Tooltip'
import { FaInfoCircle } from 'react-icons/fa'

const tableHeader = ['Team', 'Hour (s)', 'Rate Per Hour']

interface IJobTimeEntry {
    job: ReadJobType
}

const JobTimeEntry = ({ job }: IJobTimeEntry) => {
    return (
        <div className='flex flex-col pt-5 gap-x-2 bg-white px-6'>
            <div className='border border-zentive-gray-light border-1'>
                <div className='flex flex-row items-center w-full px-6 gap-2'>
                    <h1 className='text-lg font-semibold mt-5'>Labor</h1>
                    <div className='mt-7'>
                        <Tooltip
                            position='bottom'
                            alignment='start'
                            width='64'
                            fontstyle='font-sans'
                            content='To Assign Crew please go to More Actions > Edit Job > Assign Crew'
                        >
                            <FaInfoCircle className='text-black' />
                        </Tooltip>
                    </div>
                </div>
                {job?.jobCrewMembers || job?.foreman ? (
                    <div className='flex flex-row justify-between w-full px-2'>
                        <Table className='justify-evenly whitespace-normal mx-auto text-zentive-black text-base'>
                            <TableHeader style={{ marginBottom: '10px' }}>
                                <TableRow>
                                    {tableHeader?.map((header) => (
                                        <TableHead
                                            key={`${header}-head`}
                                            className={cn(
                                                'flex-row items-center text-center font-semibold text-base text-zentive-gray-medium',
                                                'items-start text-start w-[80%]',
                                            )}
                                        >
                                            <div className='inline-flex gap-[14px] whitespace-nowrap'>
                                                {header}
                                            </div>
                                        </TableHead>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {job?.foreman && (
                                    <TableRow
                                        key={job?.foreman.crewMemberId}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='whitespace-nowrap'>
                                            <p className='text-base font-bold pb-6'>
                                                {job?.foreman?.memberProfile?.firstName +
                                                    ' ' +
                                                    job?.foreman?.memberProfile?.lastName}
                                                <span className='text-base text-zentive-gray-medium'>
                                                    &nbsp;(Foreman)
                                                </span>
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className='text-base pb-6'>
                                                {formatWorkingHours(
                                                    job?.foreman.totalWorkingHours ?? 0,
                                                )}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className='text-base text-right pb-6'>
                                                {'$' +
                                                    numberFormatter.format(
                                                        job?.foreman?.memberProfile?.compensation,
                                                    )}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {job?.jobCrewMembers?.map((member: JobCrewMemberType) => (
                                    <TableRow
                                        key={member.crewMemberId}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='whitespace-nowrap'>
                                            <p className='text-base font-bold pb-6'>
                                                {member?.memberProfile?.firstName +
                                                    ' ' +
                                                    member?.memberProfile?.lastName}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className='text-base pb-6'>
                                                {formatWorkingHours(member.totalWorkingHours ?? 0)}
                                            </p>
                                        </TableCell>
                                        <TableCell>
                                            <p className='text-base text-right pb-6'>
                                                {'$' +
                                                    numberFormatter.format(
                                                        member?.memberProfile?.compensation,
                                                    )}
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow className='whitespace-nowrap hover:bg-transparent'>
                                    <TableCell className='whitespace-nowrap'></TableCell>
                                    <TableCell>
                                        {/* <p className='text-base font-bold'>
                                        {job?.jobCrewMembers?.length + ':00'}
                                    </p> */}
                                    </TableCell>
                                    <TableCell>
                                        <p className='text-base font-bold text-right'>
                                            {'$' + numberFormatter.format(job.totalCompensation)}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                ) : (
                    <p className='p-6'>
                        Time tracked to this job by you or your team will show here
                    </p>
                )}
            </div>
        </div>
    )
}

export default JobTimeEntry
