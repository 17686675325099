import { NotificationResponseType } from '@/api/auth/schema'
import { cn } from '@/utils/helper'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { COMPLETED_DATE_12_HOUR, notificationIcons } from '@/constants'
import { useAtomValue } from 'jotai'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { notificationaDateRegex } from '@/utils/regex'

export const NotificationItems = ({
    notification,
    handleClick,
}: {
    notification: NotificationResponseType
    handleClick: (id: string) => void
}) => {
    dayjs.extend(relativeTime)

    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const message = notification.content.message

    const extractedDate = message.match(notificationaDateRegex)?.[0]

    const formattedStartDate = dayjs(extractedDate as string)
        .tz(timezone?.timeZoneId)
        .format(COMPLETED_DATE_12_HOUR)

    let updatedMessage = message
    if (extractedDate) {
        updatedMessage = message.replace(notificationaDateRegex, formattedStartDate)
    }

    return (
        <button className='w-full' onClick={() => handleClick(notification.id)}>
            <div
                className={cn(
                    'flex flex-row text-start gap-5 px-5 pt-2 pb-2 h-[6rem] my-1 hover:bg-zentive-gray-bg',
                    notification.read ? ' ' : 'bg-zentive-green-light',
                )}
            >
                <div className='flex items-center justify-center py-4'>
                    <div className='bg-zentive-green-dark rounded-full w-12 h-12 p-3'>
                        <img
                            src={notificationIcons[notification?.content?.category || 'default']}
                            className='h-6 w-6'
                            alt='Notification Icon'
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <p className='font-bold text-sm text-zentive-green-dark/80'>
                        {notification.content.title}
                    </p>
                    <p className='text-sm text-zentive-black'>{updatedMessage}</p>
                    <p className='text-xs text-zentive-gray-medium'>
                        {dayjs().from(dayjs(notification.createdAt), true)} ago
                    </p>
                </div>
            </div>
        </button>
    )
}
