import { z } from 'zod'
import { employeeProfileDetailsSchema, isBirthdayNotFutureDate } from '../profile/schema'
import { addressSchema } from '../address/schema'
import { stringValidator } from '@/utils/regex'
import { PaginationSchema } from '@/components/Pagination/schema'
import { noteSchema } from '../notes/schema'
import { employeeCrewDetailsSchema } from '../crew/schema'

// Use independently on other schemas
export const EmployeeEmail = z
    .object({
        email: stringValidator('Email', { fieldType: 'email', isRequired: true }),
    })
    .required()

// Use independently on other schemas
export const employeeDetails = z.object({
    firstName: stringValidator('firstName', { isRequired: true }),
    lastName: stringValidator('lastName', { isRequired: true }),
    phoneNumber: z.string({ required_error: 'Contact number is required.' }).refine(
        (val): val is string => {
            return val === undefined || val?.length === 0 || val?.length >= 10
        },
        { message: 'Contact number must be at least 10 characters.' },
    ),
    caLicense: stringValidator('caLicense', { isRequired: true }),
    compensation: stringValidator('compensation', { isRequired: true }),
    dateHired: stringValidator('dateHired', { isRequired: true }),
    dateOfBirth: stringValidator('dateOfBirth', { isRequired: true }),
    profileImage: z.instanceof(File).or(z.string()).nullish(),
})

// Use independently on other schemas
export const employeeUpdateDetails = z.object({
    firstName: stringValidator('First Name', { isRequired: true }),
    lastName: stringValidator('Last Name', { isRequired: true }),
    phoneNumber: z.string({ required_error: 'Contact number is required.' }).refine(
        (val): val is string => {
            return val === undefined || val?.length === 0 || val?.length >= 10
        },
        { message: 'Contact number must be at least 10 characters.' },
    ),
    caLicense: z.string().nullable(),
    compensation: stringValidator('Compensation', { fieldType: 'numeric', isRequired: true }),
    dateHired: z.string({ required_error: 'Date Hired is required.' }),
    dateOfBirth: z.string({ required_error: 'Date of Birth is required.' }),
    profileImage: z.instanceof(File).or(z.string()).nullish(),
})

// Use independently on other schemas
export const updateEmployeeSchema = employeeUpdateDetails.merge(
    z.object({
        profileId: z.string(),
        roleId: z.string(),
        businessId: z.string(),
        addressId: z.string(),
        isPasswordGenerated: z.boolean(),
        status: z.string(),
        employeeNumber: z.string(),
        email: z.string(),
        address: addressSchema.partial(),
    }),
)

export const employeeSchema = employeeProfileDetailsSchema
    .pick({
        profileId: true,
        businessId: true,
        addressId: true,
        roleId: true,
        customerId: true,
        email: true,
        phoneNumber: true,
        crewMemberId: true,
        firstName: true,
        lastName: true,
        profileImage: true,
        compensation: true,
        caLicense: true,
        dateHired: true,
        updatedAt: true,
        createdAt: true,
        status: true,
        role: true,
        isPasswordGenerated: true,
    })
    .merge(
        z.object({
            dateOfBirth: z
                .string()
                .refine(isBirthdayNotFutureDate, {
                    message: 'Date of Birth cannot be a future date.',
                })
                .optional(),
            employeeNumber: z.string().optional(),
            address: addressSchema.partial(),
            notes: z.array(noteSchema).optional(),
        }),
    )

export const employeeStatusSchema = employeeProfileDetailsSchema
    .pick({
        profileId: true,
        status: true,
    })
    .merge(
        z.object({
            businessId: z.string(),
        }),
    )

export const employeeListSchema = z.object({
    content: z.array(
        employeeSchema.extend({
            employeeNumber: z.string(),
        }),
    ),
    meta: PaginationSchema.optional(),
})

export const profileEmployeeSchema = employeeProfileDetailsSchema.omit({
    customerId: true,
})

// Use independently on other schemas
export const addBulkEmployeeSchema = z.object({
    businessId: z.string(),
    employeeCSV: z.instanceof(File),
})

export type EmployeeSelfResponseType = z.infer<typeof profileEmployeeSchema>
export type EmployeeType = EmployeeSelfResponseType
export type UpdateEmployeeStatusType = z.infer<typeof employeeStatusSchema>
export type EmployeeDataType = z.infer<typeof employeeDetails>
// export type CrewListType =
export type EmployeeListType = z.infer<typeof employeeListSchema>
export type EmployeeToCrewType = z.infer<typeof employeeSchema>
export type UpdateEmployeeType = EmployeeSelfResponseType
export type AddBulkEmployeeType = z.infer<typeof addBulkEmployeeSchema>
export type EmployeeCrewDetailsType = z.infer<typeof employeeCrewDetailsSchema>
