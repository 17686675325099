import { FullAddress, GeocodeResult, fetchCoordinates, fetchTimezone } from '@/hooks/useGeocode'
import { AuthTokenResponsePassword } from '@supabase/gotrue-js'
import { WEBSITE_URL, axiosInstance } from '../axiosInstance'
import goTrueClient from '../goTrueClient'
import {
    CrewForgotPasswordType,
    EmailValidityType,
    MCSubscriptionType,
    NotificationResponseType,
    NotificationType,
    ResetPasswordType,
    SignInType,
    SignUpType,
    SignUpValidityRequest,
    SignupValidityType,
} from './schema'
import { AxiosError } from 'axios'

const domainName = window.location.origin

export const signUp = async (data: SignUpType) => {
    const coordinates = await fetchCoordinates(data.data as FullAddress)
    const timeZone = await fetchTimezone(coordinates as GeocodeResult)

    const response = await goTrueClient.signUp({
        email: data.email,
        password: data.password.newPassword,
        options: {
            data: {
                role: 'OWNER',
                latitude: coordinates?.lat,
                longitude: coordinates?.lng,
                timezone: timeZone?.timeZoneId,
                timeOffset: timeZone?.rawOffset,
                ...data.data,
            },
            emailRedirectTo: `${WEBSITE_URL}/verified`,
        },
    })

    return response
}

export const subscribeOwnerToMailChimp = async (data: MCSubscriptionType) => {
    const res = await axiosInstance.post('/public/subscribe-owner-mailchimp', data)
    return res.data
}

export const getProfileImageByEmail = async (email: string) => {
    const response = await axiosInstance.get('public/get-by-email/' + email)

    return response.data
}

export const getIsPasswordGenerated = async (user_id: string) => {
    const response = await axiosInstance.get('/profiles/' + user_id)
    return response?.data?.isPasswordGenerated
}

export const getProfileNotification = async (user_id: string, pageSize?: number) => {
    const response = await axiosInstance.get(
        `/notifications/?userId=${user_id}&pageSize=${pageSize}`,
    )
    return response.data.content as NotificationType
}
export const markNotificationRead = async (notification_id: string, data: { read: boolean }) => {
    const response = await axiosInstance.patch(`/notifications/${notification_id}`, data)
    return response.data as NotificationResponseType
}

export const markAllNotificationAsRead = async (profileId: string) => {
    const response = await axiosInstance.patch(`/notifications/read-all?to=${profileId}`)
    return response.data as NotificationResponseType
}

export const resendEmailConfirmation = async (type: 'email_change' | 'signup', email: string) => {
    const response = await goTrueClient.resend({
        type: type,
        email: email,
        options: {
            emailRedirectTo: `${WEBSITE_URL}/verified`,
        },
    })

    return response
}

export const signIn = async (data: SignInType): Promise<AuthTokenResponsePassword> => {
    const response = await goTrueClient.signInWithPassword({
        email: data.email,
        password: data.password,
    })

    return response
}

export const signOut = () => goTrueClient.signOut()

export const resetPassword = async (data: ResetPasswordType) => {
    const response = await goTrueClient.updateUser({ password: data.newPassword })

    return response.data
}

export const sendForgotPasswordConfirmation = async (email: string) => {
    const response = await goTrueClient.resetPasswordForEmail(email, {
        redirectTo: `${domainName}/reset-password`,
    })

    return response
}

export const crewForgotPassword = async (data: CrewForgotPasswordType) => {
    const response = await axiosInstance.post('/public/reset-password', data)

    return response.data.content
}

export const createStripeCustomer = async (): Promise<string> => {
    const res = await axiosInstance.post(`stripe/customer`)
    return res.data.content
}

export const sendWelcomeEmail = async (email: string) => {
    const res = await axiosInstance.post(`public/welcome/${email}`, email)

    return res
}

export const checkSignUpValidity = async (
    param: SignUpValidityRequest,
): Promise<SignupValidityType> => {
    const res = await axiosInstance.post('public/validate-signup', param)
    return res.data.content
}

export const validateUpdate = async (profileId: string): Promise<boolean> => {
    const res = await axiosInstance.post(`public/validate-update/${profileId}`)

    return res.data.content
}

// Same endpoint above ^ but for email checking only
export const checkEmailValidity = async (param: EmailValidityType): Promise<EmailValidityType> => {
    const response = await axiosInstance.post('public/validate-signup', param)
    return response.data.content
}

export const createOwnerStripeAccount = async (profileId: string | undefined) => {
    if (!profileId) {
        throw new Error('Profile ID undefined')
    }

    try {
        const res = await axiosInstance.get(`stripe/create-account/${profileId}`)

        return res.data['url']
    } catch (err) {
        if (err instanceof AxiosError) {
            throw new Error(err.message)
        }
    }
}
