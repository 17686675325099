import { updateProfileStatus } from '@/api/business'
import { BusinessStatusType as ProfileStatusType } from '@/api/business/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import DeleteProfileJobRestrictionModal from '@/components/DeleteProfileJobRestrictionModal/indexs'
import { USER_STATUS } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { UseFormReset } from 'react-hook-form'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

interface InactivateModalProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    formValues: ProfileStatusType
    valueResetter: UseFormReset<ProfileStatusType>
}

const InactivateModal = ({ open, setOpen, formValues, valueResetter }: InactivateModalProps) => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const [deleteRestrictionModal, setDeleteRestrictionModal] = useState<boolean>(false)

    const { mutate: inactivateProfileMu } = useMutation({
        mutationFn: (data: ProfileStatusType) => updateProfileStatus(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Inactivated',
                variant: 'default',
            })
            navigate('/customers/customer-list')
        },
        onError: () => setDeleteRestrictionModal(true),
    })

    const onCancel = () => {
        setOpen(false)
        valueResetter()
    }

    const onSubmit = () => {
        // Expicitly set the status to INA
        formValues.status = USER_STATUS.INACTIVE
        inactivateProfileMu(formValues)
    }

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <DeleteProfileJobRestrictionModal
                open={deleteRestrictionModal}
                setOpen={setDeleteRestrictionModal}
                type='customer'
            />
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-[#191A0A] mt-[10px]'>
                            Inactivate Account{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            By inactivating the account, the customer will not be able to receive
                            current services and purchase services.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to inactivate this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel
                            onClick={onCancel}
                            className='w-[155px] h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[155px] h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Inactivate
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default InactivateModal
