import { ReadJobType } from '@/api/job/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Dialog, DialogContent } from '@/components/Dialog'
import PhotoCarousel from '@/components/PhotoGallery'
import { useState } from 'react'
import { BsArrowLeft } from 'react-icons/bs'

interface IJobLogGallery {
    job: ReadJobType | undefined
}

const JobLogGallery = ({ job }: IJobLogGallery) => {
    const [selectedImageId, setSelectedImageId] = useState<string>('')

    if (selectedImageId && job?.jobImages && job?.jobImages?.length > 0) {
        return (
            <Dialog open={selectedImageId !== ''} onOpenChange={() => setSelectedImageId('')}>
                <DialogContent className='rounded-md w-[1024px] max-w-[1024px] h-[500px] max-h-[700px] p-0'>
                    <div className='flex flex-col justify-evenly bg-zentive-gray-medium w-full min-w-full max-w-full'>
                        <div className='absolute flex flex-row items-center gap-x-4 ml-6 mt-4 top-0'>
                            <BsArrowLeft
                                className='h-5 w-5 md:h-10 md:w-10 cursor-pointer text-white'
                                onClick={() => setSelectedImageId('')}
                            />
                            <span className='font-semibold text-sm md:text-2xl text-white'>
                                Photos
                            </span>
                        </div>
                        <div className='bg-zentive-black py-10'>
                            <PhotoCarousel images={job?.jobImages} selectedId={selectedImageId} />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    if (!job?.jobImages)
        return (
            <div className='bg-white rounded-sm'>
                <div className='w-[1260px]'> </div>
                <div className='flex justify-center'>
                    <img
                        src={emptyPlaceholder}
                        alt='empty-table-placeholder'
                        className='my-12 h-60'
                    />
                </div>
                <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
            </div>
        )
    return (
        <div className='bg-white p-10 space-y-10'>
            <div className='grid grid-cols-8 gap-1 p-6'>
                {job.jobImages?.map((image, index) => (
                    <img
                        key={index}
                        src={image.jobImageUrl}
                        alt={`job image`}
                        onClick={() => setSelectedImageId(image?.jobImageId)}
                        className='w-40 h-40 border border-zentive-green-dark object-cover rounded-md cursor-pointer'
                    />
                ))}
            </div>
        </div>
    )
}
export default JobLogGallery
