import { TableBody, TableCell, TableRow } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { BulkDeleteInvoiceType, InvoiceListType, InvoiceType } from '@/api/invoicing/schema'
import { INVOICE_STATUS } from '@/constants'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'

type InvoicingTableContentProps = {
    invoice: InvoiceListType
    setInvoiceToUpdate: React.Dispatch<
        React.SetStateAction<{
            invoiceIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    invoiceToUpdate: BulkDeleteInvoiceType
}

const InvoicingTableBody: React.FC<InvoicingTableContentProps> = ({
    invoice,
    setIsCheckAll,
    setInvoiceToUpdate,
    invoiceToUpdate,
}) => {
    const AllInvoiceCount = invoice?.content?.length


    const handleCheckClick = (invoice: InvoiceType, isCheck: boolean) => {
        if (invoice.invoiceId) {
            if (isCheck) {
                const updatedProfileIds = [...invoiceToUpdate.invoiceIds, invoice.invoiceId]
                setInvoiceToUpdate({
                    invoiceIds: updatedProfileIds,
                })
                setIsCheckAll(invoiceToUpdate.invoiceIds?.length + 1 === AllInvoiceCount)
            } else {
                const updatedProfileIds = invoiceToUpdate.invoiceIds.filter(
                    (id) => id !== invoice.invoiceId,
                )

                setInvoiceToUpdate((prev) => ({
                    ...prev,
                    invoiceIds: updatedProfileIds,
                }))
                setIsCheckAll(invoiceToUpdate.invoiceIds?.length - 1 === AllInvoiceCount)
            }
        }
    }

    return (
        <>
            <TableBody className='bg-white'>
                {invoice?.content?.map((data) => (
                    <TableRow className='whitespace-nowrap' key={data.invoiceId}>
                        <TableCell className=' text-zentive-black text-base max-w-[200px]'>
                            <div className='flex items-start text-start gap-[12px]'>
                                <Checkbox
                                    className='mr-[5px] mt-[2px]'
                                    checked={invoiceToUpdate?.invoiceIds?.some(
                                        (p) => p === data.invoiceId,
                                    )}
                                    onCheckedChange={(val) => {
                                        handleCheckClick(data, val as boolean)
                                    }}
                                    disabled={
                                        data.status === INVOICE_STATUS.PAID ||
                                        data.status === INVOICE_STATUS.PAID_OUT_OF_BAND
                                    }
                                />
                            </div>
                        </TableCell>
                        <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                            <NavLink
                                to={`/financial-management/invoicing/view-invoice/${data?.invoiceId}`}
                            >
                                {`${data.profile?.firstName} ${data.profile?.lastName}`}
                            </NavLink>
                        </TableCell>
                        <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                            <NavLink
                                to={`/financial-management/invoicing/view-invoice/${data?.invoiceId}`}
                            >
                                {dayjs(data?.createdAt).format('MM/DD/YYYY')}
                            </NavLink>
                        </TableCell>
                        <TableCell className=' text-zentive-black text-base max-w-[200px] text-ellipsis overflow-hidden'>
                            <NavLink
                                to={`/financial-management/invoicing/view-invoice/${data?.invoiceId}`}
                            >
                                {data?.quote?.productServiceGroup?.map((txt, i, row) => (
                                    <span key={txt.name}>
                                        {txt.name}
                                        {i + 1 === row?.length ? '' : ','}{' '}
                                    </span>
                                ))}
                            </NavLink>
                        </TableCell>
                        <TableCell className=' text-right text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                            <NavLink
                                to={`/financial-management/invoicing/view-invoice/${data?.invoiceId}`}
                            >
                                $
                                {data?.totalAmount?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}
                            </NavLink>
                        </TableCell>
                        <TableCell className=' flex justify-end text-zentive-black text-base max-w-[290px] text-ellipsis overflow-hidden'>
                            <NavLink
                                to={`/financial-management/invoicing/view-invoice/${data?.invoiceId}`}
                            >
                                <InvoiceStatusBar status={data?.status as string} />
                            </NavLink>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </>
    )
}

export default InvoicingTableBody
