import BreadCrumbs from '@/components/BreadCrumbs'
import { JobsCompletedByEmployeeFilter } from './JobsCompletedByEmployeeFilter'
import JobsCompletedByEmployeeTable from './JobsCompletedByEmployeeTable'
import { useEffect } from 'react'
import { useSetAtom } from 'jotai'
import { jobCompletedByEmployeeFilterAtom } from '@/store/reports'

export const JobsCompletedByEmployee = () => {
    const setJobCompletedByEmployeeFilter = useSetAtom(jobCompletedByEmployeeFilterAtom)

    useEffect(() => {
        setJobCompletedByEmployeeFilter(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Jobs Completed By Employees' />
            <JobsCompletedByEmployeeFilter />
            <JobsCompletedByEmployeeTable />
        </div>
    )
}
