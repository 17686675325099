import { CardContent, CardTitle } from '@/components/Card'
import ContentService from './Service'
import Expenses from './Expenses'
import BillingAmount from './BillingAmount'
import { startTransition, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { QuoteType } from '@/api/quoting/schema'
import { useAtomValue } from 'jotai'
import { quoteContentAtom, quoteTaxAtom } from '@/store/owner'
import { Textarea } from '@/components/TextArea'

const AddQuotingContent = () => {
    const quoteContent = useAtomValue(quoteContentAtom)
    const taxes = useAtomValue(quoteTaxAtom)
    const [internalNotes, setInternalNotes] = useState({ description: '' })
    const { setValue } = useFormContext<QuoteType>()

    const handleInternalNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target
        setInternalNotes({ description: value })
        setValue('internalNotes', value)
    }

    useEffect(() => {
        startTransition(() => {
            setValue('laborCost', quoteContent.laborCost)
            setValue('subTotal', quoteContent.subTotal)
            setValue('taxName', taxes.name)
            setValue('serviceTax', taxes.serviceTax)
            setValue('productTax', taxes.productTax)
            setValue('expenseTax', taxes.expenseTax)
        })
    }, [taxes])

    return (
        <CardContent className='pt-9 pb-20 mx-5'>
            <ContentService />
            <Expenses />
            <BillingAmount />
            <div className='border border-[#EBEBEB] p-6 '>
                <CardTitle className='text-lg mb-6'>Internal notes</CardTitle>
                <Textarea
                    className='flex w-full pt-3 text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm'
                    placeholder='Description'
                    value={internalNotes.description}
                    onChange={handleInternalNotesChange}
                />
            </div>
        </CardContent>
    )
}

export default AddQuotingContent
