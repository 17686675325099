import { SortCriterion } from '@/api/lead/schema'
import { Checkbox } from '@/components/Checkbox'
import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { leadSortCriterionAtom } from '@/store/lead'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useAtom } from 'jotai'
import { FC } from 'react'
import LeadsTableHeaderSorter from './LeadsTableHeaderSorter'
import { cn } from '@/utils/helper'
import FieldSubscriptionRestricted from '@/components/FieldSubscriptionRestricted'

type LeadsTableColumn = {
    name: string
} & Partial<SortCriterion>

const LEADS_TABLE_COLUMN: LeadsTableColumn[] = [
    {
        column: 'deal_size',
        name: 'Deal Size',
    },
    {
        column: 'customer_name',
        name: 'Customer',
    },
    {
        column: 'contact_type',
        name: 'Contact Type',
    },
    {
        column: 'source',
        name: 'Source',
    },
    {
        name: 'Service Type',
    },
    {
        column: 'deal_stage',
        name: 'Deal Stage',
    },
    {
        column: 'created_at',
        name: 'Date',
    },
    {
        name: 'Message',
    },
]

type LeadsTableHeaderProps = {
    checked: boolean
    hasLeads: boolean
    onCheckedChange: (checked: CheckedState) => void
    isFieldRestricted: boolean
}

const LeadsTableHeader: FC<LeadsTableHeaderProps> = ({
    checked,
    hasLeads,
    onCheckedChange,
    isFieldRestricted,
}) => {
    const [sortCriteria, setSortCriteria] = useAtom(leadSortCriterionAtom)

    const updateSortCriteria = (
        column: SortCriterion['column'],
        order: SortCriterion['order'],
    ): void => {
        setSortCriteria((prevCriteria) => {
            const existingIndex = prevCriteria.findIndex((criterion) => criterion.column === column)

            if (existingIndex !== -1) {
                const updatedCriterion = { ...prevCriteria[existingIndex], order }
                const updatedCriteria = prevCriteria.filter((_, index) => index !== existingIndex)
                return [updatedCriterion, ...updatedCriteria]
            } else {
                return [{ column, order }, ...prevCriteria]
            }
        })
    }

    const handleSortChange = (column: SortCriterion['column']): void => {
        const currentOrder =
            sortCriteria.find((criterion) => criterion.column === column)?.order || 'ASC'
        const newOrder = currentOrder === 'ASC' ? 'DESC' : 'ASC'
        updateSortCriteria(column, newOrder)
    }

    const getSortOrder = (column: SortCriterion['column']): SortCriterion['order'] => {
        const sortCriterion = sortCriteria.find((criteria) => criteria.column === column)

        return sortCriterion === undefined ? 'DESC' : sortCriterion.order
    }

    return (
        <TableHeader>
            <TableRow>
                <TableHead>
                    <FieldSubscriptionRestricted isFieldRestricted={isFieldRestricted}>
                        <Checkbox
                            checked={checked}
                            disabled={!hasLeads}
                            onCheckedChange={onCheckedChange}
                        />
                    </FieldSubscriptionRestricted>
                </TableHead>
                {LEADS_TABLE_COLUMN.map((item) => (
                    <TableHead key={item.name} className='text-zentive-gray-medium text-base font-semibold'>
                        <button
                            className={cn(
                                'flex flex-row items-center',
                                !!item?.column ? 'disabled:cursor-not-allowed' : 'cursor-default',
                            )}
                            disabled={!hasLeads}
                            onClick={
                                item?.column
                                    ? () => handleSortChange(item.column as SortCriterion['column'])
                                    : undefined
                            }
                            type='button'
                        >
                            <p>{item.name}</p>
                            {!!item?.column && (
                                <LeadsTableHeaderSorter order={getSortOrder(item.column)} />
                            )}
                        </button>
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default LeadsTableHeader
