import { convertImageToBase64, getInvoiceStatus } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { invoiceReportExportAtom } from '@/store/reports'
import { InvoiceReportExportType } from '@/api/reports/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { GenerateInvoiceReportPDF } from './GenerateInvoiceReportPDF'
import { formatPhoneNumber } from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/core'

const ExportInvoiceReportPDF = () => {
    const exportAtom = useAtomValue(invoiceReportExportAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: InvoiceReportExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            date: r.date,
                            invoiceId: r.invoiceId,
                            invoiceNumber: r.invoiceNumber,
                            customerName: r.customerName,
                            email: r.email,
                            invoiceAmount: r.invoiceAmount,
                            phoneNumber: `${
                                r.phoneNumber.startsWith('+1')
                                    ? `(${r.phoneNumber.substring(0, 2)})-${formatPhoneNumber(
                                          r.phoneNumber as E164Number,
                                      )
                                          .replace('0', '')
                                          .replace(' ', '-')}`
                                    : `(${r.phoneNumber.substring(0, 3)})-${formatPhoneNumber(
                                          r.phoneNumber as E164Number,
                                      )
                                          .replace('0', '')
                                          .replace(' ', '-')}`
                            }`,
                            address: r.address,
                            processedBy: r.processedBy,
                            status: getInvoiceStatus(r.status ?? '') as string,
                        })) ?? [],
                    unpaid: exportAtom?.unpaid ?? 0,
                    totalUnpaid: exportAtom?.totalUnpaid ?? 0,
                    totalPastDue: exportAtom?.totalPastDue ?? 0,
                    pastDue: exportAtom?.pastDue ?? 0,
                    totalRecords: exportAtom?.totalRecords ?? 0,
                    totalAmount: exportAtom?.totalAmount ?? 0,
                } as InvoiceReportExportType

                GenerateInvoiceReportPDF(
                    zentiveLogoUrl,
                    tableData,
                    user?.business?.businessName ?? 'Zentive',
                )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportInvoiceReportPDF
