import { InvoiceReportType } from '@/api/reports/schema'
import { displayCurrency } from '@/utils/helper'

interface InvoiceReportOverviewProps {
    report: InvoiceReportType
}

export const InvoiceReportOverView: React.FC<InvoiceReportOverviewProps> = ({ report }) => {
    return (
        <div className='flex flex-row justify-start gap-10 p-5 bg-white border border-zentive-gray-medium/50 border-1 text-zentive-black text-base'>
            <div className='flex items-center font-bold px-3 border border-1 border-zentive-black border-y-0 border-l-0'>
                <p className='text-lg'>Overview</p>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Unpaid/Awaiting Payment{' '}
                        <span className='text-zentive-green-dark'>({report?.content?.unpaid})</span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.totalUnpaid)}</p>
                </div>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Past Due{' '}
                        <span className='text-zentive-red-dark'>({report?.content?.pastDue})</span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.totalPastDue)}</p>
                </div>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Total <span>({report?.content?.totalRecords})</span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.totalAmount)}</p>
                </div>
            </div>
        </div>
    )
}
