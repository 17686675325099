import { CrewForgotPasswordType } from '@/api/auth/schema'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'
import { CardContent, CardFooter } from '@/components/Card'
import CrewActionButton from '../ActionButton'
import { useTranslation } from 'react-i18next'

const CrewForgotPasswordForm = () => {
    const { t } = useTranslation(['forgot-password'])
    const { control } = useFormContext<CrewForgotPasswordType>()

    return (
        <div className='bg-white'>
            <div className='bg-white'>
                <CardContent className='px-7'>
                    <FormField
                        control={control}
                        name='email'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='items-center font-sans w-64 md:w-100 mt-8 border border-black'
                                        placeholder={t('Email Address')}
                                        type='text'
                                        {...field}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </CardContent>
                <CardFooter className='w-full mt-4 bg-zentive-gray-bg pt-[6px]'>
                    <CrewActionButton buttonContainerClassName='justify center w-[260px] max-w-[w-[260px]' />
                </CardFooter>
            </div>
        </div>
    )
}

export default CrewForgotPasswordForm
