import {
    auth,
    checkAutoSync,
    checkIntegration,
    getData,
    syncCustomersAndInvoices,
    updateAutoSync,
} from '@/api/quickbooks'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useToast } from './useToast'
import { AxiosError } from 'axios'
import useViewedAsUser from './useViewedAsUser'

type UpdateAutoSyncArgs = {
    businessId: string
    isAutoSync: boolean
}

type UpdateAutoSyncCtx = {
    optimisticAutoSync: boolean
    previousAutoSync: boolean
}

const useQBAuth = (profileId: string | undefined) => {
    const queryClient = useQueryClient()

    const queryData = useQuery({
        enabled: false,
        queryKey: ['qbAuthUrl', profileId],
        queryFn: () => auth(profileId),
    })

    const { data: qbAuthUrl } = queryData

    useEffect(() => {
        if (qbAuthUrl) {
            window.location.href = qbAuthUrl
            return () => queryClient.removeQueries({ queryKey: ['qbAuthUrl'] })
        }
    }, [qbAuthUrl])

    return queryData
}

const useQBCheckAutoSync = (businessId: string | undefined) => {
    const queryData = useQuery({
        enabled: !!businessId,
        queryKey: ['isAutoSync', businessId],
        queryFn: () => checkAutoSync(businessId),
    })

    return queryData
}

const useQBCheckIntegration = (businessId: string | undefined, isEnabled: boolean = true) => {
    const queryData = useQuery({
        enabled: !!businessId && isEnabled,
        queryKey: ['qbStatus', businessId],
        queryFn: () => checkIntegration(businessId),
        staleTime: 5000,
    })

    return queryData
}

const useQBGetData = (businessId: string | undefined) => {
    const queryData = useQuery({
        enabled: !!businessId,
        queryKey: ['unsycedAndSyncedData', businessId],
        queryFn: () => getData(businessId),
    })

    return queryData
}

const useQBSyncData = () => {
    const queryClient = useQueryClient()
    const user = useViewedAsUser()
    const { toast } = useToast()

    const mutationData = useMutation<string, AxiosError, string | undefined>({
        mutationFn: (businessId) => syncCustomersAndInvoices(businessId, user.profileId as string),
        onSuccess: (res, variable) => {
            queryClient.invalidateQueries({
                queryKey: ['unsycedAndSyncedData', variable],
            })

            toast({ description: res })
        },
        onError: (err) =>
            toast({
                description: err.message,
                variant: 'destructive',
            }),
    })

    return mutationData
}

const useQBUpdateAutoSync = () => {
    const queryClient = useQueryClient()

    const { toast } = useToast()
    const { mutate: syncQBDataMu } = useQBSyncData()

    const mutationData = useMutation<string, AxiosError, UpdateAutoSyncArgs, UpdateAutoSyncCtx>({
        mutationFn: ({ businessId, isAutoSync }) => updateAutoSync(businessId, isAutoSync),
        onMutate: async ({ businessId, isAutoSync }) => {
            // Cancel outgoing refetches (so they don't overwrite the optimistic update)
            await queryClient.cancelQueries({
                queryKey: ['isAutoSync', businessId],
            })

            // Snapshot the previous value, cast it to boolean to resolve type error
            const previousAutoSync = queryClient.getQueryData<boolean>(['isAutoSync', businessId])

            // Optimistically update to the new value
            queryClient.setQueryData(['isAutoSync', businessId], isAutoSync)

            // Return a context object with the snapshot value (to rollback in case of error)
            return { optimisticAutoSync: isAutoSync, previousAutoSync: previousAutoSync ?? false }
        },
        onSuccess: async (res, variables, context) => {
            queryClient.invalidateQueries({
                queryKey: ['isAutoSync', variables.businessId],
            })

            toast({ description: res })

            if (context?.optimisticAutoSync) {
                syncQBDataMu(variables.businessId)
            }
        },
        onError: (err, variables, context) => {
            // Rollback to the previous value if the mutation fails
            if (context?.previousAutoSync) {
                queryClient.setQueryData(
                    ['isAutoSync', variables.businessId],
                    context.previousAutoSync,
                )
            }
            toast({
                description: err.message,
                variant: 'destructive',
            })
        },
    })

    return mutationData
}

export {
    useQBAuth,
    useQBCheckAutoSync,
    useQBCheckIntegration,
    useQBGetData,
    useQBSyncData,
    useQBUpdateAutoSync,
}
