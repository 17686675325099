import { QuoteReportType } from '@/api/reports/schema'
import { displayCurrency } from '@/utils/helper'

interface QuoteReportOverviewProps {
    report: QuoteReportType
}

export const QuoteReportOverview: React.FC<QuoteReportOverviewProps> = ({ report }) => {
    return (
        <div className='flex flex-row justify-start gap-10 p-5 bg-white border border-zentive-gray-medium/50 border-1 text-zentive-black text-base'>
            <div className='flex items-center font-bold px-3 border border-1 border-zentive-black border-y-0 border-l-0'>
                <p className='text-lg'>Overview</p>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Approved{' '}
                        <span className='text-zentive-green-dark'>
                            ({report?.content?.acceptedCount})
                        </span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.acceptedAmount)}</p>
                </div>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Declined{' '}
                        <span className='text-zentive-red-dark'>
                            ({report?.content?.deniedCount})
                        </span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.deniedAmount)}</p>
                </div>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Pending{' '}
                        <span className='text-zentive-red-dark'>
                            ({report?.content?.pendingCount})
                        </span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.pendingAmount)}</p>
                </div>
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col gap-2 items-start justify-start'>
                    <p>
                        Total <span>({report?.meta?.totalRecords})</span>
                    </p>
                    <p className='font-bold'>{displayCurrency(report?.content?.totalAmount)}</p>
                </div>
            </div>
        </div>
    )
}
