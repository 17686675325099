import { useParams } from 'react-router-dom'
import { IoImageOutline } from 'react-icons/io5'
import { IoCameraOutline } from 'react-icons/io5'
import { FaRegFolderClosed } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { useRef } from 'react'
import { CreateNewJobImageType } from '@/api/job/schema'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createNewJobImage } from '@/api/job'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import Spinner from '@/components/Spinner'
import { cn } from '@/utils/helper'

interface IUploadOptionsProps {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UploadOptions = ({ isOpen, setIsOpen }: IUploadOptionsProps) => {
    const { t } = useTranslation(['view-job'])
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const { jobId } = useParams()
    const cameraInputRef = useRef<HTMLInputElement | null>(null)
    const galleryInputRef = useRef<HTMLInputElement | null>(null)
    const chooseFileInputRef = useRef<HTMLInputElement | null>(null)

    const handlePhotoLibraryClick = () => {
        if (galleryInputRef.current) {
            galleryInputRef.current.click()
        }
    }

    const handleTakePhotoClick = () => {
        if (cameraInputRef.current) {
            cameraInputRef.current.click()
        }
    }

    const handleChooseFileClick = () => {
        if (chooseFileInputRef.current) {
            chooseFileInputRef.current.click()
        }
    }

    const { mutate: createJobImageMu, isPending } = useMutation({
        mutationFn: (data: CreateNewJobImageType) => createNewJobImage(data, jobId ?? ''),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            setIsOpen(false)
            toast({
                description: t('Photo has been added successfully'),
                variant: 'crew_interface',
                duration: 2000,
            })
        },
        onError: () =>
            toast({
                description: `Unable to upload photo`,
                variant: 'crew_interface_destructive',
                duration: 2000,
            }),
    })

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files?.[0]
        if (file) {
            const fileSizeInMB = file.size / 1048576

            if (fileSizeInMB <= 5) {
                createJobImageMu({ jobImages: [file] })
            } else {
                toast({
                    description: `File size must not be greater than 5 mb`,
                    variant: 'crew_interface_destructive',
                })
            }
        }
    }

    return (
        <div className='fixed bottom-0 w-full z-50 max-w-[1024px]'>
            <div
                className={cn(
                    'sticky transform',
                    isOpen
                        ? 'translate-y-4 h-[50dvh] md:h-[85vdh] lg:h-[85vdh] 2xl:h-[70vdh] overflow-y-auto'
                        : 'translate-y-1/4 h-[0dvh] ',
                    'transition-all duration-500 bg-white',
                )}
            >
                {isPending ? (
                    <div className='flex align-center justify-center mt-16'>
                        <Spinner variant='normal' size='10' />
                    </div>
                ) : (
                    <>
                        <div className='flex flex-row justify-end cursor-pointer w-full border border-b h-10 items-center pr-6'>
                            <IoMdClose className='h-4 w-4' onClick={() => setIsOpen(false)} />
                        </div>
                        <div
                            role='button'
                            className='flex flex-row cursor-pointer justify-between px-4 py-6 w-full border border-b h-10 items-center pr-6'
                            onClick={handlePhotoLibraryClick}
                        >
                            <p className='pl-3'>{t('Photo Library')}</p>
                            <IoImageOutline className='h-4 w-4' />
                            <input
                                type='file'
                                accept='image/*'
                                ref={galleryInputRef}
                                onChange={handleFileChange}
                                className='hidden'
                            />
                        </div>
                        <div
                            className='flex flex-row cursor-pointer justify-between px-4 py-6 w-full border border-b h-10 items-center pr-6'
                            role='button'
                            onClick={handleTakePhotoClick}
                        >
                            <p className='pl-3'>{t('Take Photo')}</p>
                            <IoCameraOutline className='h-4 w-4' />
                            <input
                                type='file'
                                accept='image/*'
                                capture='environment'
                                ref={cameraInputRef}
                                onChange={handleFileChange}
                                className='hidden'
                            />
                        </div>
                        <div
                            className='flex flex-row cursor-pointer justify-between px-4 py-6 w-full border border-b h-10 items-center pr-6'
                            role='button'
                            onClick={handleChooseFileClick}
                        >
                            <p className='pl-3'>{t('Choose File')}</p>
                            <FaRegFolderClosed className='h-4 w-4' />
                            <input
                                type='file'
                                ref={chooseFileInputRef}
                                onChange={handleFileChange}
                                className='hidden'
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default UploadOptions
