import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { Dispatch, SetStateAction } from 'react'

import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

interface DeleteQuoteModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    deleteQuoteFn: () => void
    isPending: boolean
}

const DeleteQuoteModal: React.FC<DeleteQuoteModal> = ({
    setOpen,
    open,
    deleteQuoteFn,
    isPending
}) => {
    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
                <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Delete Quote?
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Deleting this quote will permanently remove all associated data. You can create a new quote anytime after deletion.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to delete this quote?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5 mt-5'>
                    <div className='w-full flex justify-end items-end gap-4 bg-gray-100 '>
                        <AlertDialogCancel
                            className={`bg-zentive-white px-5 w-32`}
                            type='button'
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </AlertDialogCancel>
                        <Button
                            className='w-32'
                            onClick={deleteQuoteFn}
                            disabled={isPending}
                        >
                            Delete
                        </Button>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteQuoteModal
