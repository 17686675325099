import { updateLeadDealStage } from '@/api/lead'
import { LeadDetailsRequest } from '@/api/lead/schema'
import { Dialog, DialogClose, DialogContent, DialogFooter } from '@/components/Dialog'
import { Form, FormTitle } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FC, useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Button } from '@/components/Button'
import { useToast } from '@/hooks/useToast'
import { editDealStageSchema, EditLeadDealStage } from '@/api/deal_stage/schema'
import { leadToUpdateAtom } from '@/store/lead'
import { useAtom } from 'jotai'
import DealStage from '../AddLeadModal/DealStage'

type EditLeadDealStageModalProps = {
    open: boolean
    onOpenChange: (val: boolean) => void
}

const EditLeadDealStageModal: FC<EditLeadDealStageModalProps> = ({ open, onOpenChange }) => {
    const queryClient = useQueryClient()
    const [leadToUpdate, setLeadToUpdate] = useAtom(leadToUpdateAtom)
    const { toast } = useToast()

    const formMethods = useForm<EditLeadDealStage>({
        mode: 'onChange',
        resolver: zodResolver(editDealStageSchema),
    })

    const {
        handleSubmit,
        reset,
        setValue,
        formState: { isValid },
    } = formMethods

    const { mutate, isPending } = useMutation({
        mutationFn: updateLeadDealStage,
        onSuccess: () => {
            toast({
                description: 'Deal Stage has been updated successfully.',
            })
            onOpenChange(false)
            queryClient.invalidateQueries({ queryKey: ['leadList'] })
        },
    })

    const onSubmit = async (data: EditLeadDealStage) => {
        mutate(data)
    }

    useEffect(() => {
        if (!open) {
            reset()
        }
    }, [open])

    useEffect(() => {
        if (leadToUpdate) setValue('leadId', leadToUpdate)
    }, [leadToUpdate])

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent
                id='add-lead'
                className='bg-white max-h-[850px] h-fit w-[688px] rounded-[15px] p-0 overflow-y-auto no-scrollbar'
            >
                <Form {...formMethods}>
                    <form className='' onSubmit={handleSubmit(onSubmit)}>
                        <div className='pt-[88px] pb-[56px] px-[36px] '>
                            <FormTitle
                                className='pb-[32px] text-zentive-black text-lg font-semibold'
                                headingLevel={1}
                                title='Edit Deal Stage'
                            />
                            <DealStage
                                isOpen={open}
                                useFormMethods={
                                    formMethods as unknown as UseFormReturn<LeadDetailsRequest>
                                }
                            />
                        </div>
                        <DialogFooter className='bg-zentive-gray-light h-[75px] flex flex-row items-center gap-[13px] px-[25px] justify-end'>
                            <DialogClose asChild>
                                <Button
                                    onClick={() => setLeadToUpdate(null)}
                                    variant='secondary'
                                    type='button'
                                >
                                    Cancel
                                </Button>
                            </DialogClose>
                            <Button type='submit' disabled={isPending || isValid === false}>
                                Apply
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default EditLeadDealStageModal
