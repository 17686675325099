import { useNavigate } from 'react-router-dom'
import Expired from '@/assets/public/expired-free-trial.png'
import './index.css'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { useSetAtom } from 'jotai'
import { ownerFreeTrialEndedForCrewAtom } from '@/store/auth'
const FreeTrialExpiredSignIn = () => {
    const { t } = useTranslation(['sign-in'])
    const navigate = useNavigate()
    const setIsOwnerFreeTrialEndedForCrew = useSetAtom(ownerFreeTrialEndedForCrewAtom)
    return (
        <div className='crew-bg flex flex-col justify-center items-center z-0 p-4'>
            <div className='bg-white flex flex-col justify-center items-center space-y-2 rounded-md py-10'>
                <img
                    src={Expired}
                    alt='Expired'
                    className='h-[120px] w-[140px] cursor-pointer my-4'
                />
                <h1 className='w-64 md:w-100 text-center text-lg text-zentive-green-dark font-semibold'>
                    {t('Unable to Sign In')}
                </h1>
                <div className='text-zentive-gray-medium text-left px-2'>
                    <p className='text-center my-2'>
                        {t(
                            `You cannot log in because the business account's free trial has expired.`,
                        )}{' '}
                    </p>
                </div>
                <div className='text-zentive-gray-medium text-left px-2'>
                    <p className='text-center my-2'>
                        {t(
                            `To regain access, you might want to reach out to the business owner about renewing the account`,
                        )}{' '}
                    </p>
                </div>
                <div className='text-zentive-gray-medium text-left px-2'>
                    <p className='text-center my-2'>
                        {t(`If you have any questions, we're here to help!`)}{' '}
                    </p>
                </div>
                <div className='my-4'>
                    <Button
                        type={'button'}
                        onClick={() => {
                            navigate('/crew/sign-in')
                            setIsOwnerFreeTrialEndedForCrew(false)
                        }}
                    >
                        {' '}
                        {t('Login to Zentive')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FreeTrialExpiredSignIn
