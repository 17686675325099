import { getEmployeeById } from '@/api/profile'
import { Form } from '@/components/Forms'
import { useToast } from '@/hooks/useToast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'
import _ from 'lodash'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import BaseEmployee from '../BaseEmployee'
import { updateEmployeeProfile } from '@/api/employee'
import { employeeImgAtom } from '@/store/employee'
import { UpdateEmployeeType } from '@/api/employee/schema'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { getEmployeeFormValues } from '@/utils/user'
import { profileStatusAtom } from '@/store/customer'
import { employeeProfileDetailsSchema } from '@/api/profile/schema'

const UpdateEmployee: FC = () => {
    const { profileId } = useParams()
    const { toast } = useToast()

    const navigate = useNavigate()
    const setProfileStatus = useSetAtom(profileStatusAtom)

    const [selectedImageAtom, setSelectedImageAtom] = useAtom(employeeImgAtom)

    const { data, isSuccess } = useQuery({
        queryKey: ['employee', profileId],
        queryFn: () => getEmployeeById(profileId),
    })

    const updateEmployeeMethods = useForm<UpdateEmployeeType>({
        mode: 'onChange',
        resolver: zodResolver(employeeProfileDetailsSchema)
    })

    const {
        handleSubmit,
        reset,
        setValue,
        unregister,
        watch,
        formState: { isSubmitSuccessful },
    } = updateEmployeeMethods

    const isUpdated = JSON.stringify(getEmployeeFormValues(data)) !== JSON.stringify(watch())
    const profileImage = watch('profileImage')

    const { mutate: updateEmployeeMu } = useMutation({
        mutationFn: updateEmployeeProfile,
        onSuccess: () => {
            toast({
                description: 'Employee profile has been successfully updated',
                variant: 'default',
            })
            navigate('/schedule/employee')
            setSelectedImageAtom([])
        },
        onError: () => {
            toast({
                description: 'There was an error updating this profile',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: UpdateEmployeeType) => updateEmployeeMu(data)

    useEffect(() => {
        if (isSuccess && data) {
            setProfileStatus({
                businessId: data.businessId,
                profileId: data.profileId,
                status: data.status as string,
            })
            reset(getEmployeeFormValues(data))
        }
    }, [data, isSuccess])

    useEffect(() => {
        if (selectedImageAtom) {
            setValue('profileImage', selectedImageAtom[0]?.file)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImageAtom])

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(undefined, {
                keepValues: true,
                keepIsSubmitted: false,
            })

            if (profileImage) unregister('profileImage')
        }
    }, [isSubmitSuccessful, profileImage])

    return (
        <Form {...updateEmployeeMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseEmployee
                    isUpdated={isUpdated}
                    mode='update'
                    user={{
                        ...getEmployeeFormValues(data),
                        profileImage: data?.profileImage,
                        updatedAt: data?.updatedAt,
                    }}
                />
            </form>
        </Form>
    )
}

export default UpdateEmployee
