import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import { CreateDiscountType, DiscountListType } from './schema'

export const createDiscount = async (discount: CreateDiscountType) => {
    const res = await axiosInstance.post(`/stripe/discounts/create`, discount)

    return res.data
}

export const getDiscountList = async (keyword: string, p: PaginationType): Promise<DiscountListType> => {
    const { page, pageSize } = p
    const res = await axiosInstance.get(`/stripe/discounts/get?keyword=${keyword}&page=${page}&pageSize=${pageSize}`)

    return res.data
}

export const deleteDiscount = async (couponCode: string): Promise<string> => {
    const res = await axiosInstance.post(`/stripe/discounts/delete/${couponCode}`)

    return res.data.content
}

export const checkCouponCode = async(couponCode: string, subscriptionId: string): Promise<string> => {
    const res = await axiosInstance.get(`/stripe/discounts/check-code-status`,{
        params: {
            discountCode: couponCode,
            subId: subscriptionId
        }
    })

    return res.data.content
}