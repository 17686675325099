import { InvoiceListType } from '@/api/customer/schema'
import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/Table'
import { cn, formatRecordNumber, displayCurrency } from '@/utils/helper'
import { FaArrowDownLong } from 'react-icons/fa6'
import InvoiceDialog from './InvoiceDialog'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'
import { AxiosError } from 'axios'
import { useQuery } from '@tanstack/react-query'
import { getInvoiceList } from '@/api/customer'
import { useAtomValue } from 'jotai'
import { businessIdAtom, userAtom } from '@/store/auth'
import { useState } from 'react'
import { PaginationType } from '@/components/Pagination/schema'
import { Pagination } from '@/components/Pagination'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { RECORD_PREFIX } from '@/constants'
import { Card } from '@/components/Card'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

const tableHeader = ['Date', 'Invoice Number', 'Bill Amount', 'Status', 'Receipt']

const InvoiceTable = () => {
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: invoiceList, isLoading } = useQuery<unknown, AxiosError, InvoiceListType>({
        queryKey: ['invoiceList', businessId, pagination],
        queryFn: () => getInvoiceList(user?.profileId as string, businessId as string, pagination),
    })

    return (
        <div>
            <Card>
                <Table>
                    <TableHeader>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row items-center text-center font-semibold text-zentive-gray-medium text-base',
                                        header === 'Date'
                                            ? 'items-start text-start'
                                            : 'items-center text-center',
                                    )}
                                >
                                    <div className='inline-flex gap-[10px]'>
                                        {header}
                                        {header === 'Date' ||
                                            (header === 'Status' && <FaArrowDownLong />)}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading ? (
                            <TableRow>
                                <TableCell
                                    className='flex-row items-start text-start  text-[#191A0A] text-base'
                                    colSpan={6}
                                >
                                    <AppSkeletonLoadingState />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {invoiceList?.content?.map((body) => (
                                    <TableRow
                                        key={body.invoiceInfo.created}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base '>
                                            {body.invoiceInfo.created}
                                        </TableCell>
                                        <TableCell className='flex-row  items-center text-center  text-[#191A0A] text-base '>
                                            {RECORD_PREFIX.invoice +
                                                formatRecordNumber(body.invoiceInfo.number)}
                                        </TableCell>
                                        <TableCell className='flex-row items-center text-center  text-[#191A0A] text-base '>
                                            {displayCurrency(body.invoiceInfo.amount / 100)}
                                        </TableCell>
                                        <TableCell className='flex flex-row items-center justify-center'>
                                            <span className='mt-4'>
                                                <InvoiceStatusBar
                                                    status={body.invoiceInfo.status}
                                                />
                                            </span>
                                        </TableCell>
                                        <TableCell className='items-center justify-center'>
                                            <InvoiceDialog invoiceList={body} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </Card>
            <div className='mt-5'>
                <Pagination
                    setPagination={setPagination}
                    pagination={pagination}
                    totalRecords={invoiceList?.meta?.totalRecords ?? 0}
                    itemsPerPage={pagination.pageSize ?? 5}
                />
            </div>

            {!invoiceList?.content?.length && (
                <div className='bg-white rounded-sm'>
                    <div className='w-[1260px]'> </div>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-gray-900'> No invoices found </p>
                </div>
            )}
        </div>
    )
}
export default InvoiceTable
