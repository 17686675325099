import { routeAtom } from '@/store/route'
import { useAtomValue } from 'jotai'
import { useTranslation } from 'react-i18next'
import { FaScrewdriverWrench } from 'react-icons/fa6'
import { GoClockFill } from 'react-icons/go'
import { IoLocationSharp } from 'react-icons/io5'
import { JobStatusColor, JobStatusType } from '../../Jobs/JobGallery/JobList'

interface Props {
    estimatedArrival: string[] | undefined
}

const JobList = ({ estimatedArrival }: Props) => {
    const route = useAtomValue(routeAtom)
    const { t } = useTranslation(['common'])

    const jobStatus: Record<JobStatusType, JobStatusColor> = {
        ACT: {
            text: t('Open'),
            color: '#707070',
            dot: '#707070',
            bg: '#EBEBEB',
        },
        IN_PROGRESS: {
            text: t('In Progress'),
            color: '#254284',
            dot: '#254284',
            bg: '#E7EAF1',
        },
        COMPLETED: {
            text: t('Completed'),
            color: '#3B671A',
            dot: '#3B671A',
            bg: '#E0FBE2',
        },
        CANCELED: {
            text: t('Canceled'),
            color: '#EE4E4E',
            dot: '#EE4E4E',
            bg: '#FFEAEB',
        },
        LATE_VISIT: {
            text: t('Late Visit'),
            color: '#EE4E4E',
            dot: '#EE4E4E',
            bg: '#FFEAEB',
        },
        RESCHEDULE: {
            text: t('Reschedule'),
            color: '#254284',
            dot: '#254284',
            bg: '#E7EAF1',
        },
    }

    return (
        <div className=' flex flex-col h-full overflow-auto '>
            {route?.jobGroup?.map((jobGroup, index) => (
                <div className='mb-2 h-[150px] mx-8 my-2 flex flex-row bg-white shadow-lg rounded-lg   items-start space-x-4 '>
                    <div className='h-full rounded-tl-lg rounded-bl-lg px-4 bg-zentive-blue-dark  text-white  items-center justify-center flex'>
                        <span className='m-auto'> {index + 1} </span>
                    </div>
                    <div className='flex-grow my-4'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center space-x-3'>
                                {jobGroup.job?.quote?.profile?.profileImage ? (
                                    <img
                                        src={
                                            typeof jobGroup.job?.quote?.profile?.profileImage ===
                                            'string'
                                                ? jobGroup.job?.quote?.profile?.profileImage
                                                : ''
                                        }
                                        alt={`Profile Image`}
                                        className='h-[26px] w-[26px] rounded-full '
                                    />
                                ) : (
                                    <div className='h-[26px] w-[26px] rounded-full bg-zentive-blue-dark flex justify-center items-center '>
                                        <span className='text-xs text-white'>
                                            {`${
                                                jobGroup.job?.quote?.profile?.firstName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }${
                                                jobGroup.job?.quote?.profile?.lastName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                                <span className='font-semibold text-sm text-zentive-green-dark'>
                                    {jobGroup.job?.quote?.profile?.firstName +
                                        ' ' +
                                        jobGroup.job?.quote?.profile?.lastName}
                                </span>
                            </div>
                            <p
                                className='flex items-center px-4 3xs:px-1 rounded-full text-sm 3xs:text-xs 3xs:my-1'
                                style={{
                                    backgroundColor:
                                        jobStatus?.[jobGroup?.job?.status as JobStatusType]?.bg,
                                    color: jobStatus?.[jobGroup?.job?.status as JobStatusType]
                                        ?.color,
                                }}
                            >
                                <span
                                    className='w-2 h-2 mr-1 rounded-full inline-block 3xs:w-1 3xs:h-1'
                                    style={{
                                        backgroundColor:
                                            jobStatus?.[jobGroup?.job?.status as JobStatusType]?.dot,
                                    }}
                                ></span>
                                {jobStatus?.[jobGroup?.job?.status as JobStatusType]?.text}
                            </p>
                        </div>
                        <div className='mt-3 text-zentive-gray-dark pb-4'>
                            <div className='flex items-center space-x-2 w-[95%]'>
                                <IoLocationSharp className=' text-zentive-blue-dark h-4 w-4' />
                                <div>
                                    <p className=' text-xs'>{`${
                                        jobGroup.job?.quote?.profile?.address?.streetAddress + ', '
                                    }${jobGroup.job?.quote?.profile?.address?.city + ','} `}</p>
                                    <p className=' text-xs'>{`${
                                        jobGroup.job?.quote?.profile?.address?.state + ', '
                                    } ${jobGroup.job?.quote?.profile?.address?.zipCode}`}</p>
                                </div>
                            </div>
                            <div className='flex items-center space-x-2 mt-2'>
                                <FaScrewdriverWrench className='text-zentive-blue-dark h-3 w-3 ' />
                                <span className=' text-xs'>
                                    {jobGroup.job?.quote?.productServiceGroup?.map(
                                        (item, index) => (
                                            <span key={index}>
                                                {item.name}
                                                {index <
                                                    jobGroup.job?.quote?.productServiceGroup
                                                        ?.length! -
                                                        1 && ', '}
                                            </span>
                                        ),
                                    )}
                                </span>
                            </div>
                            <div className='flex items-center space-x-2 mt-2'>
                                <GoClockFill className='text-zentive-blue-dark h-3 w-3' />
                                <span className=' text-xs'>
                                    {t('Estimated time of arrival is')}{' '}
                                    {estimatedArrival ? estimatedArrival[index] : <span></span>}
                                    <span className='font-semibold'></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default JobList
