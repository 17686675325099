import { Dispatch, FC, SetStateAction, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import dayjs from 'dayjs'
import { BsFunnelFill } from 'react-icons/bs'

import Map from './Map'
import GoogleMapsApiWrapper from '@/components/GoogleMapsApiWrapper'
import { getAllCustomersMapView } from '@/api/profile'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE, ISO_8601 } from '@/constants'
import { cn } from '@/utils/helper'
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/DropdownMenu'
import { Checkbox } from '@/components/Checkbox'
import Tooltip from '@/components/Tooltip'

type MapFilter = {
    startDate: string
    endDate: string
}

type MapViewFilterProps = {
    mapFilter: MapFilter
    setMapFilter: Dispatch<SetStateAction<MapFilter>>
}

type MapViewProps = {
    isLoaded: boolean
    loadError?: Error
}

const MapViewFilter: FC<MapViewFilterProps> = ({ mapFilter, setMapFilter }) => {
    const handleStartDateChange = (val: boolean) => {
        setMapFilter((prev) => ({
            ...prev,
            startDate: val ? dayjs().format(ISO_8601) : '',
        }))
    }

    const handleEndDateChange = (val: boolean) => {
        setMapFilter((prev) => ({
            ...prev,
            endDate: val ? dayjs().add(6, 'day').format(ISO_8601) : '',
        }))
    }

    return (
        <div className='mt-[36.1px] flex flex-row-reverse'>
            <div>
                <Tooltip position='bottom' content='Filter'>
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <BsFunnelFill className='size-[25px] my-2 mr-5' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className='p-[14px] absolute right-0 w-[234px] rounded-[15px]'>
                            <div className='flex align-center space-x-[9px]'>
                                <Checkbox
                                    checked={!!mapFilter.startDate}
                                    onCheckedChange={handleStartDateChange}
                                />
                                <p className='text-base'>Today</p>
                            </div>
                            <div className='flex space-x-[9px]'>
                                <Checkbox
                                    checked={!!mapFilter.endDate}
                                    onCheckedChange={handleEndDateChange}
                                />
                                <p className='text-base'>Next 7 days</p>
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </Tooltip>
            </div>
        </div>
    )
}

const MapView: FC<MapViewProps> = ({ isLoaded, loadError }) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const [mapFilter, setMapFilter] = useState<MapFilter>({
        startDate: '',
        endDate: '',
    })

    const { data, isLoading, isError } = useQuery({
        queryKey: ['customersMapView', mapFilter.startDate, mapFilter.endDate],
        queryFn: () =>
            getAllCustomersMapView(user.businessId, mapFilter.startDate, mapFilter.endDate),
        enabled: !!user.businessId,
        select: (data) => data?.content || [],
    })

    const mapContent = useMemo(() => {
        if (isLoading || isError || !isLoaded || loadError) {
            return null
        }

        return (
            <div className='h-full w-full flex flex-col items-center'>
                <div className='mt-4 w-full min-h-[540px] lg:h-[800px] rounded-[8px] border border-zentive-gray-medium flex'>
                    <Map coordinates={data} />
                </div>
            </div>
        )
    }, [isLoading, isError, isLoaded, loadError, data])

    return (
        <div className={cn('w-full h-full pb-[80px]', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Map View' />
            <MapViewFilter mapFilter={mapFilter} setMapFilter={setMapFilter} />
            {mapContent}
        </div>
    )
}

const WrappedCustomerMapView: FC = () => (
    <GoogleMapsApiWrapper
        render={(isLoaded: boolean, loadError?: Error) => (
            <MapView isLoaded={isLoaded} loadError={loadError} />
        )}
    />
)

export default WrappedCustomerMapView
