import { useState } from 'react'
import AddCustomerModal from './AddCustomerModal'
import { PlusIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { validateCustomerInviteSchema, ValidateCustomerInviteType } from '@/api/profile/schema'
import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { inviteExistingCustomer, verifyBusinessCustomerEmailValidty } from '@/api/profile'
import { CUSTOMER_EMAIL_VALIDITY } from '@/constants'
import { Button } from '@/components/Button'

interface AddCustomerInputProps {
    businessId: string
}

export const AddCustomerButton: React.FC<AddCustomerInputProps> = ({ businessId }) => {
    const [addCustomerModal, setAddCustomerModal] = useState<boolean>(false)

    const inviteMethods = useForm<ValidateCustomerInviteType>({
        mode: 'onChange',
        resolver: zodResolver(validateCustomerInviteSchema),
        defaultValues: {
            businessId: businessId,
            email: '',
        },
    })

    const queryClient = useQueryClient()
    const { toast } = useToast()
    const navigate = useNavigate()

    const { handleSubmit } = inviteMethods

    const { mutate: inviteExistingMu } = useMutation<
        unknown,
        AxiosError,
        ValidateCustomerInviteType
    >({
        mutationFn: inviteExistingCustomer,
        onSuccess: () => {
            inviteMethods.reset()
            queryClient.invalidateQueries({ queryKey: ['Customers'] })
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
            navigate('/customers/invitation-success')
        },
        onError: () => {
            toast({
                description: 'Email already exists',
                variant: 'destructive',
            })
        },
    })

    const { mutate: validateInviteMu } = useMutation<
        string,
        AxiosError,
        ValidateCustomerInviteType
    >({
        mutationFn: verifyBusinessCustomerEmailValidty,
        onSuccess: (data) => {
            if (data === CUSTOMER_EMAIL_VALIDITY.NOT_EXISTING) {
                navigate('/customers/create-customer', {
                    state: { email: inviteMethods.getValues().email },
                })
            } else if (data === CUSTOMER_EMAIL_VALIDITY.EXISTING_IN_OTHER_BUSINESS) {
                inviteExistingMu(inviteMethods.getValues())
            } else if (data === CUSTOMER_EMAIL_VALIDITY.EXISTING_IN_CURRENT_BUSINESS) {
                toast({
                    description: 'Email already exists',
                    variant: 'destructive',
                })
            } else if (data === CUSTOMER_EMAIL_VALIDITY.DECLINED) {
                toast({
                    description: 'Customer declined previous invitation',
                    variant: 'destructive',
                })
            } else if (data === CUSTOMER_EMAIL_VALIDITY.NO_INVITE_AMOUNT_LEFT) {
                toast({
                    description: 'Pending invitation exceeds limit',
                    variant: 'destructive',
                })
            } else {
                toast({
                    description: 'Email already belongs to other roles',
                    variant: 'destructive',
                })
            }
        },
        onError: () => {
            toast({
                description: 'Email already exists',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: ValidateCustomerInviteType) => {
        validateInviteMu(data)
    }

    return (
        <div>
            <Form {...inviteMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <AddCustomerModal
                        open={addCustomerModal}
                        setOpen={setAddCustomerModal}
                        businessId={businessId}
                        inviteMethod={inviteMethods}
                        onSubmit={handleSubmit(onSubmit)}
                    />
                </form>
            </Form>
            <Button onClick={() => setAddCustomerModal(true)}>
                <span className='flex flex-row gap-2 -ml-1'>
                    <PlusIcon />
                    <p>Add Customer</p>
                </span>
            </Button>
        </div>
    )
}
