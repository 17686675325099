import { getJobById } from '@/api/job'
import { JobCrewMemberType } from '@/api/job/schema'
import { Button } from '@/components/Button'
import { Card } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { TWELVE_HOUR_CLOCK_WITH_MERIDIEM, US_FORMAT } from '@/constants'
import { timezoneAtom } from '@/store/auth'
import { cn } from '@/utils/helper'
import { EventClickArg } from '@fullcalendar/core/index.js'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface JobDetailsModalProps {
    clickInfo: EventClickArg
    jobId: string
}

const JobDetailsModal = ({ clickInfo, jobId }: JobDetailsModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const [modalStyle, setModalStyle] = useState<React.CSSProperties>({})
    const navigate = useNavigate()

    const timezone = useAtomValue(timezoneAtom)

    const formatDate = (date: string | null, format: string): string => {
        if (!date) return ''

        const dayJsDate = dayjs(date)
            .tz(timezone?.timeZoneId)
            .format(format)

        return dayJsDate
    }

    const { data: job, isPending: loading } = useQuery({
        queryKey: ['jobById', clickInfo.event._def.publicId],
        queryFn: () => getJobById(clickInfo.event._def.publicId),
    })

    useEffect(() => {
        if (modalRef.current) {
            const modalWidth = modalRef.current.offsetWidth
            const modalHeight = modalRef.current.offsetHeight
            const clickX = clickInfo.jsEvent.clientX
            const clickY = clickInfo.jsEvent.clientY
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight
            const windowMidY = windowHeight / 2

            let left = clickX + 10
            let top = clickY

            if (clickY > windowMidY) {
                top = clickY - modalHeight
            } else {
                top = clickY
            }

            if (left + modalWidth > windowWidth) {
                left = windowWidth - modalWidth - 10
            }
            if (top + modalHeight > windowHeight) {
                top = windowHeight - modalHeight - 10
            }

            setModalStyle({ top: `${top}px`, left: `${left}px`, position: 'fixed' })
        }
    }, [clickInfo, jobId])

    const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation() // Prevent the click event from reaching the document-level listener
    }

    return (
        <Card>
            <div
                className={cn('max-w-[250px] z-50 mt-2 border border-zentive-green-dark')}
                style={modalStyle}
                onClick={handleModalClick}
                ref={modalRef}
            >
                {loading ? (
                    <p className='text-zentive-gray-medium front-medium text-base'>Loading</p>
                ) : (
                    <>
                        <div className='bg-gray-200 py-2'>
                            <p className='pl-2 text-wrap text-sm font-semibold'>{`Job #00${job?.jobNumber} - ${job?.quote?.name}`}</p>
                        </div>
                        <div className='flex flex-row bg-white border-b-2 p-2 gap-x-2'>
                            <Checkbox className='border border-zentive-green-dark mt-1' />
                            <h1 className='text-zentive-green-dark'>Completed</h1>
                        </div>
                        <div className='flex flex-col bg-white border-b-2 px-2 py-2 gap-x-2'>
                            <p className='font-semibold text-sm mb-2'>Crew</p>
                            {!job?.crewId && 'No assigned crew'}
                            {job?.foreman ? (
                                <p className='font-semibold text-sm text-center py-1 my-1 bg-zentive-gray-light rounded-md'>
                                    {job?.foreman?.memberProfile?.firstName +
                                        ' ' +
                                        job?.foreman?.memberProfile?.lastName}
                                </p>
                            ) : (
                                <></>
                            )}
                            {job?.jobCrewMembers &&
                                job?.jobCrewMembers?.map((member: JobCrewMemberType) => (
                                    <p
                                        key={member?.crewMemberId}
                                        className='font-semibold text-sm text-center py-1 my-1 bg-zentive-gray-light rounded-md'
                                    >
                                        {member?.memberProfile?.firstName +
                                            ' ' +
                                            member?.memberProfile?.lastName}
                                    </p>
                                ))}
                        </div>
                        <div className='flex flex-col border-b-2 px-2 py-2 gap-x-2 bg-white'>
                            <p className='font-semibold text-sm'>Location</p>
                            <p className=' text-sm py-1 whitespace-normal'>
                                {`${job?.address?.streetAddress + ','} ${
                                    job?.address?.streetTwoAddress &&
                                    job?.address?.streetTwoAddress !== undefined &&
                                    job?.address?.streetTwoAddress !== 'undefined'
                                        ? job?.address?.streetTwoAddress + ', '
                                        : ''
                                }${job?.address?.city + ','} ${job?.address?.state + ','} ${job
                                    ?.address?.zipCode}`}
                            </p>
                        </div>
                        <div className='flex flex-row border-b-2 px-2 py-2 gap-x-10 bg-white justify-center'>
                            <div className='flex flex-col items-center'>
                                <p className='font-semibold text-sm'>Starts</p>
                                <p className='text-sm py-1 whitespace-normal'>
                                    {formatDate(
                                        job?.appointment?.startDateTime as string,
                                        US_FORMAT,
                                    )}
                                </p>
                                <p className='text-sm py-1 whitespace-normal'>
                                    {formatDate(
                                        job?.appointment?.startDateTime as string,
                                        TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
                                    )}
                                </p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <p className='font-semibold text-sm'>Ends</p>
                                <p className='text-sm py-1 whitespace-normal'>
                                    {formatDate(job?.appointment?.endDateTime as string, US_FORMAT)}
                                </p>
                                <p className='text-sm py-1 whitespace-normal'>
                                    {formatDate(
                                        job?.appointment?.endDateTime as string,
                                        TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className='flex flex-row border-b-2 px-2 py-2 gap-x-4 bg-white justify-center'>
                            <div className='items-center'>
                                <Button
                                    variant='default'
                                    onClick={() =>
                                        navigate(`/schedule/jobs/${clickInfo.event._def.publicId}`)
                                    }
                                    type={'button'}
                                    className='py-0 text-xs'
                                >
                                    View Details
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Card>
    )
}

export default JobDetailsModal
