import { PaginationType } from "@/components/Pagination/schema"
import { atom } from "jotai"

export const selectedJobLogTabAtom = atom<string>('INFO')
export const selectedCrewIdsAtom = atom<string[]>([])
export const selectedCrewFilterIdsAtom = atom<string[]>([])
export const isAllCrewSelectedFilterAtom = atom<boolean>(true)

export const selectedStatusFilterAtom = atom<string>('')
export const selectedStatusAtom = atom<string>('')
export const openFilterCheckAtom = atom<boolean>(true)
export const completedFilterCheckAtom = atom<boolean>(true)

export const jobLogPaginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const setJobLogPaginationAtom = atom(null, (_, set, newValue: PaginationType) => {
    set(jobLogPaginationAtom, newValue)
})

export const itemsPerPageAtom = atom(5)
export const jobLogSortByAtom = atom<string>('')
export const jobLogSortOrderAtom = atom<string>('')