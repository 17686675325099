import { SignUpType } from '@/api/auth/schema'
import { checkCouponCode } from '@/api/discounts'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import Spinner from '@/components/Spinner'
import { COUPON_ERROR_MESSAGES } from '@/constants'
import { couponValidityAtom } from '@/store/auth'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSetAtom } from 'jotai'
import { CheckCheckIcon, XIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

const CouponFields = () => {
    const { control, watch } = useFormContext<SignUpType>()
    const [status, setCouponStatus] = useState<string>('')
    const [error, setErr] = useState<string>('')
    const setCouponValidity = useSetAtom(couponValidityAtom)

    const [searchParams] = useSearchParams()
    const planId = searchParams.get('planid')

    const discountCode = watch('data.stripeCoupon')

    const { mutate: CheckCouponMu, isPending: isCheckValidityLoading } = useMutation({
        mutationFn: (data: string) => checkCouponCode(data, planId ?? ''),
        onSuccess: (couponStatus: string) => {
            if (couponStatus != '') {
                setCouponStatus(couponStatus)
                if (couponStatus === COUPON_ERROR_MESSAGES.NOT_EXIST) {
                    setErr('Coupon Code does not Exist')
                    setCouponValidity(false)
                } else if (couponStatus === COUPON_ERROR_MESSAGES.INVALID_COUPON_SUBS) {
                    setErr('Coupon Code is not applicable to your selected subscription')
                    setCouponValidity(false)
                } else if (couponStatus === COUPON_ERROR_MESSAGES.USAGE_EXCEED) {
                    setErr('Coupon Code already exceeds its Usage Limit')
                    setCouponValidity(false)
                } else if (couponStatus === COUPON_ERROR_MESSAGES.EXPIRED) {
                    setErr('Coupon Code is expired')
                    setCouponValidity(false) 
                } else if (couponStatus === COUPON_ERROR_MESSAGES.AVAILABLE) {
                    setErr('')
                    setCouponValidity(true)
                }
            } else {
                setErr('')
                setCouponValidity(true)
            }
        },
        onError: (err: AxiosError) => {
            setCouponValidity(true)
            console.log(err)
        },
    })

    useEffect(() => {
        if (discountCode) {
            CheckCouponMu(discountCode)
        } else {
            setCouponValidity(true)
        }
    }, [discountCode])

    return (
        <div className='flex flex-col gap-1'>
            <div className='grid grid-cols-2 gap-4 w-full'>
                <div className='flex flex-row col-span-1 gap-2'>
                    <FormField
                        name='data.stripeCoupon'
                        control={control}
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        {...field}
                                        className='font-sans-pro pl-3 h-11 border border-gray-400'
                                        placeholder='Coupon Code (Optional)'
                                        type='text'
                                    />
                                </FormControl>
                                <div className='hidden'>
                                    <FormMessage />
                                </div>
                            </FormItem>
                        )}
                    />
                    <div className='mt-2'>
                        {status ? (
                            status === COUPON_ERROR_MESSAGES.AVAILABLE ? (
                                <CheckCheckIcon className='text-zentive-green-dark' />
                            ) : (
                                <XIcon className='text-zentive-red-500' />
                            )
                        ) : null}

                        {isCheckValidityLoading && (
                            <Spinner variant='normal' className='h-5 w-5 mt-1' />
                        )}
                    </div>
                </div>
            </div>
            <p className='text-red-500'>{error}</p>
        </div>
    )
}

export default CouponFields
