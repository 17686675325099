import { checkUpgradeData } from '@/api/owner'
import { CheckUpgradeType } from '@/api/owner/schema'
import { getBusinessProfile } from '@/api/profile'
import { getPricingById } from '@/api/subscription'
import {
    ProratedSubscriptionType,
    SubscriberInfoType,
    SubscriptionType,
} from '@/api/subscription/schema'
import { Button } from '@/components/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { userAtom } from '@/store/auth'
import { displayCurrency } from '@/utils/helper'
import { decryptString } from '@/utils/crypto'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import {
    BREADCRUMBS_PADDING_STYLE,
    FULL_MONTH_WITH_DAY_AND_FULL_YEAR,
    SUBSCRIPTION_TYPES,
} from '@/constants'
import { useEffect, useState } from 'react'

const ChangeBillingCycle = () => {
    const navigate = useNavigate()

    const user = useAtomValue(userAtom)
    const dateToday = dayjs()

    const [params] = useSearchParams()

    const encriptednewPlanId = params.get('newPlan')
    const newPlanId = decryptString(encriptednewPlanId as string)

    const [endDate, setEndDate] = useState<String>('')

    const isFreeTrial = user?.business?.subscriptionType?.name === SUBSCRIPTION_TYPES.FREE_TRIAL

    const { data: businessProfileData } = useQuery<unknown, AxiosError, SubscriberInfoType>({
        enabled: !isFreeTrial,
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    const { data: currentPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        enabled: !isFreeTrial,
        queryKey: ['currentPlan'],
        queryFn: () => getPricingById(businessProfileData?.business.subscriptionTypeId as string),
    })

    const { data: newPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['newPlan'],
        queryFn: () => getPricingById(newPlanId),
    })

    const { data: upgradeSavedVal } = useQuery<unknown, AxiosError, ProratedSubscriptionType>({
        queryKey: ['savedVal'],
        queryFn: () =>
            checkUpgradeData({
                profileId: user?.profileId as string,
                planId: newPlanId as string,
            } as CheckUpgradeType),
        enabled: !!currentPlan?.subscriptionTypeId,
    })

    useEffect(() => {
        // const update = new Date(upgradeSavedVal?.nextBillingDate! * 1000).toLocaleDateString(
        //     'en-US',
        //     { year: 'numeric', month: 'long', day: '2-digit' },
        // )
        const nbd = dateToday.add(1, 'year').format(FULL_MONTH_WITH_DAY_AND_FULL_YEAR)
        setEndDate(nbd)
    }, [upgradeSavedVal])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Review Changes' />
            <div className='bg-white mt-10 pb-[96px]'>
                <div className='px-[30px] pt-[40px]'>
                    <Table className='ring-1 ring-white '>
                        <TableHeader>
                            <TableRow className='whitespace-nowrap'>
                                <TableHead className='ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Plan Selected
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Price
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Payment Interval
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light only:font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Date of Upgrade
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light only:font-semibold text-zentive-gray-medium text-[16px] w-[20%]'>
                                    Next Billing Date
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow key={currentPlan?.name} className='whitespace-nowrap'>
                                <TableCell className='ring-1 ring-zentive-gray-light text-gray-900 text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {newPlan?.name}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-gray-900 text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {displayCurrency(newPlan?.price ?? 0)}
                                </TableCell>

                                <TableCell className='ring-1 ring-zentive-gray-light text-gray-900 text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {newPlan?.interval === 'year' ? 'Annually' : 'Monthly'}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-gray-900 text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {dateToday.format(FULL_MONTH_WITH_DAY_AND_FULL_YEAR)}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-gray-900 text-[16px] pb-[45px] pt-[10px] w-[20%]'>
                                    {upgradeSavedVal && endDate}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableBody>
                            <>
                                <TableRow className='w-fill h-11'>
                                    <TableCell className='w-[40%] ring-1 ring-zentive-gray-light font-semibold text-[#707070] text-base'>
                                        Pro-rated Charge From New Plan
                                    </TableCell>
                                    <TableCell className='ring-1 ring-zentive-gray-light text-end text-base'>
                                        {upgradeSavedVal == null
                                            ? 'Loading'
                                            : displayCurrency(upgradeSavedVal.remainingTimeAmount)}
                                    </TableCell>
                                </TableRow>
                                <TableRow className='w-fill h-11'>
                                    <TableCell className='w-[40%] ring-1 ring-zentive-gray-light font-semibold text-[#707070] text-base'>
                                        Pro-rated Charge From Previous Plan
                                    </TableCell>
                                    <TableCell className='ring-1 ring-zentive-gray-light text-end text-base'>
                                        {upgradeSavedVal == null
                                            ? 'Loading'
                                            : `-${displayCurrency(
                                                  newPlan?.interval === 'month'
                                                      ? 0.0
                                                      : upgradeSavedVal.unusedTimeAmount,
                                              )}`}
                                    </TableCell>
                                </TableRow>
                            </>
                            <TableRow className='w-fill h-11'>
                                <TableCell className='w-[40%] ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base'>
                                    Total Charge for Billing Cycle Adjustment
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-end text-base'>
                                    {newPlan && displayCurrency(newPlan?.price ?? 0.0)}
                                </TableCell>
                            </TableRow>

                            <TableRow className='w-fill h-11'>
                                <TableCell className='w-[40%] ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base'>
                                    Next Billing Amount Due
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-end text-base'>
                                    {upgradeSavedVal && upgradeSavedVal?.amountDue > 0
                                        ? displayCurrency(upgradeSavedVal?.amountDue)
                                        : displayCurrency(newPlan?.price ?? 0)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className='flex justify-end bg-gray-200 rounded-b-[15px] gap-3 py-5 px-5'>
                <Button
                    onClick={() => navigate(-1)}
                    className='w-[95px] text-zentive-green-dark font-semibold text-lg'
                    variant={'outline'}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        const process = 'billing-cycle'
                        navigate(
                            `/owner-subscription/change-plan/confirm?process=${process}&newPlanId=${newPlanId}`,
                        )
                    }}
                    className='w-[95px]'
                >
                    Next
                </Button>
            </div>
        </div>
    )
}

export default ChangeBillingCycle
