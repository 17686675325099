import { AxiosError } from 'axios'
import { axiosInstance } from '../axiosInstance'
import { BusinessStatusType, ProfileBusinessType } from '../business/schema'
import { CheckUpgradeType, UpdateAutoPayType, UpdateCreditCardType } from './schema'

// For setting a new profile-business status
export const updateBusinessSubscriptionStatus = async (data: BusinessStatusType) => {
    const response = await axiosInstance.post(`stripe/renewal/update`, data)
    return response.data
}

export const updateOwnerSubscriptionStatus = async (data: ProfileBusinessType): Promise<string> => {
    const response = await axiosInstance.post(`stripe/owner/renewal/update`, data)

    return response.data.content
}

export const updateCreditCard = async (updateCredit: UpdateCreditCardType): Promise<string> => {
    try {
        const res = await axiosInstance.post(`stripe/update/payment-method`, updateCredit)

        return res.data.content
    } catch (err) {
        if (err instanceof AxiosError) {
            throw new Error(err.response?.data.error)
        } else {
            throw new Error('An unknown error occured.')
        }
    }
}

export const updateAutoPay = async (updateAutoPay: UpdateAutoPayType) => {
    const res = await axiosInstance.post(`stripe/auto-pay`, updateAutoPay)

    return res.data.content
}

export const checkUpgradeData = async (checkUpgradePlan: CheckUpgradeType) => {
    const res = await axiosInstance.post(`stripe/upgrade/confirm`, checkUpgradePlan)

    return res.data.content
}

export const getUnpaidInvoice = async (userId: string) => {
    const res = await axiosInstance.get(`stripe/owner/invoice/${userId}`)

    return res.data.content
}

export const getEmailConfirmedAt = async (profileId: string) => {
    const res = await axiosInstance.get(`profiles/email-confirmed-at/${profileId}`)
    return res.data as string
}

export const getInvoicePrice = async (invoiceId: string) => {
    const response = await axiosInstance.get(`/invoice/price?invoiceId=${invoiceId}`)

    return response.data.content
}

export const ownerPayInvoice = async (invoiceId: string) => {
    const response = await axiosInstance.post(`/stripe/owner/manual/pay/${invoiceId}`)

    return response.data.content
}

export const getAllInvoice = async (profileId: string) => {
    const response = await axiosInstance.get(`/invoice/get/all?profileId=${profileId}`)

    return response.data.content
}

export const cancelDowngrade = async (profileId: string): Promise<string> => {
    const response = await axiosInstance.get(`/stripe/downgrade/cancel/${profileId}`)

    return response.data.content
}

export const previousDowngradePlan = async (profileId: string | undefined) => {
    const response = await axiosInstance.get(`/stripe/previous/downgrade/plan/${profileId}`)

    return response.data.content
}
