import SubscriptionPlans from '../OwnerChangePlan/SubscriptionPlans'
import { useAtom } from 'jotai'
import { userAtom } from '@/store/auth'
import { BREADCRUMBS_PADDING_STYLE, ROLE } from '@/constants'

const EndTrialPlanGalery = () => {
    const [user] = useAtom(userAtom)
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    return (
        <section className={`${BREADCRUMBS_PADDING_STYLE}`}>
            {!isCustomer && (
                <div className='mx-20'>
                    <div className='flex flex-col items-center justify-center gap-6 mb-10 mx-20 text-center'>
                        <h1 className='text-zentive-green-dark font-semibold text-2xl'>
                            {' '}
                            Your Free Trial has Expired{' '}
                        </h1>
                        <p>
                            {' '}
                            Your 14-day free trial has ended. But don't worry! You can unlock the
                            full potential of our platform and enjoy uninterrupted access to all our
                            amazing features by subscribing today.{' '}
                        </p>
                        <p>
                            {' '}
                            Ready to take the next step? Simply choose a plan below to continue your
                            journey with us!{' '}
                        </p>
                    </div>
                    <SubscriptionPlans />
                </div>
            )}
        </section>
    )
}

export default EndTrialPlanGalery
