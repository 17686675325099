import { Button } from '@/components/Button'
import { Form } from '@/components/Forms'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputFormData } from './InputForm'
import { ProductServiceType } from '@/api/service/schema'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { saveService } from '@/api/service'
import { useToast } from '@/hooks/useToast'
import './style.css'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'
import { PRODUCT_SERVICE_TYPES } from '@/constants'
import { productServiceSchema } from '@/api/quoting/schema'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { capitalize } from '@/utils/helper'

const CreateService = () => {
    const user = useViewedAsUser()

    const queryClient = useQueryClient()
    const { toast } = useToast()
    const navigate = useNavigate()

    const methods = useForm<ProductServiceType>({
        defaultValues: {
            businessId: user?.businessId,
            cost: 0.0,
            description: '',
            isTaxExempt: false,
            markup: 0.0,
            name: '',
            type: PRODUCT_SERVICE_TYPES[1].toLowerCase(),
            unitPrice: 0.0,
        },
        mode: 'onChange',
        resolver: zodResolver(productServiceSchema),
    })

    const {
        handleSubmit,
        formState: { isValid },
        reset,
    } = methods

    const { mutate: saveServiceMu, isPending } = useMutation<
        ProductServiceType,
        AxiosError,
        ProductServiceType
    >({
        mutationFn: saveService,
        onSuccess: () => {
            const type = methods.getValues('type')

            toast({ description: `${capitalize(type)} Saved` })
            // invalidateQueries is best used when the query key only depends on primary keys
            // e.g: queryKey: ['userProfile', userId]

            // don't use invalidateQueries for query keys with dynamic values such as this:
            // e.g: when a query is reliant on sorters and search keywords
            // queryKey: ['productServiceType', keyword, sortBy, sortOrder]
            // --> queryKey: ['productServiceType', '', 'name', 'ASC']
            // --> queryKey: ['productServiceType', 'sample', '', 'DESC']
            // --> queryKey: ['productServiceType', 'zxc', 'type', '']
            queryClient.removeQueries({ queryKey: ['productServiceType'] })
            reset()
            navigate('/settings/product-services')
        },
        onError: (err) => {
            console.error(err)
            toast({
                description: 'Product/Service already exists',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: ProductServiceType) => saveServiceMu(data)

    return (
        <>
            <BreadCrumbs titleName='Add Items' />
            <div className='flex py-6 justify-center'>
                <Form {...methods}>
                    <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
                        <InputFormData />
                        <div className='py-5 md:py-0 bg-zentive-green-light left-0 w-full md:h-[75px] rounded-b-[8px] flex items-center justify-end px-[26px] gap-[12px]'>
                            <Button
                                className='w-[95px] h-[43px] border border-zentive-green-dark text-zentive-green-dark hover:text-white font-semibold bg-white rounded-[5px] text-[16px] hover:bg-zentive-green-dark'
                                disabled={isPending}
                                onClick={() => navigate('/settings/product-services')}
                                type='button'
                            >
                                Cancel
                            </Button>
                            <Button
                                className='w-[95px] h-[43px] text-white font-semibold bg-zentive-green-dark  rounded-[5px] text-[16px] hover:bg-zentive-green-medium'
                                disabled={!isValid || isPending}
                                type='submit'
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </>
    )
}

export default CreateService
