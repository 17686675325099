import { z } from 'zod'
import { phoneNumberValidator, stringValidator } from '@/utils/regex'
import { roleSchema } from '../role/schema'
import { addressSchema } from '../address/schema'
import { baseSchema } from '../common/schema'
import { fullProfileSchema } from '../business/schema'
import { PaginationSchema } from '@/components/Pagination/schema'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { USER_STATUS } from '@/constants'
import { QuoteType } from '../quoting/schema'
dayjs.extend(isSameOrBefore)

export const isBirthdayNotFutureDate = (val?: string): boolean => {
    if (!val || val == '') return true
    const inputDate = dayjs(val)
    const currentDate = dayjs()
    return inputDate.isSameOrBefore(currentDate, 'day')
}

export type AccountSortType = 'alphabetically' | 'newest' | 'highest' | 'verified' | 'unverified'

export const accountStatusSchema = z.enum([
    USER_STATUS.ACTIVATED,
    USER_STATUS.BILLING_PAUSED,
    USER_STATUS.DELETED,
    USER_STATUS.INACTIVE,
    USER_STATUS.PENDING,
    USER_STATUS.TERMINATED,
    USER_STATUS.UNPAID,
])

export const email = z
    .object({
        email: z
            .string({ required_error: 'Email is required' })
            .email({ message: 'Invalid email address' }),
    })
    .required()

export const profileDetails = z.object({
    firstName: stringValidator('First Name', { isRequired: true }),
    lastName: stringValidator('Last Name', { isRequired: true }),
    phoneNumber: phoneNumberValidator,
    profileImage: z.instanceof(File).or(z.string()).nullish(),
    status: accountStatusSchema.optional(),
})

export const employeeProfileDetails = z.object({
    caLicense: z.string().optional(),
    compensation: z
        .number({ required_error: 'Compensation is required.' })
        .positive('Compensation must be greater than zero.'),
    dateHired: z
        .string({ required_error: 'Date Hired is required.' })
        .refine(isBirthdayNotFutureDate, {
            message: 'Date hired cannot be a future date.',
        }),
    dateOfBirth: z.string().optional().refine(isBirthdayNotFutureDate, {
        message: 'Date of Birth cannot be a future date.',
    }),
    employeeNumber: z.string(),
    address: addressSchema,
})

export const profileSchema = z
    .object({
        profileId: z.string(),
        businessId: z.string(),
        addressId: z.string(),
        roleId: z.string(),
        status: z.string(),
        accountNumber: z.number().optional(),
        isAutoPay: z.boolean().optional(),
        cardNumber: z.string().optional(),
        customerId: z.string().optional(),
        isPasswordGenerated: z.boolean(),
        role: roleSchema.optional(),
        address: addressSchema.partial(),
        isStripeSetupLater: z.boolean().optional(),
        crewMemberId: z.string().optional(),
    })
    .merge(email)
    .merge(profileDetails)
    .merge(baseSchema.partial())

export const encryptedProfileStatusSchema = profileSchema
    .pick({ profileId: true, businessId: true })
    .extend({
        encryptedStatus: z.string(),
    })

export const validateCustomerInviteSchema = email.merge(profileSchema.pick({ businessId: true }))

export const employeeProfileDetailsSchema = profileSchema.merge(employeeProfileDetails)

export const customerProfileSchema = profileSchema.omit({
    businessId: true,
    roleId: true,
})

export const inviteCustomerProfileSchema = profileSchema
    .omit({
        profileId: true,
        addressId: true,
        roleId: true,
        customerId: true,
        status: true,
        role: true,
        isPasswordGenerated: true,
    })
    .extend({
        address: addressSchema,
        crewMemberId: z.string().optional(),
        dateOfBirth: z
            .string()
            .refine(isBirthdayNotFutureDate, {
                message: 'Date of Birth cannot be a future date.',
            })
            .optional(),
    })

export const employeeProfileSchema = employeeProfileDetailsSchema.omit({
    profileId: true,
    addressId: true,
    roleId: true,
    customerId: true,
    status: true,
    role: true,
    isPasswordGenerated: true,
})

export const bulkUpdateProfileStatusSchema = z.object({
    profileIds: z.array(z.string()),
    status: z.string().optional(),
    businessId: z.string().optional(),
})

export const billingAddressSchema = z.object({
    streetOne: stringValidator('Street 1', { fieldType: 'location', isRequired: false }).optional(),
    streetTwo: stringValidator('Street 2', { fieldType: 'location', isRequired: false }).optional(),
    city: stringValidator('City', { fieldType: 'location', isRequired: false }).optional(),
    state: stringValidator('State', { fieldType: 'location', isRequired: false }).optional(),
    zipCode: stringValidator('Zip Code', { fieldType: 'zip', isRequired: false }).optional(),
})

export const billingInfoSchema = z.object({
    customerID: z.string(),
    lastFourNo: z.string(),
    cardBrand: z.string().optional(),
    firstName: stringValidator('First Name', { isRequired: true }),
    lastName: stringValidator('Last Name', { isRequired: true }),
    email: stringValidator('Email', { fieldType: 'email', isRequired: true }),
    contactNumber: phoneNumberValidator,
    address: billingAddressSchema,
})

export const subscriptionsInfoSchema = z.object({
    subscriptionId: z.string(),
    subStatus: z.string(),
    created: z.number(),
    planName: z.string(),
    price: z.number(),
    referenceNumber: z.string(),
    status: z.string(),
    discountCode: z.string().optional(),
    discountedPrice: z.number().optional(),
})

export const subscriptionListSchema = z.object({
    content: z.array(subscriptionsInfoSchema),
    meta: PaginationSchema,
})

export const paramsFilterSchema = z.object({
    state: z.boolean().optional() || false,
    alphabetically: z.boolean().optional() || false,
    newestCustomer: z.boolean().optional() || false,
    oldestCustomer: z.boolean().optional() || false,
    updatedCustomer: z.boolean().optional() || false,
    highestPayingCustomer: z.boolean().optional() || false,
    paidInvoices: z.boolean().optional() || false,
    unpaidInvoices: z.boolean().optional() || false,
    recentlyCancelled: z.boolean().optional() || false,
})

export type Meta = {
    totalRecords: number
    pageSize: number
    page: number
}

export const customerContactDetails = profileSchema.pick({
    email: true,
    profileId: true,
    phoneNumber: true,
})

export const customerContactContentSchema = z
    .object({
        name: z.string(),
        dateDelivered: z.string(),
    })
    .merge(customerContactDetails)
    .extend({
        status: z.string(),
    })

export const customerContactSchema = z.object({
    content: z.array(customerContactContentSchema),
})

export const customerProfileListSchema = z.object({
    content: z.array(profileSchema),
    meta: PaginationSchema,
})

export const stripeSetupLaterSchema = z.object({
    profileId: z.string(),
    isSetupLater: z.boolean(),
})

export const restrictionsSchema = z.object({
    isStripeRestricted: z.boolean(),
    isSetupLater: z.boolean(),
})

export const freeTrialSchema = z.object({
    ownerEmailConfirmedDate: z.string(),
    endOfTrial: z.string(),
    currentDate: z.string(),
    daysLeft: z.number(),
})

export const failedImportRecordsSchema = z.object({
    name: z.string(),
    email: z.string(),
    reason: z.string(),
})

export const inviteEmployeeViaCsvSchema = z.object({
    employees: z.array(employeeProfileSchema),
    failedImports: z.array(failedImportRecordsSchema),
})

export const mailChimpIntegrationSchema = profileSchema.pick({ businessId: true }).extend({
    apiKey: z.string(),
    listId: z.string(),
})

export const inviteCoOwnerSchema = profileSchema.pick({
    businessId: true,
    email: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
})

export const businessOwnerSchema = profileSchema.pick({
    profileId: true,
    email: true,
    firstName: true,
    lastName: true,
    phoneNumber: true,
    createdAt: true,
    role: true,
})

export const businessOwnerListSchema = z.object({
    content: z.array(businessOwnerSchema),
    meta: PaginationSchema,
})

export type InviteCoOwnerType = z.infer<typeof inviteCoOwnerSchema>
export type BusinessOwnerLisType = z.infer<typeof businessOwnerListSchema>
export type BusunessCoOwnerType = z.infer<typeof businessOwnerSchema>

export type MailChimpIntegrationType = z.infer<typeof mailChimpIntegrationSchema>

export type CustomerContactContentType = z.infer<typeof customerContactContentSchema>
export type CustomerContactType = z.infer<typeof customerContactSchema> & { meta: Meta }
export type ViewAsProfileType = z.infer<typeof profileSchema>
export type ProfileType = z.infer<typeof profileSchema>
export type FullProfileType = z.infer<typeof fullProfileSchema> & {
    stripeConnectedAccId?: string
    updatedAt?: string
}
export type CustomerProfileType = z.infer<typeof profileSchema>
export type CustomerProfileListType = z.infer<typeof customerProfileListSchema>
export type BillingInfoType = z.infer<typeof billingInfoSchema>
export type SubscriptionsInfoType = z.infer<typeof subscriptionsInfoSchema>
export type SubscriptionListType = z.infer<typeof subscriptionListSchema>

export type InviteUserType = z.infer<typeof inviteCustomerProfileSchema> // to rename
export type InviteEmployeeType = z.infer<typeof employeeProfileSchema>
export type BulkProfileUpdateStatusType = z.infer<typeof bulkUpdateProfileStatusSchema>
export type ParamsFilterType = z.infer<typeof paramsFilterSchema>
export type StripeSetupLaterType = z.infer<typeof stripeSetupLaterSchema>
export type FreeTrialType = z.infer<typeof freeTrialSchema>
export type RestrictionsType = z.infer<typeof restrictionsSchema>
export type InviteEmployeeViaCSVType = z.infer<typeof inviteEmployeeViaCsvSchema>
export type ValidateCustomerInviteType = z.infer<typeof validateCustomerInviteSchema>
export type EncryptedProfileStatusType = z.infer<typeof encryptedProfileStatusSchema>
export type UserAccountStatusType = z.infer<typeof accountStatusSchema>
export type FailedImportType = z.infer<typeof failedImportRecordsSchema>

export type ProfileWithQuote = {
    profile: FullProfileType
    quote: QuoteType
    quoteId: string
}
