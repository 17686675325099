import React from 'react'
import { CSVLink } from 'react-csv'

const EmployeeCSVTemplate = ({
    isOpen,
    setIsOpen,
}: {
    isOpen?: boolean
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    let csvHeaders: string[] = []

    csvHeaders = [
        'First Name',
        'Last Name',
        'Email Address',
        'Contact Number',
        'Street 1',
        'Street 2',
        'City',
        'State',
        'Zip Code',
    ]

    const csvInputGuide = [
        {
            'First Name': 'John',
            'Last Name': 'Doe',
            'Email Address': 'zentive-test@gmail.com',
            'Contact Number': '1 234 567 8910',
            'Street 1': 'Street Parking',
            'Street 2': '',
            City: 'Los Angeles',
            State: 'California',
            'Zip Code': '90027',
        },
    ]

    return (
        <CSVLink
            data={csvInputGuide}
            headers={csvHeaders}
            filename={'customer-list-template.csv'}
            className={
                'w-[230px] h-[34px] text-[#191A0A] hover:bg-[#00000029] whitespace-nowrap block px-3 py-1 text-base rounded-b-md'
            }
            onClick={() => {
                setIsOpen && setIsOpen(!isOpen)
            }}
        >
            Download CSV Template
        </CSVLink>
    )
}

export default EmployeeCSVTemplate
