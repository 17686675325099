import GeolocationWarningModal from '@/components/GeolocationWarningModal'
import { fetchTimezone } from '@/hooks/useGeocode'
import { toast } from '@/hooks/useToast'
import { timezoneAtom } from '@/store/auth'
import { useSetAtom } from 'jotai'
import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const CrewPortal = () => {
    const [showGeolocationWarning, setShowGeolocationWarning] = useState(false)
    console.log("🚀 ~ CrewPortal ~ showGeolocationWarning:", showGeolocationWarning)
    const setTimezone = useSetAtom(timezoneAtom)

    useEffect(() => {
        handleEnableLocation();
    }, [])

    const handleEnableLocation = () => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                setShowGeolocationWarning(false);
                sessionStorage.setItem('geolocationWarningShown', 'true');
                const { latitude, longitude } = position.coords
                        const timezone = await fetchTimezone({ lat: latitude, lng: longitude })
                        setTimezone(timezone)
                        if (sessionStorage.getItem('geolocationWarningShown') !== 'true') {
                            toast({
                                title: 'Location permission granted',
                                description: 'Location permission has been enabled',
                                variant: 'crew_interface'
                            })
                            sessionStorage.setItem('geolocationWarningShown', 'true');
                        }
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED && sessionStorage.getItem('geolocationWarningShown') !== 'true') {
                    setShowGeolocationWarning(true);
                    toast({
                        title: 'Location permission denied',
                        description: 'Please enable location permission to continue',
                        variant: 'crew_interface_destructive'
                    })
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            }
        );

        // if (!navigator.geolocation && sessionStorage.getItem('geolocationWarningShown') !== 'true') {
        //     setShowGeolocationWarning(true);
        // } else { 
        //     setShowGeolocationWarning(false);
        //     if (sessionStorage.getItem('geolocationWarningShown') === 'false') {
        //         toast({
        //             title: 'Location permission granted'
        //         })
        //     }
            
        // }
    };

    const checkAndRequestGeolocation = async () => {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        console.log("🚀 ~ checkAndRequestGeolocation ~ permissionStatus:", permissionStatus)
            
        if (permissionStatus.state === 'denied') {
            handleEnableLocation()
        } else {
            handleEnableLocation();
        }
    };

    

    return (
        <div className='flex flex-col h-full'>
            <GeolocationWarningModal 
                open={showGeolocationWarning}
                setOpen={setShowGeolocationWarning}
                onEnableLocation={checkAndRequestGeolocation}
            />
            <Outlet />
        </div>
    )
}

export default CrewPortal
