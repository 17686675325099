import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import { AddBulkLeadsType, GetLeadsResponse, InviteLeadsViaCSVType, LeadDetailsRequest, SortCriterion } from './schema'
import { CreateGroupAndMessagingType } from '../customer/schema'
import qs from 'qs'
import { EditLeadDealStage } from '../deal_stage/schema'

export const createLead = async (data: LeadDetailsRequest): Promise<LeadDetailsRequest> => {
    const res = await axiosInstance.post('/leads/create', data)

    return res.data.content
}

export const createLeadMessage = async (params: CreateGroupAndMessagingType) => {
    const res = await axiosInstance.post(`/leads/create-message`, params)
    return res.data as { message: string }
}

export const checkExistingLead = async (email: string): Promise<boolean> => {
    const res = await axiosInstance.get(`/leads/check-existing-lead/${email}`)

    return res.data.content
}

export const getLeads = async (
    businessId: string,
    searchKeyword: string,
    sortCriteria: SortCriterion[],
    pagination?: PaginationType,
): Promise<GetLeadsResponse> => {
    const response = await axiosInstance.get(`/leads/${businessId}`, {
        params: {
            customerName: searchKeyword,
            sort: sortCriteria.map((criterion) => `${criterion.column}:${criterion.order}`),
            page: pagination?.page,
            pageSize: pagination?.pageSize,
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
    })

    return response.data
}

export const deleteLeads = async (businessId: string, leadIds: string[]): Promise<string> => {
    const res = await axiosInstance.delete(`/leads/delete/${businessId}`, {
        params: { leadIds },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        },
    })
    return res.data.content
}

// (parameter) data: {
//     dealStageId: string ;
//     leadId: string
// }
export const updateLeadDealStage = async (data: EditLeadDealStage): Promise<string> => {
    const res = await axiosInstance.patch('leads/update-deal-stage', data)

    return res.data.content
}

export const addBulkLeads = async (
    data: AddBulkLeadsType,
): Promise<InviteLeadsViaCSVType> => {
    const formData = new FormData()
    
    formData.append('businessId', data.businessId ?? '')
    formData.append('leadsCSV', data.leadsCSV)

    try {
        const response = await axiosInstance.post('/leads/bulk-import', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data.content
    } catch (err) {
        console.error('Failed to export leads via csv:', err)
        throw err
    }
}
