import { Button } from '@/components/Button'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { IoSearchOutline } from 'react-icons/io5'
import { IoIosFunnel } from 'react-icons/io'
import { useAtomValue, useSetAtom } from 'jotai'
import {
    crewInterfaceFilterAtom,
    crewjobSearchOpenAtom,
    selectedDateFilterAtom,
} from '@/store/crew'
import { cn } from '@/utils/helper'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { userAtom } from '@/store/auth'
import { FullProfileType } from '@/api/profile/schema'
import { FEATURE_RESTRICTIONS } from '@/constants'
import { useQuery } from '@tanstack/react-query'
import { UpgradeBadge } from '@/components/UpgradeBadge'

const CrewTopBar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const jobTabPathname = '/crew/jobs'
    const setIsSearchToggle = useSetAtom(crewjobSearchOpenAtom)
    const setIsDateFilterOpen = useSetAtom(crewInterfaceFilterAtom)
    const selectedDateFilter = useAtomValue(selectedDateFilterAtom)

    const user = useAtomValue(userAtom) as FullProfileType
    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['crewViewJobFeatureRestrictions', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.timeTracking,
            ),
    })

    const handleJobSearchClick = () => {
        navigate('/crew/jobs/search')
        setIsSearchToggle(true)
    }
    return (
        <div className='fixed w-full max-w-[1024px] top-0 py-1 z-20 bg-white'>
            <div className='flex flex-row justify-between'>
                <div>
                    <Button
                        variant='ghost'
                        onClick={() => navigate('/crew/jobs')}
                        className='hover:bg-transparent'
                    >
                        <img
                            src={zentiveLogo}
                            alt='Zentive Logo'
                            className='h-[30px] w-[80px] cursor-pointer'
                        ></img>
                    </Button>
                </div>

                {!isFeatureAvailable && !featureLoading && pathname !== jobTabPathname && (
                    <UpgradeBadge className='mx-3' />
                )}

                {pathname === jobTabPathname && (
                    <div className='flex flex-row my-auto gap-x-4 mx-10'>
                        <IoSearchOutline
                            className='h-5 w-5 cursor-pointer'
                            onClick={handleJobSearchClick}
                        />
                        <IoIosFunnel
                            className={cn(
                                'h-5 w-5 cursor-pointer',
                                selectedDateFilter.startDate !== '' &&
                                    selectedDateFilter.endDate !== '' &&
                                    'text-zentive-blue-dark',
                            )}
                            onClick={() => setIsDateFilterOpen(true)}
                        />
                        <span className='absolute top-2 right-8 bg-zentive-green-dark text-black text-xs rounded-full px-1'>
                            {selectedDateFilter.startDate != '' &&
                                selectedDateFilter.endDate !== '' &&
                                '1'}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CrewTopBar
