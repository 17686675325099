import { z } from 'zod'
import { PaginationSchema } from '@/components/Pagination/schema'
import { employeeSchema } from '../employee/schema'
import { stringValidator } from '@/utils/regex'
import { addressSchema } from '../address/schema'
import { employeeProfileDetailsSchema } from '../profile/schema'
import { baseSchema } from '../common/schema'

// Use independently on other schemas
export const createCrewSchema = z.object({
    crewNumber: z.number(),
    description: stringValidator('Description', { isRequired: true }),
    businessId: z.string({ required_error: 'Crew color is required.' }),
    crewColor: z.string({ required_error: 'Crew color is required.' }),
    memberProfileIds: z.array(z.string()),
    foreManId: z.string({ required_error: 'Foreman is required.' }),
    crewId: z.string().optional(),
})

export const updateCrewSchema = createCrewSchema
    .pick({
        description: true,
        businessId: true,
        crewColor: true,
        memberProfileIds: true,
        crewId: true,
        foreManId: true,
    })
    .merge(
        z.object({
            crewNumber: z.number(),
        }),
    )

export const crewMemberSchema = employeeSchema 
    .pick({
        email: true,
        phoneNumber: true,
        firstName: true,
        lastName: true,
        profileId: true,
        businessId: true,
        status: true,
        profileImage: true,
    })
    .merge(
        z.object({
            crewMemberId: z.string().optional(),
            employeeNumber: z.string(),
            name: z.string(),
            addressId: z.string(),
            caLicense: z.string(),
            compensation: z.number(),
            dateOfBirth: z.string(),
            createdAt: z.string(),
            notes: z.string(),
            isPasswordGenerated: z.boolean(),
            role: z.string(),
            roleId: z.string(),
            customerId: z.string(),
            updatedAt: z.string(),
            address: addressSchema.partial(),
        }),
    )

export const foreManSchema = z.object({
    crewMemberId: z.string(),
    crewId: z.string().optional(),
    isForeman: z.string(),
    description: z.string(),
    crewProfile: crewMemberSchema,
})

export const crewMemberShortSchema = z.object({
    crewMemberId: z.string(),
    profileId: z.string(),
    crewProfile: crewMemberSchema,
    crewId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
})

export const crewSchema = z.object({
    profileId: z.string(),
    crewId: z.string(),
    crewNumber: z.number(),
    crewColor: z.string(),
    description: z.string(),
    foreMan: crewMemberShortSchema,
    members: z.array(crewMemberShortSchema),
    status: z.string(),
    createdAt: z.string(),
    businessId: z.string().optional(),
})

export const crewListSchema = z.object({
    content: z.array(crewSchema),
    meta: PaginationSchema,
})

export const employeeToCrewSchema = z.object({
    content: crewSchema,
    meta: PaginationSchema,
})
export const bulkUpdateCrewStatusSchema = z.object({
    crewIds: z.array(z.string()),
    status: z.string().optional(),
})

export const bulkRemoveCrewMember = z.object({
    profileIds: z.array(z.string()),
})

export const crewMemberDetailsSchema = employeeProfileDetailsSchema.pick({
    firstName: true,
    lastName: true,
    crewMemberId: true,
    profileId: true,
})

export const employeeCrewDetailsSchema = crewMemberDetailsSchema
    .merge(crewMemberDetailsSchema)
    .merge(
        createCrewSchema.pick({
            crewId: true,
        }),
    )
    .merge(
        z.object({
            members: z.array(crewMemberDetailsSchema),
        }),
    )

export const jobCrewMemberSchema = z.object({
    jobCrewMemberID: z.string().optional(),
    jobId: z.string(),
    crewMemberId: z.string(),
    crewMember: z.array(crewMemberSchema),
    dtrId: z.string().optional(),  
})
.merge(baseSchema.partial())

export type CreateCrewType = z.infer<typeof createCrewSchema>
export type CrewMemberType = z.infer<typeof crewMemberSchema>
export type UpdateCrewType = z.infer<typeof updateCrewSchema>
export type CrewType = z.infer<typeof crewSchema>
export type CrewListResponseType = z.infer<typeof crewListSchema>
export type EmployeeToCrewResponseType = z.infer<typeof employeeToCrewSchema>
export type BulkUpdateCrewStatusSchema = z.infer<typeof bulkUpdateCrewStatusSchema>
export type CrewtoCrewMemberType = z.infer<typeof crewMemberShortSchema>
export type BulkRemoveCrewMemberType = z.infer<typeof bulkRemoveCrewMember>
export type CrewMemberDetailsType = z.infer<typeof crewMemberDetailsSchema>
