import { ISubscription } from '@/assets/static/signupGallery'
import FeatureTable from './FeatureTable'
import OffersTable from './OffersTable'
import { FC } from 'react'
import { SubscriptionMatrix, SubscriptionType } from '@/api/subscription/schema'
import { useQuery } from '@tanstack/react-query'
import { getSubscriptionMatrix } from '@/api/subscription'
import SubscriptionButtons from './SubscriptionButtons'

type GalleryTableProps = {
    subscriptionTypes?: SubscriptionType[]
    tableData: ISubscription
    interval: string
}

const SubscriptionPlans: FC<GalleryTableProps> = ({ subscriptionTypes, tableData, interval }) => {
    const { data: matrix, isPending: isLoading } = useQuery<SubscriptionMatrix>({
        queryKey: ['subscriptionMatrix', interval],
        queryFn: () => getSubscriptionMatrix(interval),
    })

    if (isLoading || !matrix) {
        return <></>
    }

    return (
        <>
            <FeatureTable tableData={tableData.features} />
            <OffersTable tableData={matrix?.customerManagement} tableHead='Customer Management' />
            <OffersTable tableData={matrix?.jobManagement} tableHead='Job Management' />
            <OffersTable tableData={matrix?.financeManagement} tableHead='Finance Management' />
            <OffersTable tableData={matrix?.reportGeneration} tableHead='Report Generation' />
            <OffersTable tableData={matrix?.users} tableHead='Users' />
            <OffersTable tableData={matrix?.additionalItems} tableHead='Additional Items' />
            <div>
                <SubscriptionButtons subscriptionTypes={subscriptionTypes as SubscriptionType[]} />
            </div>
        </>
    )
}

export default SubscriptionPlans
