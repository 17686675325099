import { TableBody, TableCell, TableRow } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { QuoteListType, QuoteType } from '@/api/quoting/schema'
import { NavLink } from 'react-router-dom'
import { QUOTE_STATUS } from '@/constants'
import { BulkDeleteQuoteType } from '@/api/quoting/schema'
import { formatRecordNumber } from '@/utils/helper'

type QuotingTableBodyProps = {
    quote: QuoteListType | null
    setQuotesToUpdate: React.Dispatch<
        React.SetStateAction<{
            quoteIds: string[]
        }>
    >
    quotesToUpdate: BulkDeleteQuoteType
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
}

const QuotingTableBody: React.FC<QuotingTableBodyProps> = ({
    quote,
    setQuotesToUpdate,
    quotesToUpdate,
    setIsCheckAll,
}) => {
    const AllQuotesCount = quote?.content?.length

    const handleCheckClick = (quote: QuoteType, isCheck: boolean) => {
        if (quote.quoteId) {
            if (isCheck) {
                const updatedProfileIds = [...quotesToUpdate.quoteIds, quote.quoteId]
                setQuotesToUpdate({
                    quoteIds: updatedProfileIds,
                })
                setIsCheckAll(quotesToUpdate.quoteIds?.length + 1 === AllQuotesCount)
            } else {
                const updatedProfileIds = quotesToUpdate.quoteIds.filter(
                    (id) => id !== quote.quoteId,
                )

                setQuotesToUpdate((prev) => ({
                    ...prev,
                    quoteIds: updatedProfileIds,
                }))
                setIsCheckAll(quotesToUpdate.quoteIds?.length - 1 === AllQuotesCount)
            }
        }
    }

    return (
        <>
            <TableBody className='bg-white'>
                {quote &&
                    quote?.content?.map((data) => (
                        <TableRow className='whitespace-nowrap'>
                            <TableCell className=' text-zentive-black text-base max-w-[200px]'>
                                <div className='flex items-start text-start gap-[12px]'>
                                    <Checkbox
                                        className='mr-[5px] mt-[2px]'
                                        checked={quotesToUpdate?.quoteIds?.some(
                                            (p) => p === data.quoteId,
                                        )}
                                        onCheckedChange={(val) => {
                                            handleCheckClick(data, val as boolean)
                                        }}
                                    />
                                    <NavLink
                                        to={`/financial-management/quoting/view?quoteId=${data.quoteId}`}
                                    >
                                        {formatRecordNumber(data?.quoteNumber ?? 0)}
                                    </NavLink>
                                </div>
                            </TableCell>
                            <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                <NavLink
                                    to={`/financial-management/quoting/view?quoteId=${data.quoteId}`}
                                >
                                    {data.name}
                                </NavLink>
                            </TableCell>
                            <TableCell className=' text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                <NavLink
                                    to={`/financial-management/quoting/view?quoteId=${data.quoteId}`}
                                >
                                    {data.address?.streetAddress} {data.address?.city},{' '}
                                    {data.address?.state} {data.address?.zipCode}
                                </NavLink>
                            </TableCell>
                            <TableCell className=' text-right text-zentive-black text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                <NavLink
                                    to={`/financial-management/quoting/view?quoteId=${data.quoteId}`}
                                >
                                    $
                                    {data?.totalAmount?.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </NavLink>
                            </TableCell>
                            <TableCell className=' flex justify-center text-zentive-black text-base max-w-[300px] text-ellipsis overflow-hidden'>
                                <NavLink
                                    to={`/financial-management/quoting/view?quoteId=${data.quoteId}`}
                                >
                                    {data?.status === QUOTE_STATUS.APPROVED ? (
                                        <div className='w-[100px] text-center my-auto  bg-zentive-blue-dark px-2  text-white font-normal rounded-full '>
                                            Approved
                                        </div>
                                    ) : (
                                        <div className='w-[120px] text-center my-auto  bg-[#EBEBEB] px-2  text-black font-normal rounded-full capitalize'>
                                            {data?.status}
                                        </div>
                                    )}
                                </NavLink>
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </>
    )
}

export default QuotingTableBody
