import { CardTitle } from '@/components/Card'
import AddTaxDialog from './AddTaxDialog'
import { startTransition, useEffect, useState } from 'react'
import AddDiscountDialog from './AddDiscountDialog'
import { useFormContext } from 'react-hook-form'
import { QuoteType } from '@/api/quoting/schema'
import { invoiceContentAtom, quoteContentAtom, quoteTaxAtom } from '@/store/owner'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { Dialog, DialogTrigger } from '@/components/Dialog'
import { CiTrash } from 'react-icons/ci'
import { DEFAULT_QUOTE_TAX_VALUE } from '@/constants'
import { isNoSelectedServicesQuotingAtom } from '@/store/financial'
import { displayCurrency, displayWithDecimals } from '@/utils/helper'
import LaborCostDialog from '@/components/LaborCostDialog'
import { useLocation } from 'react-router-dom'

const BillingAmount = () => {
    const quoteContent = useAtomValue(quoteContentAtom)
    const isNoSelectedServices = useAtomValue(isNoSelectedServicesQuotingAtom) as boolean

    const [taxes, setTaxes] = useAtom(quoteTaxAtom)
    const [discount, setDiscount] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0, // this is subtotalWithDiscount
    })
    const { pathname } = useLocation()

    const isAddQuoting = pathname === '/financial-management/quoting/add-quoting'

    const setContent = useSetAtom(isAddQuoting ? quoteContentAtom : invoiceContentAtom)

    const { setValue } = useFormContext<QuoteType>()

    // computed and derived values
    const discountTotal = discount.isPercent
        ? (quoteContent.subTotal * discount.percentage) / 100
        : discount.amount
    const subtotalWithDiscount = quoteContent.subTotal - discountTotal

    const productsTaxAmount = quoteContent.totalProduct * (taxes.productTax / 100)
    const servicesTaxAmount = quoteContent.totalService * (taxes.serviceTax / 100)
    const expensesTaxAmount = quoteContent.totalExpense * (taxes.expenseTax / 100)

    const calcTotalTaxes = productsTaxAmount + servicesTaxAmount + expensesTaxAmount

    const taxedSubtotalWithDiscount = subtotalWithDiscount + calcTotalTaxes

    const total = taxedSubtotalWithDiscount

    const estimate = Number(
        subtotalWithDiscount - Math.floor(quoteContent.totalProductsAndServices),
    )

    const estimatePercentage = (estimate / subtotalWithDiscount) * 100

    const handleRemoveDiscount = () => {
        setDiscount({ percentage: 0, amount: 0, isPercent: false, totalAfterDiscount: 0 })
    }

    const handleRemoveTax = () => {
        setTaxes(DEFAULT_QUOTE_TAX_VALUE)
    }

    const removeLaborCost = () => {
        setContent((prev) => ({
            ...prev,
            laborCost: 0,
            subTotal: prev.totalProduct + prev.totalService + prev.totalExpense + 0,
        }))
    }

    useEffect(() => {
        startTransition(() => {
            setValue('totalAmount', total, {
                shouldValidate: true,
            })
            setValue('tax', Number(calcTotalTaxes.toFixed(3)), {
                shouldValidate: true,
            })
        })
    }, [total])

    useEffect(() => {
        startTransition(() => {
            setValue('subTotal', quoteContent.subTotal, {
                shouldValidate: true,
            })
        })
    }, [quoteContent.subTotal])

    const getTaxesTotalPercentage = () => {
        return (calcTotalTaxes / subtotalWithDiscount) * 100
    }

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-16'>
                <CardTitle className='text-lg font-semibold mb-6'>Billing Amount</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>
                        <div className='font-semibold'>Labor Cost</div>
                    </div>
                    {quoteContent.laborCost ? (
                        <>
                            <div className='px-8'>
                            $
                                {quoteContent.laborCost.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}
                            </div>
                            <div className='flex items-center pb-2'>
                            <div>${quoteContent.laborCost.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}</div>
                                <button
                                    type='button'
                                    className='ml-1'
                                    onClick={removeLaborCost}
                                >
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    ) : (
                        <LaborCostDialog fieldName='laborCost' />
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Subtotal</div>
                    <div className='px-8'>
                        $
                        {isNaN(quoteContent.subTotal)
                            ? '0'
                            : quoteContent.subTotal.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                              })}
                    </div>
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    {discount.amount == 0 && discount.percentage == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Discount</div>
                                <DialogTrigger asChild>
                                    <button
                                        type='button'
                                        disabled={isNoSelectedServices}
                                        className={`${
                                            !isNoSelectedServices
                                                ? 'text-zentive-blue-dark'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Discount
                                    </button>
                                </DialogTrigger>
                                <AddDiscountDialog
                                    setDiscount={setDiscount}
                                    subTotal={quoteContent.subTotal}
                                    handleDiscountTypeChange={(isPercent: boolean) => {
                                        setDiscount((prevDiscount: any) => ({
                                            ...prevDiscount,
                                            isPercent,
                                        }))
                                    }}
                                />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>
                                Discount{' '}
                                {discount.isPercent ? (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <button
                                                type='button'
                                                className='text-zentive-blue-dark font-semibold hover:underline'
                                            >
                                                {discount.percentage}%{' '}
                                            </button>
                                        </DialogTrigger>
                                        <AddDiscountDialog
                                            setDiscount={setDiscount}
                                            subTotal={quoteContent.subTotal}
                                            handleDiscountTypeChange={(isPercent: boolean) => {
                                                setDiscount((prevDiscount: any) => ({
                                                    ...prevDiscount,
                                                    isPercent,
                                                }))
                                            }}
                                        />
                                    </Dialog>
                                ) : (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <button
                                                type='button'
                                                className='text-zentive-blue-dark font-semibold hover:underline'
                                            >
                                                ${discount.amount}
                                            </button>
                                        </DialogTrigger>
                                        <AddDiscountDialog
                                            setDiscount={setDiscount}
                                            subTotal={quoteContent.subTotal}
                                            handleDiscountTypeChange={(isPercent: boolean) => {
                                                setDiscount((prevDiscount: any) => ({
                                                    ...prevDiscount,
                                                    isPercent,
                                                }))
                                            }}
                                        />
                                    </Dialog>
                                )}
                            </div>
                            <div className='flex items-center pb-2'>
                                <div>-${discountTotal.toFixed(2)}</div>
                                <button
                                    type='button'
                                    className='ml-1'
                                    onClick={handleRemoveDiscount}
                                >
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total After Discount</div>
                    <div className='px-8'>
                        $
                        {subtotalWithDiscount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </div>
                </div>
                <div className='flex flex-row justify-between  h-10  text-zentive-gray-medium'>
                    {taxes.productTax == 0 && taxes.serviceTax == 0 && taxes.expenseTax == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Tax</div>
                                <DialogTrigger asChild>
                                    <button
                                        type='button'
                                        disabled={isNoSelectedServices}
                                        className={`${
                                            !isNoSelectedServices
                                                ? 'text-zentive-blue-dark'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Tax
                                    </button>
                                </DialogTrigger>
                                <AddTaxDialog />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>
                                Tax <span className='text-zentive-blue-dark'>{taxes.name}</span>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <button
                                            type='button'
                                            className='text-zentive-blue-dark font-semibold hover:underline'
                                        >
                                            &nbsp;{' '}
                                            {`(${displayWithDecimals(
                                                getTaxesTotalPercentage(),
                                                3,
                                            )}%)`}
                                        </button>
                                    </DialogTrigger>
                                    <AddTaxDialog />
                                </Dialog>
                            </div>
                            <div className='flex items-center  pb-2'>
                                <div className=''>{displayCurrency(calcTotalTaxes)}</div>
                                <button type='button' className='ml-1' onClick={handleRemoveTax}>
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>

                {calcTotalTaxes > 0 && (
                    <div className='flex flex-col h-auto text-zentive-gray-medium mb-4'>
                        {taxes.productTax > 0 ? (
                            <div className='flex flex-row justify-between px-8'>
                                <p>- Products {`${displayWithDecimals(taxes.productTax, 2)}%`} </p>
                                <p>
                                    $
                                    {productsTaxAmount.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                        ) : null}
                        {taxes.serviceTax > 0 ? (
                            <div className='flex flex-row justify-between px-8'>
                                <p>- Services {`${displayWithDecimals(taxes.serviceTax, 2)}%`}</p>
                                <p>
                                    $
                                    {servicesTaxAmount.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                        ) : null}
                        {taxes.expenseTax > 0 ? (
                            <div className='flex flex-row justify-between px-8'>
                                <p>- Expenses {`${displayWithDecimals(taxes.expenseTax, 2)}%`}</p>
                                <p>
                                    $
                                    {expensesTaxAmount.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                        ) : null}
                    </div>
                )}
                <div className='flex flex-row justify-between pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total</div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {displayCurrency(subtotalWithDiscount + calcTotalTaxes)}
                    </div>
                </div>
            </div>
            <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
                <CardTitle className='text-lg mb-6'>Cost and Estimated Margin</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Service and Products Cost</div>
                    <div className='px-8'>
                        {displayCurrency(Math.floor(quoteContent.totalProductsAndServices))}
                    </div>
                </div>
                <div className='flex flex-row justify-between h-10 mb-4 text-zentive-gray-medium'>
                    <div className='flex flex-col px-4'>
                        <div className='font-semibold'>Net Profit Margin</div>
                        <div className='font-semibold'>(Excluded of Tax)</div>
                    </div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {`${displayCurrency(estimate)} (${displayWithDecimals(
                            estimatePercentage,
                            2,
                        )}%)`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAmount
