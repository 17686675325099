import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'

const tableHeader = [
    { textAlign: 'text-start', title: 'Items', width: 'w-[40%]' },
    { textAlign: 'text-center', title: 'Description' },
    { textAlign: 'text-center', title: 'Date' },
    { textAlign: 'text-end', title: 'Amount' },
]

const ExpenseTableHead = () => {
    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                {tableHeader?.map((header) => (
                    <TableHead
                        key={header.title}
                        className={cn(
                            header.textAlign,
                            header.width,
                            'flex-row font-semibold text-zentive-gray-medium text-base items-start',
                        )}
                    >{header.title}</TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default ExpenseTableHead
