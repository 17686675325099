import { signUpGalleryAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { SubscriptionType } from '@/api/subscription/schema'
import { getPricingById } from '@/api/subscription'
import ChangePlanButton from './ChangePlanButton'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import Spinner from '@/components/Spinner'

interface ActionButtonProps {
    interval: string
}

const ActionButton: React.FC<ActionButtonProps> = ({ interval }) => {
    const selected = useAtomValue(signUpGalleryAtom)
    const params = new URLSearchParams(window.location.search)
    const subscriptionTypeId = params.get('subscriptionTypeId')

    const profileID = params.get('profileID')

    const {
        data: businessProfile,
        isError: isBusinessProfileError,
        error: businessProfileError,
    } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        enabled: !!profileID,
        queryKey: ['businessProfile', profileID],
        queryFn: () => getBusinessProfile(profileID as string),
    })

    const { data: currentPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['Plan'],
        queryFn: () => getPricingById(subscriptionTypeId as string),
    })

    const hasError = isBusinessProfileError

    if (hasError) {
        const errorMessage = businessProfileError?.message || 'An error occurred'
        return <p className='text-[16px] text-zentive-red-dark font-semibold'>{errorMessage}</p>
    }

    if (!currentPlan) return <Spinner size='50' variant='normal' />

    const renderChangePlanButton = (plan: SubscriptionType, changePlanType: string) => (
        <ChangePlanButton
            plan={plan}
            changePlanType={changePlanType}
            isChangePlan={businessProfile?.business?.isChangePlan as string}
        />
    )

    const getChangePlanType = (plan: SubscriptionType) => {
        const currentPricing = currentPlan?.price ?? 0
        const planPricing = plan?.price ?? 0

        if (plan.interval === interval && currentPlan?.interval === interval) {
            if (currentPlan.name === plan.name) {
                return 'Current Plan'
            } else {
                return planPricing > currentPricing ? 'Upgrade' : 'Downgrade'
            }
        } else {
            if (currentPlan.name === plan.name) {
                return interval === 'month' ? 'Switch to Monthly' : 'Switch to Yearly'
            } else {
                if (planPricing > currentPricing) {
                    return 'Upgrade'
                } else {
                    return 'Downgrade'
                }
            }
        }
    }

    const renderPlanButtons = () => {
        return selected
            ?.sort((a, b) => {
                const order = ['Starter', 'Standard', 'Growth Pro']
                return order.indexOf(a.name as string) - order.indexOf(b.name as string)
            })
            ?.map((plan) => (
                <div key={plan?.subscriptionTypeId}>
                    {renderChangePlanButton(plan, getChangePlanType(plan))}
                </div>
            ))
    }

    return (
        <div className='flex justify-end w-full overflow-x-hidden overflow-y-hidden'>
            <div className='mt-5 inline-flex gap-x-24'>{renderPlanButtons()}</div>
        </div>
    )
}

export default ActionButton
