import { inviteCoOwner } from '@/api/profile'
import { FullProfileType, InviteCoOwnerType, inviteCoOwnerSchema } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import PhoneNumberInput from '@/components/InputNumber'
import { useToast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const CreateCoOwner = () => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const queryClient = useQueryClient()

    const methods = useForm<InviteCoOwnerType>({
        defaultValues: {
            businessId: user?.businessId,
        },
        mode: 'onChange',
        resolver: zodResolver(inviteCoOwnerSchema),
    })

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = methods

    const { mutate: inviteCoOwnerMu, isPending } = useMutation<
        unknown,
        AxiosError,
        InviteCoOwnerType
    >({
        mutationFn: inviteCoOwner,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['coOwnersList'] })
            queryClient.invalidateQueries({ queryKey: ['subSeat'] })
            toast({
                description: 'Co-Owner invited successfully',
                duration: 2000,
            })
            navigate('/settings/co-owner')
        },
    })

    const onSubmit = (data: InviteCoOwnerType) => {
        inviteCoOwnerMu(data)
    }

    return (
        <>
            <BreadCrumbs titleName='Add User' />

            <div className='bg-transparent w-1/3 mt-5'>
                <h1 className='text-zentive-blue-dark border border-4 border-zentive-blue-dark border-x-0 border-t-0 text-center'>
                    Personal Information
                </h1>
            </div>

            <div className='flex flex-col bg-white h-fit'>
                <Form {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='px-10 py-10 flex flex-col gap-5'>
                            <div className='flex flex-row gap-5'>
                                <FormField
                                    control={control}
                                    name='firstName'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='w-[217px] max-w-[217px] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='First Name'
                                                    type='text'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name='lastName'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='w-[217px] max-w-[217px] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='Last Name'
                                                    type='text'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    name='email'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='w-[217px] max-w-[217px] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='Email'
                                                    type='email'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className='flex flex-row gap-5'>
                                <FormField
                                    control={control}
                                    name='phoneNumber'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <PhoneNumberInput
                                                    className='w-full'
                                                    inputProps={{
                                                        name: 'Contact Number',
                                                        placeholder: 'Contact Number',
                                                    }}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-5 bg-zentive-gray-light items-center justify-end p-5'>
                            <Button
                                variant={'outline'}
                                disabled={isPending}
                                onClick={() => navigate('/settings/co-owner')}
                                className='w-1/6'
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                disabled={!isValid || isPending}
                                className='w-1/6'
                            >
                                Save
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </>
    )
}
