import { QuoteType } from '@/api/quoting/schema'
import { Button } from '@/components/Button'
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { jobInvoiceDiscountAtom } from '@/store/job'
import { DialogClose } from '@radix-ui/react-dialog'
import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const AddDiscountDialog = ({
    setDiscount,
    subTotal,
    handleDiscountTypeChange,
}: {
    setDiscount: (discount: {
        percentage: number
        amount: number
        isPercent: boolean
        totalAfterDiscount: number
    }) => void
    subTotal: number
    handleDiscountTypeChange: (isPercent: boolean) => void
}) => {
    const setJobInvoiceDiscount = useSetAtom(jobInvoiceDiscountAtom)
    const [discount, setDiscountInside] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0,
    })
    const { setValue } = useFormContext<QuoteType>()

    const resetDiscountState = () => {
        setDiscountInside({ percentage: 0, amount: 0, isPercent: false, totalAfterDiscount: 0 })
    }

    const handleOnDiscountChange = (discountRate: number) => {
        if (isNaN(discountRate)) {
            discountRate = 0
        }

        if (discount.isPercent) {
            const discountAmount = (subTotal * discountRate) / 100
            const totalAfterDiscount = subTotal - discountAmount
            setDiscountInside((prevState) => ({
                ...prevState,
                percentage: discountRate,
                amount: discountAmount,
                totalAfterDiscount: totalAfterDiscount,
            }))
        } else {
            setDiscountInside((prevState) => ({
                ...prevState,
                amount: discountRate,
                totalAfterDiscount: subTotal - discountRate,
            }))
        }
        setValue('isDiscountPercent', discount.isPercent)
        setValue('discount', discountRate)
    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        resetDiscountState()
        const selectedOption = event.target.value
        const isPercent = selectedOption === '%'
        setDiscountInside((prevState) => ({
            ...prevState,
            isPercent: isPercent,
        }))
        handleDiscountTypeChange(isPercent)
    }

    const handleSaveDiscount = () => {
        setDiscount(discount)
        resetDiscountState()
        setJobInvoiceDiscount(discount.amount)
    }

    const handleDiscountCancel = () => {
        setValue('discount', 0)
        setJobInvoiceDiscount(0)
    }

    return (
        <DialogPortal>
            <DialogOverlay>
                <DialogContent className='max-w-[400px] w-[400px] max-h-[280px] h-[280px] rounded-md '>
                    <DialogHeader className='m-6'>
                        <DialogTitle className='mb-2'>
                            <div className='text-2xl font-semibold text-left mb-6'>
                                Add Discount
                            </div>
                        </DialogTitle>
                        <DialogDescription className='no-scrollbar'>
                            <div className='flex justify-center'>
                                <Input
                                    className='w-full flex focus:border-r-0 justify-center items-center text-base rounded-tr-none rounded-br-none h-11 border border-black border-r-0 disabled:bg-gray-100 text-gray-900'
                                    placeholder='Discount'
                                    name='rate'
                                    type='number'
                                    value={
                                        discount.isPercent == true
                                            ? discount.percentage
                                            : discount.amount
                                    }
                                    onChange={(val) =>
                                        handleOnDiscountChange(parseFloat(val.target.value))
                                    }
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                />
                                <select
                                    name='row'
                                    className='w-[20%] focus:outline-none font-sans-pro text-base pl-3 border border-black disabled:bg-gray-100 text-gray-900 rounded-r-sm'
                                    onChange={handleSelectChange}
                                >
                                    <option>$</option>
                                    <option>%</option>
                                </select>
                            </div>
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className='absolute self-end py-4 px-6 w-full rounded-b-md justify-center items-center bg-zentive-gray-light'>
                        <div className='flex flex-row w-full justify-center gap-x-4 items-center'>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-[50%]'
                                    onClick={handleDiscountCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogClose>
                            <DialogClose asChild>
                                <Button
                                    type='submit'
                                    className='w-[50%]'
                                    disabled={
                                        discount.amount == 0 && discount.percentage == 0
                                            ? true
                                            : false
                                    }
                                    onClick={handleSaveDiscount}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    )
}

export default AddDiscountDialog
