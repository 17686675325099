import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { jobCompletionExportAtom } from '@/store/reports'
import { JobCompletionExportType } from '@/api/reports/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { GenerateJobCompletionReportPDF } from './GenerateJobCompletionReportPDF'
import { US_FORMAT } from '@/constants'
import dayjs from 'dayjs'

const ExportJobCompletionReportPDF = () => {
    const exportAtom = useAtomValue(jobCompletionExportAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: JobCompletionExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            jobNumber: r.jobNumber ?? 0,
                            crewName: r.crewName ?? '',
                            services: r.services ?? [],
                            invoiceNumber: r.invoiceNumber ?? 0,
                            invoiceId: r.invoiceId ?? '',
                            quoteId: r.quoteId ?? '',
                            quoteNumber: r.quoteNumber ?? 0,
                            dateCreated: dayjs(r.dateCreated).format(US_FORMAT),
                            plannedCompletionDate: dayjs(r.plannedCompletionDate).format(US_FORMAT),
                            completionDate: dayjs(r.completionDate).format(US_FORMAT),
                            invoiceAmount: r.invoiceAmount ?? 0,
                            laborCost: r.laborCost ?? 0,
                            timeSpent: r.timeSpent ?? 0,
                            address: r.address ?? '',
                            jobPrice: r.jobPrice,
                        })) ?? [],
                    totalLaborCost: exportAtom?.totalLaborCost ?? 0,
                    totalTimeSpent: exportAtom?.totalTimeSpent ?? 0,
                    totalInvoiceAmount: exportAtom?.totalInvoiceAmount ?? 0,
                } as JobCompletionExportType

                GenerateJobCompletionReportPDF(
                    zentiveLogoUrl,
                    tableData,
                    user?.business?.businessName ?? 'Zentive',
                )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportJobCompletionReportPDF
