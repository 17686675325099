import * as z from 'zod'
import { billingInfoSchema, profileSchema } from '../profile/schema'
import { PaginationSchema } from '@/components/Pagination/schema'
import { phoneNumberValidator } from '@/utils/regex'
import { quoteSchema } from '../quoting/schema'

export const settingsSchema = z.object({
    type: z.enum(['activate', 'inactivate'], {
        required_error: 'You need to select an action.',
    }),
})

export const baseInvoiceSchema = z.object({
    profileID: z.string(),
    customerID: z.string().optional(),
    amount: z.number(),
    description: z.string(),
})

export const invoiceSchema = z
    .object({
        invoiceID: z.string(),
        invoiceNumber: z.string(),
        quoteID: z.string().optional(),
        quote: quoteSchema.optional(),
        businessId: z.string().optional(),
        profile: profileSchema.optional(),
        status: z.string(),
        createdAt: z.string(),
    })
    .merge(baseInvoiceSchema)

export const invoiceInfoSchema = z.object({
    ID: z.string(),
    number: z.number(),
    status: z.string(),
    paidDate: z.string(),
    amount: z.number(),
    description: z.string(),
    created: z.string(),
})

export const invoiceListRecordsSchema = z.object({
    customerBillingInfo: billingInfoSchema,
    ownerBillingInfo: billingInfoSchema,
    invoiceInfo: invoiceInfoSchema,
})

export const invoiceListSchema = z.object({
    content: z.array(invoiceListRecordsSchema),
    meta: PaginationSchema,
})

export const CustomerAutoPaySchema = z.object({
    profileId: z.string(),
    isAutoPay: z.boolean(),
    invoiceOwed: baseInvoiceSchema,
})

export const createGroupAndMessagingSchema = z.object({
    smsBody: z.string().optional(),
    emailBody: z.string().optional(),
    title: z.string(),
    status: z.string(),
    subject: z.string().optional(),
    sendSchedule: z.string(),
    type: z.string(),
    recipients: z.array(z.string()),
    profileId: z.string().optional(),
    messageId: z.string().optional(),
    dateSent: z.string().optional(),
})

export const messageRecipientList = z.object({
    name: z.string(),
    profileId: z.string(),
    email: z.string(),
    phoneNumber: phoneNumberValidator,
    dateDelivered: z.string(),
    status: z.string(),
})

export const messagesListSchema = z.object({
    messageId: z.string(),
    groupId: z.string(),
    title: z.string(),
    recipientCount: z.number(),
    smsBody: z.string().optional(),
    emailBody: z.string().optional(),
    type: z.string(),
    sentBy: z.string(),
    dateSent: z.string(),
    timeSent: z.string(),
    dateCreated: z.string(),
    subject: z.string().optional(),
    status: z.string(),
    recipients: z.array(messageRecipientList),
    completeDateSent: z.string(),
})

export const messagesInboxListSchema = z.object({
    content: z.array(messagesListSchema),
    meta: PaginationSchema,
})

export const reimbursementSchema = z.object({
    invoiceId: z.string(),
    amount: z.number(),
})
export const customerCountSchema = z.object({
    active: z.number(),
    pending: z.number(),
    inactive: z.number(),
})

export const customerStatusCountSchema = z.object({
    content: customerCountSchema,
})

export const failedImportRecordsSchema = z.object({
    name: z.string(),
    email: z.string(),
    reason: z.string(),
})

export const failedImportSchema = z.object({
    records: z.array(failedImportRecordsSchema),
})

export type CreateGroupAndMessagingType = z.infer<typeof createGroupAndMessagingSchema>
export type InvoiceType = z.infer<typeof invoiceSchema>
export type InvoiceListType = z.infer<typeof invoiceListSchema>
export type InvoiceListRecordType = z.infer<typeof invoiceListRecordsSchema>
export type SettingsType = z.infer<typeof settingsSchema>
export type InvoiceOwedType = z.infer<typeof baseInvoiceSchema>
export type CustomerAutoPayType = z.infer<typeof CustomerAutoPaySchema>
export type MessageListType = z.infer<typeof messagesListSchema>
export type MessagesInboxListType = z.infer<typeof messagesInboxListSchema>
export type ReimbursementType = z.infer<typeof reimbursementSchema>
export type CustomerStatusCount = z.infer<typeof customerStatusCountSchema>
export type FailedImportsType = z.infer<typeof failedImportSchema>
