import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import mailChompLogo from '@/assets/private/mail-chimp-logo.svg'
import { Button } from '@/components/Button'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { FEATURE_RESTRICTIONS } from '@/constants'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import MailChimpIntegrationModal from '../MailChimpIntegrationModal'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const NonMailChimpUser = () => {
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const [mcIntegrationModal, setMcIntegrationMOdal] = useState<boolean>(false)

    const user = useViewedAsUser()

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['mcFeatureRestriction', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId,
                FEATURE_RESTRICTIONS.emailMarketing,
            ),
    })

    return (
        <div className='bg-white h-full flex flex-col items-center justify-center gap-10'>
            {!featureLoading && !isFeatureAvailable && (
                <div className='flex flex-row items-start justify-start w-full pl-5 -mt-24'>
                    <UpgradeBadge />
                </div>
            )}
            <img src={mailChompLogo} />
            <h1 className='font-bold text-zentive-green-dark text-2xl'>
                No Linked MailChimp Account
            </h1>
            <p>
                To link your MailChimp account, please click 'Set up MailChimp Account' button
                below.
            </p>
            <Button
                onClick={() => {
                    setRestrictionModal(!isFeatureAvailable)
                    setMcIntegrationMOdal(isFeatureAvailable as boolean)
                }}
                disabled={featureLoading}
            >
                Set Up MailChimp Account
            </Button>
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            <MailChimpIntegrationModal
                open={mcIntegrationModal}
                setOpen={setMcIntegrationMOdal}
                mode='create'
            />
        </div>
    )
}

export default NonMailChimpUser
