import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import OwnerDashboard from './OwnerDashboard'
import AdminDashboard from './AdminDashboard'
import { ROLE } from '@/constants'

const Dashboard = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (user?.role?.roleName === ROLE.ADMIN) {
        return <AdminDashboard />
    }

    if (user?.role?.roleName === ROLE.CO_OWNER || user?.role?.roleName === ROLE.OWNER) {
        return <OwnerDashboard />
    }

    return null
}

export default Dashboard
