import { CreateDiscountType } from '@/api/discounts/schema'
import { getSubscriptionNames } from '@/api/subscription'
import { Checkbox } from '@/components/Checkbox'
import Spinner from '@/components/Spinner'
import { useQuery } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'

export const Elligibility = () => {
    const { setValue, watch } = useFormContext<CreateDiscountType>()
    const { data: subs, isLoading } = useQuery<string[]>({
        queryKey: ['subs'],
        queryFn: () => getSubscriptionNames(),
    })

    // Watch current subTypes value in the form
    const selectedSubTypes = watch('subTypes') || []

    const [checkedSubs, setCheckedSubs] = useState<string[]>([])

    useEffect(() => {
        setCheckedSubs(selectedSubTypes)
    }, [selectedSubTypes])

    const handleCheck = (sub: string, checked: boolean) => {
        let updatedSubs = checked ? [...checkedSubs, sub] : checkedSubs.filter((s) => s !== sub)
        setCheckedSubs(updatedSubs)
        setValue('subTypes', updatedSubs)
    }

    const handleCheckAll = (checked: boolean) => {
        const updatedSubs = checked ? subs || [] : []
        setCheckedSubs(updatedSubs)
        setValue('subTypes', updatedSubs)
    }

    return (
        <>
            {isLoading ? (
                <Spinner variant={'normal'} />
            ) : (
                <ul>
                    <li className='flex flex-row gap-2 items-center'>
                        <Checkbox
                            checked={checkedSubs.length === subs?.length}
                            onCheckedChange={(e) => handleCheckAll(e as boolean)}
                        />
                        All
                    </li>
                    {subs?.map((s) => (
                        <li key={s} className='flex flex-row gap-2 items-center'>
                            <Checkbox
                                checked={checkedSubs.includes(s)}
                                onCheckedChange={(e) => handleCheck(s, e as boolean)}
                            />
                            {s}
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}
