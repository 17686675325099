import emptySearch from '@/assets/private/empty-search.png'

const LeadNotFound = () => (
    <div className='flex flex-col items-center'>
        <img alt='empty lead' className='h-[201px] w-[266px] object-scale-down' src={emptySearch} />
        <p className='text-zentive-green-dark text-2xl font-semibold'>No Leads Found</p>
    </div>
)

export default LeadNotFound
