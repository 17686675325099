import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Textarea } from '@/components/TextArea'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

interface JobNoteProps {
    jobNumber: string
    quoteNumber: number | string
    featureAvailable: boolean
    isLoading: boolean
}

export const JobNote = ({
    jobNumber,
    quoteNumber,
    featureAvailable = false,
    isLoading,
}: JobNoteProps) => {
    const { control, setValue } = useFormContext()
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)

    const handleFocus = () => {
        setRestrictionModal(!featureAvailable)
    }

    useEffect(() => {
        setValue('internalNotes', '')
    }, [restrictionModal])

    return (
        <section className='w-1/2'>
            <div className='flex flex-row gap-11'>
                <h1 className='font-bold text-xl'>Job Number</h1>
                <input
                    className='font-sans font-extrabold pl-3 w-[200px] text-base h-11 border border-gray-400 disabled:bg-zentive-white rounded-sm'
                    type='number'
                    disabled={true}
                    placeholder='Job Number'
                    value={jobNumber}
                />
            </div>
            <div className='flex flex-row gap-4 mt-5'>
                <h1 className='font-bold mr-1.5 text-xl'>Quote Number</h1>
                <input
                    className='font-sans font-extrabold pl-3 w-[200px] text-base h-11 border border-gray-400 disabled:bg-zentive-white rounded-sm'
                    type='number'
                    disabled={true}
                    placeholder='Quote Number'
                    value={quoteNumber}
                />
            </div>
            <div className='w-full mt-5 flex flex-col gap-5'>
                {!isLoading && !featureAvailable && (
                    <span>
                        <UpgradeBadge />
                    </span>
                )}
                <FormField
                    control={control}
                    name='note'
                    // disabled={isAccountInactive()}
                    render={({ field }) => (
                        <FormItem>
                            <FormControl>
                                <Textarea
                                    className='border p-4 h-24 w-full max-w-full border-gray-400 text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0 bg-white placeholder-transparent'
                                    placeholder='Please add a note'
                                    onFocus={() => handleFocus()}
                                    {...field}
                                    disabled={isLoading}
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
            </div>
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </section>
    )
}
