import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { BellIcon } from '@radix-ui/react-icons'
import { NotificationsHeader } from './NotificationsHeader'
import { NotificationItems } from './NotificationItems'
import { getProfileNotification, markAllNotificationAsRead, markNotificationRead } from '@/api/auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { NotificationResponseType } from '@/api/auth/schema'
import { AxiosError } from 'axios'
import { Button } from '@/components/Button'
import { FullProfileType } from '@/api/profile/schema'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { Label } from '@radix-ui/react-label'

const Notofications = () => {
    const [pageSize, setPageSize] = useState<number>(10)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const queryClient = useQueryClient()
    const { data: notificationData, isFetching: loading } = useQuery({
        queryKey: ['Notifications', pageSize, user],
        queryFn: () => getProfileNotification(user?.profileId as string, pageSize),
    })

    const { mutate: markAsReadMu } = useMutation<NotificationResponseType, AxiosError, string>({
        mutationKey: ['MarkAsRead'],
        mutationFn: (id) => markNotificationRead(id, { read: true }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
        },
        onError: (err) => console.log(err),
    })

    const { mutate: markAllAsReadMu } = useMutation<NotificationResponseType, AxiosError, string>({
        mutationKey: ['MarkAsRead'],
        mutationFn: (id) => markAllNotificationAsRead(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
        },
        onError: (err) => console.log(err),
    })

    const handleMarkAsReadClick = (id: string) => {
        if (!id) return
        markAsReadMu(id)
    }

    return (
        <div className='relative inline-block text-left'>
            <Popover as='div' className='my-3'>
                <Popover.Button className='max-w-xs flex items-center justify-end text-sm focus:outline-none'>
                    {notificationData?.notifications && (
                        <span className='absolute top-1 -right-2 rounded-full text-white bg-zentive-green-dark items-center justify-center text-[0.9rem] px-[0.4rem]'>
                            {notificationData?.unreadCount}
                        </span>
                    )}
                    <BellIcon className='h-5 w-5' />
                </Popover.Button>

                <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                >
                    <Popover.Panel className='z-10 origin-top-right absolute right-0 top-10 w-[25rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[25rem] overflow-x-auto'>
                        <NotificationsHeader onClick={() => markAllAsReadMu(user?.profileId)} />

                        {!notificationData ||
                        !notificationData.notifications ||
                        notificationData.notifications?.length === 0 ? (
                            <div className='flex justify-center text-center mb-8'>
                                <div>
                                    <img
                                        src={emptyPlaceholder}
                                        alt='email-logo'
                                        className='w-full mb-6 h-40'
                                    />
                                    <Label className='text-gray-900'>Currently Empty</Label>
                                </div>
                            </div>
                        ) : (
                            <>
                                {notificationData.notifications?.map((notification) => (
                                    <NotificationItems
                                        key={notification.id}
                                        notification={notification}
                                        handleClick={handleMarkAsReadClick}
                                    />
                                ))}
                                <div className='flex w-full items-center justify-center'>
                                    <Button
                                        variant={'link'}
                                        onClick={() => setPageSize(pageSize + 10)}
                                    >
                                        Show More Notifications
                                    </Button>
                                </div>
                            </>
                        )}
                        {loading && 'Loading..'}
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    )
}

export default Notofications
