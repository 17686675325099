import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { signIn, signOut } from '@/api/auth'
import { SignInType, signInSchema } from '@/api/auth/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { AuthTokenResponse, AuthError } from '@supabase/gotrue-js'
import { useUpdateProfile } from './useUpdateProfile'
import { useAppNavigate } from './useAppNavigate'
import { useAtomValue, useSetAtom } from 'jotai'
import { sessionAtom, timezoneAtom } from '@/store/auth'
import { ISO_8601_WITH_UTC_OFFSET_IOS, ROLE } from '@/constants'
import { isCrew, isOwnerOrCustomer } from '@/utils/user'
import { useLocation } from 'react-router-dom'
import { useToast } from './useToast'
import { useTranslation } from 'react-i18next'
import useViewedAsUser from './useViewedAsUser'
import { formatDateTime } from '@/pages/private/CrewPortal/Jobs/JobGallery/JobDateFilter/DateSelector'
import dayjs from 'dayjs'
import { TimeZoneResult } from './useGeocode'
import { selectedDateFilterAtom } from '@/store/crew'

export const useSignInForm = () => {
    const user = useViewedAsUser()
    const { t } = useTranslation(['sign-in'])
    const { pathname } = useLocation()
    const { updateUserState } = useUpdateProfile()
    const { toast } = useToast()
    const { navigateTo } = useAppNavigate()
    const setSelectedDateFilter = useSetAtom(selectedDateFilterAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const setSession = useSetAtom(sessionAtom)

    const signInForm = useForm<SignInType>({
        mode: 'onSubmit',
        resolver: zodResolver(signInSchema),
    })

    const handleSuccess = async (res: AuthTokenResponse) => {
        if (res.data.session && res.data.user) {
            try {
                const role = res.data.session?.user.user_metadata.role
                if (
                    (isOwnerOrCustomer(role) && pathname !== '/sign-in') ||
                    (isCrew(role) && pathname !== '/crew/sign-in')
                ) {
                    const { setValue, setError } = signInForm
                    toast({
                        description: `Please use the ${role.toLowerCase()} interface.`,
                        variant: !isCrew(role) ? 'crew_interface_destructive' : 'default',
                    })
                    setValue('email', '', { shouldValidate: true })
                    setValue('password', '')
                    setError('password', { message: t('Invalid password') })
                    signOut()
                    return
                }

                setSession(res.data.session)

                await updateUserState()

                if (res.data.session?.user.user_metadata.role === ROLE.CREW) {
                    setSelectedDateFilter({
                        startDate: formatDateTime(
                            dayjs().startOf('day').toString(),
                            timezone?.timeZoneId as string,
                            ISO_8601_WITH_UTC_OFFSET_IOS,
                        ),
                        endDate: formatDateTime(
                            dayjs().endOf('day').toString(),
                            timezone?.timeZoneId as string,
                            ISO_8601_WITH_UTC_OFFSET_IOS,
                        ),
                    })
                    return navigateTo(
                        user?.isPasswordGenerated ? '/crew/create-password' : '/crew/jobs',
                    )
                }
            } catch (err) {
                console.error(err)
            }
        }

        if (res.error) {
            signInForm.setError('password', {
                type: 'manual',
                message: res.error.message,
            })
        }
    }

    const handleError = (err: AuthError) => console.error(err.message)

    const signInMutator = useMutation<AuthTokenResponse, AuthError, SignInType>({
        mutationFn: signIn,
        onSuccess: handleSuccess,
        onError: handleError,
    })

    return { signInForm, signInMutator }
}
