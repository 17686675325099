import { createExpense } from '@/api/quoting'
import BackButtonTopBar from '../../../Common/BackButtonTopBar'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { CreateExpenseType, expenseSchema } from '@/api/quoting/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { getJobById } from '@/api/job'
import { Button } from '@/components/Button'
import { useTranslation } from 'react-i18next'
import { useToast } from '@/hooks/useToast'
import { handleNumberInputChange } from '@/utils/eventHandler'
import { ABBREVIATED_MONTH_DAY_YEAR } from '@/constants'
import { Switch } from '@/components/Switch'

const AddExpense = () => {
    const { t } = useTranslation(['view-job'])
    const { toast } = useToast()
    const { jobId } = useParams()
    const navigate = useNavigate()
    const [isExpenseIncludedInInvoice, setIsExpenseIncludedInInvoice] = useState<boolean>(true)

    const createExpenseMethods = useForm<CreateExpenseType>({
        mode: 'onChange',
        resolver: zodResolver(expenseSchema),
    })

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const {
        handleSubmit,
        control,
        setValue,
        formState: { isSubmitting, isValid },
    } = createExpenseMethods

    const { mutate: createExpenseMu } = useMutation({
        mutationFn: (data: CreateExpenseType) => createExpense(data, job?.quoteId ?? ''),
        onError: (err: AxiosError) => console.log('Create Expense Error: ', err.message),
        onSuccess: () => {
            navigate(`/crew/job/expenses/${jobId}`)
            toast({
                description: t('Expense has been added successfully'),
                variant: 'crew_interface',
                duration: 2000,
            })
        },
    })

    const onTotalChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const total = handleNumberInputChange(evt)

        setValue('total', total, {
            shouldValidate: true,
        })
    }

    const onSubmit = (data: CreateExpenseType) => {
        createExpenseMu(data)
    }

    useEffect(() => {
        setValue('date', dayjs().format(ABBREVIATED_MONTH_DAY_YEAR))
        setValue('quoteId', job?.quoteId ?? '')
        setValue('isNotIncludedInInvoice', isExpenseIncludedInInvoice)
        setValue('isSavedFromCrew', true)
    }, [job, isExpenseIncludedInInvoice])

    return (
        <Form {...createExpenseMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
                <div className='flex flex-col space-y-3'>
                    <BackButtonTopBar title={t('Add')} />
                    <div className='w-10/12 flex flex-col mx-auto bg-white rounded-sm shadow-md border px-3 py-4 space-y-4'>
                        <FormField
                            control={control}
                            name='accountName'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            type='text'
                                            placeholder={t('Additional Expense Name')}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name='description'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            className='border p-4 h-24 w-full max-w-full  text-zentive-black text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                            placeholder={t('Description (Optional)')}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name='total'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            className='w-full flex justify-center items-center font-sans text-base h-11 border disabled:bg-gray-100 text-gray-900'
                                            placeholder={t('Cost ($)')}
                                            type='number'
                                            {...field}
                                            onChange={onTotalChange}
                                            onKeyDown={(e) => {
                                                if (
                                                    e.key === '-' ||
                                                    e.key === '+' ||
                                                    e.key === 'e' ||
                                                    e.key === 'E'
                                                ) {
                                                    e.preventDefault()
                                                }
                                            }}
                                            name='total'
                                            isCurrency
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className='flex flex-row justify-between'>
                            <p>{t('Include in customer invoice?')}</p>
                            <Switch
                                className='mt-[.20rem]'
                                onCheckedChange={(checked) =>
                                    setIsExpenseIncludedInInvoice(!checked)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center gap-x-4'>
                    <Button
                        variant='ghost'
                        className='absolute top-1 right-5 z-50 text-zentive-green-dark'
                        disabled={isSubmitting || !isValid}
                    >
                        {t('Save')}
                    </Button>
                </div>
            </form>
        </Form>
    )
}

export default AddExpense
