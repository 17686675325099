import { z } from 'zod'
import { baseSchema } from '../common/schema'
import { REQUIRED } from '@/constants'

export const expenseAccountSchema = baseSchema.extend({
    expenseAccountId: z.string().optional(),
    businessId: z.string().optional(),
    expenseAccountCode: z.number().optional(),
    expenseAccountName: z.string().min(1, { message: `Account name ${REQUIRED}` }).min(1),
})

export const expenseAccountListSchema = z.object({
    content: z.array(expenseAccountSchema),
})

export type ExpenseAccountResponseType = z.infer<typeof expenseAccountSchema>
export type ExpenseAccountListResponseType = z.infer<typeof expenseAccountListSchema>
export type CreateExpenseAccountType = z.infer<typeof expenseAccountSchema>
