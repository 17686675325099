import { TableHeader, TableRow, TableHead, TableBody, TableCell, Table } from '@/components/Table'
import { cn } from '@/utils/helper'
import { QuoteType } from '@/api/quoting/schema'
import { useNavigate } from 'react-router-dom'
import { COMPLETE_FORMAT_WITHOUT_TIME, QUOTE_STATUS, statusColor } from '@/constants'
import { formatTo24HourClock } from '@/utils/time'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'

interface QuoteTypeListProps {
    quoteList: QuoteType[] | undefined
}

const tableHeader = ['Quote Number', 'Date', 'Amount', 'Status']

const QuoteTable = ({ quoteList }: QuoteTypeListProps) => {
    const navigate = useNavigate()

    const onRowClick = (quoteId: string, status: string) => {
        if (status === 'pending') {
            navigate(`/customer-portal/quotes/respond?quoteId=${quoteId}`)
        } else {
            navigate(`/customer-portal/quotes/view?quoteId=${quoteId}`)
        }
    }

    return (
        <Table>
            <TableHeader>
                <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                    {tableHeader?.map((header) => (
                        <TableHead
                            key={`${header}-head`}
                            className={cn(
                                'flex-row items-center font-semibold text-zentive-gray-medium text-base',
                            )}
                        >
                            {header}
                        </TableHead>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {quoteList?.length ? (
                    quoteList?.map((body) => (
                        <TableRow
                            key={body.quoteId}
                            className='whitespace-nowrap'
                            onClick={() => onRowClick(body.quoteId!, body.status)}
                        >
                            <TableCell className='flex-row items-start text-zentive-black text-base '>
                                {body.quoteNumber}
                            </TableCell>
                            <TableCell className='flex-row items-center text-zentive-black text-base '>
                                {formatTo24HourClock(
                                    body.createdAt as string,
                                    COMPLETE_FORMAT_WITHOUT_TIME,
                                )}
                            </TableCell>
                            <TableCell className='flex-row items-center text-zentive-black text-base '>
                                $
                                {body.totalAmount?.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}
                            </TableCell>
                            <TableCell
                                className={cn(
                                    'flex-row items-center text-base capitalize',
                                    statusColor[body.status] || statusColor.default,
                                )}
                            >
                                {body?.status === QUOTE_STATUS.DECLINED ? 'Rejected' : body?.status}
                            </TableCell>
                        </TableRow>
                    ))
                ) : (
                    <TableRow>
                        <TableCell colSpan={tableHeader?.length} className='text-center'>
                            <div className='flex flex-col items-center'>
                                <img
                                    src={emptyPlaceholder}
                                    alt='empty-table-placeholder'
                                    className='my-12 h-60'
                                />
                                <p className='pb-4 text-2xl text-gray-900'>Currently Empty</p>
                            </div>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    )
}

export default QuoteTable
