import { Button } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import SearchBar from '@/components/InputSearchBox'
import { Modal } from '@/components/Modal'
import { Pagination } from '@/components/Pagination'
import { useQuery } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import React, { useEffect, useMemo, useState } from 'react'
import { getLeads } from '@/api/lead'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { Lead } from '@/api/lead/schema'
import { selectedLeadsAtom } from '@/store/lead'
import { customerLeadsContactPaginationAtom } from '@/store/customer'

interface IBulkSelectModalProps {
    isOpen: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const BulkSelectModal: React.FunctionComponent<IBulkSelectModalProps> = (props) => {
    const user = useViewedAsUser()
    const { businessId = '' } = user

    const [searchKeyword, setSearchKeyword] = useState('')
    const [selected, setSelected] = useAtom(selectedLeadsAtom)
    const [pagination, setPagination] = useAtom(customerLeadsContactPaginationAtom)

    const { data: contactData } = useQuery({
        enabled: !!businessId,
        queryKey: ['leadList', businessId, searchKeyword, pagination],
        queryFn: () => getLeads(businessId, searchKeyword, [], pagination),
    })

    const isAllContactsSelected = useMemo(() => {
        if (!contactData || !contactData.content) {
            return false
        }
        const selectedOnPage = contactData.content.filter(
            (item) => selected?.some((selectedItem) => selectedItem.leadId === item.leadId),
        )
        return selectedOnPage.length === contactData.content.length
    }, [selected])

    const handleCheckClick = (content: Lead, isCheck: boolean) => {
        isCheck
            ? setSelected((prev: Lead[]) => [...prev, content])
            : setSelected((prev: Lead[]) =>
                  prev.filter((sub: Lead) => sub.leadId !== content.leadId),
              )
    }
    const handleCheckAllClick = (isCheck: boolean) => {
        if (contactData) {
            isCheck ? setSelected(contactData?.content ?? []) : setSelected([])
        }
    }
    const cancelClearSelected = () => {
        if (selected.length > 0) {
            setSelected((prev: Lead[]) =>
                prev.filter(
                    (sub: Lead) =>
                        contactData?.content.some(
                            (selectedItem) => selectedItem.leadId === sub.leadId,
                        ),
                ),
            )
        } else {
            setSelected([])
        }
        // submit selected
        // setSelected(selectedContact)
        props.onClose(false)
    }

    const submitSelected = () => {
        // setSelectedContact(selected)
        props.onClose(false)
    }

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
            pageSize: 5,
        }))
    }, [setPagination, searchKeyword])

    return (
        <Modal
            isOpen={props?.isOpen}
            isHideCloseButton
            onClose={() => {
                props.onClose(false)
            }}
            title='Bulk Select'
            titleClassName='text-indigo-900'
            containerClassName='max-w-[900px]'
        >
            <div className='px-5 pb-5'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                />
                <div className='pt-1'>
                    <table className='table-auto text-left w-full border'>
                        <thead>
                            <tr className='border-b text-indigo-900'>
                                <th className='px-6 py-3'>
                                    {' '}
                                    <Checkbox
                                        checked={isAllContactsSelected}
                                        className='mr-1 mt-0.5 accent-zentive-green-dark'
                                        onCheckedChange={(val) =>
                                            handleCheckAllClick(val as boolean)
                                        }
                                    />
                                    &nbsp; Name
                                </th>
                                <th className='px-6 py-3'>Email</th>
                                <th className='px-6 py-3'>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactData?.content?.length ? (
                                contactData.content.map((items) => (
                                    <tr key={items.leadId} className='border-b text-indigo-900'>
                                        <td className='px-6 py-3'>
                                            <Checkbox
                                                id={'customer-id' + ' ' + items.leadId}
                                                checked={selected?.some(
                                                    (prev) =>
                                                        prev.leadId === items.leadId ||
                                                        prev.email === items.email,
                                                )}
                                                className='mr-[10px] mt-[2px]'
                                                onCheckedChange={(val) => {
                                                    handleCheckClick(items, val as boolean)
                                                }}
                                            />
                                            &nbsp;
                                            {items.firstName} {items.lastName}
                                        </td>
                                        <td className='px-6 py-3'>{items.email}</td>
                                        <td className='px-6 py-3'>{items.contactNumber}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={3} className='text-center py-3 '>
                                        No results found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {user && contactData && contactData.content?.length > 0 && (
                        <div className='mb-5 mt-3 p-0'>
                            <Pagination
                                itemsPerPage={pagination.pageSize ?? 5}
                                pagination={pagination}
                                setPagination={setPagination}
                                totalRecords={contactData?.meta?.totalRecords ?? 1}
                            />
                        </div>
                    )}
                </div>
                <div className='mt-3 flex justify-between'>
                    <Button
                        className='w-97 h-11 text-base border-2 border-zentive-green-dark font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark hover:text-white'
                        onClick={() => cancelClearSelected()}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={selected.length === 0}
                        onClick={() => submitSelected()}
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                    >
                        Proceed
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default BulkSelectModal
