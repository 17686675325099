import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getPricingById } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import ChangePlanButton from './ChangePlanButton'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import Spinner from '@/components/Spinner'

const ActionButton = ({
    selected,
    interval,
}: {
    selected: SubscriptionType[]
    interval: string
}) => {
    const user = useViewedAsUser()
    const { profileId = '' } = user

    const {
        data: businessProfile,
        isSuccess: isBusinessProfileSuccess,
        isError: isBusinessProfileError,
        error: businessProfileError,
    } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        enabled: !!profileId,
        queryKey: ['businessProfile', profileId],
        queryFn: () => getBusinessProfile(profileId),
    })

    const {
        data: currentPlan,
        isError: isPricingByIdError,
        error: pricingByIdError,
    } = useQuery<unknown, AxiosError, SubscriptionType>({
        enabled: !!businessProfile?.business?.subscriptionTypeId && isBusinessProfileSuccess,
        queryKey: ['pricingById', businessProfile?.business?.subscriptionTypeId],
        queryFn: () => getPricingById(businessProfile?.business.subscriptionTypeId as string),
    })

    const hasError = isBusinessProfileError || isPricingByIdError

    if (hasError) {
        const errorMessage =
            (businessProfileError || pricingByIdError)?.message || 'An error occurred'
        return <p className='text-[16px] text-zentive-red-dark font-semibold'>{errorMessage}</p>
    }

    if (!currentPlan) return <Spinner size='50' variant='normal' />

    const renderChangePlanButton = (plan: SubscriptionType, changePlanType: string) => (
        <ChangePlanButton
            plan={plan}
            changePlanType={changePlanType}
            isChangePlan={businessProfile?.business?.isChangePlan as string}
        />
    )

    const getChangePlanType = (plan: SubscriptionType) => {
        const currentPricing = currentPlan?.price ?? 0
        const planPricing = plan?.price ?? 0

        if (plan.interval === interval && currentPlan?.interval === interval) {
            if (currentPlan.name === plan.name) {
                return 'Current Plan'
            } else {
                return planPricing > currentPricing ? 'Upgrade' : 'Downgrade'
            }
        } else {
            if (currentPlan.name === plan.name) {
                return interval === 'month' ? 'Switch to Monthly' : 'Switch to Yearly'
            } else {
                if (planPricing > currentPricing) {
                    return 'Upgrade'
                } else {
                    return 'Downgrade'
                }
            }
        }
    }

    const renderPlanButtons = () => {
        return selected
            ?.sort((a, b) => {
                const order = ['Starter', 'Standard', 'Growth Pro']
                return order.indexOf(a.name as string) - order.indexOf(b.name as string)
            })
            ?.map((plan) => (
                <div key={plan?.subscriptionTypeId}>
                    {renderChangePlanButton(plan, getChangePlanType(plan))}
                </div>
            ))
    }

    return (
        <div className='flex justify-end w-full'>
            <div className='inline-flex xl:gap-x-[22px] 2xl:gap-x-[85px] w2xl:gap-x-[110px] 3xl:gap-x-[195px] 4xl:gap-x-[408px]'>
                {renderPlanButtons()}
            </div>
        </div>
    )
}

export default ActionButton
