import { ExpenseRecordType } from '@/api/reports/schema'
import { TableCell, TableRow } from '@/components/Table'
import { displayCurrency } from '@/utils/helper'
import { FC } from 'react'

const ExpenseTotal: FC<{
    report: ExpenseRecordType[] | undefined
}> = ({ report }) => {
    if (!Array.isArray(report) || report?.length === 0) {
        return null
    }

    return (
        <TableRow className='text-zentive-black font-semibold text-base'>
            <TableCell colSpan={4}>Total</TableCell>
            <TableCell colSpan={1} className='text-right'>
                {displayCurrency(report.reduce((a, b) => a + b.amount, 0) ?? 0)}
            </TableCell>
        </TableRow>
    )
}
export default ExpenseTotal
