import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import dayjs from 'dayjs'
import { PDFEmployeeExport } from '..'
import { EmployeeListType } from '@/api/employee/schema'
import { E164Number } from 'libphonenumber-js/core'
import { formatPhoneNumber } from 'react-phone-number-input'

interface IExportDataToPDF {
    employeeNumber: string
    name: string
    compensation: string
    caLicense: string
    email: string
    address: string
    phoneNumber: string
    dateOfBirth: string
    dateHired: string
}
interface IExportEmployeePDFProps {
    employeeListData?: EmployeeListType
}

const ExportEmployeePDF = ({ employeeListData }: IExportEmployeePDFProps) => {
    if (employeeListData === null || !employeeListData?.content?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: IExportDataToPDF[] = employeeListData.content?.map((employee) => ({
                    employeeNumber: '000' + employee?.employeeNumber,
                    name: (employee?.firstName ?? '') + ' ' + (employee?.lastName ?? ''),
                    compensation: '$' + employee?.compensation,
                    caLicense: employee?.caLicense ?? 'N/A',
                    email: employee?.email,
                    address: `${employee?.address.streetAddress + ','} ${
                        employee?.address.streetTwoAddress !== undefined
                            ? employee?.address.streetTwoAddress + ', '
                            : ''
                    }${employee?.address.city + ','} ${employee?.address.state + ','} ${employee
                        ?.address.zipCode}`,
                    phoneNumber: `(${employee.phoneNumber.substring(0, 3)})-${formatPhoneNumber(
                        employee.phoneNumber as E164Number,
                    ).replace('0', '')}`,
                    dateOfBirth: employee.dateOfBirth ? dayjs(employee?.dateOfBirth).format('MMMM DD, YYYY hh:mm A') : '',
                    dateHired: dayjs(employee?.createdAt).format('MMMM DD, YYYY hh:mm A'),
                })) as IExportDataToPDF[]

                PDFEmployeeExport(zentiveLogoUrl, tableData)
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportEmployeePDF
