import { cn } from '@/utils/helper'
import { Menu } from '@headlessui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import ImportEmployeeCSV from './ImportEmployeeCSV'
import EmployeeCSVTemplate from './EmployeeCSVTemplate'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { FEATURE_RESTRICTIONS } from '@/constants'
import { useMutation } from '@tanstack/react-query'
import { FullProfileType } from '@/api/profile/schema'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { AxiosError } from 'axios'
import FeatureRestrictionContactModal from '@/components/FeatureRestructionContactModal'

interface AddEmployeeDropdownProps {
    seatLimit?: number
    totalUsers?: number
}

const AddEmployeeDropdown = (props: AddEmployeeDropdownProps) => {
    const { seatLimit, totalUsers } = props

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const menuRef = React.useRef<HTMLDivElement>(null)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const [restrictionContactModal, setRestrictionContactModal] = useState<boolean>(false)
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const onOpenMenu = () => {
        setIsOpen(!isOpen)
    }

    const { mutate: checkSubsRestrictionMu } = useMutation<
        boolean,
        AxiosError,
        { businessId: string; name: string }
    >({
        mutationFn: ({ businessId, name }) => checkSubscriptionFeatureRestriction(businessId, name),
        onSuccess: (res) => {
            if (totalUsers == seatLimit && seatLimit == 15) {
                setRestrictionContactModal(!res)
                return
            } else {
                setRestrictionModal(!res)
            }
            if (res) {
                setIsOpen(!isOpen)
                navigate('/schedule/employee/add')
            }
        },
    })

    const handleAddUser = () => {
        checkSubsRestrictionMu({
            businessId: user?.businessId as string,
            name: FEATURE_RESTRICTIONS.userCount,
        })
    }

    return (
        <div ref={menuRef} className='relative inline-block text-left'>
            <Menu>
                <Menu.Button
                    className={cn(
                        'bg-zentive-green-dark text-white',
                        'flex justify-center text-center items-center px-4 w-auto h-[43px] gap-x-1.5 rounded-[4px] text=[#3B671A] font-semibold border border-zentive-green-dark text-base',
                    )}
                    onClick={onOpenMenu}
                >
                    Add Employee
                    <FiChevronDown className='-mr-1 h-5 w-5 text-gray-400' aria-hidden='true' />
                </Menu.Button>

                {isOpen ? (
                    <div className='mt-2 w-[150x] h-auto absolute right-0 z-10 origin-top-right rounded-md bg-white shadow-lg focus:outline-none'>
                        <div className='bg-white rounded-md'>
                            <button
                                className={
                                    'w-full h-8 hover:bg-[#00000029] rounded-t-md text-left px-3'
                                }
                                onClick={() => {
                                    handleAddUser()
                                }}
                            >
                                <span className='text-base'> Create </span>
                            </button>
                            <ImportEmployeeCSV 
                                setRestrictionModal={setRestrictionModal} 
                                setRestrictionContactModal={setRestrictionContactModal}
                                seatLimit={seatLimit} 
                                totalUsers={totalUsers} 
                            />
                            <EmployeeCSVTemplate />
                        </div>
                    </div>
                ) : null}
            </Menu>
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            <FeatureRestrictionContactModal open={restrictionContactModal} setOpen={setRestrictionContactModal} />
        </div>
    )
}

export default AddEmployeeDropdown
