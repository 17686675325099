import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { FailedImportType } from '@/api/profile/schema'

interface ImportCustomerCSVModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    failedImports: FailedImportType[]
}

const ImportCustomerCSVModal: React.FC<ImportCustomerCSVModalProps> = ({
    open,
    setOpen,
    failedImports,
}) => {
    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
        >
            <div className='flex flex-col items-center justify-center gap-5 px-10'>
                <div className='flex flex-col items-center'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <h1 className='text-left font-semibold text-2xl text-gray-900 mt-2.5 capitalize'>
                        Import Failed
                    </h1>

                    <div className='flex flex-col gap-0 mt-5  overflow-y-auto max-h-[20rem]'>
                        {failedImports?.map((f) => (
                            <div className='flex flex-col gap-1 border border-1 p-3'>
                                <p>Name: {f.name}</p>
                                <p>Email: {f.email}</p>
                                <p>Reason: {f.reason}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='mt-6 flex justify-center gap-x-4 bg-gray-300 py-6 px-6'>
                <Button
                    onClick={() => setOpen(false)}
                    className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium cursor-pointer'
                >
                    Got it, Thanks
                </Button>
            </div>
        </Modal>
    )
}

export default ImportCustomerCSVModal
