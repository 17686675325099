import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import SearchBar from '@/components/InputSearchBox'
import { Button } from '@/components/Button'
import { useState } from 'react'
import emptyPlaceholder from '@/assets/private/expense_account_empty.png'
import debounce from 'debounce'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { FullProfileType } from '@/api/profile/schema'
import { ROLE } from '@/constants'
import { getExpenseAccountsByBusinessId } from '@/api/expense_account'
import { ExpenseAccountResponseType } from '@/api/expense_account/schema'
import ActionButton from './ExpenseAccountsTable/ActionButtons'
import UpdateExpenseAccount from './ExpenseAccountsTable/UpdateExpenseAccount'
import CreateExpenseAccount from './ExpenseAccountsTable/CreateExpenseAccount'
import { useNavigate } from 'react-router-dom'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { expensesSortByAtom, expensesSortOrderAtom } from '@/store/financial'

const ExpenseAccounts = () => {
    const navigate = useNavigate()

    const [selectedExpenseAccountId, setSelectedExpenseAccountId] = useState<string>('')
    const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false)
    const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false)
    const [keyword, setKeyword] = useState<string>('')

    const [expensesSortBy, setExpensesSortBy] = useAtom(expensesSortByAtom)
    const [expensesSortOrder, setExpensesSortOrder] = useAtom(expensesSortOrderAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    const { data: expenseAccounts, isLoading } = useQuery({
        queryKey: ['expenseAccounts', keyword, user?.businessId, expensesSortBy, expensesSortOrder],
        queryFn: () =>
            getExpenseAccountsByBusinessId(
                keyword,
                user?.businessId as string,
                expensesSortBy,
                expensesSortOrder,
            ),
    })

    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 300)

    const handleSearch = (query: string) => {
        setKeyword(query)
    }

    const handleAddItems = () => {
        setIsCreateOpen(true)
    }

    const expenseNames: Array<String> = Array.isArray(expenseAccounts?.content)
        ? expenseAccounts?.content?.map((exp) => exp.expenseAccountName)
        : []

    const onHandleSorting = (column: string) => {
        if (expensesSortBy === column) {
            setExpensesSortOrder(expensesSortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setExpensesSortBy(column)
            setExpensesSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (expensesSortBy === column) {
            return expensesSortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <section>
            {!isCustomer && (
                <>
                    <p className='text-3xl font-semibold text-zentive-gray-medium'>
                        Expense Account
                    </p>
                    <p className='text-md mt-2 mb-5 text-zentive-black'>
                        Record expenses for each job and categorize them by creating accounting
                        codes.
                    </p>
                    <div className='flex flex-row w-full space-x-16 pt-6 rounded-t-md items-start text-left justify-between bg-white'>
                        <div className='flex flex-row w-full items-start text-left justify-between px-10'>
                            <SearchBar
                                placeHolder='Searching for...'
                                onSearchChange={(e) => debouncedSearch(e.target.value)}
                            />
                            {expenseAccounts &&
                                expenseAccounts?.content?.length >= 1 &&
                                !isLoading && (
                                    <div className='items-end mb-4'>
                                        {' '}
                                        <Button
                                            type='button'
                                            onClick={handleAddItems}
                                            className='border bg-zentive-green-dark text-white px-8 py-5'
                                        >
                                            + Add Item
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                    {expenseAccounts && expenseAccounts?.content?.length >= 1 && !isLoading ? (
                        <div className='rounded-t-xl'>
                            <div
                                className={`bg-white pb-4  ${
                                    expenseAccounts?.content?.length > 0
                                        ? 'h-[550px] max-h-[550px] w-full overflow-y-scroll green-scrollbar'
                                        : 'h-auto'
                                }`}
                            >
                                <Table>
                                    <TableHeader className='border-t-2'>
                                        <TableRow className='whitespace-nowrap '>
                                            <TableHead className='font-semibold text-zentive-gray-medium text-base px-10'>
                                                <p className='py-4'>
                                                    Account Code
                                                    <button
                                                        onClick={() =>
                                                            onHandleSorting('Account Code')
                                                        }
                                                        className='ml-2'
                                                    >
                                                        {getSortIcon('Account Code')}
                                                    </button>
                                                </p>
                                            </TableHead>
                                            <TableHead className='font-semibold text-zentive-gray-medium text-base px-10'>
                                                <p className='py-4'>
                                                    Account Name
                                                    <button
                                                        onClick={() =>
                                                            onHandleSorting('Account Name')
                                                        }
                                                        className='ml-2'
                                                    >
                                                        {getSortIcon('Account Name')}
                                                    </button>
                                                </p>
                                            </TableHead>
                                            <TableHead className='font-semibold text-zentive-gray-medium text-base text-center'>
                                                <p className='py-4'>Actions</p>
                                            </TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {isLoading && <AppSkeletonLoadingState />}
                                        {!isLoading &&
                                            expenseAccounts?.content?.map(
                                                (
                                                    expenseAccount: ExpenseAccountResponseType,
                                                    index: number,
                                                ) =>
                                                    !isUpdateOpen ||
                                                    selectedExpenseAccountId !==
                                                        expenseAccount.expenseAccountId ? (
                                                        <TableRow
                                                            key={index}
                                                            className='whitespace-nowrap'
                                                        >
                                                            <TableCell className='p-0 flex-row items-start text-start text-zentive-gray-medium text-base px-10'>
                                                                {'000' +
                                                                    expenseAccount.expenseAccountCode}
                                                            </TableCell>
                                                            <TableCell className='p-0 px-10 flex-row items-start text-start text-zentive-gray-medium max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                                {expenseAccount.expenseAccountName}
                                                            </TableCell>
                                                            <TableCell className='p-0 flex-row items-start text-start text-zentive-black  text-base overflow-hidden text-ellipsis'>
                                                                <ActionButton
                                                                    expenseAccount={expenseAccount}
                                                                    setSelectedExpenseAccountId={
                                                                        setSelectedExpenseAccountId
                                                                    }
                                                                    setIsOpen={setIsUpdateOpen}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        <TableRow
                                                            key={index}
                                                            className='whitespace-nowrap'
                                                        >
                                                            <UpdateExpenseAccount
                                                                expenseNames={expenseNames}
                                                                expenseAccount={expenseAccount}
                                                                setIsOpen={setIsUpdateOpen}
                                                                setSelectedExpenseAccountId={
                                                                    setSelectedExpenseAccountId
                                                                }
                                                            />
                                                        </TableRow>
                                                    ),
                                            )}
                                    </TableBody>
                                </Table>
                            </div>
                            {isCreateOpen && (
                                <div className='flex flex-row w-full px-28 py-6 border-t-2 rounded-b-xl text-left justify-between bg-white'>
                                    <CreateExpenseAccount
                                        businessId={user?.businessId as string}
                                        setIsCreateOpen={setIsCreateOpen}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className='bg-white w-auto rounded-sm py-8'>
                            <div className='mt-4 w-[1260px]'>
                                {' '}
                                {/* width basis for empty state */}{' '}
                            </div>
                            <div className='flex justify-center items-center'>
                                <img
                                    src={emptyPlaceholder}
                                    alt='empty-table-placeholder'
                                    className='my-12 h-60'
                                />
                            </div>
                            <p className='pb-4 text-center text-zentive-green-dark text-2xl font-semibold'>
                                {' '}
                                No Expense Account to Show{' '}
                            </p>
                            <div className='flex justify-center items-center'>
                                <Button
                                    type='button'
                                    onClick={() => navigate('/settings/expense-accounts/add')}
                                    className='border  bg-zentive-green-dark text-white px-8 py-5'
                                >
                                    + Add Item
                                </Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </section>
    )
}

export default ExpenseAccounts
