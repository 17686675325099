import { QuoteType } from '@/api/quoting/schema'

const HeaderClientDetails = ({ quote }: { quote: QuoteType }) => {
    return (
        <div className='mx-5 mt-6'>
            <div className='text-zentive-green-darker font-bold text-2xl mt-4'>{quote?.name}</div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6  mb-4'>
                        <span className='font-bold text-base'>Property Address</span>
                    </div>
                    <p className='text-zentive-gray-medium'>{quote?.address?.streetAddress}</p>
                    <p className='text-zentive-gray-medium'>
                        {quote?.address?.city}, {quote?.address?.state} {quote?.address?.zipCode}
                    </p>
                </div>
                <div className='flex flex-col mb-4'>
                    <span className='font-bold pt-6 mb-4 text-base'>Contact Details</span>
                    <p className='text-zentive-gray-medium'>{quote?.profile?.phoneNumber}</p>
                    <p className='text-zentive-gray-medium'>{quote?.profile?.email}</p>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
