import { Checkbox } from '@/components/Checkbox'
import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { manageSubsTabAtom, toExportSubsAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useAtom, useAtomValue } from 'jotai'
import { FC } from 'react'
import { SubscriberProps } from '../..'
import { subscribersSortByAtom, subscribersSortOrderAtom } from '@/store/subscription'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'

const T_HEADER_CLASS = 'font-semibold text-zentive-gray-medium text-base'

const SubsribersTableHead: FC<SubscriberProps> = ({ subscriberList }) => {
    const subStatus = useAtomValue(manageSubsTabAtom)

    const [toexportBusinessData, setToExportBusinessData] = useAtom(toExportSubsAtom)
    const [sortBy, setSortBy] = useAtom(subscribersSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(subscribersSortOrderAtom)

    const handleSelectAllSubs = (isChecked: boolean) => {
        setToExportBusinessData(isChecked ? subscriberList : [])
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <TableHeader className='bg-white'>
            <TableRow className='whitespace-nowrap'>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex items-center text-start gap-[10px]'>
                        <Checkbox
                            key={subStatus}
                            id='business-name'
                            className='mr-[5px] mt-[2px]'
                            onCheckedChange={(val) => handleSelectAllSubs(val as boolean)}
                            checked={
                                toexportBusinessData?.length
                                    ? toexportBusinessData?.length > 0 &&
                                      toexportBusinessData?.length == subscriberList?.length
                                    : false
                            }
                        />
                        Business Name
                        <button onClick={() => onHandleSorting('Business Name')} className='ml-2'>
                            {getSortIcon('Business Name')}
                        </button>
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Subscriber
                        <button onClick={() => onHandleSorting('Subscriber')} className='ml-2'>
                            {getSortIcon('Subscriber')}
                        </button>
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center  text-end gap-[10px]'>
                        Subscription
                        <button onClick={() => onHandleSorting('Subscription')} className='ml-2'>
                            {getSortIcon('Subscription')}
                        </button>
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center  text-end gap-[10px]'>
                        Billing Cycle
                        <button onClick={() => onHandleSorting('Billing Cycle')} className='ml-2'>
                            {getSortIcon('Billing Cycle')}
                        </button>
                    </div>
                </TableHead>
                {subStatus === 'TER' && (
                    <TableHead
                        key='contacted'
                        className={cn(T_HEADER_CLASS, 'w-12 px-2 text-left')}
                    >
                        Contacted
                    </TableHead>
                )}
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Email Address
                        <button onClick={() => onHandleSorting('Email Address')} className='ml-2'>
                            {getSortIcon('Email Address')}
                        </button>
                    </div>
                </TableHead>

                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Address
                        <button onClick={() => onHandleSorting('Address')} className='ml-2'>
                            {getSortIcon('Address')}
                        </button>
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Contact Number
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>
                    <div className='flex justify-bet items-center text-start gap-[10px]'>
                        Registered On
                        <button onClick={() => onHandleSorting('Registered On')} className='ml-2'>
                            {getSortIcon('Registered On')}
                        </button>
                    </div>
                </TableHead>
                <TableHead className={cn(T_HEADER_CLASS, '')}>Note</TableHead>
            </TableRow>
        </TableHeader>
    )
}

export default SubsribersTableHead
