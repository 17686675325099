import { QuoteType } from '@/api/quoting/schema'
import { CardTitle } from '@/components/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'
import { useLocation } from 'react-router-dom'

const tableHeader = [
    { textAlign: 'text-start', title: 'Service Type', width: 'w-[40%]' },
    { textAlign: 'text-center', title: 'Quantity', width: 'w-[15%]' },
    { textAlign: 'text-center', title: 'Unit Price', width: 'w-[15%]' },
    { textAlign: 'text-center', title: 'Markup %', width: 'w-[15%]' },
    { textAlign: 'text-end', title: 'Cost', width: 'w-[15%]' },
]

const ProductsAndServicesTable = ({ quote }: { quote: QuoteType | undefined }) => {
    const { pathname } = useLocation()
    return (
        <>
            {/* <CardTitle className='text-lg font-semibold mb-8'>Services and Products</CardTitle> */}
            <CardTitle className='text-lg font-bold mb-4'>Services and Products</CardTitle>
            <div className='p-[20px] bg-clip-content shadow-[inset_0_0_0_20px_#F5F5F5]'>
                <Table className='rounded-lg'>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap !border-0'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={header.title}
                                    className={cn(
                                        header.textAlign,
                                        header.width,
                                        'flex-row font-semibold text-zentive-gray-medium text-base items-start',
                                    )}
                                    hidden={
                                        pathname.startsWith(
                                            '/financial-management/invoicing/',
                                        ) &&
                                        (header.title === 'Unit Price' ||
                                            header.title === 'Markup %')
                                    }
                                >
                                    <div className='inline-flex p-0 m-0'>{header.title}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {quote?.productServiceGroup?.map((data) => (
                            <TableRow
                                key={data.productServiceId}
                                className='whitespace-break-spaces'
                            >
                                <TableCell className='flex-row items-start text-center text-[#191A0A] text-[16px]'>
                                    <div className='text-left flex flex-col'>
                                        <span className='font-semibold'>{data?.name}</span>
                                        <span className='text-sm text-zentive-gray-medium'>
                                            {data?.description}
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell className='flex-row items-start text-center text-[#191A0A] text-[16px]'>
                                    {data?.quantity && data?.quantity > 0 ? data?.quantity : 1}
                                </TableCell>
                                {!pathname.startsWith(
                                    '/financial-management/invoicing/',
                                ) && (
                                    <TableCell className='flex-row items-start text-center text-[#191A0A] text-[16px]'>
                                        ${' '}
                                        {data?.unitPrice?.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </TableCell>
                                )}
                                {!pathname.startsWith(
                                    '/financial-management/invoicing/',
                                ) && (
                                    <TableCell className='flex-row items-start text-center text-[#191A0A] text-[16px]'>
                                        {data.cost
                                            ? '$' +
                                              (
                                                  ((data.quantity ? data.quantity : 1) *
                                                      data.unitPrice *
                                                      (data.markup ? data.markup : 0)) /
                                                  100
                                              ).toFixed(2)
                                            : '$' + '0.00'}
                                    </TableCell>
                                )}
                                <TableCell className='flex-row items-start text-end text-[#191A0A] text-[16px]'>
                                    ${' '}
                                    {data?.cost?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}

export default ProductsAndServicesTable
