import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { IoIosInformationCircle } from 'react-icons/io'
import Tooltip from '@/components/Tooltip'
import { FeatureType } from '@/api/subscription/schema'

interface OffersTableProps {
    tableData: FeatureType[]
    tableHead: string
}

const OffersTable: React.FC<OffersTableProps> = ({ tableData, tableHead }) => {
    const sortedFeatures = tableData.sort((a, b) => a.order - b.order)

    return (
        <Table className='w-full'>
            <TableHeader>
                <TableRow className='bg-[#0C3D3C] hover:bg-[#0C3D3C]'>
                    <TableHead className='w-[50%] text-white text-[16px] py-auto pl-[26px]'>
                        {tableHead}
                    </TableHead>
                    <TableHead className='w-[160px] text-center text-white '>
                        <span className='sr-only'>Starters</span>
                    </TableHead>
                    <TableHead className='w-[160px] text-center text-white '>
                        <span className='sr-only'>Standard</span>
                    </TableHead>
                    <TableHead className='w-[160px] text-center text-white '>
                        <span className='sr-only'>Growth Pro</span>
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody className=''>
                {sortedFeatures?.map((data) => (
                    <TableRow
                        key={data.feature}
                        className='divide-x divide-gray-200 hover:bg-none odd:bg-white even:bg-zentive-green-light'
                    >
                        <TableCell>
                            <div className='relative flex items-center pl-[10px]'>
                                <div className='mr-2 text-[16px]'>{data.label}</div>
                                {data.tooltip && (
                                    <Tooltip
                                        position='bottom'
                                        alignment='start'
                                        content={data.tooltip}
                                        fontstyle='font-sans'
                                        width='64'
                                    >
                                        <IoIosInformationCircle className='h-5 w-5' />
                                    </Tooltip>
                                )}
                            </div>
                        </TableCell>
                        <TableCell className='w-[160px] text-[16px]'>
                            {data.starterValue ? (
                                <span className='flex justify-center text-zentive-green-dark'>
                                    {data?.starterValue}
                                </span>
                            ) : (
                                <span className='flex justify-center'>
                                    {data.starter ? (
                                        <span className='text-zentive-green-dark'>
                                            <FaCheck />
                                        </span>
                                    ) : (
                                        <span className='text-zentive-red-dark'>
                                            <FaTimes />
                                        </span>
                                    )}
                                </span>
                            )}
                        </TableCell>
                        <TableCell className='w-[160px] text-[16px]'>
                            {data.standardValue ? (
                                <span className='flex justify-center text-zentive-green-dark'>
                                    {data?.standardValue}
                                </span>
                            ) : (
                                <span className='flex justify-center'>
                                    {data.standard ? (
                                        <span className='text-zentive-green-dark'>
                                            <FaCheck />
                                        </span>
                                    ) : (
                                        <span className='text-zentive-red-dark'>
                                            <FaTimes />
                                        </span>
                                    )}
                                </span>
                            )}
                        </TableCell>
                        <TableCell className='w-[160px] text-[16px]'>
                            {data.proValue ? (
                                <span className='flex justify-center text-zentive-green-dark'>
                                    {data?.proValue}
                                </span>
                            ) : (
                                <span className='flex justify-center'>
                                    {data.pro ? (
                                        <span className='text-zentive-green-dark'>
                                            <FaCheck />
                                        </span>
                                    ) : (
                                        <span className='text-zentive-red-dark'>
                                            <FaTimes />
                                        </span>
                                    )}
                                </span>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default OffersTable
