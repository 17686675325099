import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'

const tableHeader = ['Items', 'Description', 'Date', 'Amount']

const ExpenseTableHead = () => {
    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                {tableHeader?.map((header) => (
                    <TableHead
                        className={cn(
                            'flex-row items-center text-center font-semibold text-base w-1/3',
                            'items-start text-start',
                        )}
                    >
                        {header}
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default ExpenseTableHead
