import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import { getPricingById } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import { Alert, AlertDescription } from '@/components/Alert'
import { USER_STATUS, SUBSCRIPTION_TYPES } from '@/constants'
import { manageCurrentSubTypeAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import UpdateBilingInfo from './UpdateBillingInfo'
import UpdateCardDetails from './UpdateCardDetails'
import UpdatePlan from './UpdatePlan'
import { isFreeTrialEndedAtom } from '@/store/owner'
import { useEffect } from 'react'
import useNextAutoBillDate from '@/hooks/useNextAutoBillDate'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const OwnerBillingInfo = () => {
    const user = useViewedAsUser()
    const setSubID = useSetAtom(manageCurrentSubTypeAtom)
    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    const { data: businessProfileData, isLoading } = useQuery<
        unknown,
        AxiosError,
        ProfileSelfResponseType
    >({
        queryKey: ['businessProfile', user],
        queryFn: () => getBusinessProfile(user?.profileId as string),
        enabled: !!user,
    })

    const { data: nextAutoBillDate, isSuccess } = useNextAutoBillDate(
        businessProfileData?.businessId as string,
    )

    const { data: plan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['Plan', businessProfileData, user],
        enabled: !!businessProfileData?.business?.subscriptionTypeId,
        queryFn: () =>
            getPricingById(
                businessProfileData?.business?.subscriptionTypeId ??
                    user?.business?.subscriptionTypeId,
            ),
    })

    useEffect(() => {
        if (businessProfileData?.business?.subscriptionTypeId) {
            setSubID(businessProfileData.business.subscriptionTypeId)
        }
    }, [businessProfileData])

    return (
        <div>
            {isLoading && <> Loading . . .</>}
            {!isLoading && (
                <div className='bg-white'>
                    {plan?.name !== SUBSCRIPTION_TYPES.FREE_TRIAL && (
                        <div className='pt-[26px] mb-[32px] mx-[40px]'>
                            <Alert className={'bg-zentive-green-dark'}>
                                <AlertDescription
                                    className={cn('text-base text-zentive-green-dark')}
                                >
                                    {businessProfileData?.business?.status ===
                                        USER_STATUS.ACTIVATED &&
                                        nextAutoBillDate &&
                                        isSuccess && (
                                            <div className='flex flex-row gap-3 text-white'>
                                                <Label className='text-base font-semibold'>
                                                    Next auto-bill date:
                                                </Label>
                                                <p>{nextAutoBillDate}</p>
                                            </div>
                                        )}

                                    {businessProfileData?.business?.status ===
                                        USER_STATUS.BILLING_PAUSED &&
                                        isSuccess && (
                                            <Label className='text-base font-semibold text-white'>
                                                Expires at {nextAutoBillDate}
                                            </Label>
                                        )}
                                </AlertDescription>
                            </Alert>
                        </div>
                    )}
                    <div className='pt-6 mb-8 pb-2 mx-[40px]'>
                        {!isFreeTrialEnded && (
                            <UpdatePlan
                                businessProfileData={businessProfileData as ProfileSelfResponseType}
                                plan={plan as SubscriptionType}
                            />
                        )}
                        <UpdateCardDetails
                            businessProfileData={businessProfileData as ProfileSelfResponseType}
                        />
                        <UpdateBilingInfo
                            businessProfileData={businessProfileData as ProfileSelfResponseType}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OwnerBillingInfo
