import SubscriptionSettings from './SubscriptionSettings'
import SubscriptionPlans from './SubscriptionPlans'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE, ROLE } from '@/constants'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const OwnerChangePlanGalery = () => {
    const user = useViewedAsUser()
    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    return (
        <section className={`${BREADCRUMBS_PADDING_STYLE}`}>
            {!isCustomer && (
                <>
                    <BreadCrumbs titleName='Change Plan' />
                    <SubscriptionSettings />
                    <SubscriptionPlans />
                </>
            )}
        </section>
    )
}

export default OwnerChangePlanGalery
