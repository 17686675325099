import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import ChangeCardDetails from './ChangeCardFields'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const BillingInformation: React.FC = () => {
    const stripeKey = import.meta.env.PROD
        ? import.meta.env.VITE_PROD_STRIPE_PUBLISHABLE_KEY
        : import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY
    const stripePromise = loadStripe(stripeKey)

    return (
        <>
            <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
                <BreadCrumbs titleName='Update Payment Information' />
                <Elements stripe={stripePromise}>
                    <ChangeCardDetails />
                </Elements>
            </div>
        </>
    )
}

export default BillingInformation
