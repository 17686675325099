import { TableHead, TableHeader, TableBody, TableCell, TableRow, Table } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { BulkDeleteRouteType } from '@/api/routing/schema'
import { useNavigate } from 'react-router-dom'
import { useAtom, useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { bulkDeleteRoutesAtom, routeSortByAtom, routeSortOrderAtom } from '@/store/route'
import { FullProfileType } from '@/api/profile/schema'
import { useEffect, useState } from 'react'
import emptyPlaceholder from '@/assets/private/route-list-empty.png'
import SearchBar from '@/components/InputSearchBox'
import { Button } from '@/components/Button'
import { bulkDeleteRoutes, getAllRoutes } from '@/api/routing'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useToast } from '@/hooks/useToast'
import { Pagination } from '@/components/Pagination'
import { paginationAtom } from '@/store/manageSubs'
import { FaArrowDownLong, FaArrowUpLong, FaRegTrashCan } from 'react-icons/fa6'
import BulkDeleteRouteModal from '../BulkDeleteRouteModal'
import { cn } from '@/utils/helper'
import { FC } from 'react'
import FieldSubscriptionRestricted from '@/components/FieldSubscriptionRestricted'
import debounce from 'debounce'

type RoutingTableProps = {
    isFieldRestricted: boolean
}

const RoutingTable: FC<RoutingTableProps> = ({ isFieldRestricted }) => {
    const navigate = useNavigate()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const [deleteRoutesModal, setDeleteRoutesModal] = useState<boolean>(false)
    const [searchKeyword, setSearchKeyword] = useState('')
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [pagination, setPagination] = useAtom(paginationAtom)
    const [bulkDeleteRoute, setBulkDeleteAtom] = useAtom(bulkDeleteRoutesAtom)
    const [sortBy, setSortBy] = useAtom(routeSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(routeSortOrderAtom)

    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)

    const { data: route, isLoading } = useQuery({
        queryKey: ['RouteType', pagination, searchKeyword, user?.businessId, sortBy, sortOrder],
        queryFn: () =>
            getAllRoutes(user?.businessId as string, searchKeyword, pagination, {
                sortBy: sortBy,
                sortOrder: sortOrder,
            }),
    })

    const routeIds = route?.content?.map((data) => data.routeId).filter(Boolean) as string[]

    const { mutate: BulkDeleteRoutesMu } = useMutation<unknown, AxiosError, BulkDeleteRouteType>({
        mutationFn: bulkDeleteRoutes,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['RouteType'] })

            toast({
                description: 'Routes deleted successfully',
                variant: 'default',
            })
            setBulkDeleteAtom(null)
        },
        onError: () => {
            toast({
                description: 'Failed to delete routes',
                variant: 'destructive',
            })
        },
    })

    const handleDelete = () => {
        setDeleteRoutesModal(true)
    }

    const handleNavigation = (routeId: string | undefined) => {
        navigate(`/schedule/routing/view-route/${routeId}`)
    }

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString)
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }
        return date.toLocaleDateString('en-US', options)
    }

    const handleCheckBoxClick = (routeId: string, isChecked: boolean) => {
        if (isChecked) {
            const routeIdsToDelete = bulkDeleteRoute?.routeIds
                ? [...(bulkDeleteRoute?.routeIds as string[]), routeId]
                : [routeId]
            setBulkDeleteAtom({
                routeIds: routeIdsToDelete as string[],
                businessId: user?.businessId as string,
            })
        } else {
            const routeIdsToDelete = bulkDeleteRoute?.routeIds?.filter((id) => id !== routeId)

            setBulkDeleteAtom({
                businessId: user?.businessId as string,
                routeIds: routeIdsToDelete as string[],
            })
        }
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const handleSearch = (query: string) => {
        setSearchKeyword(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }
    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 500)

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    useEffect(() => {
        if (isCheckAll) {
            setBulkDeleteAtom({
                businessId: user?.businessId as string,
                routeIds: routeIds as string[],
            })
        } else {
            setBulkDeleteAtom(null)
        }
    }, [isCheckAll])

    return (
        <>
            <BulkDeleteRouteModal
                open={deleteRoutesModal}
                setOpen={setDeleteRoutesModal}
                handleClick={BulkDeleteRoutesMu}
                routesToDelete={bulkDeleteRoute}
            />
            <div className='flex items-start text-left justify-between mt-10 bg-white py-5 px-5 rounded-t-xl'>
                <div className='mt-1'>
                    <SearchBar
                        placeHolder='Search for...'
                        onSearchChange={(event) => {
                            debouncedSearch(event.target.value)
                            
                        }}
                    />
                </div>
                <div className='flex '>
                    <FieldSubscriptionRestricted isFieldRestricted={isFieldRestricted}>
                        <Button
                            type='button'
                            onClick={handleDelete}
                            disabled={!bulkDeleteRoute || bulkDeleteRoute?.routeIds?.length == 0}
                            className='border bg-white border-zentive-gray-medium text-zentive-gray-medium hover:text-zentive-green-light px-6'
                        >
                            <FaRegTrashCan className='mr-2' />
                            Delete
                        </Button>
                    </FieldSubscriptionRestricted>
                </div>
            </div>
            {route && route?.content?.length >= 1 ? (
                <>
                    <Table className='table-auto whitespace-normal mx-auto'>
                        <TableHeader className='bg-white'>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    <FieldSubscriptionRestricted
                                        isFieldRestricted={isFieldRestricted}
                                    >
                                        <Checkbox
                                            className='mr-[16px] mt-[2px]'
                                            onCheckedChange={() => setIsCheckAll(!isCheckAll)}
                                            checked={
                                                routeIds?.length ===
                                                bulkDeleteRoute?.routeIds?.length
                                            }
                                        />
                                    </FieldSubscriptionRestricted>
                                    Route Number
                                    <button
                                        onClick={() => onHandleSorting('Route Number')}
                                        className='ml-2'
                                    >
                                        {getSortIcon('Route Number')}
                                    </button>
                                </TableHead>
                                <TableHead
                                    className={`font-semibold text-zentive-gray-medium text-base`}
                                >
                                    Customers
                                </TableHead>
                                <TableHead
                                    className={`font-semibold text-zentive-gray-medium text-base text-end`}
                                >
                                    Crew
                                    <button
                                        onClick={() => onHandleSorting('Crew')}
                                        className='ml-2'
                                    >
                                        {getSortIcon('Crew')}
                                    </button>
                                </TableHead>
                                <TableHead
                                    className={`font-semibold text-zentive-gray-medium text-base text-center`}
                                >
                                    Status
                                    <button
                                        onClick={() => onHandleSorting('Status')}
                                        className='ml-2'
                                    >
                                        {getSortIcon('Status')}
                                    </button>
                                </TableHead>
                                <TableHead
                                    className={`font-semibold text-zentive-gray-medium text-base text-center justify-center max-w-[300px]`}
                                >
                                    Scheduled Date
                                    <button
                                        onClick={() => onHandleSorting('Scheduled Date')}
                                        className='ml-2'
                                    >
                                        {getSortIcon('Scheduled Date')}
                                    </button>
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody className='bg-white'>
                            {!isLoading &&
                                route &&
                                route?.content?.map((data) => (
                                    <TableRow className='whitespace-nowrap text-base' key={data.routeId}>
                                        <TableCell className='text-[#191A0A] text-base max-w-[200px]'>
                                            <div className='flex items-start text-start gap-[12px]'>
                                                <FieldSubscriptionRestricted
                                                    isFieldRestricted={isFieldRestricted}
                                                >
                                                    <Checkbox
                                                        className='mr-[5px] mt-[2px]'
                                                        onCheckedChange={(val) => {
                                                            handleCheckBoxClick(
                                                                data?.routeId as string,
                                                                val as boolean,
                                                            )
                                                        }}
                                                        checked={
                                                            (bulkDeleteRoute &&
                                                                bulkDeleteRoute?.routeIds?.some(
                                                                    (prev) =>
                                                                        prev === data?.routeId,
                                                                )) as boolean
                                                        }
                                                    />
                                                </FieldSubscriptionRestricted>
                                                {data?.routeNumber}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className='hover:cursor-pointer text-[#191A0A] flex flex-row text-base max-w-[150px] text-ellipsis overflow-hidden items-start justify-start'
                                            onClick={() => handleNavigation(data.routeId)}
                                        >
                                            {data?.jobGroup &&
                                                data?.jobGroup
                                                    .slice(0, 5)
                                                    ?.map((customer, index) => (
                                                        <div
                                                            key={customer.job?.quote.profileId}
                                                            className={cn(
                                                                'relative h-[26px] w-[26px]',
                                                                index > 0 ? '-ml-6' : '-ml-4',
                                                            )}
                                                        >
                                                            {customer.job?.quote.profile
                                                                ?.profileImage ? (
                                                                <img
                                                                    src={
                                                                        typeof customer.job?.quote
                                                                            .profile
                                                                            ?.profileImage ===
                                                                        'string'
                                                                            ? customer.job?.quote
                                                                                  .profile
                                                                                  ?.profileImage
                                                                            : undefined
                                                                    }
                                                                    alt={`Profile ${index + 1}`}
                                                                    className='h-[26px] w-[26px] rounded-full mr-1 absolute border-white border-2'
                                                                    style={{
                                                                        left: `${index * 17}px`,
                                                                        zIndex:
                                                                            data?.jobGroup
                                                                                ?.length! - index,
                                                                    }}
                                                                    title={`${customer.job?.quote.profile?.firstName} ${customer.job?.quote.profile?.lastName}`}
                                                                />
                                                            ) : (
                                                                <div
                                                                    className='h-[26px] w-[26px] rounded-full border-white border-2 bg-zentive-blue-dark flex justify-center items-center mr-1 absolute'
                                                                    style={{
                                                                        left: `${index * 18}px`,
                                                                        zIndex:
                                                                            data?.jobGroup
                                                                                ?.length! - index,
                                                                    }}
                                                                    title={`${customer.job?.quote.profile?.firstName} ${customer.job?.quote.profile?.lastName}`}
                                                                >
                                                                    <span className='text-zentive-gray-light text-sm'>
                                                                        {`${
                                                                            customer.job?.quote.profile?.firstName
                                                                                ?.charAt(0)
                                                                                ?.toUpperCase() ??
                                                                            ''
                                                                        }${
                                                                            customer.job?.quote.profile?.lastName
                                                                                ?.charAt(0)
                                                                                ?.toUpperCase() ??
                                                                            ''
                                                                        }`}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                            {data?.jobGroup && data?.jobGroup?.length > 5 && (
                                                <div className='flex justify-end w-[5.5rem]'>
                                                    ...
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className='hover:cursor-pointer text-[#191A0A] text-end text-ellipsis overflow-hidden'
                                            onClick={() => handleNavigation(data.routeId)}
                                        >
                                            {data.crew?.description}
                                        </TableCell>
                                        <TableCell
                                            className='hover:cursor-pointer text-center text-[#191A0A] max-w-[150px] text-ellipsis overflow-hidden'
                                            onClick={() => handleNavigation(data.routeId)}
                                        >
                                            {data?.status === 'in progress' ? (
                                                <div className='w-[120px] text-center m-auto bg-zentive-blue-dark px-2 text-white font-normal rounded-full'>
                                                    In Progress
                                                </div>
                                            ) : data?.status === 'done' ? (
                                                <div className='w-[120px] text-center m-auto bg-zentive-blue-dark px-2 text-white font-normal rounded-full'>
                                                    Done
                                                </div>
                                            ) : (
                                                <div className='w-[120px] text-center m-auto bg-[#EBEBEB] px-2 text-black font-normal rounded-full capitalize'>
                                                    Open
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className='hover:cursor-pointer flex justify-center text-[#191A0A] text-base text-center text-ellipsis overflow-hidden'
                                            onClick={() => handleNavigation(data.routeId)}
                                        >
                                            {data.jobDueDate
                                                ? formatDate(data.jobDueDate.toString())
                                                : null}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <div className='mb-5 pt-4 bg-white border-t-2 pr-2 rounded-b-xl'>
                        {pagination.pageSize !== undefined && (
                            <Pagination
                                itemsPerPage={pagination.pageSize}
                                setPagination={setPagination}
                                totalRecords={route?.meta?.totalRecords ?? 1}
                                pagination={pagination}
                            />
                        )}
                    </div>
                </>
            ) : (
                <div className='bg-white w-full rounded-xl -mt-1'>
                    <div className='flex justify-center items-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <div className='flex flex-col pb-10 -mt-6 rounded-xl'>
                        <p className='pb-2 text-center text-2xl text-zentive-green-dark font-semibold'>
                            Routing List is Empty
                        </p>
                        <p className='pb-10 text-center text-md'>
                            You do not have any routing list to display.
                        </p>
                    </div>
                </div>
            )}
        </>
    )
}

export default RoutingTable
