import { Card } from '@/components/Card'
import { BulkDeleteQuoteType, QuoteType } from '@/api/quoting/schema'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import ViewQuotingContent from './CardContent'
import ViewQuotingHeader from './CardHeader'
import { deleteQuote, getJobQuote, getQuote } from '@/api/quoting'
import { Button } from '@/components/Button'
import { BREADCRUMBS_PADDING_STYLE, QUOTE_STATUS } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import { toast } from '@/hooks/useToast'
import { useAtomValue } from 'jotai'
import { quotePaginationAtom, quoteSortByAtom, quoteSortOrderAtom } from '@/store/quote'
import { serviceProductApprovedFilter, serviceProductFilter } from '@/store/customer'
import { viewedAsUserAtom, userAtom, isViewedAsAtom } from '@/store/auth'
import { useState } from 'react'
import DeleteQuoteModal from './DeleteQuoteModal'

const ViewQuoting = () => {
    const planParam = new URLSearchParams(window.location.search)
    const quoteId = planParam.get('quoteId')
    const queryClient = useQueryClient();
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const pagination = useAtomValue(quotePaginationAtom)
    const serviceProduct = useAtomValue(serviceProductFilter)
    const serviceProductApproved = useAtomValue(serviceProductApprovedFilter)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const quoteSortBy = useAtomValue(quoteSortByAtom)
    const quoteSortOrder = useAtomValue(quoteSortOrderAtom)

    const [showModal, setShowModal] = useState(false);


    const navigate = useNavigate()

    const { data: quote, isLoading: isQuoteLoading } = useQuery<unknown, AxiosError, QuoteType>({
        queryKey: ['quote', quoteId],
        queryFn: () => getQuote(quoteId as string),
    })

    const { data: jobQuote, isLoading: isJobQuoteLoading } = useQuery({
        queryKey: ['quoteExist', quoteId],
        queryFn: () => getJobQuote(quoteId as string),
    })

    const {mutate: deleteQuoteMu, isPending } = useMutation<undefined, undefined, BulkDeleteQuoteType>({
        mutationFn: deleteQuote,
        onSuccess: () => {
            toast({
                description: 'Quote has been deleted',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['quoteList',
                pagination,
                '',
                serviceProduct,
                serviceProductApproved,
                user?.businessId,
                quoteSortBy,
                quoteSortOrder,] 
            })
            setShowModal(false)
            navigate('/financial-management/quoting')
        },
        onError: () => {
            toast({
                description: 'Something went wrong. Please try again later.',
                variant: 'destructive',
            })
        }
    })

    if (isJobQuoteLoading || isQuoteLoading) {
        return null
    }

    // TO DO: update error redirection once 1550 and 1546 are merged
    if (!quote) {
        return <Navigate to='/error' />
    }

    const deleteQuoteFn = () => {
        deleteQuoteMu({quoteIds: [quote.quoteId || ""]})
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={quote?.name} pathId={`${quote?.quoteId}`} label={quote?.name} />
            <div className='mt-10'>
                {quote?.status === QUOTE_STATUS.APPROVED && !jobQuote?.isJobExist ? (
                    <div className='w-full flex justify-end '>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/new-job/${quoteId}`)}
                        >
                            Convert to Job
                        </Button>
                    </div>
                ) : quote?.status === QUOTE_STATUS.APPROVED ? (
                    <div className='w-full flex justify-end '>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/${jobQuote?.jobId}`)}
                        >
                            View Job
                        </Button>
                    </div>
                ) : quote?.status === QUOTE_STATUS.PENDING ? (
                    <div className='w-full flex justify-end '>
                         <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base'
                            onClick={() => setShowModal(true)}
                            variant='destructive'
                        >
                            Delete Quote
                        </Button>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/${jobQuote?.jobId}`)}
                            disabled
                        >
                            Pending Approval
                        </Button>
                    </div>
                ) : (
                    <div className='w-full flex justify-end '></div>
                )}

                <Card className='border-0 border-t-8 border-zentive-green-dark '>
                    <ViewQuotingHeader quote={quote as QuoteType} />
                    <ViewQuotingContent quote={quote as QuoteType} />
                </Card>
                <DeleteQuoteModal 
                    open={showModal}
                    setOpen={setShowModal}
                    isPending={isPending}
                    deleteQuoteFn={deleteQuoteFn}
                />
            </div>
        </div>
    )
}

export default ViewQuoting
