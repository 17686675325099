import { sendReimbursement } from '@/api/invoicing'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { useToast } from '@/hooks/useToast'
import { invoiceUserAtom } from '@/store/owner'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'

interface AlertReimbursementModalProps {
    amount: number
}

const AlertReimbursementModal = ({ amount }: AlertReimbursementModalProps) => {
    const { toast } = useToast()
    const navigate = useNavigate()
    const invoice = useAtom(invoiceUserAtom)

    const { mutate: sendReimbursements } = useMutation({
        mutationFn: () => sendReimbursement(invoice[0]?.invoiceId!, amount.toString()),
        onSuccess: () => {
            toast({
                description: 'Reimbursement sent!',
                variant: 'default',
            })

            navigate('/settings/reimbursement')
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const onSubmit = () => {
        sendReimbursements()
    }

    const onCancel = () => {
        // setOpen(false)
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <Button type='submit' className='w-full' disabled={amount == 0.0 ? true : false}>
                    Reimburse
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-zentive-black mt-[10px]'>
                            Reimburse Amount
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            <p>You are about to reimburse ${amount.toFixed(2)} to this customer.</p>
                            <p>Are you sure you want to reimburse the amount?</p>
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='absolute self-end py-3 px-6 w-full rounded-b-md justify-center items-center bg-zentive-gray-light'>
                    <div className='w-full flex justify-end items-end gap-x-4'>
                        <AlertDialogCancel
                            onClick={onCancel}
                            className='w-[30%] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                        >
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[30%] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Reimburse
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default AlertReimbursementModal
