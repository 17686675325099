import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { resetJobProgress } from '@/api/job'
import { ReadJobType } from '@/api/job/schema'
import { JOB_STATUS } from '@/constants'

const ResetJobLogModal = () => {
    const { jobId } = useParams()
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const navigate = useNavigate()
    const [open, setOpen] = useState<boolean>(false)
    const jobDetails = queryClient.getQueryData<ReadJobType>(["jobById", jobId])

    const { mutate: updateJobStatusMu, isPending } = useMutation({
        mutationFn: () => resetJobProgress(jobId ?? ''),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
            toast({
                description: `Job has been successfully reset`,
                variant: 'default',
            })
            localStorage.removeItem('utcStoredTime')
            localStorage.removeItem('storedJobID')
            navigate('/schedule/job-log')
        },
        onError: (err) => console.log(err),
    })

    const isInProgress = jobDetails?.status == JOB_STATUS.IN_PROGRESS

    return (
        <div>
            <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
                <AlertDialogTrigger asChild>
                    <Button
                        variant={'ghost'}
                        className='px-14 py-6 my-4 justify-center bg-red-600 border shadow-md text-white hover:none'
                        disabled={!isInProgress}
                    >
                        Reset Progress
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-2xl p-0'>
                    <div className='flex gap-5 px-6 pt-10'>
                        <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                            <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                        </div>
                        <div className=' overflow-auto'>
                            <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                                Reset Progress
                            </AlertDialogTitle>
                            <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                                Resetting the Progress will reopen the job.
                            </AlertDialogDescription>
                            <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                                Are you sure you want to reset your progress?
                            </AlertDialogDescription>
                        </div>
                    </div>
                    <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-2xl pr-6 pb-4 pt-1.5'>
                        <div className='w-full flex justify-end items-end gap-4'>
                            <AlertDialogCancel
                                onClick={() => {
                                    setOpen(false)
                                }}
                                className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'
                            >
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                                disabled={isPending}
                                onClick={() => {
                                    updateJobStatusMu()
                                }}
                                className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                            >
                                Reset
                            </AlertDialogAction>
                        </div>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    )
}

export default ResetJobLogModal
