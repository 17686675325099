import { useFormContext } from 'react-hook-form'

import { AppRichTextBox } from '@/components/Common/AppRichTextbox'
import { EmailServiceParamType } from '@/api/service/schema'

export const RichTextEditorWrapper = () => {
    const { setValue } = useFormContext<EmailServiceParamType>()

    const onBodyChange = (val: string) => {
        const textOnly = val
            .replace(/<[^>]*>/g, '')
            .replace(/"/g, '')
            .trim()

        if (textOnly.length > 0) {
            const modifiedValue = val
                .replace(/<p><\/p>/g, '<br>')
                .replace(/\\/g, '')
                .replace(/<p\s*style\s*=\s*"text-align:\s*center"\s*><\/p>/g, '<br>')
                .replace(/<p\s*style\s*=\s*"text-align:\s*right"\s*><\/p>/g, '<br>')
            setValue('body', modifiedValue)
        } else {
            setValue('body', '')
        }
    }

    return (
        <div className='space-y-4 mt-[36px]'>
            <AppRichTextBox name='email' onChange={(val) => onBodyChange(val)} />
        </div>
    )
}
