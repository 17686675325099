import { checkSignUpValidity } from '@/api/auth'
import { SignUpValidityRequest, SignupValidityType } from '@/api/auth/schema'
import { getCustomerByProfileIdAndBusinessId, inviteUser, updateProfile } from '@/api/profile'
import {
    CustomerProfileType,
    FullProfileType,
    InviteUserType,
    inviteCustomerProfileSchema,
} from '@/api/profile/schema'
import { Form } from '@/components/Forms'
import { SELECT_VALID_STREET_ADDRESS } from '@/constants'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { validateAddress } from '@/hooks/useGeocode'
import { useToast } from '@/hooks/useToast'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { customerImgAtom, customerProfileAtom } from '@/store/customer'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBlocker, useLocation, useNavigate } from 'react-router-dom'
import BaseCustomer from '../../BaseCustomer'
import DiscardModal from '../../BaseCustomer/CustomerModals/DiscardModal'
import BreadCrumbs from '@/components/BreadCrumbs'

const CreateCustomer: FC = () => {
    const queryClient = useQueryClient()
    const { navigateTo } = useAppNavigate()
    const navigate = useNavigate()
    const { toast } = useToast()

    const setCustomerProfileData = useSetAtom(customerProfileAtom)
    const [selectedImageAtom, setSelectedImageAtom] = useAtom(customerImgAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const currentUser = useAtomValue(
        isViewedAsOwner ? viewedAsUserAtom : userAtom,
    ) as FullProfileType

    const location = useLocation()
    const { email } = location.state || {}

    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)

    const createCustomerMethods = useForm<InviteUserType>({
        mode: 'onChange',
        resolver: zodResolver(inviteCustomerProfileSchema),
    })

    if (!email) {
        navigate('/customers/customer-list')
    }

    const {
        handleSubmit,
        setError,
        setValue,
        watch,
        reset,
        formState: { isValid },
    } = createCustomerMethods

    const formValues = watch()

    const handleSuccessCreate = () => {
        queryClient.invalidateQueries({ queryKey: ['Notifications'] })
        toast({
            description: 'Customer has been invited successfully',
            variant: 'default',
        })
        setSelectedImageAtom([])
        navigateTo('/customers/customer-list')
    }

    const { mutate: createCustomerMu, isPending } = useMutation({
        mutationFn: inviteUser,
        onError: (err: AxiosError) => console.log('Create Customer Error: ', err.message),
        onSuccess: (data) => {
            const newCustomerProfileId = data.content.id

            getCustomerByProfileIdAndBusinessId(newCustomerProfileId)
                .then((data: CustomerProfileType) => {
                    const newCustomerData = {
                        profileId: data.profileId,
                        addressId: data.addressId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        roleId: '',
                        businessId: '',
                        business: {},
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                        crewMemberId: data.crewMemberId,
                        isPasswordGenerated: data?.isPasswordGenerated,
                        status: data.status,
                        address: {
                            streetAddress: data.address.streetAddress,
                            streetTwoAddress: data.address.streetTwoAddress,
                            city: data.address.city,
                            state: data.address.state,
                            zipCode: data.address.zipCode,
                        },
                        profileImage: selectedImageAtom[0]?.file,
                    }

                    updateProfile(newCustomerData)
                        .then(() => handleSuccessCreate())
                        .catch((error) => {
                            console.error('Error uploading image', error)
                        })
                    queryClient.invalidateQueries({ queryKey: ['Notifications'] })
                    queryClient.invalidateQueries({ queryKey: ['customerStatusCount'] })
                })
                .catch((error) => {
                    console.error('Error getting new customer', error)
                    handleSuccessCreate()
                })
        },
    })

    const { mutate: checkValidityMu, isPending: isCheckValidityPending } = useMutation<
        SignupValidityType,
        AxiosError,
        SignUpValidityRequest
    >({
        mutationFn: checkSignUpValidity,
        onSuccess: (data: SignupValidityType) => {
            if (data.isEmailExist) {
                setError('email', { message: 'Email address already exists.' })
            }
            if (data.isPhoneNumberExist) {
                setError('phoneNumber', { message: 'Phone number already exists' })
            }
            if (!data.isEmailExist && !data.isPhoneNumberExist) {
                createCustomerMu(formValues)
            }
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const isFormLoaded = useCallback(() => {
        if (
            formValues.firstName ||
            formValues.lastName ||
            formValues.email ||
            formValues.phoneNumber ||
            formValues.address?.streetAddress ||
            formValues.address?.streetTwoAddress ||
            selectedImageAtom?.length !== 0
        ) {
            return false // At least one field has a value
        } else {
            return true // All fields are empty
        }
    }, [formValues, selectedImageAtom])

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        // Check if navigation is to a different location
        const isDifferentLocation =
            isFormSubmitting === false && currentLocation.pathname !== nextLocation.pathname

        const isBlockNeeded = !isFormLoaded() && !isCheckValidityPending

        return isBlockNeeded && isDifferentLocation
    })

    useEffect(() => {
        // Listen for changes in the blocker state
        if (blocker.state === 'blocked') {
            setIsDiscardModalOpen(true)
        } else {
            setIsDiscardModalOpen(false)
        }
    }, [blocker.state, setIsDiscardModalOpen])

    /* Delete current selected customerProfile to 
       reset the form's disabled statefor INACTIVE customers */
    useEffect(() => {
        setCustomerProfileData(null)
    })

    const onSubmit = async (data: InviteUserType) => {
        try {
            const addressToValidate = `${data?.address?.streetAddress || ''} ${
                data?.address?.state || ''
            }`
            await validateAddress(addressToValidate)
            checkValidityMu({
                email: data.email,
                phoneNumber: data.phoneNumber,
                businessId: currentUser?.businessId,
            })
            // Need to handle the blocker state for different location using this hook
            setIsFormSubmitting(true)
        } catch (err) {
            console.error(err)
            createCustomerMethods.setError('address.streetAddress', {
                type: 'manual',
                message: SELECT_VALID_STREET_ADDRESS,
            })
        }
    }

    const handleDiscard = () => {
        reset()
        setSelectedImageAtom([])
        setIsDiscardModalOpen(false)
        navigate(-1) // to review
    }

    const handleCancel = () => {
        setIsDiscardModalOpen(false)
        blocker.state = 'unblocked' // Reset the blocker state
    }

    useEffect(() => {
        setValue('businessId', currentUser?.businessId as string)
    }, [])

    useEffect(() => {
        setValue('email', email)
    }, [email])

    return (
        <Form {...createCustomerMethods}>
            <BreadCrumbs titleName='Add Customer' containerClassName='mb-5' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseCustomer mode='create' isValid={isValid} isSubmitting={isPending} />
                <DiscardModal
                    isOpen={isDiscardModalOpen}
                    onClose={handleCancel}
                    handleDiscard={handleDiscard}
                />
            </form>
        </Form>
    )
}

export default CreateCustomer
