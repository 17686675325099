import BreadCrumbs from '@/components/BreadCrumbs'
import { RevenueReportFilter } from './RevenueFilter'
import RevenueTable from './RevenueTable'
import { useSetAtom } from 'jotai'
import { revenueReportExportAtom, revenueReportFilterAtom } from '@/store/reports'
import { useEffect } from 'react'

export const Revenue = () => {
    const setExportAtom = useSetAtom(revenueReportExportAtom)
    const setRevenueReportFilter = useSetAtom(revenueReportFilterAtom)
    
    useEffect(() => {
        setExportAtom(null)
        setRevenueReportFilter({
            start: '',
            end: '',
            keyword: '',
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <BreadCrumbs titleName='Revenue' />
            <RevenueReportFilter />
            <RevenueTable />
        </div>
    )
}
