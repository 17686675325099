import { SignUpType, signUpSchema } from '@/api/auth/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Form } from '@/components/Forms'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/Card'
import { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import ActionButton from './ActionButton'
import IdentityFields from './Fields/IdentityFields'
import LocationFields from './Fields/LocationFields'
import PasswordFields from './Fields/PasswordFields'
import CouponFields from './Fields/CouponFields'
import { getPricingById } from '@/api/subscription'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSetAtom } from 'jotai'
import { isPlanSelected } from '@/store/auth'
import { cn, numberFormatter } from '@/utils/helper'
import { SubscriptionType } from '@/api/subscription/schema'

const SignUpForm = () => {
    const setPlanError = useSetAtom(isPlanSelected)
    const [searchParams] = useSearchParams()
    const planID = searchParams.get('planid')

    const methods = useForm<SignUpType>({
        resolver: zodResolver(signUpSchema),
        mode: 'all',
    })
    const { setValue } = methods

    const { data: plan, isError: planError } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['SubscriptionType', planID],
        queryFn: () => getPricingById(planID as string),
        retry: 1,
    })

    useEffect(() => {
        const email = searchParams.get('email')
        if (email) {
            setValue('email', email, { shouldValidate: true })
        }
    }, [])

    useEffect(() => {
        if (plan && plan?.subscriptionTypeId) {
            setValue('data.subscriptionTypeId', plan.subscriptionTypeId, {
                shouldValidate: true,
            })
        }
    }, [plan, setValue])

    useEffect(() => {
        setPlanError(planError)
    }, [planError])

    return (
        <Form {...methods}>
            <Card className='p-4 w-auto my-10'>
                <CardHeader>
                    <CardTitle className='text-zentive-green-dark text-center'>
                        Profile Information
                    </CardTitle>
                    <CardDescription className='text-md text-center'>
                        Please fill out the below personal information to complete your profile.
                    </CardDescription>
                </CardHeader>
                <CardContent className='w-[722px] '>
                    <section>
                        <div className='flex justify-between p-4 bg-gray-100 rounded-md my-4'>
                            <span className='flex'>
                                <p className='text-md text-zentive-green-dark font-semibold'>
                                    {'Plan Selected:'}
                                </p>
                                <p
                                    className={cn(
                                        !plan ? 'text-zentive-red-dark' : ' text-gray-900',
                                        'text-md px-2',
                                    )}
                                >
                                    {plan &&
                                        plan.name === 'Free Trial' &&
                                        `Free Trial, $${'0'}/${plan.interval}`}
                                    {plan &&
                                        plan.name === 'Starter' &&
                                        `Starter, $${
                                            plan.price && numberFormatter.format(plan.price)
                                        }/${plan.interval}`}
                                    {plan &&
                                        plan.name === 'Standard' &&
                                        `Standard, $${
                                            plan.price && numberFormatter.format(plan.price)
                                        }/${plan.interval}`}
                                    {plan &&
                                        plan.name === 'Growth Pro' &&
                                        `Growth Pro, $${
                                            plan.price && numberFormatter.format(plan.price)
                                        }/${plan.interval}`}
                                    {!plan && 'No plan selected'}
                                </p>
                            </span>
                            <div>
                                <Link to={'/sign-up'} className='text-md  text-zentive-green-dark'>
                                    {' '}
                                    Change Plan
                                </Link>
                            </div>
                        </div>
                    </section>
                    <section className='space-y-4'>
                        <IdentityFields />
                        <LocationFields />
                    </section>
                    <section className='mt-6 space-y-[18px]'>
                        <CardTitle className='text-zentive-green-dark text-lg font-semibold'>
                            Coupon
                        </CardTitle>
                        <CouponFields />
                    </section>
                    <section className='mt-6 space-y-[18px]'>
                        <CardTitle className='text-zentive-green-dark text-lg font-semibold'>
                            Create Password
                        </CardTitle>
                        <PasswordFields />
                    </section>
                    <CardFooter className='flex flex-col items-center justify-center p-0 pb-6'>
                        <ActionButton />
                    </CardFooter>
                </CardContent>
            </Card>
        </Form>
    )
}

export default SignUpForm
