import { CardHeader, CardTitle } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import HeaderClientDetails from './ClientDetails'
import { QUOTE_STATUS, US_FORMAT } from '@/constants'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'

const ViewQuotingHeader = ({ quote }: { quote: QuoteType }) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    return (
        <CardHeader className=' border-b-[12px] border-zentive-gray-light'>
            <div className=' mx-5'>
                <div className='flex flex-row w-full justify-between py-6 border-b-2'>
                    <CardTitle className='text-2xl font-bold'>
                        Quote Number: {quote?.quoteNumber}
                    </CardTitle>
                    <div>
                        {quote?.status === QUOTE_STATUS.APPROVED ? (
                            <div className='flex flex-col'>
                                <div className='w-[100px] text-center my-auto  bg-zentive-blue-dark px-2  text-white font-normal rounded-full'>
                                    <p>Approved</p>
                                </div>
                            </div>
                        ) : quote?.status === QUOTE_STATUS.DECLINED ? (
                            <div className='w-[120px] text-center my-auto  bg-[#EBEBEB] px-2  text-black font-normal rounded-full'>
                                Declined
                            </div>
                        ) : (
                            <div className='w-[120px] text-center my-auto  bg-[#EBEBEB] px-2  text-black font-normal rounded-full'>
                                Pending
                            </div>
                        )}
                        <p>
                            Date Sent:{' '}
                            {dayjs(quote?.createdAt)
                                .tz(timezone.timeZoneId)
                                .format(US_FORMAT)}
                        </p>
                    </div>
                </div>
            </div>
            <HeaderClientDetails quote={quote} />
        </CardHeader>
    )
}

export default ViewQuotingHeader
