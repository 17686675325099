import {
    ProfileBusinessType,
    ProfileSelfResponseType,
    SubscriptionListType,
} from '@/api/business/schema'
import { IFile } from '@/components/Common/ImageUploader'
import { PaginationType } from '@/components/Pagination/schema'
import { USER_STATUS } from '@/constants'
;('@/api/manageSubscription/schema')
import { atom } from 'jotai'

export const manageNewSubTypeAtom = atom<string>('')
export const manageSubsAtom = atom<SubscriptionListType | null>(null) // to check later, - Air
export const toExportSubsAtom = atom<ProfileBusinessType[] | null>([]) // possible to migrate this store
export const manageSubsTabAtom = atom<string>(USER_STATUS.ACTIVATED)
export const redirectedSubsTabAtom = atom<string | null>(null)
export const currentSubsInfoAtom = atom<ProfileSelfResponseType | null>(null) //Atom that stores the selected subscriber from the table.
export const profileImgAtom = atom<IFile[]>([])
export const manageInfoTabAtom = atom<string>('personalInformation')
export const manageUserIDAtom = atom<string>('')
export const manageCurrentSubTypeAtom = atom<string>('')
export const isPersonalInformationFormDirtyAtom = atom<boolean>(false)
export const isOpenModalForChangesAtom = atom<boolean>(false)
export const nextTabAtom = atom<string>('personalInformation')

export const paginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const sortingSubscriptionAtom = atom<string>('')
export const sortingToggle = atom<boolean>(false)
export const sortingCustomersAtom = atom<string>('')
export const sortingToggleCustomers = atom<boolean>(false)

export const setPaginationAtom = atom(null, (_, set, newValue: PaginationType) => {
    set(paginationAtom, newValue)
})

export const itemsPerPageAtom = atom(5)
export const fromDashboardAtom = atom(false)
