import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { EmployeeStatusModalProps } from '../../EmployeeSettings'

const ActivateModal = ({
    handleProfileStatusUpdate,
    onOpenChange,
    open,
}: EmployeeStatusModalProps) => {
    const onSubmit = () => handleProfileStatusUpdate()

    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-zentive-black mt-[10px]'>
                            Activate Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-base text-zentive-gray-medium mt-[18px]'>
                            By activating an account, user will have access to services, content or
                            features associated with the subscription plan.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-base text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to activate account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={onSubmit}
                            className='w-[155px] h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Activate
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
export default ActivateModal
