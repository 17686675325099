import { ReadJobType } from '@/api/job/schema'
import { ABBREVIATED_DATE_12_HOUR, APPOINTMENT_TYPES } from '@/constants'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { timezoneAtom } from '@/store/auth'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'

interface ViewJobProps {
    job: ReadJobType
}

const JobDetails = ({ job }: ViewJobProps) => {
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const formattedStartDate = dayjs(job?.appointment?.startDateTime as string)
        .tz(timezone?.timeZoneId)
        .format(ABBREVIATED_DATE_12_HOUR)
    // formatToUTCWithOffset(
    //     job?.appointment?.startDateTime as string,
    //     timezone?.timeZoneId,
    //     ABBREVIATED_DATE_12_HOUR,
    // )

    const formattedEndDate = dayjs(job?.appointment?.endDateTime as string)
        .tz(timezone?.timeZoneId)
        .format(ABBREVIATED_DATE_12_HOUR)

    // const formattedEndDate = formatToUTCWithOffset(
    //     job?.appointment?.endDateTime as string,
    //     timezone?.timeZoneId,
    //     ABBREVIATED_DATE_12_HOUR,
    // )

    return (
        <div className='flex flex-row mt-4 gap-x-2'>
            <div className='flex-1'>
                <p className='text-2xl text-zentive-green-darker font-semibold mt-5 ml-4'>
                    {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                </p>
                <div className='flex px-4 justify-between py-4'>
                    <div className='flex flex-row  w-full gap-x-10'>
                        <div>
                            <p className='text-base font-semibold'>Property Address</p>
                            <p className='text-base'>{`${job?.address?.streetAddress + ','} ${
                                job?.address?.streetTwoAddress &&
                                job?.address?.streetTwoAddress != 'undefined'
                                    ? job?.address?.streetTwoAddress + ', '
                                    : ''
                            }${job?.address?.city + ','} ${job?.address?.state + ','} ${job?.address
                                ?.zipCode}`}</p>
                        </div>
                        <div>
                            <p className='text-base font-semibold'>Contact Details</p>
                            <p className='text-base'>{job?.quote?.profile?.email ?? ''}</p>
                            <p className='text-base'>{job?.quote?.profile?.phoneNumber ?? ''}</p>
                        </div>
                    </div>
                </div>
                <div className='bg-white ml-4 p-5 w-[27rem] 2xl:w-[50rem] h-32 max-h-36 overflow-y-scroll green-scrollbar'>
                    <p className='text-base font-semibold'>Job Notes</p>
                    {job?.note?.map((singleNote, index) => (
                        <p
                            key={index}
                            className='text-base py-1 w-[27rem] 2xl:w-[50rem] p-5 pr-10 break-all'
                        >
                            {singleNote?.note ? `- ${singleNote?.note}` : 'N/A'}
                        </p>
                    ))}
                </div>
            </div>
            <div className='flex-1 bg-white'>
                <div className='justify-between'>
                    <div className=''>
                        <p className='p-2 text-lg font-semibold'>Job Details</p>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>Job Type</p>
                            <p className='text-base'>
                                {job?.appointment?.appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB
                                    ? 'One Time Job'
                                    : 'Recurring Job'}
                            </p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>Available Anytime</p>
                            <p className='text-base'>
                                {job.appointment.availableAnytime ? 'Yes' : 'No'}
                            </p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>
                                {job.appointment.availableAnytime
                                    ? 'Prefer To Start On'
                                    : 'Starts On'}
                            </p>
                            <p className='text-base'>{formattedStartDate}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>Ends on</p>
                            <p className='text-base'>{formattedEndDate}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>Time Zone</p>
                            <p className='text-base'>{timezone ? timezone.timeZoneName : ''}</p>
                        </div>
                        <div className='flex flex-row justify-between border-b py-2 px-4'>
                            <p className='text-base'>Invoice Status</p>
                            <p className='text-base'>Upon Job Completion</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobDetails
