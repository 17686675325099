import { MONTHLY, ANNUALLY } from '@/assets/static/signupGallery'
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/Card'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { useSetAtom } from 'jotai'
import { signUpGalleryAtom } from '@/store/auth'
import { useMemo, useState } from 'react'
import { getPricing } from '@/api/subscription'
import { useMutation } from '@tanstack/react-query'
import { SubscriptionType } from '@/api/subscription/schema'
import { AxiosError } from 'axios'
import GalleryTable from '@/pages/public/SignUp/SignUpGallery/Table'
import ActionButton from '../ActionButton'

const ChangePlanGalery = () => {
    const setSelected = useSetAtom(signUpGalleryAtom)
    const [interval, setInterval] = useState<string>('month')

    const { mutate: getPricingMu } = useMutation<SubscriptionType[], AxiosError, string>({
        mutationKey: ['Pricing'],
        mutationFn: (param) => getPricing(param),
        onSuccess: (data) => setSelected(data),
        onError: (err) => console.log(err),
    })
    useMemo(() => {
        getPricingMu(interval)
    }, [interval])

    return (
        <section>
            <div className='bg-white mx-[100px] mt-[99px] overflow-scroll'>
                <div className='min-w-[1088px] my-10 '>
                    <CardHeader>
                        <CardTitle className='text-zentive-green-dark text-center'>
                            Subscription Plans
                        </CardTitle>
                    </CardHeader>
                    <CardContent className='p-0'>
                        <div className='flex justify-center mt-4 mb-14'>
                            <Tabs
                                defaultValue='month'
                                onValueChange={(val) => setInterval(val)}
                                className='w-[400px] '
                            >
                                <TabsList className='grid w-full grid-cols-2 rounded-full bg-[#0C3D3C]'>
                                    <TabsTrigger
                                        value='month'
                                        className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                    >
                                        Monthly
                                    </TabsTrigger>
                                    <TabsTrigger
                                        value='year'
                                        className=' data-[state=active]:bg-transparent data-[state=active]:rounded-full data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:rounded-full data-[state=inactive]:text-gray-900'
                                    >
                                        Annually
                                    </TabsTrigger>
                                </TabsList>
                            </Tabs>
                        </div>
                        {
                            {
                                month: <GalleryTable tableData={MONTHLY} interval={interval} />,
                                year: <GalleryTable tableData={ANNUALLY} interval={interval} />,
                            }[interval]
                        }
                    </CardContent>
                    <CardFooter>
                        <ActionButton interval={interval} />
                    </CardFooter>
                </div>
            </div>
        </section>
    )
}

export default ChangePlanGalery
