import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { JobCompletionExportType } from '@/api/reports/schema'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { jobCompletionExportAtom } from '@/store/reports'
import { formatWorkingHours } from '@/utils/time'

const ExportJobCompletionReportCSV = () => {
    const exportAtom = useAtomValue(jobCompletionExportAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: JobCompletionExportType = {
        records:
            exportAtom?.records?.map((r) => ({
                jobNumber: r.jobNumber ?? 0,
                crewName: r.crewName ?? '',
                services: r.services ?? [],
                invoiceNumber: r.invoiceNumber ?? 0,
                invoiceId: r.invoiceId ?? '',
                quoteId: r.quoteId ?? '',
                quoteNumber: r.quoteNumber ?? 0,
                dateCreated: dayjs(r.dateCreated).format(US_FORMAT),
                plannedCompletionDate: dayjs(r.plannedCompletionDate).format(US_FORMAT),
                completionDate: dayjs(r.completionDate).format(US_FORMAT),
                invoiceAmount: r.invoiceAmount ?? 0,
                laborCost: r.laborCost ?? 0,
                timeSpent: r.timeSpent ?? 0,
                address: r.address ?? '',
                jobPrice: r.jobPrice,
            })) ?? [],
        totalLaborCost: exportAtom?.totalLaborCost ?? 0,
        totalTimeSpent: exportAtom?.totalTimeSpent ?? 0,
        totalInvoiceAmount: exportAtom?.totalInvoiceAmount ?? 0,
    } as JobCompletionExportType

    if (!tableData) return

    const csvHeaders = [
        'Job Number',
        'Crew Name',
        'Job Type',
        'Date Created',
        'Planned Completion Date',
        'Date Completed',
        'Job Price',
        'Invoice Amount',
        'Labor Cost',
        'Time Spent',
        'Address',
        'Invoice Number',
        'Quote Number',
    ]

    const csvData =
        tableData?.records?.map((r) => ({
            'Job Number': RECORD_PREFIX.job + formatRecordNumber(r.jobNumber as number), // Ensure that the value is either string or number
            'Crew Name': r.crewName ?? 'N/A',
            'Job Type': r.services.length ? r.services.join(', ') : 'N/A', // Handle empty services array ,
            'Date Created': r.dateCreated ? dayjs(r.dateCreated).format('MMMM DD, YYYY') : 'N/A', // Format the date or use a fallback ,
            'Planned Completion Date': r.dateCreated
                ? dayjs(r.plannedCompletionDate).format('MMMM DD, YYYY')
                : 'N/A', // Format the date or use a fallback ,
            'Date Completed': r.dateCreated
                ? dayjs(r.completionDate).format('MMMM DD, YYYY')
                : 'N/A', // Format the date or use a fallback ,
            'Job Price': displayCurrency(r.jobPrice),
            'Invoice Amount': displayCurrency(r.invoiceAmount),
            'Labor Cost': displayCurrency(r.laborCost),
            'Time Spent': formatWorkingHours(r.timeSpent) ?? 'N/A',
            Address: r.address ?? 'N/A',
            'Invoice Number':
                r.invoiceNumber > 0
                    ? RECORD_PREFIX.invoice + formatRecordNumber(r.invoiceNumber as number)
                    : 'N/A',
            'Quote Number':
                r.quoteNumber > 0
                    ? RECORD_PREFIX.quote + formatRecordNumber(r.quoteNumber as number)
                    : 'N/A',
        })) || []

    csvData.push({
        'Job Number': 'TOTAL',
        'Crew Name': '', // Empty for Job Type
        'Job Type': '', // Empty for Customer Name
        'Job Price': '',
        'Date Created': '',
        'Planned Completion Date': '',
        'Date Completed': '',
        'Invoice Amount': displayCurrency(tableData.totalInvoiceAmount) ?? 0,
        'Labor Cost': displayCurrency(tableData.totalLaborCost) ?? 0,
        'Time Spent': formatWorkingHours(tableData.totalTimeSpent) ?? 0,
        Address: '', // Empty for Paid Via
        'Invoice Number': '', // Empty for Invoice Number
        'Quote Number': '', // Empty for Quote Number
    })

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'expense-report.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportJobCompletionReportCSV
