import { JobListType, JobLogResponseType } from '@/api/job/schema'
import { Card, CardContent, CardFooter } from '@/components/Card'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { jobLogSortByAtom, jobLogSortOrderAtom, selectedJobLogTabAtom } from '@/store/jobLog'
import { cn, getQuoteServiceNames, formatRecordNumber } from '@/utils/helper'
import dayjs from 'dayjs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { HiOutlineDocument } from 'react-icons/hi'
import { NavLink, useNavigate } from 'react-router-dom'
import { getJobLogEndTime, getJobLogStartTime } from '@/utils/time'
import { COMPLETE_FORMAT_WITHOUT_TIME, getJobFontColor, JOB_STATUS_LABEL } from '@/constants'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'

const tableHeader = [
    'Date',
    'Job',
    'Customer',
    'Address',
    'Crew',
    'Start',
    'Finish',
    'Job Status',
    'Invoice Status',
    'Notes',
]

interface IJobLogTableProps {
    jobs: JobListType
}

const JobLogTable: React.FC<IJobLogTableProps> = ({ jobs }) => {
    const navigate = useNavigate()

    const [sortBy, setSortBy] = useAtom(jobLogSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(jobLogSortOrderAtom)
    const setSelected = useSetAtom(selectedJobLogTabAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const handleNotesClick = (jobId: string) => {
        setSelected('NOTES')
        navigate(`/schedule/job-log/${jobId}`)
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='whitespace-normal w-full'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                >
                                    <div className='inline-flex gap-1'>
                                        {header}
                                        <button
                                            onClick={() => onHandleSorting(header)}
                                            disabled={header === 'Invoice Status'}
                                            className='ml-2'
                                        >
                                            {getSortIcon(header)}
                                        </button>
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {jobs &&
                            jobs?.content?.map((job: JobLogResponseType, index) => (
                                <TableRow key={index} className='whitespace-nowrap'>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <div className='flex items-center'>
                                                <div>
                                                    {dayjs(
                                                        job?.appointment?.startDateTime as string,
                                                    )
                                                        .tz(timezone?.timeZoneId)
                                                        .format(COMPLETE_FORMAT_WITHOUT_TIME)}
                                                </div>
                                            </div>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p className='truncate'>
                                                {getQuoteServiceNames(
                                                    job.quote.productServiceGroup,
                                                )}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job.quote.profile?.firstName +
                                                    ' ' +
                                                    job.quote.profile?.lastName}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job?.address?.streetAddress +
                                                    ', ' +
                                                    job?.address?.city +
                                                    ', ' +
                                                    job?.address?.state}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>
                                                {job.crew?.crewNumber
                                                    ? 'Crew ' +
                                                      formatRecordNumber(job.crew?.crewNumber)
                                                    : 'Unassigned'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>{getJobLogStartTime(job, timezone.timeZoneId)}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>{getJobLogEndTime(job, timezone.timeZoneId)}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className={`text-zentive-black text-base`}>
                                        <span
                                            className={cn(
                                                'ml-2 inline-block',
                                                getJobFontColor(job?.status as string),
                                            )}
                                        >
                                            {job?.status && JOB_STATUS_LABEL[job?.status]}
                                        </span>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <NavLink
                                            to={`/schedule/job-log/${job.jobId}`}
                                            onClick={() => setSelected('INFO')}
                                        >
                                            <p>Upon Job Completion</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <HiOutlineDocument
                                            className='mr-2 h-5 w-5 cursor-pointer'
                                            onClick={() => handleNotesClick(job.jobId ?? '')}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default JobLogTable
