import { CardContent, CardTitle } from '@/components/Card'
import Expenses from './Expenses'
import BillingAmount from './BillingAmount'
import Reimbursement from './Reimbursement'
import ProductsAndServicesTable from '@/pages/common/FinancialManagement/ProductsAndServicesTable'
import { ExpenseType, QuoteType } from '@/api/quoting/schema'
import { useAtomValue } from 'jotai'
import { invoiceUserAtom } from '@/store/owner'

const ViewInvoiceContent = () => {
    const invoice = useAtomValue(invoiceUserAtom)

    return (
        <CardContent className='pt-9 pb-20 mx-5'>
            <ProductsAndServicesTable quote={invoice?.quote as QuoteType} />

            <Expenses expense={(invoice?.expense as ExpenseType[]) ?? []} />

            <BillingAmount />
            <div className='border border-[#EBEBEB] p-6 rounded-md'>
                <CardTitle className='text-lg mb-6'>Internal notes</CardTitle>
                <div className='flex w-full text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-md p-2'>
                    {invoice?.internalNotes ?? ''}
                </div>
            </div>

            {invoice?.status === 'paid' ? <Reimbursement /> : <></>}
        </CardContent>
    )
}

export default ViewInvoiceContent
