import BreadCrumbs from '@/components/BreadCrumbs'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import EmailDetails from './EmailDetails'
import Recipients from './Recipients'
import { BREADCRUMBS_PADDING_STYLE, MESSAGE_TYPE } from '@/constants'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { getCustomerMessageByID } from '@/api/customer'
import { cn } from '@/utils/helper'

const MessageContents = () => {
    const { id } = useParams()

    const { data: message } = useQuery({
        queryKey: ['specificMessage', id],
        queryFn: () => getCustomerMessageByID(id ?? ''),
    })

    const messageTitle = message?.title
    const messageType = message?.type
    const emailLabel = MESSAGE_TYPE.EMAIL.charAt(0).toUpperCase() + MESSAGE_TYPE.EMAIL.slice(1)
    const smsLabel = MESSAGE_TYPE.SMS?.toUpperCase()
    
    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs
                titleName={
                    messageType === MESSAGE_TYPE.BOTH
                        ? emailLabel + ' & ' + smsLabel + ' Preview - ' + messageTitle
                        : messageType === MESSAGE_TYPE.SMS
                          ? smsLabel + ' Preview - ' + messageTitle
                          : messageType === MESSAGE_TYPE.EMAIL
                            ? emailLabel + ' Preview - ' + messageTitle
                            : ''
                }
            />
            <Tabs defaultValue='ED'>
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4 bg-white rounded-md'>
                    <TabsTrigger
                        value='ED'
                        className={
                            'w-1/3 h-14 text-base text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Email Details
                    </TabsTrigger>
                    <TabsTrigger
                        value='RP'
                        className={
                            'w-1/3 h-14 text-base text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Recipients ({message?.recipientCount})
                    </TabsTrigger>
                </TabsList>

                <TabsContent className='mt-5 px-10 py-12 bg-white rounded-md w-full' value='ED'>
                    <EmailDetails message={message!} />
                </TabsContent>
                <TabsContent className='mt-5 py-5 bg-white rounded-md w-full' value='RP'>
                    <Recipients message={message!} />
                </TabsContent>
            </Tabs>
        </div>
    )
}

export default MessageContents
