import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    BulkUpdateCrewStatusSchema,
    CreateCrewType,
    CrewListResponseType,
    EmployeeToCrewResponseType,
    UpdateCrewType,
} from './schema'
import { SortParams } from '../job'

interface getAllUnassignedCrewsParams {
    keyWord: string
    businessId: string
    p: PaginationType
}

export const getAllCrews = async (
    keyWord: string,
    status: string,
    businessId: string,
    sort: SortParams,
    p?: PaginationType, // Made `p` optional
) => {
    const { data } = await axiosInstance.get('crews/', {
        params: {
            keyword: keyWord,
            status,
            businessId,
            page: p?.page ?? 1,
            pageSize: p?.pageSize ?? 10,
            sortBy: sort.sortBy || '',
            sortOrder: sort.sortOrder || '',
        },
    })
    return data as CrewListResponseType
}

export const getCrewById = async (
    crewId: string | undefined,
): Promise<EmployeeToCrewResponseType> => {
    if (!crewId) {
        throw new Error('Crew ID not found')
    }

    const response = await axiosInstance.get(`crews/${crewId}`)
    return response.data
}

export const getAllAssignedCrewMembers = async (profileIds: string[]): Promise<string> => {
    const response = await axiosInstance.post(`jobs/jobs-crew-members`, profileIds)
    return response.data
}

export const getAllCrewMembersById = async (crewId: string) => {
    if (crewId) {
        const response = await axiosInstance.get(`crews/all/${crewId}`)
        return response.data as EmployeeToCrewResponseType
    } else {
        return null
    }
}

export const getUnassignedCrews = async (params: getAllUnassignedCrewsParams) => {
    const response = await axiosInstance.get(
        `crews/unassigned?keyword=${params.keyWord}&businessId=${params.businessId}&page=${params.p.page}&pageSize=${params.p.pageSize}`,
    )
    return response.data
}

export const getNextCrewNumber = async (businessId: string) => {
    const response = await axiosInstance.get(`crews/next-crew-no/${businessId}`)
    return response.data.content
}

export const createCrew = async (props: CreateCrewType) => {
    const response = await axiosInstance.post(`crews/`, props)
    return response.data
}

export const updateCrew = async (props: UpdateCrewType) => {
    const response = await axiosInstance.patch(`crews/update`, props)
    return response.data
}

export const updateCrewStatus = async (data: BulkUpdateCrewStatusSchema) => {
    const response = await axiosInstance.post(`crews/bulk-update/status`, data)
    return response.data
}

export const updateCrewMemberJobStatus = async ({
    crewMemberId,
    status,
}: {
    crewMemberId: string
    status: 'ACT' | 'INA'
}) => {
    const response = await axiosInstance.patch(
        `crews/member/update/job-status?=crewMemberId=${crewMemberId}&status=${status}`,
    )

    return response.data.content
}

export const assignForeman = async (crewId: string, crewMemberId: string) => {
    const response = await axiosInstance.post(
        `crews/assign-foreman?crewId=${crewId}&crewMemberId=${crewMemberId}`,
    )

    return response.data.content
}
