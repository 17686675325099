import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { RevenueReportExportType } from '@/api/reports/schema'
import { formatRecordNumber, displayCurrency } from '@/utils/helper'
import { US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { revenueReportExportAtom } from '@/store/reports'

const RevenueReportCSV = () => {
    const exportAtom = useAtomValue(revenueReportExportAtom)

    // const selectedTab = useAtomValue(expenseReportTabsAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: RevenueReportExportType = {
        records:
            exportAtom?.records?.map((r) => ({
                accountNumber: r.accountNumber,
                businessName: r.businessName,
                planType: r.planType,
                billingCycle: r.billingCycle,
                subStart: r.subStart,
                subStatus: r.subStatus,
                totalRevenue: r.totalRevenue,
            })) ?? [],
    } as RevenueReportExportType

    if (!tableData) return

    const csvHeaders = [
        'Subscriber ID',
        'Business Name',
        'Plan Type',
        'Billing Cycle',
        'Sub. Start',
        'Sub. Status',
        'Total Revenue',
    ]

    const csvData =
        tableData?.records?.map((data) => ({
            'Subscriber ID': 'S-' + formatRecordNumber(data.accountNumber) ?? 'N/A', // Ensure that the value is either string or number
            'Business Name': data.businessName ?? 'Zentive',
            'Plan Type': data.planType ?? 'N/A',
            'Billing Cycle': data.billingCycle ?? 'N/A',
            'Sub. Start': dayjs(data.subStart).format(US_FORMAT),
            'Sub. Status': data.subStatus,
            'Total Revenue': displayCurrency(data.totalRevenue),
        })) || []

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'revenue-report.csv'}
            className={
                'w-[135px] h-[34px] text-[#191A0A] hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default RevenueReportCSV
