import { ExpenseType, ProductServiceType } from '@/api/quoting/schema'
import { atom } from 'jotai'
import { CreateJobType, JobFilterStatusType, JobListType, ReadJobType } from '@/api/job/schema'
import { PaginationType } from '@/components/Pagination/schema'
import { ISO_8601, JobStatus } from '@/constants'
import dayjs from 'dayjs'
import { atomWithStorage } from 'jotai/utils'
import { InvoiceType } from '@/api/invoicing/schema'

// Default Job Date will always be set to the date today
const defaultJobDate = dayjs().format(ISO_8601)

export const jobDateAtom = atom<string>(defaultJobDate)

export const jobSortByAtom = atom<string>('')
export const jobSortOrderAtom = atom<string>('')

export const recurringDatesAtom = atom<string[]>([])

jobDateAtom.onMount = (setAtom) => {
    return () => setAtom(defaultJobDate)
}

export const jobListAtom = atom<JobListType | null>(null)
export const jobListCrewPortalAtom = atom<CreateJobType[] | null>(null)
export const unfilteredCompletedJobListAtom = atom<CreateJobType[] | null>(null)
export const selectedJobStatusAtom = atom<JobStatus>('ALL')

export const jobPaginationAtom = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const editJobAtom = atomWithStorage<boolean>('isJobEdit', false, undefined, {
    getOnInit: true,
})

editJobAtom.onMount = (set) => () => set(false)

export const setJobPaginationAtom = atom(null, (_, set, newValue: PaginationType) => {
    set(jobPaginationAtom, newValue)
})

export const itemsPerPageAtom = atom(5)

export const clientPagination = atom<PaginationType>({
    page: 1,
    pageSize: 5,
})

export const productServiceAtomToNewJobAtom = atom<ProductServiceType[] | null>([])
export const expenseAtomToNewJobAtom = atom<ExpenseType[] | null>([])
export const jobStatusFilterAtom = atom<JobFilterStatusType | null>(null)
export const jobStartFilterAtom = atom<string>('')
export const jobGenerateInvoiceAtom = atom<InvoiceType | null>(null)
export const jobInvoiceDiscountAtom = atom<number>(0)
export const selectedJobForInvoiceAtom = atom<ReadJobType | null>(null)

