import { z } from 'zod'
import { baseSchema } from '../common/schema'
import { stringValidator } from '@/utils/regex'

// Make baseSchema partial for the following reason(s)
// The props for timestamps are commonly used for responses
// The addressSchema properties are used on forms that do not require timestamps
// Partial so we can still use the schema for form validation as well as response type
// partial is equivalent to optional but only usable on a z.object
// reference: https://zod.dev/?id=partial

export const coordinates = z.object({ gmapLat: z.number(), gmapLng: z.number() })

export const addressSchema = z
    .object({
        addressId: z.string().optional(),
        streetAddress: stringValidator('Street 1', { fieldType: 'location', isRequired: true }),
        streetTwoAddress: stringValidator('Street 2', { fieldType: 'location' }),
        city: stringValidator('City', { fieldType: 'location', isRequired: true }),
        state: stringValidator('State', { fieldType: 'location', isRequired: true }),
        zipCode: stringValidator('Zip Code', {isRequired: true }),
        timeOffset: z.number().optional(),
        timezone: z.string().optional(),
    })
    .merge(coordinates.partial())
    .merge(baseSchema.partial())

export type CoordinatesType = z.infer<typeof coordinates>
