import { CardHeader } from '@/components/Card'
import HeaderClientDetails from './ClientDetails'
import { CustomerProfileType } from '@/api/profile/schema'

const ViewInvoiceHeader = ({
    invoiceNumber,
    sharedNotes,
    profile,
    status,
    quoteNumber,
    quoteId,
    dateSent,
    invoiceId
}: {
    invoiceNumber: number
    sharedNotes?: string
    profile: CustomerProfileType
    status?: string
    quoteNumber?: number
    quoteId?: string
    dateSent?: string
    invoiceId: string
}) => {
    return (
        <CardHeader className='border-b-[12px]'>
            <div className='flex flex-row'>
                <HeaderClientDetails
                    profile={profile}
                    status={status}
                    invoiceNumber={invoiceNumber}
                    sharedNotes={sharedNotes}
                    quoteNumber={quoteNumber}
                    quoteId={quoteId}
                    dateSent={dateSent}
                    invoiceId={invoiceId}
                />
            </div>
        </CardHeader>
    )
}

export default ViewInvoiceHeader
