import featureRestriction from '@/assets/private/expense_feature_restriction.png'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

export const FeatureRestriction = () => {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col bg-white w-full items-center justify-center text-center gap-5 py-10'>
            <img src={featureRestriction} className='h-[15rem] w-[18rem]' />
            <p className='text-zentive-green-dark text-2xl font-semibold'>No Expenses to Display</p>
            <p className='text-zentive-gray-dark text-base'>
                Sorry, we can't display your expenses. <br /> Please upgrade your plan.
            </p>
            <Button onClick={() => navigate(`/owner-subscription/change-plan`)}>Upgrade Now</Button>
        </div>
    )
}
