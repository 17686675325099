import React from 'react'
import { toExportSubsAtom } from '@/store/manageSubs'
import { numberFormatter } from '@/utils/helper'
import { format } from 'date-fns'
import { useAtomValue } from 'jotai'
import { CSVLink } from 'react-csv'

interface IExportDataToCSV {
    businessName: string
    subscriberName: string
    subscription: string
    email: string
    address: string
    phoneNumber: string
    createdAt: string
}

const ExportCSV = ({
    isOpen,
    setIsOpen,
}: {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const subsData = useAtomValue(toExportSubsAtom)

    const tableData: IExportDataToCSV[] = subsData?.map((data) => {
        return {
            businessName: data?.business.businessName,
            subscriberName: (data?.firstName ?? '') + ' ' + (data?.lastName ?? ''),
            subscription: numberFormatter
                .format(data?.business?.subscriptionType?.price ?? 0)
                .toLocaleString(),
            email: data?.email,
            phoneNumber: data?.phoneNumber
                ? '+' +
                  data?.phoneNumber
                      .replace(/\D+/g, '')
                      .replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, (_, p1, p2, p3) => {
                          const parts = [p1]
                          if (p2) parts.push(p2)
                          if (p3) parts.push(p3)
                          return parts.join(' ')
                      })
                : '',
            address: data?.address.streetAddress,
            createdAt: format(new Date(data?.createdAt || ''), 'LLL-dd-yyyy hh:mm:ss a'),
        }
    }) as IExportDataToCSV[]

    if (!tableData) return

    const csvHeaders = [
        'Business Name',
        'Subscriber',
        'Subscription',
        'Email Address',
        'Address',
        'Contact Number',
        'Register On',
    ]

    const csvData = tableData?.map((item) => ({
        'Business Name': item.businessName,
        Subscriber: item.subscriberName,
        Subscription: `$${item.subscription}`,
        'Email Address': item.email,
        Address: item.address,
        'Contact Number': item.phoneNumber,
        'Register On': item.createdAt,
    }))

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'subscribers_report.csv'}
            className={
                'w-[135px] h-[34px] text-zentive-black hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
            onClick={() => {
                setIsOpen(!isOpen)
            }}
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportCSV
