import autoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import { NetGrossExportType } from '@/api/reports/schema'
import { RECORD_PREFIX } from '@/constants'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'

export const GenerateGrossReportPDF = (
    zentiveLogoUrl: string,
    tableData: NetGrossExportType,
    businessName: string,
) => {
    const doc = new jsPDF({ orientation: 'landscape' })

    let startY = 10

    const imageHeight = 17
    const imageWidth = 50

    const pageWidth = doc.internal.pageSize.getWidth()
    const centerX = (pageWidth - imageWidth) / 2

    doc.addImage(zentiveLogoUrl, 'JPEG', centerX, startY, imageWidth, imageHeight)

    startY += imageHeight + 10

    doc.setFontSize(16)
    doc.text(businessName + ' Gross Income Report', pageWidth / 2, startY, {
        align: 'center',
    })

    startY += 10

    const head = [
        [
            'Job Number',
            'Job Type',
            'Customer Name',
            'Date Completed',
            'Gross Income',
            'Paid Via',
            'Invoice Number',
            'Quote Number',
        ],
    ]

    const body = tableData?.records?.map((data) => [
        RECORD_PREFIX.job + formatRecordNumber(data.jobNumber as number), // Ensure that the value is either string or number
        data.services.length ? data.services.join(', ') : 'N/A', // Handle empty services array
        data.customerName ?? 'N/A',
        data.date ? dayjs(data.date).format('MMMM DD, YYYY') : 'N/A', // Format the date or use a fallback
        displayCurrency(data.grossIncome) ?? 'N/A',
        data.paidVia ?? 'N/A',
        RECORD_PREFIX.invoice + formatRecordNumber(data.invoiceNumber as number),
        RECORD_PREFIX.quote + formatRecordNumber(data.quoteNumber as number),
    ])

    body.push([
        'TOTAL',
        '', // Empty for Job Type
        '', // Empty for Customer Name
        '', // Empty for Date Completed
        displayCurrency(tableData.totalGrossIncome) ?? 0, // Total Gross Income
        '', // Empty for Paid Via
        '', // Empty for Invoice Number
        '', // Empty for Quote Number
    ])

    autoTable(doc, {
        startY: startY,
        head: head,
        body: body,
        theme: 'striped',
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        headStyles: { fillColor: [59, 103, 26], textColor: [255, 255, 255], fontSize: 8 },
        didDrawPage: (data) => {
            const pageCount = doc.getNumberOfPages()
            const footerStr = `Page ${data.pageNumber} of ${pageCount}`
            doc.setFontSize(10)
            doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.getHeight() - 10)
        },
    })

    doc.save('gross-income-report.pdf')
}
