import { axiosInstance } from '../axiosInstance'
import {
    CreateExpenseAccountType,
    ExpenseAccountListResponseType,
    ExpenseAccountResponseType,
} from './schema'

export const getExpenseAccountsByBusinessId = async (keyword: string, businessId: string, sortBy?: string, sortOrder?: string) => {
    const { data } = await axiosInstance.get(
        `expenses/accounts?keyword=${keyword}&businessId=${businessId}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    )
    return data as ExpenseAccountListResponseType
}

export const getExpenseAccountById = async (expenseAccountId: string) => {
    const response = await axiosInstance.get(`expenses/account/${expenseAccountId}`)
    return response.data as ExpenseAccountResponseType
}

export const getNextExpenseAccountNumber = async (businessId: string) => {
    const response = await axiosInstance.get(`expenses/next-account-code/${businessId}`)
    return response.data.content as number
}

export const createExpenseAccount = async (props: CreateExpenseAccountType) => {
    const response = await axiosInstance.post(`expenses/account`, props)
    return response.data.content as ExpenseAccountResponseType
}

export const updateExpenseAccount = async (props: CreateExpenseAccountType) => {
    const response = await axiosInstance.patch(`expenses/account`, props)
    return response.data.content as ExpenseAccountResponseType
}

export const deleteExpenseAccount = async (expenseAccountId: string) => {
    const response = await axiosInstance.post(`expenses/account/delete/${expenseAccountId}`)
    return response.data
}
