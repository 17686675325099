import { getCustomerConnectedBusinesses } from '@/api/business'
import {
    CustomerConnectedBusinessesType,
    CustomerConnectedBusinessListType,
} from '@/api/business/schema'
import { FullProfileType } from '@/api/profile/schema'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card'
import { businessIdAtom, userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { FaChevronRight } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { FC, useEffect } from 'react'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'
import { toast } from '@/hooks/useToast'
import { checkOwnerActiveStatus } from '@/api/profile'

type SelectBusinessButtonProps = {
    business: CustomerConnectedBusinessesType
}

const SelectBusinessButton: FC<SelectBusinessButtonProps> = ({ business }) => {
    const setBusinessId = useSetAtom(businessIdAtom)

    const handleClick = async (business: CustomerConnectedBusinessesType) => {
        const isActive = await checkOwnerActiveStatus(business?.businessId)
        if (isActive) {
            setBusinessId(business?.businessId)
        } else {
            toast({
                description: 'Account Restricted: Business no longer active',
                variant: 'destructive',
                duration: 2000,
            })
        }
    }

    return (
        <button
            type='button'
            onClick={() => handleClick(business)}
            className='items-start text-left border border-b-1 border-t-0 border-l-0 border-r-0 border-zentive-gray-light w-full px-5 py-2 hover:bg-zentive-gray-light/30'
        >
            <div className='flex flex-row justify-between'>
                <div className='flex flex-col gap-2'>
                    <p className='font-bold text-md text-zentive-green-dark'>
                        {business?.businessName}
                    </p>
                    <p className='text-zentive-gray-medium text-base'>{business?.email}</p>
                </div>
                <div className='py-5'>
                    <FaChevronRight className='text-zentive-gray-medium w-2' />
                </div>
            </div>
        </button>
    )
}

const SelectBusiness = () => {
    const user = useAtomValue(userAtom) as FullProfileType
    const businessId = useAtomValue(businessIdAtom)
    const navigate = useNavigate()

    const {
        data: businesses,
        isLoading: isCustomerBusinessesLoading,
        error,
    } = useQuery<CustomerConnectedBusinessListType>({
        queryKey: ['customerBusinesses', user?.profileId],
        queryFn: () => getCustomerConnectedBusinesses(user?.profileId),
    })

    const { data: isConnectedToStripe, isSuccess } = useStripeConnectStatus()

    useEffect(() => {
        if (isSuccess) {
            navigate(`/customer-portal/${isConnectedToStripe ? 'payments' : 'quotes'}`)
        }
    }, [isSuccess, isConnectedToStripe, businessId])

    if (isCustomerBusinessesLoading) {
        return <></>
    }

    if (error) {
        return (
            <div className='w-full flex flex-row items-center justify-center py-20'>
                <p className='text-zentive-red-dark'>{error.message}</p>
            </div>
        )
    }

    return (
        <div className='w-full flex flex-row items-center justify-center'>
            <div className='mt-20 forgot-password-bg flex items-center justify-center h-[36.25rem] w-[70rem] bg-zentive-green-light'>
                <div className='bg-white bg-no-repeat bg-clip-padding box-shadow border rounded-lg opacity-100'>
                    <Card>
                        <CardHeader>
                            <CardTitle className='text-zentive-green-dark text-center'>
                                Choose Business Account to Login
                            </CardTitle>
                        </CardHeader>
                        <CardContent className='mb-5'>
                            <div className='text-gray-400 w-107 text-left'>
                                <p className='text-center'>
                                    Please click the account that you would like to use to login the
                                    app.
                                </p>
                            </div>
                            <div className='flex flex-col items-start mt-5'>
                                {businesses?.map((business) => (
                                    <SelectBusinessButton
                                        business={business}
                                        key={business.businessId}
                                    />
                                ))}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default SelectBusiness
