import { JobLogsInfoType, ReadJobType } from '@/api/job/schema'
import { Card, CardContent } from '@/components/Card'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { JOB_STATUS, TWELVE_HOUR_CLOCK_WITH_MERIDIEM } from '@/constants'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { FaCircle, FaPencilAlt } from 'react-icons/fa'
import UpdateDtr from './UpdateDtr'
import { cn } from '@/utils/helper'
import { jobStatus, JobStatusColor } from '..'
import { useAtomValue } from 'jotai'
import { timezoneAtom } from '@/store/auth'

interface IJobLogTimeTableProps {
    job: ReadJobType | undefined
}

export type UpdateDtrType = {
    startTimeId: string
    startTime: string
    endTimeId: string
    endTime: string
    jobCrewMemberId: string
}

const JobLogTimeTable: React.FC<IJobLogTimeTableProps> = ({ job }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedJobCrewMemberId, setSelectedJobCrewMemberId] = useState<string>('')
    const timeZone = useAtomValue(timezoneAtom)
    const [updates, setUpdates] = useState<UpdateDtrType>({
        startTimeId: '',
        startTime: '',
        endTimeId: '',
        endTime: '',
        jobCrewMemberId: '',
    })

    const handleMergeCrewMemberAndDtr = useCallback(
        (job: ReadJobType) => {
            if (job) {
                const { foreman, jobCrewMembers } = job

                const crewMembersById: Record<string, JobLogsInfoType> = {}
                if (job?.crew && job?.crewId) {
                    // Always include the foreman in the crewMembersById
                    crewMembersById[foreman?.jobCrewMemberId as string] = foreman

                    if (jobCrewMembers) {
                        jobCrewMembers.forEach((member) => {
                            crewMembersById[member?.jobCrewMemberId as string] = member
                        })
                    }

                    //Include the foreman and jobCrewMembers in the updated jobCrewMembers array
                    const updatedJobCrewMembers = [
                        crewMembersById[foreman?.jobCrewMemberId as string], // Ensure foreman is at the beginning
                        ...(jobCrewMembers
                            ? jobCrewMembers?.map(
                                  (member) => crewMembersById[member?.jobCrewMemberId as string],
                              )
                            : []),
                    ]

                    return {
                        ...job,
                        jobCrewMembers: updatedJobCrewMembers,
                    }
                }
            }
            return job
        },
        [job],
    )

    const mergedDtrWithCrews = job && handleMergeCrewMemberAndDtr(job)

    const handleEditClick = (dtr: JobLogsInfoType) => {
        setSelectedJobCrewMemberId(dtr?.memberProfile?.profileId)
        setIsOpen(true)
        setUpdates({
            startTime: (() => {
                const firstStartRecord =
                    dtr.dailyTimeRecord &&
                    dtr.dailyTimeRecord?.length > 0 &&
                    dtr.dailyTimeRecord[0].type === 'start'
                        ? dtr.dailyTimeRecord[0]
                        : null
                return firstStartRecord && firstStartRecord.time ? firstStartRecord.time : ''
            })(),
            startTimeId: (() => {
                const firstStartRecord =
                    dtr.dailyTimeRecord &&
                    dtr.dailyTimeRecord?.length > 0 &&
                    dtr.dailyTimeRecord[0].type === 'start'
                        ? dtr.dailyTimeRecord[0]
                        : null
                return firstStartRecord ? firstStartRecord.dtrId : ''
            })(),
            endTime: (() => {
                const endRecords = dtr.dailyTimeRecord?.filter((record) => record.type === 'end')
                const lastEndRecord =
                    endRecords && endRecords?.length > 0 ? endRecords[endRecords?.length - 1] : null
                return lastEndRecord && lastEndRecord.time ? lastEndRecord.time : ''
            })(),
            endTimeId: (() => {
                const endRecords = dtr.dailyTimeRecord?.filter((record) => record.type === 'end')
                const lastEndRecord =
                    endRecords && endRecords?.length > 0 ? endRecords[endRecords?.length - 1] : null
                return lastEndRecord ? lastEndRecord.dtrId : ''
            })(),
            jobCrewMemberId: dtr?.jobCrewMemberId as string,
        })
    }

    const getJobStatusPerCrewMember = (dtr: JobLogsInfoType, status: string): JobStatusColor => {
        if (!dtr.dailyTimeRecord && status !== JOB_STATUS.COMPLETED) {
            return jobStatus.ACT
        } else if (
            Array.isArray(dtr.dailyTimeRecord) &&
            dtr.dailyTimeRecord.length > 0 &&
            (dtr.dailyTimeRecord[dtr.dailyTimeRecord.length - 1]?.type === 'start' ||
                dtr.dailyTimeRecord[dtr.dailyTimeRecord.length - 1]?.type === 'pause') &&
            status !== JOB_STATUS.COMPLETED
        ) {
            return jobStatus.IN_PROGRESS
        } else {
            return jobStatus.COMPLETED
        }
    }

    if (!mergedDtrWithCrews?.crew) return 'No assigned crew yet'

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent className='px-0'>
                <Table>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap bg-[#F6FFEF] '>
                            <TableHead className='font-semibold text-black text-base border-r-2'>
                                Name
                            </TableHead>
                            <TableHead
                                colSpan={2}
                                className='font-semibold text-center pr-44 text-black border-r-2 text-base'
                            >
                                Time Spent
                            </TableHead>
                            <TableHead className='font-semibold text-black text-base border-r-2'>
                                Work Status
                            </TableHead>
                            <TableHead className='font-semibold text-black text-base'>
                                Actions
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {mergedDtrWithCrews?.jobCrewMembers &&
                            mergedDtrWithCrews?.jobCrewMembers?.map(
                                (dtr: JobLogsInfoType, index: number) => {
                                    return !isOpen ||
                                        selectedJobCrewMemberId !==
                                            dtr?.memberProfile?.profileId ? (
                                        <TableRow key={index}>
                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                <p>
                                                    {dtr?.memberProfile?.firstName +
                                                        ' ' +
                                                        dtr.memberProfile?.lastName}
                                                    {dtr?.memberProfile?.profileId ===
                                                        job?.foreman?.memberProfile?.profileId &&
                                                        ` (Foreman)`}
                                                </p>
                                            </TableCell>

                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] border-l-2 text-base overflow-hidden text-ellipsis'>
                                                <div className='w-[120px]'>
                                                    <p>
                                                        {(() => {
                                                            const firstStartRecord =
                                                                dtr.dailyTimeRecord &&
                                                                dtr.dailyTimeRecord?.length > 0 &&
                                                                dtr.dailyTimeRecord[0].type ===
                                                                    'start'
                                                                    ? dtr.dailyTimeRecord[0]
                                                                    : null
                                                            return firstStartRecord
                                                                ? dayjs(firstStartRecord.time)
                                                                      .tz(timeZone?.timeZoneId)
                                                                      .format(
                                                                          TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
                                                                      )
                                                                : '00:00'
                                                        })()}
                                                    </p>
                                                </div>
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] border-r-2 text-base overflow-hidden text-ellipsis'>
                                                <div className='w-[120px]'>
                                                    <p>
                                                        {(() => {
                                                            const endRecords =
                                                                dtr.dailyTimeRecord?.filter(
                                                                    (record) =>
                                                                        record.type === 'end',
                                                                )
                                                            const lastEndRecord =
                                                                endRecords && endRecords?.length > 0
                                                                    ? endRecords[
                                                                          endRecords?.length - 1
                                                                      ]
                                                                    : null
                                                            return lastEndRecord
                                                                ? dayjs(lastEndRecord.time)
                                                                      .tz(timeZone?.timeZoneId)
                                                                      .format(
                                                                          TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
                                                                      )
                                                                : '00:00'
                                                        })()}
                                                    </p>
                                                </div>
                                            </TableCell>

                                            <TableCell className='flex flex-row text-zentive-black max-w-[15rem] border-r-2 text-base overflow-hidden text-ellipsis'>
                                                <p className='text-gray-500'>
                                                    <span
                                                        className={`ml-2 inline-block`}
                                                        style={{
                                                            color: getJobStatusPerCrewMember(
                                                                dtr,
                                                                job?.status as string,
                                                            ).color,
                                                        }}
                                                    >
                                                        <FaCircle className='inline-block mb-1 w-2 h-2' />{' '}
                                                        {
                                                            getJobStatusPerCrewMember(
                                                                dtr,
                                                                job?.status as string,
                                                            ).text
                                                        }
                                                    </span>
                                                </p>
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                <FaPencilAlt
                                                    className={cn(
                                                        'h-3 w-28 mr-24 pr-24',
                                                        job?.status === JOB_STATUS.COMPLETED ||
                                                            job?.status === JOB_STATUS.ACTIVE
                                                            ? 'text-zentive-gray-medium'
                                                            : 'cursor-pointer text-indigo-900',
                                                    )}
                                                    onClick={() =>
                                                        job?.status !== JOB_STATUS.COMPLETED &&
                                                        job?.status !== JOB_STATUS.ACTIVE
                                                            ? handleEditClick(dtr)
                                                            : () => {}
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow key={index} className='whitespace-nowrap'>
                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                <p>
                                                    {dtr.memberProfile?.firstName +
                                                        ' ' +
                                                        dtr.memberProfile?.lastName}
                                                    {dtr?.memberProfile?.profileId ===
                                                        job?.foreman?.memberProfile?.profileId &&
                                                        ` (Foreman)`}
                                                </p>
                                            </TableCell>
                                            <UpdateDtr
                                                job={job}
                                                updates={updates}
                                                setIsOpen={setIsOpen}
                                                status={
                                                    getJobStatusPerCrewMember(
                                                        dtr,
                                                        job?.status as string,
                                                    ).text
                                                }
                                            />
                                        </TableRow>
                                    )
                                },
                            )}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}

export default JobLogTimeTable
