import { FormItem } from '@/components/Forms'
import { FC, useState } from 'react'
import { USER_STATUS } from '@/constants'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { FaInfoCircle } from 'react-icons/fa'
import './index.css'
import DeactivateModal from '../EmployeeModals/DeactivateEmployeeModal'

import { useAtomValue, useSetAtom } from 'jotai'

import ActivateModal from '../EmployeeModals/ActivateEmployeeModal'
import { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { updateProfileStatus } from '@/api/business'
import { BusinessStatusType } from '@/api/business/schema'
import { useNavigate } from 'react-router-dom'
import DeleteEmployeeModal from '../EmployeeModals/DeleteEmployeeModal'
import { profileStatusAtom } from '@/store/customer'
import { selectedEmployeeTabStatusAtom } from '@/store/employee'
import { useQueryClient } from '@tanstack/react-query'
import { StatusType } from '@/store/auth'
import { validateUpdate } from '@/api/auth'
import ExistingPhoneNumberModal from '@/components/ExistingPhoneNumberModal'
import RestoreEmployeeModal from '../EmployeeModals/RestoreEmployeeModal'

export type EmployeeStatusModalProps = {
    handleProfileStatusUpdate: () => Promise<unknown>
    onOpenChange: (val: boolean) => void
    open: boolean
}

const EmployeeSettings: FC = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { toast } = useToast()
    const profileStatus = useAtomValue(profileStatusAtom)
    const setSelected = useSetAtom(selectedEmployeeTabStatusAtom)
    const [existingPhoneNumberModal, setExistingPhoneNumberModal] = useState<boolean>(false)

    const [modalType, setModalType] = useState<Record<StatusType, boolean>>({
        INA: false,
        ACT: false,
        DEL: false,
    })

    const isRadioDisabled = profileStatus?.status === USER_STATUS.DELETED

    const onChange = (x: string, y: boolean = true) =>
        setModalType((prev) => ({
            ...prev,
            [x]: y,
        }))

    const { mutateAsync: updateProfileStatusMu } = useMutation<
        BusinessStatusType,
        AxiosError,
        BusinessStatusType
    >({
        mutationFn: updateProfileStatus,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['employeeList'] })
            toast({
                description: 'Employee status has been successfully updated',
                variant: 'default',
            })
            setSelected(data.status)
            navigate('/schedule/employee')
        },
        onError: (err) => {
            if (err instanceof AxiosError) {
                throw new Error(err.message)
            }
        },
    })

    const { mutateAsync: validateUpdateMu } = useMutation<unknown, AxiosError, string>({
        mutationFn: (data) => validateUpdate(data),
        onSuccess: (isValid) => {
            if (isValid) {
                updateProfileStatusMu({
                    ...(profileStatus as BusinessStatusType),
                    status: USER_STATUS.ACTIVATED,
                })
            } else {
                setExistingPhoneNumberModal(true)
            }
        },
    })

    const handleProfileStatusUpdate = (val: StatusType) => () => {
        if (val === USER_STATUS.ACTIVATED) {
            return validateUpdateMu(profileStatus?.profileId ?? '')
        }

        return updateProfileStatusMu({
            ...(profileStatus as BusinessStatusType),
            status: val,
        })
    }

    return (
        <div className='bg-white p-8'>
            <ExistingPhoneNumberModal
                setOpen={setExistingPhoneNumberModal}
                open={existingPhoneNumberModal}
            />
            <div className='bg-zentive-gray-bg p-6 mb-6'>
                <p className='text-lg font-semibold'>Account Status</p>
                <p className='text-zentive-gray-medium '>
                    Indicates whether the user's subscription is currently active or terminated.
                </p>

                <RadioGroup
                    onValueChange={onChange}
                    value={profileStatus?.status || ''}
                    disabled={isRadioDisabled}
                    className='flex flex-col space-y-1'
                >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                        <RadioGroupItem
                            value='ACT'
                            className='text-zentive-green-dark'
                            checked={profileStatus?.status === 'ACT'}
                        />

                        <div className='text-base flex text-center'>
                            Activate{' '}
                            <span className='tooltip'>
                                <FaInfoCircle className='ml-2 mt-1 text-zentive-gray-medium' />
                                <span className='tooltiptext'>
                                    User have currently have access in the application and could be
                                    assigned to any jobs.
                                </span>
                            </span>
                        </div>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                        <RadioGroupItem
                            value='INA'
                            className='text-zentive-green-dark'
                            checked={profileStatus?.status === 'INA'}
                        />
                        <div className='text-base flex text-center '>
                            Deactivate{' '}
                            <span className='tooltip'>
                                <FaInfoCircle className='ml-2 mt-1 text-zentive-gray-medium' />
                                <span className='tooltiptext'>
                                    Indicates that the user will temporarily lose access to the
                                    application and cannot be assigned to any jobs, unless
                                    activated.
                                </span>
                            </span>
                        </div>
                    </FormItem>
                </RadioGroup>
            </div>
            {profileStatus?.status === USER_STATUS.ACTIVATED ? (
                <div className='bg-zentive-gray-bg p-6'>
                    <p className='text-lg font-semibold'>Delete Employee</p>
                    <p className='text-zentive-gray-medium'>
                        Deleting employee's account will remove all associated data and access.
                    </p>
                    <DeleteEmployeeModal
                        handleProfileStatusUpdate={handleProfileStatusUpdate(USER_STATUS.DELETED)}
                        open={modalType.DEL}
                        onOpenChange={(val: boolean) => onChange(USER_STATUS.DELETED, val)}
                    />
                </div>
            ) : (
                <div className='bg-gray-200 p-6'>
                    <p className='text-lg font-semibold'>Restore Account</p>
                    <p className='text-zentive-gray-medium'>
                        By restoring the account, user will regain the account status the user
                        previously have.
                    </p>
                    <RestoreEmployeeModal
                        handleProfileStatusUpdate={handleProfileStatusUpdate(USER_STATUS.ACTIVATED)}
                        open={modalType.DEL}
                        onOpenChange={(val: boolean) => onChange(USER_STATUS.DELETED, val)}
                    />
                </div>
            )}

            <DeactivateModal
                handleProfileStatusUpdate={handleProfileStatusUpdate(USER_STATUS.INACTIVE)}
                open={modalType.INA}
                onOpenChange={(val: boolean) => onChange(USER_STATUS.INACTIVE, val)}
            />
            <ActivateModal
                handleProfileStatusUpdate={handleProfileStatusUpdate(USER_STATUS.ACTIVATED)}
                open={modalType.ACT}
                onOpenChange={(val: boolean) => onChange(USER_STATUS.ACTIVATED, val)}
            />
        </div>
    )
}

export default EmployeeSettings
