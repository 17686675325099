import { getPricingById } from '@/api/subscription'
import { SubscriberInfoType, SubscriptionType } from '@/api/subscription/schema'
import { getBusinessProfile } from '@/api/profile'
import { Button } from '@/components/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { numberFormatter } from '@/utils/helper'
import { decryptString } from '@/utils/crypto'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const PlanComparisonDowngrade = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const params = new URLSearchParams(window.location.search)
    const encriptednewPlanId = params.get('newPlan')
    const newPlanId = decryptString(encriptednewPlanId as string)

    const { data: businessProfileData } = useQuery<unknown, AxiosError, SubscriberInfoType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    const { data: newPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['newPlan'],
        queryFn: () => getPricingById(newPlanId as string),
    })

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Review Changes' />
            <div className='bg-white mt-10 pb-[96px]'>
                <div className='px-[30px] pt-[50px]'>
                    <Table className='ring-1 ring-white'>
                        <TableHeader>
                            <TableRow className='whitespace-pre-wrap'>
                                <TableHead className=' ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Plan Selected
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Price
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base w-[20%]'>
                                    Payment Interval
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light only:font-semibold text-zentive-gray-medium text-[16px] w-[20%]'>
                                    Date of Downgrade
                                </TableHead>
                                <TableHead className='ring-1 ring-zentive-gray-light only:font-semibold text-zentive-gray-medium text-[16px] w-[20%]'>
                                    Next Billing Date
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow key={newPlan?.name} className='whitespace-nowrap'>
                                <TableCell className='ring-1 ring-zentive-gray-light text-[#191A0A] text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {newPlan?.name}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-[#191A0A] text-base pb-[45px] pt-[10px] w-[20%]'>
                                    ${numberFormatter.format(newPlan?.price ?? 0)}
                                </TableCell>

                                <TableCell className='ring-1 ring-zentive-gray-light text-[#191A0A] text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {newPlan?.interval === 'year' ? 'Annually' : 'Monthly'}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-[#191A0A] text-base pb-[45px] pt-[10px] w-[20%]'>
                                    {dayjs().format('MMMM DD, YYYY')}
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-[#191A0A] text-[16px] pb-[45px] pt-[10px] w-[20%]'>
                                    {dayjs(
                                        businessProfileData?.business.subscriptionExpiryDate?.substring(
                                            0,
                                            10,
                                        ),
                                    ).format('MMMM DD, YYYY')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableBody>
                            <TableRow className='w-fill h-11'>
                                <TableCell className='w-[40%] ring-1 ring-zentive-gray-light font-semibold text-zentive-gray-medium text-base'>
                                    Next Billing Amount Due
                                </TableCell>
                                <TableCell className='ring-1 ring-zentive-gray-light text-end text-base'>
                                    ${numberFormatter.format(newPlan?.price ?? 0)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className='flex justify-end bg-gray-200 rounded-b-[15px] gap-3 py-5 px-5'>
                <Button
                    onClick={() => navigate(-1)}
                    className='w-[95px] text-zentive-green-dark font-semibold text-lg'
                    variant={'outline'}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        const process = 'Downgrade'
                        navigate(
                            `/owner-subscription/change-plan/confirm?process=${process}&newPlanId=${newPlanId}`,
                        )
                    }}
                    className='w-[95px]'
                >
                    Next
                </Button>
            </div>
        </div>
    )
}

export default PlanComparisonDowngrade
