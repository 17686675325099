import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import _ from 'lodash'
import {
    CreateExpenseAccountType,
    ExpenseAccountResponseType,
    expenseAccountSchema,
} from '@/api/expense_account/schema'
import { updateExpenseAccount } from '@/api/expense_account'
import { Button } from '@/components/Button'
import { TableCell } from '@/components/Table'
import { Input } from '@/components/Input'

type UpdateExpenseAccountProps = {
    expenseNames: Array<String>
    expenseAccount: ExpenseAccountResponseType
    setSelectedExpenseAccountId: React.Dispatch<React.SetStateAction<string>>
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const UpdateExpenseAccount = ({
    expenseAccount,
    expenseNames,
    setSelectedExpenseAccountId,
    setIsOpen,
}: UpdateExpenseAccountProps) => {
    const { toast } = useToast()
    const queryClient = useQueryClient()
    const updateExpenseAccountMethods = useForm<CreateExpenseAccountType>({
        mode: 'onChange',
        resolver: zodResolver(expenseAccountSchema),
    })

    const {
        control,
        setValue,
        setError,
        handleSubmit,
        watch,
        formState: { isValid },
    } = updateExpenseAccountMethods

    const isFormDirty = useCallback(() => {
        const { expenseAccountName } = watch()
        return (
            _.isEqual(expenseAccount.expenseAccountName, expenseAccountName) ||
            !expenseAccountName?.trim()
        )
    }, [expenseAccount, watch])

    const { mutate: updateExpenseAccountMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: CreateExpenseAccountType) => updateExpenseAccount(data),
        onSuccess: () => {
            toast({
                description: 'Expense Account successfully updated',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['expenseAccounts'] })
            setSelectedExpenseAccountId('')
            setIsOpen(false)
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = 'Error occurred, please try again later'
            setSelectedExpenseAccountId('')
            setIsOpen(false)
            if (err.request.status === 409) {
                const responseData = err.response?.data as { message: string } | undefined
                const errorToastMessage = responseData?.message || defaultErrMessage
                toast({
                    description: `${errorToastMessage
                        .charAt(0)
                        .toUpperCase()}${errorToastMessage.slice(1)}`,
                    variant: 'default',
                })
            } else {
                toast({
                    description: defaultErrMessage,
                    variant: 'destructive',
                })
            }
        },
    })

    const handleCancelClick = () => {
        setIsOpen(false)
        setSelectedExpenseAccountId('')
    }

    useEffect(() => {
        setValue('expenseAccountId', expenseAccount.expenseAccountId)
        setValue('expenseAccountCode', expenseAccount.expenseAccountCode)
        setValue('expenseAccountName', expenseAccount.expenseAccountName)
    }, [setValue, expenseAccount])

    const onSubmit = (data: CreateExpenseAccountType) => {
        if (expenseNames.includes(data.expenseAccountName)) {
            setError('expenseAccountName', { message: 'Expense name already exist' })
            return
        }

        updateExpenseAccountMu(data)
    }

    return (
        <TableCell className='w-full' colSpan={3}>
            <Form {...updateExpenseAccountMethods}>
                <form className='flex flex-row w-full items-start justify-start text-zentive-black text-base space-x-16 px-4 mr-24 transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted whitespace-nowrap'>
                    <div>
                        <FormField
                            control={control}
                            name='expenseAccountCode'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            className='font-sans pl-1 text-base h-11 w-[350px] max-w-[350px] disabled:bg-gray-100 rounded-sm'
                                            type='number'
                                            placeholder='Account Code'
                                            disabled
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div>
                        <FormField
                            control={control}
                            name='expenseAccountName'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            className='font-sans pl-1 text-base h-11 w-[400px] max-w-[400px rounded-sm'
                                            type='text'
                                            placeholder='Account Name*'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='flex flex-row gap-x-4'>
                        <Button
                            variant='secondary'
                            onClick={() => handleCancelClick()}
                            className='w-28 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={updateLoading || isFormDirty() || !isValid}
                            onClick={handleSubmit(onSubmit)}
                            className='w-28 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Update
                        </Button>
                    </div>
                </form>
            </Form>
        </TableCell>
    )
}

export default UpdateExpenseAccount
