import poweredByStripe from '@/assets/public/Powered-by-Stripe.png'
import stripeCC from '@/assets/public/Edited-CC.png'

const StripeHeader = () => (
    <div className='flex flex-col items-center h-fit w-full gap-[16.72px]'>
        <img alt='Stripe CC Logo' className='h-[33.36px] w-[277.96px]' src={stripeCC} />
        <img
            alt='Powered by Stripe Logo'
            className='w-[122.63px] h-[27.8px]'
            src={poweredByStripe}
        />
    </div>
)

export default StripeHeader
