import { getDealStagesByBusinessId } from '@/api/deal_stage'
import { DealStage } from '@/api/deal_stage/schema'
import { SingleProductService } from '@/api/quoting/schema'
import { getProductsAndServices } from '@/api/service'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CSVLink } from 'react-csv'

const LeadCSVTemplate = () => {
    const currentUser = useViewedAsUser()
    const { businessId = '' } = currentUser

    const { data: dealStages, isSuccess } = useQuery({
        enabled: !!businessId,
        queryKey: ['dealStages', businessId],
        queryFn: () => getDealStagesByBusinessId(businessId),
    })

    const { data: items, isSuccess: isProductsAndServiecsSuccess } = useQuery<
        SingleProductService[],
        AxiosError
    >({
        enabled: !!businessId,
        queryKey: ['productsAndServices', businessId],
        queryFn: () => getProductsAndServices(businessId),
    })

    let csvHeaders: string[] = []
    csvHeaders = [
        'First Name',
        'Last Name',
        'Email Address',
        'Contact Number',
        'Street 1',
        'Street 2',
        'City',
        'State',
        'Zip Code',
        'Deal Size',
        'Deal Stage Label',
        'Contact Type',
        'Source',
        'Product Service Types',
    ]

    const csvInputGuide = [
        {
            'First Name': 'John',
            'Last Name': 'Doe',
            'Email Address': 'johndoe@gmail.com',
            'Contact Number': '122518836',
            'Street 1': 'Street Parking',
            'Street 2': '',
            City: 'Los Angeles',
            State: 'California',
            'Zip Code': '90027',
            'Deal Size': '500',
            'Deal Stage Label': dealStages?.[0]?.label as string,
            'Contact Type': 'Company',
            Source: 'Facebook',
            'Product Service Types': 'Lawn Mowing, Roof Cleaning',
        },
    ]

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': '',
    })

    csvInputGuide.push({
        'First Name': 'Input guide for Deal Stage ID and Product Service Type IDs',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': 'Delete everything from input guide when saving',
    })

    csvInputGuide.push({
        'First Name': 'Copy and paste the chosen deal stage label below for Deal Stage Label column',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': '',
    })

    if (isSuccess && dealStages?.length > 0) {
        dealStages.forEach((stage: DealStage) => {
            csvInputGuide.push({
                'First Name': stage.label,
                'Last Name': '',
                'Email Address': '',
                'Contact Number': '',
                'Street 1': '',
                'Street 2': '',
                City: '',
                State: '',
                'Zip Code': '',
                'Deal Size': '',
                'Deal Stage Label': '',
                'Contact Type': '',
                Source: '',
                'Product Service Types': '',
            })
        })
    }

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': '',
    })

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': '',
    })

    csvInputGuide.push({
        'First Name':
            'Copy and paste the chosen ids below for Product Service Types column. Example: Roof Cleaning, Lawn Mowing',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage Label': '',
        'Contact Type': '',
        Source: '',
        'Product Service Types': '',
    })

    if (isProductsAndServiecsSuccess && items?.length > 0) {
        items.forEach((item: SingleProductService) => {
            csvInputGuide.push({
                'First Name': item.name as string,
                'Last Name': '',
                'Email Address': '',
                'Contact Number': '',
                'Street 1': '',
                'Street 2': '',
                City: '',
                State: '',
                'Zip Code': '',
                'Deal Size': '',
                'Deal Stage Label': '',
                'Contact Type': '',
                Source: '',
                'Product Service Types': '',
            })
        })
    }

    return (
        <CSVLink
            data={csvInputGuide}
            headers={csvHeaders}
            filename={'import-lead-template.csv'}
            className='relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
        >
            Download CSV Template
        </CSVLink>
    )
}

export default LeadCSVTemplate
