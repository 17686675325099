import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import {
    FullProfileType,
    mailChimpIntegrationSchema,
    MailChimpIntegrationType,
} from '@/api/profile/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mailChimpIntegration } from '@/api/profile'
import { AxiosError } from 'axios'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { MAIL_CHIMP_INTEGRATION } from '@/constants'
import { useToast } from '@/hooks/useToast'

interface MailChimpIntegrationModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    mode: 'update' | 'create'
}

const MailChimpIntegrationModal: React.FC<MailChimpIntegrationModalProps> = ({ setOpen, open }) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const { toast } = useToast()

    const methods = useForm<MailChimpIntegrationType>({
        defaultValues: {
            businessId: user?.businessId,
        },
        mode: 'onChange',
        resolver: zodResolver(mailChimpIntegrationSchema),
    })

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = methods

    const handleClose = () => {
        setOpen(false)
        methods.resetField('apiKey')
        methods.resetField('listId')
        methods.clearErrors()
    }

    const queryClient = useQueryClient()

    const { mutate: mailChimpIntegrationMu, isPending: isLoading } = useMutation<
        string,
        AxiosError,
        MailChimpIntegrationType
    >({
        mutationFn: mailChimpIntegration,
        onSuccess: (data) => {
            if (
                data === MAIL_CHIMP_INTEGRATION.invalidCredentials ||
                data === MAIL_CHIMP_INTEGRATION.invalidApiKey
            ) {
                methods.setError('apiKey', { message: 'Invalid API key' })
            } else if (data === MAIL_CHIMP_INTEGRATION.invalidListId) {
                methods.setError('listId', { message: 'Invalid list ID' })
            } else if (data === MAIL_CHIMP_INTEGRATION.conflictingApiKey) {
                methods.setError('apiKey', { message: 'API key already used by other user' })
            } else if (data === MAIL_CHIMP_INTEGRATION.success) {
                toast({
                    description: 'Mailchimp integrated successfuly',
                    duration: 2000,
                })
                queryClient.invalidateQueries({ queryKey: ['mcCreds'] })
                setOpen(false)
            } else {
                toast({
                    description: 'Error integrating mailchimp',
                    duration: 2000,
                    variant: 'destructive',
                })
            }
        },
    })

    const onSubmit = (data: MailChimpIntegrationType) => {
        mailChimpIntegrationMu(data)
    }

    return (
        <Form {...methods}>
            <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
                <AlertDialogContent className='bg-white h-[285px] w-[377px] rounded-[15px] p-0'>
                    <div className='flex flex-col gap-5 p-6'>
                        <div className='overflow-auto'>
                            <AlertDialogTitle className='text-center font-semibold text-2xl text-gray-900 mt-2.5'>
                                MailChimp Integration
                            </AlertDialogTitle>
                        </div>
                        <FormField
                            control={control}
                            name='apiKey'
                            render={({ field }) => (
                                <FormItem className='col-span-3'>
                                    <FormControl>
                                        <Input
                                            className='w-full'
                                            placeholder='API Key*'
                                            type='text'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name='listId'
                            render={({ field }) => (
                                <FormItem className='col-span-3'>
                                    <FormControl>
                                        <Input
                                            className='w-full'
                                            placeholder='Audience ID*'
                                            type='text'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pb-4 pt-1.5 w-full'>
                        <div className='w-full flex justify-center items-center gap-4'>
                            <Button
                                onClick={handleClose}
                                className='w-97 h-11 text-base font-semibold'
                                variant={'outline'}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                onClick={handleSubmit(onSubmit)}
                                disabled={!isValid || isLoading}
                                className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                            >
                                Submit
                            </Button>
                        </div>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Form>
    )
}

export default MailChimpIntegrationModal
