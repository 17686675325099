import { signOut } from '@/api/auth';
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'

const ActionButton = ({ disabled, isLoading }: { disabled: boolean; isLoading: boolean }) => {
    const navigate = useNavigate()
    return (
        <div className='flex gap-3 justify-end bg-zentive-gray-light rounded-b-[15px]'>
            <Button
                disabled={isLoading}
                onClick={() => {
                    signOut()
                    navigate('/')
                }}
                className='w-97 text-zentive-green-dark font-semibold text-lg'
                variant={'outline'}
                type='button'
            >
                Cancel
            </Button>
            <Button className='w-97' type='submit' disabled={!disabled}>
                Update
            </Button>
        </div>
    )
}

export default ActionButton
