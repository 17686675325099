import InvoiceTable from './InvoiceTable'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const CPInvoice = () => {
    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Invoices' />

            <InvoiceTable />
        </div>
    )
}
export default CPInvoice
