import { Card, CardContent, CardHeader } from '@/components/Card'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { formatTo24HourClock, formatToDateTimeWithUTC } from '@/utils/time'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { jobDateAtom } from '@/store/job'
import dayjs from 'dayjs'
import { ISO_8601 } from '@/constants'
import { Switch } from '@/components/Switch'
import { OneJobDatePicker } from '@/components/OneOffJobDatePicker'

// Transfer to dedicated store/jobs.ts once functional
// no need to transfer if jobDateAtom is only used here and not being reused

export const OneOffJobSchedule = () => {
    const [jobDate, setJobDate] = useAtom(jobDateAtom)
    const {
        control,
        formState: { errors },
        getValues,
        setValue,
    } = useFormContext()

    const [availableAnytime, setAvailableAnytime] = useState<boolean>(false)

    const handleJobDateChange = (date: Date | undefined) => {
        if (date) {
            const value = dayjs(date).format(ISO_8601)
            setJobDate(value)
        }
    }

    useEffect(() => {
        const onChange =
            (field: 'appointment.startDateTime' | 'appointment.endDateTime') => (value: string) =>
                setValue(field, value, { shouldValidate: true })

        const startDateTime = formatTo24HourClock(getValues('appointment.startDateTime'))
        const endDateTime = formatTo24HourClock(getValues('appointment.endDateTime'))

        formatToDateTimeWithUTC(jobDate, startDateTime, onChange('appointment.startDateTime'))
        formatToDateTimeWithUTC(jobDate, endDateTime, onChange('appointment.endDateTime'))
    }, [jobDate])

    useEffect(() => {
        setValue('appointment.availableAnytime', availableAnytime)
    }, [availableAnytime])

    return (
        <Card className='mt-7 rounded-none'>
            <CardHeader className='bg-zentive-bg-100'>
                <h1 className='font-bold text-lg'>One-Off Job</h1>
            </CardHeader>
            <CardContent className='flex flex-col gap-5 my-10'>
                <div className='flex flex-col gap-[8px]'>
                    <OneJobDatePicker setJobDate={handleJobDateChange} jobDate={jobDate} />
                    {errors?.jobDate?.message ? (
                        <p className='text-base text-zentive-red-dark'>
                            {String(errors.jobDate.message)}
                        </p>
                    ) : null}
                </div>

                <div className='flex flex-row justify-between'>
                    <p className='text-zentive-black font-semibold'>Available Anytime</p>
                    <Switch
                        className='mt-[.20rem]'
                        onCheckedChange={() => setAvailableAnytime(!availableAnytime)}
                    />
                </div>

                <p className='text-zentive-black-medium font-bold'>Preferred Time</p>

                <div className='flex flex-row gap-5 w-full'>
                    <FormField
                        control={control}
                        name='appointment.startDateTime'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='relative font-sans pl-3 text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                        type='time'
                                        placeholder='Start Time'
                                        {...field}
                                        onChange={(evt) => {
                                            formatToDateTimeWithUTC(
                                                jobDate,
                                                evt.target.value,
                                                field.onChange,
                                            )
                                        }}
                                        value={formatTo24HourClock(field.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') e.preventDefault()
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={control}
                        name='appointment.endDateTime'
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input
                                        className='font-sans pl-3 text-base h-11 border border-gray-400 disabled:bg-[#EBEBEB] rounded-sm'
                                        type='time'
                                        placeholder='End Time'
                                        {...field}
                                        onChange={(evt) =>
                                            formatToDateTimeWithUTC(
                                                jobDate,
                                                evt.target.value,
                                                field.onChange,
                                            )
                                        }
                                        value={formatTo24HourClock(field.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') e.preventDefault()
                                        }}
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div>
                    <h1 className='font-bold text-base'>Invoice</h1>
                    <p>Upon Completion</p>
                </div>
            </CardContent>
        </Card>
    )
}
