import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import BaseEmployee from '../../BaseEmployee'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getNextEmployeeNumber } from '@/api/employee'
import { AxiosError } from 'axios'
import { addUserImage, inviteEmployee } from '@/api/profile'
import { useAtom, useAtomValue } from 'jotai'
import { FullProfileType, InviteEmployeeType, employeeProfileSchema } from '@/api/profile/schema'
import { useToast } from '@/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import { employeeImgAtom } from '@/store/employee'
import { checkSignUpValidity } from '@/api/auth'
import { SignupValidityType } from '@/api/auth/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import BreadCrumbs from '@/components/BreadCrumbs'
import dayjs from 'dayjs'
import { ISO_8601 } from '@/constants'

const CreateEmployee: FC = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { toast } = useToast()
    const [selectedImageAtom, setSelectedImageAtom] = useAtom(employeeImgAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)

    const currentUser = useAtomValue(
        isViewedAsOwner ? viewedAsUserAtom : userAtom,
    ) as FullProfileType

    const { data: nextEmployeeNumber } = useQuery({
        queryKey: ['nextEmpNumber'],
        queryFn: () => getNextEmployeeNumber(currentUser.businessId),
    })

    const createEmployeeMethods = useForm<InviteEmployeeType>({
        mode: 'onChange',
        resolver: zodResolver(employeeProfileSchema),
    })

    const {
        handleSubmit,
        setError,
        setValue,
        unregister,
        watch,
        formState: { isSubmitSuccessful },
    } = createEmployeeMethods

    const profileImage = watch('profileImage')

    const formValues = watch()

    const handleSuccessCreate = () => {
        toast({
            description: 'Successfully Saved',
            variant: 'default',
        })
        setSelectedImageAtom([])
        navigate('/schedule/employee')
    }

    const { mutate: createEmployeeMu } = useMutation({
        mutationFn: inviteEmployee,
        onError: (err: AxiosError) => console.log('Create Employee Error: ', err.message),
        onSuccess: (data) => {
            if (selectedImageAtom[0]?.file) {
                addUserImage({
                    profileId: data.content.id,
                    profileImage: selectedImageAtom[0].file,
                }).catch((err) => console.error('Error uploading image', err))
            }
            handleSuccessCreate()
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
            queryClient.invalidateQueries({ queryKey: ['subSeat'] })
        },
    })

    const { mutate: checkValidityMu } = useMutation({
        mutationFn: checkSignUpValidity,
        onSuccess: (data: SignupValidityType) => {
            if (data.isEmailExist) {
                setError('email', { message: 'Email address already exists.' })
            }
            if (data.isPhoneNumberExist) {
                setError('phoneNumber', { message: 'Phone number already exists' })
            }
            if (!data.isEmailExist && !data.isPhoneNumberExist) {
                createEmployeeMu(formValues)
            }
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const onSubmit = (data: InviteEmployeeType) =>
        checkValidityMu({ email: data.email, phoneNumber: data.phoneNumber })

    useEffect(() => {
        setValue('businessId', currentUser?.businessId as string)
        setValue('dateHired', dayjs().format(ISO_8601))
        setValue('employeeNumber', String(nextEmployeeNumber))
    }, [nextEmployeeNumber])

    useEffect(() => {
        if (isSubmitSuccessful && profileImage) {
            unregister('profileImage')
        }
    }, [isSubmitSuccessful, profileImage])

    return (
        <Form {...createEmployeeMethods}>
            <BreadCrumbs titleName='Add Employee' containerClassName='mb-5' />
            <form onSubmit={handleSubmit(onSubmit)}>
                <BaseEmployee mode='create' />
            </form>
        </Form>
    )
}

export default CreateEmployee
