import { getInvoiceList } from '@/api/profile'
import { FullProfileType, SubscriptionListType } from '@/api/profile/schema'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { isViewedAsAtom, viewedAsUserAtom } from '@/store/auth'
import { ROLE } from '@/constants'
import { userAtom } from '@/store/auth'
import { cn, displayCurrency, unixToLocalDate } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { PaginationType } from '@/components/Pagination/schema'
import { useState } from 'react'
import { Pagination } from '@/components/Pagination'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'

const PaymentHistory = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const isCustomer = user?.role.roleName === ROLE.CUSTOMER

    const { data: subscriptionsData, isLoading } = useQuery<
        unknown,
        AxiosError,
        SubscriptionListType
    >({
        queryKey: ['subscriptionInvoiceList', user, pagination],
        queryFn: () => getInvoiceList(user?.profileId as string, pagination),
    })

    return (
        <section>
            {!isCustomer && (
                <div className='bg-white pt-5 pb-7 px-5'>
                    <Table>
                        <TableHeader>
                            <TableRow className='whitespace-nowrap'>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Date
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Product
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Reference
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Amount
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Discount Code
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Discounted Price
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Status
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {isLoading && (
                                <TableRow className='whitespace-nowrap'>
                                    <TableCell
                                        className='flex-row items-start text-start  text-[#191A0A] text-[16px]'
                                        colSpan={7}
                                    >
                                        <AppSkeletonLoadingState />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!isLoading && (
                                <>
                                    {subscriptionsData?.content?.map((data) => (
                                        <TableRow
                                            key={data.subscriptionId}
                                            className='whitespace-nowrap'
                                        >
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {unixToLocalDate(data.created)}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {data.planName}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {data.referenceNumber}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {displayCurrency(data.price / 100)}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {data.discountCode === ''
                                                    ? 'N/A'
                                                    : data.discountCode}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                                {displayCurrency(data.discountedPrice ?? 0)}
                                            </TableCell>
                                            <TableCell
                                                className={cn(
                                                    data.status === 'paid'
                                                        ? 'text-zentive-green-dark'
                                                        : '',
                                                    'flex-row items-start text-start text-[16px]',
                                                )}
                                            >
                                                {data.status === 'paid' ? 'Successful' : 'Unpaid'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
            {subscriptionsData && subscriptionsData?.content?.length > 0 && (
                <div className='mt-5'>
                    <Pagination
                        itemsPerPage={subscriptionsData?.meta?.pageSize ?? 0}
                        totalRecords={subscriptionsData?.meta?.totalRecords ?? 0}
                        setPagination={setPagination}
                        pagination={pagination}
                    />
                </div>
            )}
        </section>
    )
}

export default PaymentHistory
