import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useAtom, useAtomValue } from 'jotai'
import { getAllCrews } from '@/api/crew'
import { USER_STATUS } from '@/constants'
import { Button } from '@/components/Button'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { useState, ChangeEvent } from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { formatRecordNumber } from '@/utils/helper'
import { FullProfileType } from '@/api/profile/schema'
import ConflictingScheduleModal from '@/components/ConflictingScheduleModal'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { AlertDialogContent, AlertDialogFooter, AlertDialogTitle } from '@/components/AlertDialog'
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@/components/Accordion'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import bullet from '@/assets/private/bullet.svg'
import { crewPaginationAtom } from '@/store/paginationAtom'

interface SelectCrewProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectCrew: React.FC<SelectCrewProps> = ({ setOpen }) => {
    const { setValue } = useFormContext()
    const [searchValue, setSearchValue] = useState('')
    const [selectedCrewId, setSelectedCrewId] = useState<string | null>(null)
    const [conflictModal, setConflictModal] = useState<boolean>(false)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [pagination, setPagination] = useAtom(crewPaginationAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const { data, isLoading } = useQuery({
        queryKey: ['crews', pagination, searchValue, user?.businessId],
        queryFn: () =>
            getAllCrews(
                '',
                USER_STATUS.ACTIVATED,
                user?.businessId as string,
                { sortBy: '', sortOrder: '' },
                {
                    page: pagination.page,
                    pageSize: pagination.pageSize,
                    itemsPerPage: pagination.itemsPerPage,
                },
            ),
        enabled: !!user?.businessId,
    })

    const handleSave = () => {
        setValue('crewId', selectedCrewId as string, {
            shouldValidate: true,
        })
        setOpen(false)
    }

    const crews = data?.content || []

    const handleSetCrewId = (crewId: string) => {
        setSelectedCrewId(crewId)
    }

    if (isLoading) {
        return null
    }

    return (
        <AlertDialogContent className='flex flex-col h-[500px] w-[704px] outline-none rounded-[13px] py-[44px] px-[37px] shadow-none border-none overflow-y-auto'>
            <ConflictingScheduleModal setOpen={setConflictModal} open={conflictModal} />
            <AlertDialogTitle>
                <h1 className='text-left font-semibold text-2xl text-zentive-black'>Select Crew</h1>
            </AlertDialogTitle>
            <div className='font-light mb-4 w-[164px]'>
                <SearchBar placeHolder='Searching for...' onSearchChange={handleSearchChange} />
            </div>

            <div className='text-left text-base text-zentive-black overflow-y-auto pb-[3rem]'>
                {crews?.length ? (
                    <RadioGroup onValueChange={(val) => handleSetCrewId(val)}>
                        {crews?.map((crew) => {
                            return (
                                <div className='flex flex-row gap-[12px]' key={crew.crewId}>
                                    <RadioGroupItem
                                        checked={selectedCrewId === crew.crewId}
                                        value={crew.crewId}
                                        className='mt-5'
                                    />
                                    <Accordion
                                        type='single'
                                        className='w-full border p-2'
                                        collapsible
                                    >
                                        <AccordionItem
                                            value='item-1'
                                            className='border border-1 p-1'
                                        >
                                            <AccordionTrigger className='h-5 font-semibold'>
                                                {formatRecordNumber(crew.crewNumber)}
                                            </AccordionTrigger>
                                            <AccordionContent>
                                                <ul className='pl-3 text-base text-zentive-gray-dark'>
                                                    {
                                                        <li
                                                            key={
                                                                crew?.foreMan?.crewProfile
                                                                    ?.profileId
                                                            }
                                                        >
                                                            <img
                                                                src={bullet}
                                                                alt='bullet'
                                                                className=' h-[6px] w-[6px] -ml-[12px] mt-2 -mb-[12px]'
                                                            />
                                                            {crew?.foreMan?.crewProfile?.firstName}{' '}
                                                            {crew?.foreMan?.crewProfile?.lastName}
                                                        </li>
                                                    }
                                                    {crew.members?.map((member) => (
                                                        <li key={member.crewMemberId}>
                                                            <img
                                                                src={bullet}
                                                                alt='bullet'
                                                                className=' h-[6px] w-[6px] -ml-[12px] mt-2 -mb-[13px]'
                                                            />
                                                            {member?.crewProfile?.firstName}{' '}
                                                            {member?.crewProfile?.lastName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            )
                        })}
                    </RadioGroup>
                ) : (
                    <div className='bg-white w-auto rounded-sm'>
                        <div className='mt-4 w-[1260px]'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-6 h-24'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'>Currently Empty</p>
                    </div>
                )}
            </div>
            <div className='mb-12 -pt-4 h-[40px] flex'>
                {pagination.pageSize !== undefined && (
                    <Pagination
                        itemsPerPage={pagination.pageSize}
                        setPagination={setPagination}
                        totalRecords={data?.meta?.totalRecords ?? 1}
                        pagination={pagination}
                    />
                )}
            </div>

            <AlertDialogFooter className='absolute left-0 bottom-0 h-[84px] w-full pr-[26px] bg-zentive-gray-light rounded-b-[13px] flex flex-row gap-[16px] items-center justify-end'>
                <Button
                    className='h-[53px] w-[96px]'
                    variant='outline'
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>

                <Button
                    className='h-[53px] w-[100px]'
                    disabled={!selectedCrewId}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </AlertDialogFooter>
        </AlertDialogContent>
    )
}

export default SelectCrew
