import { Skeleton } from '@/components/Skeleton'
import { SKLTN_PAYMENT_BTN_STYLE } from '@/constants'

const SkeletonActionButtons = () => (
    <div className='flex items-center justify-between gap-[16px] w-full h-full'>
        <Skeleton className={SKLTN_PAYMENT_BTN_STYLE} />
        <Skeleton className={SKLTN_PAYMENT_BTN_STYLE} />
    </div>
)

export default SkeletonActionButtons
