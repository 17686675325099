import BreadCrumbs from '@/components/BreadCrumbs'
import { ExpenseReportTabs } from './ExpenseReportTabs'
import { expenseReportFilterAtom, expenseReportTabsAtom } from '@/store/reports'
import { useAtomValue, useSetAtom } from 'jotai'
import { EXPENSE_REPORT_TABS, FEATURE_RESTRICTIONS } from '@/constants'
import InternalExpenseTable from './InternalExpenseTable'
import ExternalExpenseTable from './ExternalExpenseTable'
import { useEffect } from 'react'
import { ExpenseReportFilter } from './ExpenseReportFilter'
import { useQuery } from '@tanstack/react-query'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { AxiosError } from 'axios'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { getSubscriptions } from '@/api/profile'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import { DowngradeNotification } from '@/components/DowngradeNotification'
import { FeatureRestriction } from './FeatureRestriction'
import Spinner from '@/components/Spinner'
import useViewedAsUser from '@/hooks/useViewedAsUser'

export const ExpenseReport = () => {
    const user = useViewedAsUser()
    const tab = useAtomValue(expenseReportTabsAtom)
    const setFilter = useSetAtom(expenseReportFilterAtom)

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['expenseReportFeatureSubscription', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.expenseTracking,
            ),
    })

    const { data: subscriptionsData, isLoading: subsDateLoading } = useQuery<
        unknown,
        AxiosError,
        SubscriptionsInfoType[]
    >({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(user?.customerId as string),
        enabled: !!user?.customerId,
    })

    const isFirstPlan: boolean = (subscriptionsData?.length ?? 0) <= 1

    useEffect(() => {
        setFilter((prev) => {
            if (!prev) {
                return {
                    start: '',
                    end: '',
                    keyword: '',
                    type: tab,
                }
            }

            return {
                ...prev,
                type: tab,
            }
        })
    }, [tab, setFilter])

    if (featureLoading || subsDateLoading) {
        return <Spinner variant={'normal'} />
    }

    const showRestriction = !isFeatureAvailable

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <div className='flex flex-row gap-5'>
                <BreadCrumbs titleName='Expenses' />
                {showRestriction && (
                    <span className='-mt-1'>
                        <UpgradeBadge />
                    </span>
                )}
            </div>
            {showRestriction && !isFirstPlan && <DowngradeNotification />}
            {showRestriction ? (
                <FeatureRestriction />
            ) : (
                <>
                    <ExpenseReportFilter />
                    <ExpenseReportTabs />
                    {tab === EXPENSE_REPORT_TABS.internal ? (
                        <InternalExpenseTable />
                    ) : (
                        <ExternalExpenseTable />
                    )}
                </>
            )}
        </div>
    )
}
