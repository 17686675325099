import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { UseMutateFunction } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { BulkDeleteRouteType } from '@/api/routing/schema'

interface RestoreModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    handleClick: UseMutateFunction<
        unknown,
        AxiosError<unknown, any>,
        {
            businessId: string
            routeIds: string[]
        },
        unknown
    >
    routesToDelete: BulkDeleteRouteType | null
}

const BulkDeleteRouteModal: React.FC<RestoreModalProps> = ({
    setOpen,
    open,
    handleClick,
    routesToDelete,
}) => {
    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Delete routes?
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            This action is irreversible and will remove all selected routes.
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-[155px] h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            onClick={() => handleClick(routesToDelete as BulkDeleteRouteType)}
                            className='w-[155px] h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default BulkDeleteRouteModal
